import React from "react";
import "./Loader.scss";
import "styled-components/macro";
import { useTheme } from "src/customHooks";
import styled from "styled-components";

const SpinLoader = ({
  proposalPage,
  customHeight,
  customWidth,
  zIndexGiven,
}) => {
  const { colors } = useTheme();

  const PrimaryColor = colors.primary_color;

  return (
    <SpinLoaderWrapper
      className="loader"
      proposalPage={proposalPage}
      customWidth={customWidth}
      zIndexGiven={zIndexGiven}
    >
      <div
        className={!proposalPage ? "spinner" : "proposal"}
        css={`
          height: ${customHeight} !important ;
          & > div {
            background-color: ${PrimaryColor};
          }
        `}
      >
        <div className="bounce1"></div>
        <div className="bounce2"></div>
        <div className="bounce3"></div>
      </div>
    </SpinLoaderWrapper>
  );
};

export default SpinLoader;

const SpinLoaderWrapper = styled.div`
  position: ${({ proposalPage }) => proposalPage && "unset !important"};
  width: ${({ customWidth }) => customWidth && `${customWidth} !important`};
  display: inline !important;
  z-index: ${({ zIndexGiven }) => zIndexGiven};
`;
