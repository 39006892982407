import FHTypography from "src/styles/Typography";
import styled from "styled-components";
import useEditLocationStep from "../hooks/useEditLocationStep";
import {
  FLEX_COL,
  FLEX_ROW,
  FLEX_ROW_CENTER,
  FONT,
  FWEIGHT,
  ROUNDED,
  SHADES,
  SHADOW,
} from "src/styles/styleGuide";
import { Fragment } from "react";
import { useTheme } from "src/customHooks";
import TextInput2 from "src/components/TextInput2";
import { FaRegTimesCircle } from "react-icons/fa";
import { RiLoader4Fill } from "react-icons/ri";
import { useResponsiveCondition } from "src/pos/hooks";
import { DEVICES, VIEW } from "src/styles/responsiveStyle";
import { PopularCity } from "src/pages/InputPage/components/LocationEditForm";
import { stringLengthCutter } from "src/utils/string.utility";
function EditLocationStep({ setEditStep, onClose }) {
  const { width, breakpoint } = useResponsiveCondition(DEVICES.tabletBIG);
  const {
    activeGroup,
    groupsName,
    isFetching,
    locationData,
    pincodeValue,
    pincodeError,
    popularcities,
    updateEnquiryQuery,
    setPincodeError,
    setPincodeValue,
    setActiveGroup,
    handleUpdateLocation,
  } = useEditLocationStep();
  const { colors } = useTheme();

  const showLocationModal =
    locationData?.length === 1 && pincodeValue === locationData[0].pincode;
  return (
    <EditLocationStepContainerStyled colors={colors}>
      <FHTypography variant="h5" weight="semi" className="location_edit_head">
        Tell Us Where {activeGroup?.groupTitle} Live
      </FHTypography>
      <section className="location_edit_groups">
        {groupsName?.map(group => {
          if (group.type === "all") return <Fragment key={group.id}></Fragment>;
          return (
            <button
              className={FONT.subheading1}
              data-set-active={activeGroup?.id == group.id && "active"}
              key={group.id}
              onClick={() => {
                setPincodeError("");
                setActiveGroup(group);
              }}
              disabled={!activeGroup?.pincode}
            >
              {activeGroup.groupName === group.groupName
                ? group.groupName
                : stringLengthCutter(group.groupName, 15)}
            </button>
          );
        })}
      </section>

      <section className="location_edit_pincode">
        <TextInput2
          label="Enter Pincode"
          value={pincodeValue}
          onChange={e => {
            setPincodeError("");
            setPincodeValue(e.target.value);
          }}
        />
        {activeGroup?.pincode === pincodeValue && (
          <FHTypography
            variant="subheading2"
            weight="md"
            color={colors.secondary_color}
          >
            You have already entered the Pincode/City. Do you want to edit?
          </FHTypography>
        )}
        {activeGroup?.pincode && (
          <button
            className="pincode_clear"
            onClick={() => {
              setPincodeValue("");
            }}
          >
            <FaRegTimesCircle size={width > breakpoint ? 25 : 22} />
          </button>
        )}

        <section>
          {pincodeError && (
            <FHTypography variant="subheading2" weight="md" type="error">
              {pincodeError}
            </FHTypography>
          )}
        </section>

        {pincodeValue.length > 2 &&
          pincodeValue.length < 7 &&
          !showLocationModal && (
            <ul className="location_fetched_modal">
              {!isFetching ? (
                locationData?.length > 0 &&
                locationData.map(location => {
                  return (
                    <li
                      key={location.pincode}
                      onClick={() => {
                        setPincodeValue(location.pincode);
                      }}
                    >
                      <FHTypography variant="subheading1" weight="semi">
                        {location.city}
                      </FHTypography>
                    </li>
                  );
                })
              ) : (
                <li>
                  <FHTypography variant="subheading1" weight="base">
                    fetching locations ...
                  </FHTypography>
                </li>
              )}
            </ul>
          )}
      </section>

      {/* add popular cities */}
      <section className="location_popular_cities">
        {popularcities.map(cityUnit => {
          return (
            <PopularCity
              onChange={city => {
                setPincodeValue(city.pincode);
                handleUpdateLocation({
                  pincodeValue: city.pincode,
                  onClose,
                  setEditStep,
                });
              }}
              key={cityUnit.name}
              city={cityUnit}
              checked={cityUnit.pincode === pincodeValue}
            />
          );
        })}
      </section>

      <section className="location_edit_actions">
        <button
          className={`actions_back ${FONT.h5} ${
            !activeGroup?.pincode && "disableElement"
          }`}
          onClick={() => setEditStep(1)}
          disabled={!activeGroup?.pincode}
        >
          Back
        </button>
        <button
          className={`actions_continue ${FONT.h5}`}
          onClick={() => {
            if (updateEnquiryQuery.isLoading) return;
            handleUpdateLocation({ pincodeValue, onClose, setEditStep });
          }}
        >
          Continue
          {updateEnquiryQuery.isLoading && (
            <RiLoader4Fill className="rotate" size={25} />
          )}
        </button>
      </section>
    </EditLocationStepContainerStyled>
  );
}

const EditLocationStepContainerStyled = styled.div`
  padding: 2rem;
  ${FLEX_COL};
  align-items: center;

  .location_edit_head {
    text-transform: capitalize;
  }

  .location_edit_groups {
    padding: 1rem;
    ${FLEX_ROW_CENTER};
    gap: 1rem;
    button {
      padding: 0.5rem 1rem;
      min-width: 6rem;
      border-radius: ${ROUNDED.full};
      background-color: ${SHADES.WHITE};
      color: ${SHADES.SUB_HEADING};
      text-transform: capitalize;
      font-weight: ${FWEIGHT.semi};

      text-overflow: ellipsis;
      white-space: nowrap;
      position: relative;

      &[data-set-active="active"] {
        background-color: ${({ colors }) => colors.primary_color};
        color: ${SHADES.WHITE};

        ::before {
          content: ".";
          position: absolute;
          top: 100%;
          left: 50%;
          transform: translateX(-50%);
          width: 0;
          height: 0;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          border-top: 8px solid ${({ colors }) => colors.primary_color};
        }
      }
    }
  }

  .location_edit_pincode {
    padding: 1rem;
    max-width: 30rem;
    width: 100%;
    position: relative;
    ${FLEX_COL};
    gap: 0.5rem;

    .pincode_clear {
      position: absolute;
      top: 40%;
      right: 2rem;
      transform: translateY(-50%);

      ${VIEW.md} {
        top: 45%;
      }
    }
  }

  .location_fetched_modal {
    position: absolute;
    top: 90%;
    width: 95%;
    background-color: ${SHADES.WHITE};
    border-radius: ${ROUNDED.base};
    box-shadow: ${SHADOW.md};
    overflow: hidden;
    ${FLEX_COL};
    z-index: 1;

    li {
      padding: 1rem;
      transition: all 0.1s ease;
      cursor: pointer;
      :hover {
        background-color: ${({ colors }) => colors.primary_shade};
        color: ${SHADES.HEADING};
      }

      :not(:last-child) {
        border-bottom: 1px solid ${SHADES.DARK_GRAY};
      }
    }
  }

  .location_popular_cities {
    ${FLEX_ROW};
    flex-wrap: wrap;
    gap: 0.8rem;
    max-width: 25rem;
    justify-content: center;
  }

  .location_edit_actions {
    padding: 1rem;
    padding-top: 3rem;
    ${FLEX_ROW};
    align-items: center;
    justify-content: space-between;
    max-width: 30rem;
    width: 100%;

    button {
      width: 8rem;
      padding: 0.8rem 0;
      border-radius: ${ROUNDED.lg};
      background-color: ${SHADES.WHITE};
      font-weight: ${FWEIGHT.semi};
    }
    .actions_continue {
      background-color: ${({ colors }) => colors.primary_color};
      color: ${SHADES.WHITE};
    }

    .disableElement {
      opacity: 0.9;
      cursor: not-allowed;
    }
  }
`;

export default EditLocationStep;
