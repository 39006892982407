import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "src/components/Common/Button/ContinueButton";
import { useMembers, useTheme, useUpdateEnquiry } from "src/customHooks";
import { useGetEnquiry } from "src/customHooks/useGetCommonQueries";
import {
  Group,
  GroupWrapper,
  PortExpiryContainerStyled,
} from "src/pages/InputPage/components/PortExpiryDateForm";
import { PortDatePicker } from "src/pages/InputPage/components/PortabilityForm";
import { setShowEditMembers } from "src/pages/NewQuotePage/QuotePageSlice/quote.slice";
import FHTypography from "src/styles/Typography";
import { FONT } from "src/styles/styleGuide";
import { getFormattedDate } from "src/utils/helper";

const EditPortExpiryDate = ({
  goToLocationStep = () => {},
  onClose = () => {},
}) => {
  const dispatch = useDispatch();

  const { groups } = useGetEnquiry();
  const { colors } = useTheme();
  const {
    getGroupPortExpiryDate,
    getGroupMembers,
    getMembersText,
    getPreviousGroup,
    getNextGroup,
    getGroup,
  } = useMembers();

  const { updateEnquiry } = useUpdateEnquiry();

  const [loader, setLoader] = useState({
    continueLoader: false,
  });

  const updatedGroups = groups?.filter(group => group?.type !== "all");

  const [currentGroupCode, setCurrentGroupCode] = useState("");

  const [portExpiryDate, setPortExpiryDate] = useState(
    getGroupPortExpiryDate(currentGroupCode),
  );

  const currentGroup = getGroup(currentGroupCode);

  const [error, setError] = useState(null);

  const membersText = getMembersText({ id: parseInt(currentGroupCode) });

  const groupMembers = getGroupMembers(parseInt(currentGroupCode));

  const isSelf = groupMembers?.some(member => member.code === "self");

  const nextGroup = getNextGroup(currentGroupCode);

  const previousGroup = getPreviousGroup(currentGroupCode);

  const goBack = () => {
    if (previousGroup) {
      setCurrentGroupCode(prev => prev - 1);
    } else {
      goToLocationStep(2);
    }
  };

  const handleSubmit = async ({ port_expiry_date, groupCode }) => {
    setLoader(current => ({
      ...current,
      continueLoader: true,
    }));
    try {
      await updateEnquiry({ port_expiry_date, groupCode });

      setLoader(current => ({
        ...current,
        continueLoader: false,
      }));

      if (nextGroup) {
        setCurrentGroupCode(prev => prev + 1);
      } else {
        onClose();
        return dispatch(setShowEditMembers(false));
      }
    } catch (error) {
      return setLoader(current => ({
        ...current,
        continueLoader: false,
      }));
    }
  };

  //   useEffects:-

  useEffect(() => {
    const nonExpiryDateGroup = groups.find(
      group => !group?.port_expiry_date && group.type !== "all",
    );
    nonExpiryDateGroup
      ? setCurrentGroupCode(nonExpiryDateGroup?.id)
      : setCurrentGroupCode(groups?.[0]?.id);
  }, []);

  useEffect(() => {
    setPortExpiryDate(getGroupPortExpiryDate(currentGroup?.id));
  }, [currentGroup?.id]);

  return (
    <>
      <PortExpiryContainerStyled colors={colors} isOnModal={true}>
        <div className="label">
          <FHTypography variant="h5" weight="semi" className="expriy_edit_head">
            Tell Us Your {isSelf ? "" : `${membersText}'s `} existing
            policy&apos;s expiry date
          </FHTypography>
        </div>
        <div className="group_members ">
          {updatedGroups?.map((group, index) => (
            <GroupWrapper
              key={group?.id}
              active={group?.id === currentGroupCode}
              color={colors.primary_color}
              onClick={() => setCurrentGroupCode(group?.id, index)}
            >
              <Group
                active={group?.id === currentGroupCode}
                color={colors.primary_color}
                shade={colors.secondary_shade}
              >
                {group?.members
                  ?.map(memberText => memberText.split("_").join("-"))
                  .join(", ")}
              </Group>
              <span className="pointer"></span>
            </GroupWrapper>
          ))}
        </div>
        <div className="input_wrapper">
          <div className="input_field">
            <PortDatePicker
              value={portExpiryDate}
              setValue={setPortExpiryDate}
              setError={setError}
            />
            {error?.groupCode === currentGroupCode && (
              <div className={"error_msg"}>
                <FHTypography variant="body1" weight="base" type="error">
                  {error?.error}
                </FHTypography>
              </div>
            )}
            <div className="portCTA">
              <div>
                <button className="back_button" onClick={goBack}>
                  <FHTypography variant="h5" weight="semi">
                    Back
                  </FHTypography>
                </button>
                <Button
                  className={`back_btn continue_btn ${FONT.h5} `}
                  loaderPrimaryColor
                  onClick={() =>
                    handleSubmit({
                      port_expiry_date: getFormattedDate(portExpiryDate),
                      groupCode: currentGroupCode,
                    })
                  }
                  disabled={error || !portExpiryDate}
                  arrow
                  loader={loader.continueLoader}
                  font="h5"
                >
                  Continue
                </Button>
              </div>
            </div>
          </div>
        </div>
      </PortExpiryContainerStyled>
    </>
  );
};

export default EditPortExpiryDate;
