import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { useCallApiMutation } from "src/api/api";
import { useFrontendBoot } from "src/customHooks/useGetCommonQueries";
import { useTheme } from "src/customHooks";
import {
  setCKYCReferenceId,
  setKYCRedirectionURL,
  setProposalRefetchConfirmationModal,
  setProposalRefetchModal,
  setRevalidateKYCVerificationUtility,
  setShowErrorPopup,
  setFailedAsyncPanNumber,
  setDocUploadUtility,
} from "../../ProposalSections/ProposalSections.slice";
import useAsyncTextField from "./useAsyncTextField";
import { FWEIGHT, ROUNDED } from "src/styles/styleGuide";
import { RiLoader4Fill } from "react-icons/ri";
import { useResponsiveCondition } from "src/pos/hooks";
import { stringLengthCutter } from "src/utils/string.utility";

const AsyncTextField = ({
  name = "",
  label = "",
  // placeholder = "",
  onChange = "",
  checkValidation = {},
  value = "",
  mode = "",
  maxLength = "",
  minLength = "",
  endpoint = "",
  textTransform = "",
  error = "",
  additionalPayload = [],
  values = {},
  asyncValidate,
  allow,
  buttonText = "Verify",
  failureMessage,
  onAsyncSuccess = () => {},
  onAsyncFailure = () => {},
}) => {
  const {
    input,
    status,
    setInput,
    setStatus,
    setFocused,
    inputErrorUtility,
    disableField,
    setDisableField,
  } = useAsyncTextField({
    initialValue: value,
    asyncValidate,
    allow,
  });

  const [triggerMutation, { isLoading }] = useCallApiMutation();
  const dispatch = useDispatch();
  const { subJourneyType } = useFrontendBoot();
  const {
    colors: { primary_color },
  } = useTheme();
  const { width, breakpoint } = useResponsiveCondition(430);
  const [disableInputOnly, setDisableInputOnly] = useState(false);
  const { proposalData = {}, docUploadUtility } = useSelector(
    state => state.proposalPage,
  );
  const additionalPayloadGenerator = ({
    additionalPayload = [],
    values = {},
  }) => {
    if (additionalPayload.length) {
      const requiredPayload = additionalPayload.filter(
        ({ required }) => required,
      );
      const allRequiredPayloadExist = requiredPayload.every(
        ({ key }) => values?.[key],
      );
      let adPayload = {};
      if (allRequiredPayloadExist) {
        additionalPayload.forEach(
          ({ key }) => (adPayload[key] = values?.[key]),
        );
      }
      return {
        disableVerify: !allRequiredPayloadExist,
        adPayload,
      };
    } else {
      return {
        disableVerify: false,
        adPayload: {},
      };
    }
  };

  const source = useRouteMatch({ path: "/productdetails" })
    ? "isProductDetails"
    : "isProposal";

  const { disableVerify, adPayload } = additionalPayloadGenerator({
    additionalPayload,
    values,
  });

  const disableSave =
    input.length < minLength ||
    input.length > maxLength ||
    disableVerify ||
    inputErrorUtility?.toShow ||
    disableField;

  const inputChangeHandler = e => {
    if (checkValidation?.matches === "onlyNumbers") {
      if (!/^[0-9]*$/.test(e.target.value)) {
        return;
      }
    } else if (checkValidation?.matches === "panNumber") {
      if (!/^[0-9a-zA-Z]*$/.test(e.target.value)) {
        return;
      }
    }
    if (maxLength && e.target.value.length > maxLength) return;

    if (textTransform === "uppercase") {
      setInput(e.target.value.toUpperCase());
      return;
    }

    setInput(e.target.value);
    return;
  };

  const saveClickHandler = e => {
    e.preventDefault();
    e.stopPropagation();

    let sourceObj =
      subJourneyType === "renewal"
        ? {
            source,
          }
        : {};
    onChange(
      {
        target: {
          value: "in-progress",
        },
      },
      "in-progress",
    );
    triggerMutation({
      url: endpoint,
      body: {
        payload: input,
        mode,
        additionalPayload: adPayload,
        ...sourceObj,
      },
      method: "POST",
    }).then(res => {
      if (res?.error) {
        setStatus("failure");
        onAsyncFailure();

        if (res?.error?.data?.data?.every(obj => !!obj?.redirection_url)) {
          let redirectionArray = [];
          if (res.error.data.data[0]?.revalidate_kyc_verification_until) {
            redirectionArray = [
              {
                ...res.error.data.data[0],
                revalidateUtility: {
                  revalidate_kyc_verification_until:
                    res.error.data.data[0].revalidate_kyc_verification_until,
                },
              },
            ];
          } else {
            redirectionArray = res.error.data.data;
          }
          dispatch(setKYCRedirectionURL(redirectionArray));
        }

        if (
          res?.error?.data?.data?.some(obj => !!obj?.redirect_after_proposal)
        ) {
          dispatch(
            setShowErrorPopup({
              show: true,
              head: "",
              msg: "You will be redirected to Insurance Company's website to complete the CKYC process after uploading documents.",
            }),
          );
        } else if (res?.error?.data?.data?.some(obj => !!obj?.message)) {
          let flag = false;
          res?.error?.data?.data?.map(item => {
            if (item?.message && !flag) {
              dispatch(
                setShowErrorPopup({
                  show: true,
                  head: "",
                  msg: item.message,
                }),
              );
              flag = true;
            }
          });
        } else {
          toast.error(
            failureMessage ? failureMessage : `Failed to verify ${label}.`,
            {
              duration: 5000,
              position: "top-center",
              style: {
                fontWeight: "bold",
                padding: "25px 40px",
              },
            },
          );
        }

        if (res?.error?.data?.data?.some(obj => !!obj?.show_document_fields)) {
          dispatch(setDocUploadUtility(true));
        }

        // FOR POI SUCCESS CASE IN BAJAJ CKYC
        // Temporary solution added (data[0]) until grouping is implemented in Bajaj IC
        if (
          res?.error?.data?.data?.[0]?.meta_data?.poi_status === "POI Success"
        ) {
          onChange(
            {
              target: {
                value: "POI Success",
              },
            },
            "POI Success",
          );
          return;
        }

        if (res?.error?.data?.data?.disabled_field) {
          setDisableField(res?.error?.data?.data?.disabled_field);
        }

        if (
          res?.error?.data?.data?.[0]?.refetch_proposer_name_confirmation_pop_up
        ) {
          dispatch(
            setProposalRefetchConfirmationModal({
              visible: true,
              title: `Proposer name doesn't match with KYC details. Name found - ${
                res.error.data.data[0]?.customer_details?.fullName ||
                res.error.data.data[0]?.name
              }. We will update information based on your CKYC. Click 'YES' to continue with updated information or click 'NO' to redo your kyc.`,
              forNameUpdate: true,
            }),
          );
        }

        if (res?.error?.data?.data?.revalidate_kyc_verification_until) {
          dispatch(
            setRevalidateKYCVerificationUtility({
              revalidate_kyc_verification_until:
                res?.error?.data?.data?.revalidate_kyc_verification_until,
            }),
          );
        }
        if (res?.error?.data?.data?.ckyc_reference_id) {
          dispatch(
            setCKYCReferenceId(res?.error?.data?.data?.ckyc_reference_id),
          );
        }

        if (name.includes("pan")) {
          dispatch(setFailedAsyncPanNumber(input));
        }
        onChange(
          {
            target: {
              value: "failure",
            },
          },
          "failure",
        );
        return;
      }

      const {
        verification_status,
        redirection_url,
        message,
        refetch_proposal_data,
        refetch_proposal_data_confirmation_pop_up,
        refetch_proposal_data_confirmation_msg = "",
        ckyc_reference_id,
        show_document_fields,
      } = res?.data?.data[0];
      if (verification_status) {
        setStatus("success");
        onAsyncSuccess();

        onChange(
          {
            target: {
              value: input,
            },
          },
          input,
        );
        if (message) {
          dispatch(
            setShowErrorPopup({
              show: true,
              head: "",
              msg: message,
            }),
          );
        } else {
          toast.success(`${label} verified successfully.`, {
            position: "top-center",
          });
        }
        if (refetch_proposal_data) {
          dispatch(
            setProposalRefetchModal({
              visible: true,
              title:
                "We have updated some of your details based on your CKYC. you can check later in the Proposer Details section.",
            }),
          );
        }
        if (refetch_proposal_data_confirmation_pop_up) {
          dispatch(
            setProposalRefetchConfirmationModal({
              visible: true,
              title: `
              ${
                refetch_proposal_data_confirmation_msg.length > 0
                  ? refetch_proposal_data_confirmation_msg
                  : "We will update information based on your CKYC."
              }
               Click 'YES' to continue with updated information or click 'NO' to continue with current information.
              `,
              forNameUpdate: false,
            }),
          );
        }

        if (ckyc_reference_id) {
          dispatch(setCKYCReferenceId(ckyc_reference_id));
        }
      } else {
        setStatus("failure");
        redirection_url && dispatch(setKYCRedirectionURL(redirection_url));
        onAsyncFailure();

        if (show_document_fields) {
          dispatch(setDocUploadUtility({ showDocFields: true }));
        }
        if (message) {
          dispatch(
            setShowErrorPopup({
              show: true,
              head: "",
              msg: message,
            }),
          );
        } else {
          toast.error(
            failureMessage ? failureMessage : `Failed to verify ${label}.`,
            {
              duration: 5000,
              position: "top-center",
              style: {
                fontWeight: "bold",
                padding: "25px 40px",
              },
            },
          );
        }

        if (ckyc_reference_id) {
          dispatch(setCKYCReferenceId(ckyc_reference_id));
        }

        onChange(
          {
            target: {
              value: "failure",
            },
          },
          "failure",
        );
      }
    });
  };
  const dummyFunction = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  let displayOnButton = buttonText;
  if (status === "success") {
    displayOnButton = "Verified";
  }
  if (status === "failure") {
    displayOnButton = "Retry";
  }

  useEffect(() => {
    if (input === "failure") {
      setInput("");
    }
    return () => {};
  }, [input]);

  useEffect(() => {
    if (Object.keys(proposalData?.["Proposer Details"] || {}).length) {
      if (
        name.includes("pan") &&
        "pan_card" in proposalData["Proposer Details"] &&
        proposalData["Proposer Details"]?.pan_card?.length
      ) {
        setInput(proposalData["Proposer Details"].pan_card);
        setDisableInputOnly(true);
      } else if (
        name.includes("aadhar") &&
        "aadhar_card" in proposalData["Proposer Details"] &&
        proposalData["Proposer Details"]?.aadhar_card?.length
      ) {
        setInput(proposalData["Proposer Details"].aadhar_card);
        setDisableInputOnly(true);
      }
    }
    return () => {};
  }, [name]);

  useEffect(() => {
    if (docUploadUtility.showDocFields === "removeMsg") {
      // Document upload fields will be visible once pan async field's value will be hidden
      onChange(
        {
          target: {
            value: "",
          },
        },
        "",
      );
    }
    return () => {};
  }, [docUploadUtility]);

  return (
    <>
      <InputContainer status={status} color={primary_color}>
        <div className="fields-container">
          <Input
            id={name}
            onChange={inputChangeHandler}
            value={input}
            readOnly={disableField || disableInputOnly}
            onFocus={() => setFocused(true)}
          />
          <button
            disabled={disableSave}
            onClick={status === "success" ? dummyFunction : saveClickHandler}
          >
            {displayOnButton}{" "}
            {isLoading && <RiLoader4Fill className="rotate" size={20} />}
          </button>

          <Label
            className="floating-label"
            mandatory={checkValidation?.required === true}
          >
            {width < breakpoint ? stringLengthCutter(label, 15) : label}
          </Label>
        </div>
      </InputContainer>

      {error || inputErrorUtility?.exist ? (
        <p className="formbuilder__error">
          {error || inputErrorUtility?.toShow}
        </p>
      ) : (
        <></>
      )}
    </>
  );
};

const InputContainer = styled.div`
  margin-top: 0.3rem !important;
  position: relative;

  margin-bottom: 2px !important;

  .fields-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & button {
      font-size: 14px;
      padding: 12px;
      min-width: 150px;
      color: #fff;
      border: 0;
      font-weight: ${FWEIGHT.semi};
      border-radius: ${ROUNDED.base};
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      background: ${props => {
        return props.status === "success" ? "green" : props.color;
      }};
    }
  }
`;

const Input = styled.input`
  width: 100%;
  height: 100%;
  font-size: 14px;
  padding: 12px;
  text-transform: ${props => props.textTransform};
  color: #090909;
  border: ${props => (props.error ? "solid 1px #c7222a" : "1px solid #d4d5d9")};
  background-color: transparent;
  font-weight: ${props => (props.boldText ? FWEIGHT.semi : 300)};
  cursor: ${props => {
    return props.isHovering && props.readOnly ? "not-allowed" : "pointer";
  }};
  border-radius: ${ROUNDED.base};
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  text-transform: uppercase;
  outline: none;
  box-shadow: none;
  transition: all 0.3s ease-in-out;
  touch-action: manipulation;
  position: relative;
  border-right: unset;
  &:focus {
    border-color: ${props => (props.error ? "#c7222a" : "solid 1px  #393939")};
    color: #000;
  }
  &:focus ~ .floating-label {
    top: 0;
    font-size: 12px;
    color: #000;
    background: #fff;
    font-weight: ${FWEIGHT.semi};

    &::after {
      color: red;
    }
  }

  &:not([${({ value }) => value !== ""}]) ~ .floating-label {
    top: 0;
    font-size: 12px;
    color: #000;
    background: #fff;
    font-weight: ${FWEIGHT.semi};

    &::after {
      color: red;
    }
  }

  &:before {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  &:after {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  ::placeholder {
    text-transform: capitalize;
  }
`;

const Label = styled.label`
  text-align: left;
  pointer-events: none;
  display: inline-block;
  font-size: 13px !important;
  color: grey;
  position: absolute;
  left: 8px;
  top: 50%;
  margin: 0;
  max-width: 95%;
  transition: top 0.3s ease-in-out;
  transform: translateY(-50%);
  padding: 0 4px;

  ${({ mandatory }) => {
    return mandatory ? "&::after {  content: '*'; }" : "";
  }}
`;

export default AsyncTextField;
