import { FaTimes } from "react-icons/fa";
import Backdrop from "src/components/Common/Backdrop";
import useGetRouteMatch from "src/customHooks/useGetRouteMatch";
import FHTypography from "src/styles/Typography";
import { SHADES } from "src/styles/styleGuide";
import EditTab from "../components/EditTab";
import useAddMemberModal from "../hooks/useAddMemberModal";
import EditMemberStep from "./EditMemberStep";
import {
  MembersModalContainerStyled,
  MembersModalStyled,
} from "./membersModal.styled";
import EditLocationStep from "./EditLocationStep";
import useHandleBodyScroll from "src/customHooks/useHandleBodyScroll";
import { useEffect } from "react";
import EditPortExpiryDate from "./EditPortExpiryDate";
import { useFrontendBoot } from "src/customHooks/useGetCommonQueries";

function MembersModal({ isModalOpen, title, onClose }) {
  const { isQuoteRoute, isProductDetailsRoute } = useGetRouteMatch();
  const {
    allMembersListModal,
    selectedMembersModal,
    createEnquiryQueryResponse,
    updateMemberQueryResponse,
    memberErrorMessageModal,
    membersErrorModal,
    editStep,
    setEditStep,
    handleAddMemberModal,
    removeMemberModal,
    handleSubmitMembersModal,
    handleApplyMembersEdit,
    handleMultiSelectMemberModal,
    handleUpdateMembersEdit,
  } = useAddMemberModal();

  const { subJourneyType } = useFrontendBoot();

  const { hideBodyOverflow, unsetBodyOverflow } = useHandleBodyScroll();

  useEffect(() => {
    // Disable scrolling when modal is open
    if (isModalOpen) {
      hideBodyOverflow();
    } else {
      unsetBodyOverflow();
    }

    return () => {
      unsetBodyOverflow();
    };
  }, [isModalOpen]);

  // Components Return statement
  return (
    <MembersModalContainerStyled
      data-is-product-details-page={!!isProductDetailsRoute}
    >
      <MembersModalStyled>
        <section className="members_modal_head">
          <FHTypography variant="h4" weight="semi" colorType="subHeading">
            {title}
          </FHTypography>
          {editStep === 1 && (
            <button onClick={onClose}>
              <FaTimes size={22} color={SHADES.SUB_HEADING} />
            </button>
          )}
        </section>
        {isQuoteRoute && (
          <EditTab setEditStep={setEditStep} editStep={editStep} />
        )}
        {editStep === 1 && (
          <EditMemberStep
            allMembersList={allMembersListModal}
            createEnquiryQueryResponse={createEnquiryQueryResponse}
            updateMemberQueryResponse={updateMemberQueryResponse}
            handleAddMemberModal={handleAddMemberModal}
            handleApplyMembersEdit={handleApplyMembersEdit}
            handleSubmitMembersModal={handleSubmitMembersModal}
            handleUpdateMembersEdit={handleUpdateMembersEdit}
            memberErrorMessageModal={memberErrorMessageModal}
            membersErrorModal={membersErrorModal}
            onClose={onClose}
            removeMemberModal={removeMemberModal}
            selectedMembersModal={selectedMembersModal}
            handleMultiSelectMemberModal={handleMultiSelectMemberModal}
          />
        )}
        {editStep === 2 && (
          <section className="edit_location_tab">
            <EditLocationStep setEditStep={setEditStep} onClose={onClose} />
          </section>
        )}
        {editStep === 3 && subJourneyType === "port" && (
          <EditPortExpiryDate
            goToLocationStep={setEditStep}
            onClose={onClose}
          />
        )}
      </MembersModalStyled>

      <Backdrop zIndex="0" bgColor={SHADES.DIM_GRAY + "77"} />
    </MembersModalContainerStyled>
  );
}

export default MembersModal;
