import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import { getMemberName } from "src/utils/string.utility";
import { useMembers, useTheme } from "src/customHooks";
import useIsPos from "../../../pos/useIsPos";
import {
  noForAllCheckedFalse,
  setShowErrorPopup,
  setShowPlanNotAvail,
} from "../ProposalSections/ProposalSections.slice";
import { VIEW } from "src/styles/responsiveStyle";
import { FLEX_COL, FLEX_ROW } from "src/styles/styleGuide";
import { generateAge } from "src/components/FormBuilder/formUtils";
import toast from "react-hot-toast";

//* When a Medical question has no sub question this component must be rendered.
const Toggle = ({
  label,
  customOptions,
  members = [],
  name,
  value,
  warning,
  onChange,
  notAllowed = false,
  noNotAllowed = false,
  values,
  showMembers,
  showMembersIf,
  notAllowedIf,
  disable_Toggle = false,
  disable_toggle_to_NO = false,
  restrictMaleMembers = false,
  message,
  allowBoth = false,
  noForAll,
  error,
  render = "",
  restrictedMembers = "",
  checkValidation = {},
  additionalOptions = {},
  dependentMembers,
  memberAgeNotAllowed,
}) => {
  const isMandatoryMQ = label.toLowerCase().includes("mandatory");

  const { colors } = useTheme();
  const { noNotAllowedMessage = "You cannot select No for this option." } =
    additionalOptions;

  const PrimaryColor = colors.primary_color,
    SecondaryColor = colors.secondary_color,
    PrimaryShade = colors.primary_shade;

  const { isPosJourney } = useIsPos();

  const { genderOfSelf } = useMembers();

  // For future general Medical ques warning
  const [oppositeToggleMQWarning, setOppositeToggleMQWarning] = useState("");
  const { proposalData } = useSelector(state => state.proposalPage);

  useEffect(() => {
    if (notAllowedIf && noForAll === true) {
      setOppositeToggleMQWarning(
        "Please read the highlighted question carefully and then continue",
      );
    }
    if (noForAll === false) {
      setOppositeToggleMQWarning("");
    }
    return () => {};
  }, [noForAll]);

  // eslint-disable-next-line no-unused-vars
  const [customShowMembers, setCustomShowMembers] = useState(false);

  const [membersToMap, setMembersToMap] = useState(members);

  const [membersSelectedTillNow, setMembersSelectedTillNow] = useState(false);

  useEffect(() => {
    if (isMandatoryMQ) {
      let questionsToCheck = showMembersIf.split("||");
      let membersSelectedTillNow = membersToMap.reduce((acc, member) => {
        let isMemberPresent = questionsToCheck.some(
          question => values?.[question] && values[question]?.members?.[member],
        );
        return isMemberPresent ? { ...acc, [member]: true } : acc;
      }, {});

      setMembersSelectedTillNow(membersSelectedTillNow);
    }
    return () => {};
  }, [values]);

  useEffect(() => {
    if (restrictedMembers) {
      const restrictedMembersArray = restrictedMembers.split("|");
      let membersToRemove = [];
      members.map(member => {
        restrictedMembersArray.map(resMember => {
          if (member.includes(resMember)) {
            membersToRemove.push(member);
          }
        });
      });
      setMembersToMap(
        members.filter(member => !membersToRemove.includes(member)),
      );
    }
    return () => {};
  }, [restrictedMembers]);

  useEffect(() => {
    if (dependentMembers) {
      const dependentMembersObj = values?.[dependentMembers]?.members || {};
      const selectedMembers =
        Object.keys(dependentMembersObj).filter(
          member => dependentMembersObj[member],
        ) || [];

      if (selectedMembers.length) {
        setMembersToMap(selectedMembers);
      }
    }
    return () => {};
  }, [dependentMembers]);

  const [boolean, setBoolean] = useState(
    disable_Toggle ? "Y" : value?.[`is${name}`] || "",
  );

  const initialMemberStatus = () => {
    if (disable_Toggle)
      return members.reduce((acc, member) => ({ ...acc, [member]: true }), {});
    else return {};
  };

  const [membersStatus, setMembersStatus] = useState(
    value?.members || initialMemberStatus(),
  );

  useEffect(() => {
    if (disable_Toggle) {
      setMembersStatus(
        membersToMap.reduce((acc, member) => ({ ...acc, [member]: true }), {}),
      );
    }
    return () => {};
  }, []);

  const dispatch = useDispatch();

  useEffect(() => {
    const allMaleMembers = [
      "son",
      "grand_father",
      "father",
      "father_in_law",
      ...[1, 2, 3, 4].map(i => `son${i}`),
    ];
    if (value && notAllowed && value[`is${name}`] === "Y" && !disable_Toggle) {
      setBoolean("N");
      setMembersStatus({});
    } else if (
      value instanceof Object &&
      Object.keys(value).length &&
      !disable_Toggle
    ) {
      setBoolean(value[`is${name}`]);
      setMembersStatus(value.members);
    }
    if (restrictMaleMembers) {
      if (genderOfSelf === "M") {
        setMembersToMap(membersToMap.filter(member => member !== "self"));
      } else {
        setMembersToMap(membersToMap.filter(member => member !== "spouse"));
      }

      setMembersToMap(prev => prev.filter(el => !allMaleMembers.includes(el)));
    }
    return () => {};
  }, [value]);

  useEffect(() => {
    if (!value && !disable_Toggle) {
      setBoolean("");
      setMembersStatus({});
    }
    if (value && notAllowed && value[`is${name}`] === "Y" && !disable_Toggle) {
      setBoolean("N");
      setMembersStatus({});
    }
    return () => {};
  }, [value, customShowMembers]);

  useEffect(() => {
    let isValid = true;

    if (
      (boolean === "Y" &&
        (showMembers !== false || customShowMembers) &&
        !Object.values(membersStatus).includes(true)) ||
      (!boolean && !disable_Toggle)
    ) {
      isValid = false;
    }

    if (!isMandatoryMQ) {
      if ((boolean === "N" || boolean === "") && !customShowMembers) {
        onChange({
          [`is${name}`]: boolean,
          members: {},
          isValid,
        });
      } else {
        onChange({
          ...value,
          [`is${name}`]: boolean,
          members: membersStatus,
          isValid,
        });
      }
    }
    return () => {};
  }, [
    boolean,
    Object.keys(membersStatus).filter(m => membersStatus[m]).length,
    customShowMembers,
  ]);

  useEffect(() => {
    if (isMandatoryMQ && membersSelectedTillNow) {
      onChange({
        ...value,
        [`is${name}`]: Object.values(membersSelectedTillNow).includes(true)
          ? "Y"
          : "N",
        members: membersSelectedTillNow,
        isValid: true,
      });
    }
    return () => {};
  }, [Object.keys(membersSelectedTillNow).length]);

  const yesClickHandler = e => {
    if (!isMandatoryMQ) {
      if (restrictMaleMembers && membersToMap.length === 0) {
        dispatch(
          setShowErrorPopup({
            show: true,
            head: "",
            msg: "Male members are not eligible for this question.",
          }),
        );
        setBoolean("N");
      } else if (
        notAllowedIf !== "N" &&
        message &&
        message.stp_block_message &&
        !allowBoth
      ) {
        dispatch(
          setShowErrorPopup({
            show: true,
            head: "",
            msg: message.stp_block_message,
            showQuotesBtn: true,
          }),
        );
      } else if (
        notAllowedIf !== "N" &&
        message &&
        message.stp_pos_block_message &&
        isPosJourney &&
        !allowBoth
      ) {
        dispatch(
          setShowErrorPopup({
            show: true,
            head: "",
            htmlProps: message.stp_pos_block_message,
          }),
        );
      } else if (
        notAllowedIf !== "N" &&
        message &&
        message.npos_switch_medical_selection_message &&
        !Object.keys(values).some(
          question => values[question]?.[`is${question}`] === "Y",
        ) &&
        !allowBoth
      ) {
        dispatch(
          setShowErrorPopup({
            show: true,
            head: "",
            msg: message.npos_switch_medical_selection_message,
          }),
        );

        setBoolean(e.target.value);

        membersToMap.length === 1 &&
          !disable_Toggle &&
          (customShowMembers || e.target.value === "Y") &&
          setMembersStatus({
            ...membersStatus,
            [membersToMap[0]]: true,
          });
      } else if (notAllowed) {
        dispatch(setShowPlanNotAvail(true));
      } else if (
        membersToMap.length === 1 &&
        !disable_Toggle &&
        (customShowMembers || e.target.value === "Y")
      ) {
        setBoolean(e.target.value);
        setMembersStatus({
          ...membersStatus,
          [membersToMap[0]]: true,
        });
      } else {
        setBoolean(e.target.value);
      }
    }
  };

  const noClickHandler = e => {
    if (notAllowedIf === "N") dispatch(setShowPlanNotAvail(true));
    else if (noNotAllowed) {
      dispatch(
        setShowErrorPopup({
          show: true,
          head: "",
          msg: noNotAllowedMessage,
        }),
      );
    } else if (!isMandatoryMQ) {
      setBoolean(e.target.value);
      !showMembersIf && setMembersStatus({});
    }
  };

  useEffect(() => {
    if (disable_toggle_to_NO) {
      noClickHandler({ target: { value: "N" } });
    }
    return () => {};
  }, [disable_toggle_to_NO]);

  const isSingleSon =
    membersToMap.filter(item => item.startsWith("son")).length == 1 || false;
  const isSingleDaughter =
    membersToMap.filter(item => item.startsWith("daughter")).length == 1 ||
    false;
  const isMemberAgeValid = member => {
    const memberDOB = proposalData?.["Insured Details"]?.[member]?.["dob"];
    const result = generateAge({ dob: memberDOB }) > memberAgeNotAllowed;

    return result;
  };

  return (
    <>
      <ToggleOuter disable={disable_toggle_to_NO}>
        <div className="box_shadow_box_card_medical">
          <RowContainer>
            <Question
              id={name}
              className="p_propsal_form_r_q_m toggle_question"
              SecondaryColor={SecondaryColor}
              oppositeToggleMQWarning={oppositeToggleMQWarning}
              render={render}
            >
              {label}
            </Question>

            <ToggleContainerStyled
              PrimaryColor={PrimaryColor}
              isMandatoryMQ={isMandatoryMQ}
              disable_Toggle={disable_Toggle}
              PrimaryShade={PrimaryShade}
              customOptions={customOptions}
              className=" wid middle mobile-left "
            >
              <ToggleButton
                disable={disable_toggle_to_NO}
                onClick={() => {
                  dispatch(noForAllCheckedFalse());
                }}
              >
                <input
                  type="radio"
                  name={`is${name}`}
                  onChange={yesClickHandler}
                  value="Y"
                  checked={boolean === "Y"}
                />
                <div className="front-end  box capitalize-mobile btn_border">
                  <span>{customOptions ? customOptions?.[0] : "Yes"}</span>
                </div>
              </ToggleButton>
              <ToggleButton disable={disable_toggle_to_NO}>
                <input
                  type="radio"
                  name={`is${name}`}
                  value="N"
                  onChange={noClickHandler}
                  checked={boolean === "N"}
                />
                <div className="front-end box capitalize-mobile btn_border">
                  <span>{customOptions ? customOptions?.[1] : "No"}</span>
                </div>
              </ToggleButton>
            </ToggleContainerStyled>
          </RowContainer>
        </div>
        {!isMandatoryMQ &&
        membersToMap.length > 1 &&
        showMembers !== false &&
        !disable_Toggle ? (
          (customShowMembers || boolean === "Y") && (
            <Group className="position-relative" render={render}>
              {membersToMap.map((item, index) => (
                <>
                  <Fragment key={index}>
                    <input
                      type="checkbox"
                      name={item}
                      id={"rb1" + name + index + item}
                      onChange={e => {
                        if (memberAgeNotAllowed && !isMemberAgeValid(item)) {
                          toast.error(
                            `${item.toUpperCase()} is not eligible for this question based on their age!`,
                            {
                              id: "age_error",
                              position: "top-center",
                              style: {
                                width: 300,
                                background: colors.primary_color,
                                color: "#fefefe",
                              },
                            },
                          );
                          return;
                        }
                        setMembersStatus({
                          ...membersStatus,
                          [e.target.name]: e.target.checked,
                        });
                      }}
                      checked={!!membersStatus[item]}
                    />
                    <label
                      htmlFor={"rb1" + name + index + item}
                      css={`
                        color: ${colors.primary_color} !important;
                        margin-bottom: 19px;
                        width: fit-content !important;
                        min-width: 80px !important;
                        border-color: ${membersStatus[item]
                          ? colors.primary_color
                          : ""} !important;
                      `}
                    >
                      {getMemberName({
                        memberCode: item,
                        isSingleSon,
                        isSingleDaughter,
                      })}
                    </label>
                  </Fragment>
                </>
              ))}

              {!checkValidation?.selectAllRequired &&
              !Object.keys(membersStatus).some(i => membersStatus[i]) ? (
                <SelectMsgStyled
                  className="formbuilder__error position-absolute"
                  render={render}
                >
                  Please select at least one
                </SelectMsgStyled>
              ) : (
                <></>
              )}
            </Group>
          )
        ) : (
          <></>
        )}
        {warning && <p className="formbuilder__warning">{warning?.message}</p>}

        {error && (
          <SelectMsgStyled
            render={render}
            css={`
              padding-left: ${render?.when ? "50px" : "16px"};
            `}
            className="formbuilder__error error_msg"
          >
            {error}
          </SelectMsgStyled>
        )}

        {oppositeToggleMQWarning && (
          <ToggleMQWarning className="formbuilder__warning">
            {oppositeToggleMQWarning}
          </ToggleMQWarning>
        )}
      </ToggleOuter>
    </>
  );
};

export default Toggle;

const SelectMsgStyled = styled.p`
  bottom: 11px;
  left: 31px;

  background: white;
  font-size: 14px;
  padding-left: ${({ render }) => {
    render?.when ? "50px" : "16px";
  }};
  @media (max-width: 767px) {
    bottom: -15px;
    left: 15px;
    background: unset;
  }
`;

// const LabelStyled = styled.label`
//   color: ${({ colors }) => colors.primary_color} !important;
//   margin-bottom: 19px;
//   width: fit-content !important;
//   min-width: 80px !important;
//   border-color: ${({ membersStatus, colors, item }) => {
//     membersStatus[item] ? colors.primary_color : "";
//   }} !important;
// `;

const ToggleMQWarning = styled.p`
  margin-top: 10px;
  width: 100%;
  display: inline-block;
  text-align: left;
  border-radius: 7px;
  padding: 8px 16px 8px;
  background-color: #fee8d2;
`;

const RowContainer = styled.div`
  ${FLEX_COL}
  .wid {
    width: 100%;
  }
  ${VIEW.md} {
    ${FLEX_COL};
  }

  ${VIEW.lg} {
    ${FLEX_ROW}
    justify-content: space-between;
    align-items: center;
  }
`;

const ToggleContainerStyled = styled.div`
  & input[type="radio"]:checked + .box {
    background-color: ${({ PrimaryColor }) => PrimaryColor};
  }
  & .box {
    background-color: ${({ PrimaryShade }) => PrimaryShade};
  }
  opacity: ${({ isMandatoryMQ }) => {
    isMandatoryMQ ? "0.5" : "1";
  }} !important;
  display: ${({ disable_Toggle }) => {
    disable_Toggle ? "none" : "block";
  }};
  text-align: end !important;
  @media (max-width: 767px) {
    text-align: start !important;
    padding-left: 16px !important;
  }

  .btn_border {
    width: ${({ customOptions }) => {
      customOptions?.[0]?.length > 4 && "144px !important";
    }};
    line-height: 42px !important;
    height: 34px !important;
  }
`;

const ToggleOuter = styled.div`
  opacity: ${({ disable }) => (disable ? 0.7 : 1)};
  ${({ disable }) => (disable ? "cursor : not-allowed;" : "")};
`;

const Question = styled.p`
  width: 100%;
  padding-left: ${props => {
    return props.render?.when ? "50px" : "16px";
  }};
  ${props =>
    props.oppositeToggleMQWarning.length && "font-weight: 600 !important;"}

  &:after {
    content: "";
    height: 22px;
    width: 6px;
    position: absolute;
    left: -2px;
    top: 2px;
    background-color: ${props => props.SecondaryColor};
    border-radius: 50px;
    @media (max-width: 767px) {
      height: calc(100% - 6px);
    }
  }
  @media (max-width: 767px) {
    font-size: 14px !important;
  }
`;
const Group = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 600px;
  overflow: auto;
  align-items: center;
  padding-bottom: 17px;
  padding-left: ${props => {
    return props.render?.when ? "60px" : "25px";
  }};
  & input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    z-index: -1;
    & + label {
      text-transform: capitalize;
      padding-left: 1em;
      color: #000000;
      border: 1px solid #e7ebf0;
      border-radius: 50px;
      padding: 9px 19px;
      font-size: 16px;
      font-weight: 600;
      width: 140px;
      text-align: center;
      margin-right: 11px;
      box-shadow: 0 3px 6px 0 #004b8326 !important;
      transition: 0.25s all ease;
      cursor: pointer;
      background: #fff;
      @media (max-width: 767px) {
        padding: 4px 10px;
        font-size: 14px;
      }
    }
    &:checked + label {
      padding-left: 1em;
      color: #000000;
      border: 2px solid #0a87ff;
      border-radius: 50px;
      padding: 7px 19px;
      font-size: 16px;
      font-weight: 600;
      width: 140px;
      text-align: center;
      margin-right: 11px;
      background-color: #ecf6ff;
      @media (max-width: 767px) {
        width: 66px;
        padding: 4px 10px;
        font-size: 14px;
      }
    }
  }
  @media (max-width: 767px) {
    padding: 12px 12px 6px;
    background-color: #f7f9fa;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
  }
`;

const ToggleButton = styled.label`
  opacity: ${({ disable }) => (disable ? 0.7 : 1)};
  ${({ disable }) => (disable ? "cursor : not-allowed;" : "")}
`;
