import styled from "styled-components/macro";
import UibFooter from "src/components/Common/Footer/UibFooter";
import BajajFooter from "src/components/Common/Footer/BajajFooter/BajajFooter";
import { MAX } from "src/styles/responsiveStyle";
import { brokerTenant } from "src/utils/tenantSettings";
import useGetRouteMatch from "src/customHooks/useGetRouteMatch";

const FooterSection = () => {
  const { isProposalSummaryRoute } = useGetRouteMatch();
  return (
    <Footer isProposalSummaryRoute={isProposalSummaryRoute}>
      <UibFooter />
      <BajajFooter />
    </Footer>
  );
};

const Footer = styled.footer`
  width: 100% !important;
  overflow: hidden;

  padding-bottom: ${props => props.isProposalSummaryRoute && "5rem"};
  ${MAX.md} {
    padding-bottom: ${brokerTenant("tmibasl") ? "13rem" : "10rem"};
  }
`;

export default FooterSection;
