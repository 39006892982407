// CUSTOM HOOKS
import {
  useAdditionalDiscount,
  useTheme,
  useTotalPremiumLoader,
} from "src/customHooks";
import { useGetEnquiry } from "src/customHooks/useGetCommonQueries";
import useSeeDetailsUtility from "src/customHooks/useSeeDetailsUtility";
// UTILS
import { amount } from "src/utils/string.utility";
import { getTotalPremiumWithDiscount } from "src/utils/helper";
// DESIGN SYSTEM
import FHTypography from "src/styles/Typography";
// STYLED COMPONENTS
import {
  ICImageAndSeeDetailsWrapper,
  PlanInfoCardStyled,
  SeeDetailsButtonStyled,
  TotalPremiumLoader,
  TotalPremiumTextWrapper,
  TotalPremiumWrapper,
} from "src/pages/ProductDetails/components/CartMobile/CartMobile.style";
// CUSTOM COMPONENTS
import ICLogoBase from "src/components/Common/ICLogo";

export default function PlanInfoCard({ cartEntry, cartMobileToggle }) {
  const { colors } = useTheme();
  //  another issue > cannot get groupcode on proposal page
  const { groupCode } = useGetEnquiry();

  const { product, netPremiumWithoutDiscount } = cartEntry;
  const isTotalPremiumLoading = useTotalPremiumLoader(cartEntry);

  const { handleUpdateSeeDetailsModal } = useSeeDetailsUtility();
  const { getTotalDiscountAmount } = useAdditionalDiscount(groupCode);

  const totalPremium = getTotalPremiumWithDiscount({
    netPremiumWithoutDiscount,
    totalDiscountAmount: getTotalDiscountAmount(),
  });

  const handleSeeDetailsButtonClick = () => {
    cartMobileToggle.off();

    handleUpdateSeeDetailsModal({
      payload: cartEntry,
      section: "plan-details",
    });
  };

  return (
    <PlanInfoCardStyled>
      <ICImageAndSeeDetailsWrapper>
        <ICLogoBase width={80} alias={product.company.alias} />
        <SeeDetailsButtonStyled onClick={handleSeeDetailsButtonClick}>
          <FHTypography
            variant="subheading2"
            weight="semi"
            color={colors.primary_color}
          >
            See Details
          </FHTypography>
        </SeeDetailsButtonStyled>
      </ICImageAndSeeDetailsWrapper>
      <div className="plan-details">
        <FHTypography
          variant="h5"
          weight="md"
          colorType="heading"
          className="plan-name"
        >
          {product.name}
        </FHTypography>
        <TotalPremiumWrapper>
          <TotalPremiumTextWrapper>
            <FHTypography variant="h6" weight="md" colorType="subHeading">
              Total Premium
            </FHTypography>
            <FHTypography variant="body1" colorType="body">
              (Incl. GST)
            </FHTypography>
            <FHTypography variant="h6" weight="md" colorType="subHeading">
              :
            </FHTypography>
          </TotalPremiumTextWrapper>
          {isTotalPremiumLoading ? (
            <TotalPremiumLoader />
          ) : (
            <FHTypography variant="h4" weight="md" colorType="black">
              {amount(totalPremium)}
            </FHTypography>
          )}
        </TotalPremiumWrapper>
      </div>
    </PlanInfoCardStyled>
  );
}
