import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { FaTimes } from "react-icons/fa";
import {
  useFrontendBoot,
  useGetEnquiry,
} from "src/customHooks/useGetCommonQueries";
import { useTheme } from "src/customHooks";
import useUrlQuery from "src/customHooks/useUrlQuery";
import { setShowBMI } from "../../ProposalSections.slice";
import {
  ButtonWrapper,
  Container,
  Popup,
  PopupWrapper,
  ViewQuotesWrapper,
} from "./index.style";

const BMI = () => {
  const { showBMI, failedBmiData } = useSelector(state => state.proposalPage);
  const history = useHistory();
  const { data: frontBootData } = useFrontendBoot();
  const urlQuery = useUrlQuery();
  const enquiryId = urlQuery.get("enquiryId");
  const { groups } = useGetEnquiry();
  const member = groups[0]?.id;
  const { colors } = useTheme();
  const dispatch = useDispatch();
  if (!showBMI) return <></>;
  if (showBMI)
    return (
      <PopupWrapper>
        <Popup>
          <FaTimes
          className="iconstyle"
            onClick={() => {
              dispatch(setShowBMI(false));
            }}
          />
          <Container>
            {frontBootData?.settings?.medical_stp_declaration_message &&
            frontBootData?.settings?.display_stp_message_over_bmi_message ? (
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    frontBootData?.settings?.medical_stp_declaration_message,
                }}
              ></div>
            ) : (
              <>
                {failedBmiData?.message ? (
                  <p>{failedBmiData.message}</p>
                ) : (
                  <p>
                    Based on BMI of <span>{showBMI}</span> this plan isn&#39;t
                    available. Please change your details for selected plan or
                    choose another plan
                  </p>
                )}
              </>
            )}

            <ButtonWrapper color={colors?.primary_color}>
              <button
                onClick={() => {
                  dispatch(setShowBMI(false));
                }}
              >
                OK
              </button>
            </ButtonWrapper>
            <ViewQuotesWrapper color={colors?.primary_color}>
              <button
                onClick={() => {
                  history.push(`/quotes/${member}?enquiryId=${enquiryId}`);
                }}
              >
                View Quotes {">"}
              </button>
            </ViewQuotesWrapper>
          </Container>
        </Popup>
      </PopupWrapper>
    );
};

export default BMI;
