import { useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { useGetProposalSchemaQuery } from "../api/api";
// import testSchema from "../pages/ProposalPage/test-schema";

const useProposalSchema = () => {
  const urlQueryStrings = new URLSearchParams(window.location.search);
  const isProposalRoute = useRouteMatch({ path: "/proposal" });
  const isProposalSummaryRoute = useRouteMatch({ path: "/proposal_summary" });
  const enquiryId = urlQueryStrings.get("enquiryId");

  const skipToken = !isProposalRoute && !isProposalSummaryRoute;

  const {
    kycSection: { kycFailed },
  } = useSelector(state => state.proposalPage);

  const {
    data,
    isLoading,
    isFetching,
    isUninitialized,
    isError: schemaError,
    refetch: schemaRefetch,
  } = useGetProposalSchemaQuery(
    {
      enquiryId,
    },
    { skip: !enquiryId || skipToken },
  );

  const schemaData = data?.data;
  let schemaLoading = isLoading || isFetching || isUninitialized;
  let listOfForms = schemaData ? Object.keys(schemaData) : [];
  let schemaExist = !!schemaData;

  const formIndexGenerator = (formName = "") => {
    return listOfForms.findIndex(nameOfForm => nameOfForm === formName);
  };

  const sectionVisibility = formName => {
    if (formName === "Proposer Details" || formName === "KYC Details") {
      return listOfForms.includes(formName);
    } else {
      return listOfForms.includes(formName) && !kycFailed;
    }
  };

  const checkFormSectionExist = ({ formName = "" }) => {
    return listOfForms.includes(formName);
  };

  return {
    schemaData,
    schemaLoading,
    schemaError,
    schemaRefetch,
    listOfForms,
    schemaExist,
    sectionVisibility,
    formIndexGenerator,
    checkFormSectionExist,
  };
};

export default useProposalSchema;
