import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import "styled-components/macro";
import FormBuilder from "../../../components/FormBuilder/FormBuilder";
import { useFrontendBoot } from "src/customHooks/useGetCommonQueries";
import { useMembers } from "src/customHooks";
import BackBtn from "../components/Buttons/BackBtn";
import ContinueBtn from "../components/Buttons/ContinueBtn";
import { components } from "../components/componentSchema";
import { Form } from "./../ProposalPage.style";
import useInsuredDetails from "./useInsuredDetails";
import RevisedPremiumPopup from "../../ProductDetails/components/ReviewCart/components/RevisedPremiumPopup";
import useFormNavigation from "./useFormNavigation";
import useProposalSections from "./useProposalSections";
import CustomPanel from "src/components/Common/CustomPanel/CustomPanel";
import { useEventTracking } from "src/customHooks/useEventTracking";
import { setIsContinueBtn } from "../schema.slice";

const InsuredDetails = ({
  formSchema = {},
  rawSchema = {},
  name = "",
  defaultValue = {},
  setActivateLoader = () => {},
  setBlockTabSwitch = () => {},
  listOfForms = [],
  isContinueClicked,
  setIsContinueClicked,
}) => {
  const { proposalData } = useSelector(state => state.proposalPage);

  const { insuredMembers: membersDataFromGreetingPage } = useFrontendBoot();

  const { getGroupMembers, groups } = useMembers();
  const { webEngageTrackEvent } = useEventTracking();
  const fullName = proposalData["Proposer Details"]?.name;

  const insuredSectionRef = useRef(null);

  const {
    values,
    setValues,
    isValid,
    setValid,
    submit,
    setSubmit,
    additionalErrors,
    revisedPremiumPopupUtilityObject,
    setErrorInField,
    triggerSaveForm,
    setErrors,
    enquiriesData,
    show,
    setShow,
  } = useProposalSections({
    name,
    defaultValue,
    partialLength: Object.keys(formSchema).length,
    setActivateLoader,
    schema: rawSchema,
    setBlockTabSwitch,
    listOfForms,
    activeFormSchema: formSchema,
  });

  const { getPanelDescContent, getMemberAge } = useInsuredDetails(
    name,
    formSchema,
    proposalData,
    values,
    membersDataFromGreetingPage,
    groups,
    setValues,
    defaultValue,
    enquiriesData,
  );

  const { navigateTo } = useFormNavigation();
  const dispatch = useDispatch();
  useEffect(() => {
    if (isValid.includes(false)) {
      setShow(isValid.indexOf(false));
      insuredSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    return () => {};
  }, [...isValid]);

  const handleContinue = () => {
    setSubmit("PARTIAL");
    dispatch(setIsContinueBtn(true)); //append code to each member object for enquiry api
    let sendedVal = {};
    Object.keys(values).map(item => {
      let memberCode = item;
      if (item === "son1") {
        memberCode = "son";
      }
      if (item === "daughter1") {
        memberCode = "daughter";
      }
      sendedVal[item] = { ...values[item], code: memberCode };
    });
    triggerSaveForm({ sendedVal, formName: name });
    webEngageTrackEvent("Insured Details Submitted", sendedVal);
  };

  const handleBack = () => {
    navigateTo({ back: true });
  };

  useEffect(() => {
    //? To handle continue click on Proposal page Mobile
    if (isContinueClicked) {
      handleContinue();
      setIsContinueClicked("");
    }
    return () => {};
  }, [isContinueClicked]);

  const isSingleSon =
    Object.keys(formSchema).filter(item => item.startsWith("son")).length == 1;
  const isSingleDaughter =
    Object.keys(formSchema).filter(item => item.startsWith("daughter"))
      .length == 1;

  return (
    <div ref={insuredSectionRef}>
      {Object.keys(formSchema).map((item, index) => {
        return (
          <CustomPanel
            allMembers={getGroupMembers(parseInt(item))}
            formName={name}
            isFilled={
              Object.keys(values && values[item] ? values[item] : {}).length
            }
            values={getPanelDescContent(item)}
            memberAge={getMemberAge(item)}
            key={index}
            isSingleSon={isSingleSon}
            isSingleDaughter={isSingleDaughter}
            title={`${item}`}
            show={show === index}
            onClick={() => setShow(prev => (prev === index ? false : index))}
          >
            <div>
              <Form
                onSubmit={e => {
                  e.preventDefault();
                }}
                className="word_break"
              >
                <FormBuilder
                  isInsuredDetails
                  keyStr={item}
                  lastName={fullName?.split(" ").slice(-1)}
                  schema={formSchema[item]}
                  components={components}
                  fetchValues={(res = () => {}) => {
                    setValues(prev => {
                      return { ...prev, [item]: res(prev?.[item]) };
                    });
                  }}
                  fetchErrors={res => {
                    setErrors(prev => ({ ...prev, [item]: res }));
                  }}
                  setErrorInField={setErrorInField}
                  fetchValid={res => {
                    let valid = isValid;
                    valid[index] = res;
                    setValid(valid);
                  }}
                  options={{
                    defaultValues: values?.[item] || {},
                    validateOn: "change",
                  }}
                  formName={name}
                  additionalErrors={additionalErrors[item]}
                  setSubmit={setSubmit}
                  submitTrigger={submit}
                  isPanelVisible={show === index}
                  proposalData={proposalData}
                  preFilledDataBase={defaultValue ? defaultValue[item] : {}}
                />
              </Form>
            </div>
          </CustomPanel>
        );
      })}

      <div className="proposal_continue_back_margin container">
        <BackBtn onClick={handleBack} />

        <ContinueBtn disabled={false} onClick={handleContinue} />
      </div>

      {revisedPremiumPopupUtilityObject.isOnProposal && (
        <RevisedPremiumPopup
          revisedPremiumPopupUtilityObject={revisedPremiumPopupUtilityObject}
          onClose={revisedPremiumPopupUtilityObject.off}
        />
      )}
    </div>
  );
};

export default InsuredDetails;
