import styled from "styled-components/macro";

export const Box = styled.section`
  margin-bottom: 0.5rem;
`;
export const FlexEndBox = styled.section`
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
`;

export const Label = styled.label`
  font-size: 1rem;
  font-weight: 600;
`;

export const OTPInput = styled.input`
  width: 100%;
  height: 50px;
  padding: 0.5rem;
  box-sizing: border-box;
  margin: 0.5rem 0;
  border: 1px solid lightgrey;
  border-radius: 0.3rem;
`;

export const Error = styled.p`
  margin: unset;
  font-size: 0.8rem;
  color: red;
`;
