import { VIEW } from "src/styles/responsiveStyle";
import styled from "styled-components";

export const LocationFormStyled = styled.div`
  padding: 1rem 0;
  ${VIEW.md} {
    padding: 1rem;
  }
`;

export const LocationEditInfoWrapper = styled.div`
  text-align: center;
  margin-bottom: 2em;
  color: ${props => props.colors.secondary_color};

  @media (max-width: 768px) {
    margin-bottom: 1em;
  }
`;

export const InputFormCtaWrapper = styled.div`
  margin-top: 2.5rem;
`;

export const PopularCitiesContainer = styled.div`
  margin-top: 3rem;
`;

export const PopularCitiesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0;
  padding-right: 10px;

  & > div {
    margin: 0 0.2rem 0.6rem 0.2rem;
  }
`;

export const PopularCityInputStyled = styled.input`
  & + label {
    ${props =>
      props.selectedCity?.city === props.name &&
      `
            box-shadow: ${props.colors.primary_color} 0px 0px 1px 1px;
            background-color: #fff;
            border-color: transparent;
            color: ${props.colors.primary_color};
            `}
  }
  & + label:hover,
  &:checked + label {
    box-shadow: ${props => props.colors.primary_color} 0px 0px 1px 1px;
    background-color: #fff;
    border-color: transparent;
    color: ${props => props.colors.primary_color};
  }
`;

export const PopularCityLabelStyled = styled.label`
  cursor: pointer;
  background-color: ${props => props.colors.secondary_shade};
  border: 1px solid ${props => props.colors.secondary_shade};
  border-radius: 2em;
  font-size: 0.87rem;
  line-height: 12px;
  letter-spacing: 1px;
  padding: 13px 26px;
  @media (max-width: 480px) {
    min-width: fit-content;
    padding: 13px 20px;
    font-size: 12px !important;
  }
`;

export const LocationInputContainer = styled.div`
  position: relative;
  & p.location__loading__state {
    margin-top: 10px;
  }
`;

export const ErrorMessageWrapper = styled.div`
  width: 70%;
  margin: 3px auto;
`;

export const MemberLocationInfoWrapper = styled.div`
  width: 70%;
  margin-top: 3rem;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const CityDropDownStyles = styled.ul`
  background-color: white;
  box-shadow: ${props => {
    return props.length <= 1 ? "none" : "0 2px 3px 0 rgba(189, 213, 218, 0.57)";
  }};
  border-radius: 4px;
  margin-top: 2px;
  overflow: hidden;

  li {
    cursor: pointer;
    list-style: none;
    padding: 0.6em;
    &:first-child {
      background-color: ${props =>
        !props.mouseEntered && props.colors.secondary_color};
      color: ${props => (!props.mouseEntered ? "white" : "black")};
    }
    &:hover {
      color: white;
      background-color: ${props => props.colors.secondary_color};
    }
  }
`;

export const Group = styled.div`
  width: 130px;
  white-space: nowrap;
  text-transform: capitalize;
  overflow: hidden !important;
  text-overflow: ellipsis;
  font-size: 0.9em;
  font-weight: 600;
  position: relative;
  color: ${props => (props.active ? "#fff" : "black")};
  padding: 0.75rem 0.8rem;
  background: ${props => {
    return props.active
      ? props.colors.primary_color
      : props.colors.secondary_shade;
  }};
  border-radius: 1000px;
  text-align: center;
  transition: width 4s;

  &:hover {
    min-width: 130px;
    width: max-content;
    white-space: wrap;
    overflow: none;
    text-overflow: none;
  }
`;

export const GroupContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 3rem;
  @media (max-width: 768px) {
    margin: 0 auto;
    overflow: auto;
    height: 80px;
    text-align: center;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const GroupWrapper = styled.div`
  position: relative;
  cursor: pointer;
color:${props => props.colors.primary_color}
  &:first-child {
    margin-left: auto;
  }
  &:last-child {
    margin-right: auto;
  }

  & span {
    content: "";
    width: 15px;
    height: 10px;
    clip-path: polygon(0 0, 50% 100%, 100% 0);
    -webkit-clip-path: polygon(0 0, 50% 100%, 100% 0);
    background: ${props => props.color};
    display: ${props => (props.active ? "block" : "none")};
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 90%);
  }
`;
