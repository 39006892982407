import { IoAddCircleOutline } from "react-icons/io5";
import { GrSubtractCircle } from "react-icons/gr";
import styled from "styled-components";
import { FLEX_ROW_CENTER, FWEIGHT, ROUNDED } from "src/styles/styleGuide";

const AddField = ({ value, onChange, initialValue, maxValue }) => {
  const handleButtonClick = action => {
    let updatedValue = value || initialValue;
    if (action === "add") {
      updatedValue++;
    } else {
      updatedValue--;
    }

    onChange(
      {
        target: {
          value: updatedValue,
        },
      },
      updatedValue,
    );
  };

  return (
    <ButtonGroupStyled>
      {!value || value < maxValue ? (
        <ButtonStyled onClick={() => handleButtonClick("add")}>
          <IoAddCircleOutline size={16} /> Add another claim
        </ButtonStyled>
      ) : (
        <></>
      )}
      {value && value !== initialValue ? (
        <ButtonStyled onClick={() => handleButtonClick("remove")}>
          <GrSubtractCircle size={13} /> Remove claim
        </ButtonStyled>
      ) : (
        <></>
      )}
    </ButtonGroupStyled>
  );
};

export default AddField;

const ButtonGroupStyled = styled.div`
  ${FLEX_ROW_CENTER};
  gap: 1rem;
`;
const ButtonStyled = styled.button`
  ${FLEX_ROW_CENTER};
  color: #090909;
  font-size: 14px;
  gap: 5px;
  font-weight: ${FWEIGHT.md};
  border: 1px solid black;
  padding: 10px;
  border-radius: ${ROUNDED.base};
`;
