import { useParams } from "react-router-dom";
// CUSTOM HOOKS
import { useAdditionalDiscount } from "src/customHooks";
// UTILS
import { amount } from "src/utils/string.utility";
// CUSTOM COMPONENTS
import { CartDetailRow, CollapseCartSection } from ".";

export default function DiscountsList({ groupCode: gp, ...props }) {
  const tempGp = +useParams()?.groupCode;

  const groupCode = +(gp || tempGp);

  const { getSelectedAdditionalDiscounts } = useAdditionalDiscount(groupCode);

  const selectedAdditionalDiscounts = getSelectedAdditionalDiscounts();

  if (!selectedAdditionalDiscounts?.length) return null;

  return (
    <CollapseCartSection initialState={true} title="Discounts" {...props}>
      {selectedAdditionalDiscounts.map(additionalDiscount => (
        <DiscountDetails
          additionalDiscount={additionalDiscount}
          groupCode={groupCode}
          key={additionalDiscount?.alias}
        />
      ))}
    </CollapseCartSection>
  );
}

function DiscountDetails({ additionalDiscount, groupCode, ...props }) {
  const { name, is_discount_default = false } = additionalDiscount;

  const { getDiscountAmount } = useAdditionalDiscount(groupCode);

  const discountAmount = getDiscountAmount(additionalDiscount);

  return (
    <CartDetailRow
      id={`auto_${name}`}
      data-auto-test-script="tag-content"
      title={name}
      value={is_discount_default ? "Included" : "- " + amount(discountAmount)}
      {...props}
    />
  );
}
