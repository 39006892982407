import ReactSelect from "react-select";
import styled from "styled-components";
import { useGetEnquiry } from "src/customHooks/useGetCommonQueries";
import { getAge, getReactSelectOption } from "src/utils/helper";
import { generateAge } from "src/components/FormBuilder/formUtils";
import { FWEIGHT, ROUNDED, SHADES, SHADOW } from "src/styles/styleGuide";
import { useDispatch, useSelector } from "react-redux";
import { setShowErrorPopup } from "../ProposalSections/ProposalSections.slice";
import useAppropriateOptions from "./useAppropriateOptions";
import { callApi } from "src/components/FormBuilder/FormBuilder.slice";
import { useEffect } from "react";
import { OuterLabel, OuterLabelContainer } from "./DropDown";
import CustomToolTip from "src/components/Common/CustomTooltip";

const SearchableDropDown = ({
  name,
  values,
  label,
  onChange,
  height = false,
  options = { value: "key" },
  value,
  selectedValues,
  allValues = {},
  error,
  asyncOptions,
  readOnly,
  checkValidation,
  deleteValue = () => {},
  directUpdateValue = () => {},
  invalidateOption,
  fill = false,
  relation,
  formName,
  tooltip_msg = "",
}) => {
  const { selectOption } = useAppropriateOptions({
    values,
    allValues,
    asyncOptions,
    options,
    label,
    name,
    directUpdateValue,
    value,
    fill,
    deleteValue,
  });
  const {
    data: {
      data: {
        input: { members },
      },
    },
  } = useGetEnquiry();
  const proposalSelectedDOBRedux = useSelector(
    ({ proposalPage }) => proposalPage?.proposalData["Proposer Details"]?.dob,
  );
  const dispatch = useDispatch();

  let proposerAgeTemp = parseInt(
    members?.filter(i => i.type === "self")[0]?.age,
  );

  let currentYear = new Date().getFullYear();

  let estimatedProposerDOB = `${currentYear - proposerAgeTemp}`;
  const proposerAge = getAge(
    selectedValues?.dob || proposalSelectedDOBRedux || estimatedProposerDOB,
  );

  const rawLabel = label;

  const changeHandler = values => {
    const { label, value } = values;
    if (
      invalidateOption &&
      invalidateOption?.option &&
      invalidateOption.option === value
    ) {
      dispatch(
        setShowErrorPopup({
          show: true,
          head: "",
          msg: invalidateOption.message,
        }),
      );
    } else {
      onChange({ target: { name: label, value } }, value);
    }
  };

  const validOptionsGenerator = (selectOption = {}) => {
    let rawOptions = Object.keys(selectOption).map(key => ({
      display_name: selectOption[key],
      code: key,
    }));

    rawOptions = rawOptions.map(item => ({
      ...item,
      display_name: item.display_name,
    }));

    //! ------ VALIDATIONS ------

    if (
      selectedValues?.title &&
      selectedValues.title === "mrs" &&
      label === "Marital Status*"
    ) {
      //? Martial status should render all except single.
      rawOptions = rawOptions.filter(item => item?.code !== "single");
    } else if (
      selectedValues?.title &&
      selectedValues?.title === "mr" &&
      label === "Marital Status*" &&
      proposerAge < 21
    ) {
      //? Martial status render only single.
      rawOptions = rawOptions.filter(item => item?.code === "single");
    } else if (
      selectedValues?.title &&
      selectedValues?.title === "mr" &&
      label === "Occupation*"
    ) {
      //? Occupation should not render "House wife".
      rawOptions = rawOptions.filter(item => item?.code !== "House wife");
    } else if (
      formName === "Insured Details" &&
      relation.includes("daughter") &&
      label === "Title*" &&
      generateAge({ dob: values?.dob }) < 18
    ) {
      rawOptions = rawOptions.filter(item => !item?.code?.includes("mrs"));
    } else if (
      allValues?.["Proposer Details"]?.marital_status === "single" &&
      name === "nominee_relation"
    ) {
      //? Nominee relation should not render "in laws" for single proposer.
      rawOptions = rawOptions.filter(
        item =>
          !item?.code?.endsWith("_in_law") &&
          !item?.code?.includes("son") &&
          !item?.code?.includes("daughter") &&
          !item?.code?.includes("spouse"),
      );
    }

    return rawOptions;
  };

  useEffect(() => {
    if (value) {
      if (
        selectOption[value] &&
        values &&
        values[name] !== selectOption[value] &&
        !fill
      ) {
        directUpdateValue(name, value);
      } else if (!selectOption[value]) deleteValue();
    }
    if (fill && name.includes("town")) {
      if (values[name] && fill) {
        dispatch(
          callApi(fill.using, {
            [name]: values[name],
            [fill.alsoUse]: values[fill.alsoUse],
          }),
          fill,
        );
      }
    }

    return () => {};
  }, [value]);

  return (
    <SelectContainer height={height}>
      {rawLabel.length >= 30 && (
        <OuterLabelContainer>
          <CustomToolTip
            disable={!tooltip_msg.length}
            description={tooltip_msg}
            showInfoIcon={tooltip_msg.length}
            className="tooltip"
          ></CustomToolTip>
          <OuterLabel mandatory={checkValidation?.required}>
            {rawLabel}
          </OuterLabel>
        </OuterLabelContainer>
      )}
      <ReactSelect
        closeMenuOnSelect={true}
        options={validOptionsGenerator(selectOption).map(getReactSelectOption)}
        hideSelectedOptions={false}
        value={
          value
            ? { label: selectOption[value], value: selectOption[value] }
            : null
        }
        onChange={e => changeHandler(e)}
        isDisabled={readOnly}
        placeholder={"- Select -"}
        styles={{
          menu: provided => ({
            ...provided,
            borderRadius: ROUNDED.md,
            border: `1px solid ${SHADES.LIGHT_GRAY}`,
            overflow: "hidden",
            padding: "0.125rem 0",
            boxShadow: SHADOW.lg,
          }),
          option: (provided, state) => ({
            ...provided,
            fontSize: "12px",
            padding: "2px 10px 2px 10px",
            color: state.isSelected ? SHADES.WHITE : SHADES.SUB_HEADING,
          }),

          control: base => ({
            ...base,
            boxShadow: "none",
            width: "100%",
            height: "40px",
            padding: "0.1rem 0.5rem",
          }),
          placeholder: provided => ({
            ...provided,
            fontSize: "0.8rem",
          }),
          container: provided => ({
            ...provided,
            width: "100%",
            border: `1px solid ${SHADES.LIGHT_GRAY}`,
          }),
          indicatorSeparator: provided => ({
            ...provided,
            display: "none",
          }),
          singleValue: provided => ({
            ...provided,
            fontSize: "0.9rem",
          }),
          dropdownIndicator: (provided, state) => ({
            ...provided,
            padding: 0,
            color: SHADES.SUB_HEADING,
            marginLeft: "0.5rem",
            transition: "all .2s ease",
            transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
          }),
          valueContainer: provided => ({
            ...provided,
            padding: "0",
            flexDirection: "row",
            cursor: "pointer !important",
          }),
          input: provided => ({
            ...provided,
            caretColor: "transparent",
            cursor: "pointer",
            padding: 0,
            margin: 0,
          }),
        }}
      />
      {rawLabel.length < 30 && (
        <Label height={height} mandatory={checkValidation?.required}>
          {rawLabel}
        </Label>
      )}
      {error && <p className="formbuilder__error">{error}</p>}
    </SelectContainer>
  );
};

export default SearchableDropDown;

export const SelectContainer = styled.div`
  margin-top: ${props => {
    return !props.height ? "0.3rem !important" : "9px !important";
  }};
  position: relative;

  margin-bottom: ${props => {
    return !props.height ? "12px !important" : "9px !important";
  }};

  @media (max-width: 767px) {
    margin-bottom: 12px !important;
    margin-top: ${props => !props.height && "15px !important"};
  }
`;

export const Label = styled.label`
  text-align: left;
  display: inline-block;
  font-size: 13px !important;
  color: #000;
  position: absolute;
  left: 8px;
  top: 0;
  margin: 0;
  background: #fff;
  transition: all 0.3s ease-in-out;
  font-weight: ${FWEIGHT.semi};
  transform: translateY(-50%);
  padding: 0 4px;

  ${({ mandatory }) => {
    return mandatory ? "&::after {  content: '*'; color: red;}" : "";
  }}
`;
