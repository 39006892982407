import React, { useEffect } from "react";
import "styled-components";
import styled from "styled-components/macro";
import { useDispatch } from "react-redux";
import { setShowPlanNotAvail } from "../ProposalSections/ProposalSections.slice";

const CustomCheckBox = ({
  name,
  placeholder,
  value = "N",
  onChange,
  innerMember,
  notAllowed = false,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!value) onChange({ target: { value: "N" } });
  }, []);

  useEffect(() => {
    if (value === "Y" && notAllowed) {
      dispatch(setShowPlanNotAvail(true));
    }
    return () => {};
  }, [value]);

  return (
    <CustomCheckBoxStyled>
      <input
        className="inp-cbx"
        id={name + innerMember}
        type="checkbox"
        checked={value === "Y" ? true : false}
        onChange={onChange}
      />
      <label className="cbx" htmlFor={name + innerMember}>
        <span>
          <svg width="12px" height="10px">
            <use xlinkHref="#check"></use>
          </svg>
        </span>
      </label>
      <svg className="inline-svg">
        <symbol id="check" viewBox="0 0 12 10">
          <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
        </symbol>
      </svg>
      <Question> {placeholder}</Question>
    </CustomCheckBoxStyled>
  );
};

export default CustomCheckBox;

const Question = styled.span`
  margin-left: 7px !important;

  &:after {
    content: "";
    height: 22px;
    width: 6px;
    position: absolute;
    left: -2px;
    top: 2px;
    background-color: ${props => props.SecondaryColor};
    border-radius: 50px;
    @media (max-width: 767px) {
      height: calc(100% - 6px);
    }
  }
  @media (max-width: 767px) {
    font-size: 14px !important;
    font-family: "Inter-Regular";
  }
`;

const CustomCheckBoxStyled = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;
