import useProposalSchema from "src/customHooks/useProposalSchema";
import ProductSummary from "./components/ProductSummary";
import SchemaError from "./components/SchemaError";
import ProposalForm from "./ProposalForm";
import styled from "styled-components/macro";
import ProposalLoader from "./components/ProposalLoader";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProposalData } from "./ProposalSections.slice";
import { useUrlQueries } from "src/customHooks/useUrlQuery";
import ProposalTabs from "./components/ProposalTabs";
import { useResponsiveCondition } from "src/pos/hooks";
import ProposalCartMobile from "./ProposalCartMobile";

const MainBody = () => {
  const { listOfForms } = useProposalSchema();
  const { width, breakpoint } = useResponsiveCondition(1225);

  const [isContinueClicked, setIsContinueClicked] = useState("");

  return (
    <>
      {width > breakpoint ? <ProposalTabs listOfForms={listOfForms} /> : <></>}
      <Outer>
        <AreaWrapper>
          {width > 768 ? (
            <ProductSummary />
          ) : (
            <ProposalCartMobile setIsContinueClicked={setIsContinueClicked} />
          )}
        </AreaWrapper>
        <FormSection
          isContinueClicked={isContinueClicked}
          setIsContinueClicked={setIsContinueClicked}
        />
      </Outer>
    </>
  );
};

const FormSection = ({ isContinueClicked, setIsContinueClicked }) => {
  const dispatch = useDispatch();
  const { enquiryId } = useUrlQueries();
  const { schemaError, schemaRefetch, schemaLoading } = useProposalSchema();
  const { proposalDataLoading } = useSelector(state => state.proposalPage);

  //? Initial fetching of proposal data.
  useEffect(() => {
    dispatch(getProposalData());
  }, [enquiryId]);

  if (schemaLoading || proposalDataLoading)
    return <ProposalLoader message={"Preparing Proposal, Please wait..."} />;

  if (schemaError)
    return (
      <SchemaError
        title="Failed To Fetch Proposal Schema."
        refetch={schemaRefetch}
      />
    );

  return (
    <ProposalForm
      isContinueClicked={isContinueClicked}
      setIsContinueClicked={setIsContinueClicked}
    />
  );
};

const Outer = styled.section`
  @media (min-width: 1225px) {
    display: flex;
    flex-direction: row;
  }
`;

const AreaWrapper = styled.div`
  margin-bottom: 20px;

  @media (min-width: 1225px) {
    max-width: 330px;
    min-width: 330px;
    margin-right: 26px;
  }

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export default MainBody;
