import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useCallApiMutation } from "src/api/api";
import styled from "styled-components/macro";
import { Button } from "src/components/Common/Button/ContinueButton";
import OtpVerificationModal from "../../../ProposalSummary/OtpVerificationModal";
import {
  setProposalRefetchConfirmationModal,
  setProposalRefetchModal,
} from "../../ProposalSections/ProposalSections.slice";
import useOTPTextField from "./useOTPTextField";

const OTPTextField = ({
  name,
  label,
  placeholder,
  onChange,
  checkValidation = {},
  value,
  maxLength,
  minLength,
  triggerOnly = false,
  mode,
  endpoint,
  asyncValidate,
  textTransform,
  otp_verification_mode,
  onAsyncSuccess = () => {},
  onAsyncFailure = () => {},
  error,
  buttonText = "OTP Validation",
}) => {
  const dispatch = useDispatch();
  const [triggerMutation, { isLoading }] = useCallApiMutation();
  const {
    input,
    warningText,
    status,
    showModal,
    inputErrorUtility,
    setFocused,
    setInput,
    setStatus,
    setWarningText,
    toggleModal,
  } = useOTPTextField({ value, asyncValidate });

  const disableSave =
    input.length < minLength ||
    input.length > maxLength ||
    inputErrorUtility?.toShow;

  const inputChangeHandler = e => {
    if (maxLength && e.target.value.length > maxLength) return;

    if (textTransform === "uppercase") {
      setInput(e.target.value.toUpperCase());
      return;
    }
    setInput(e.target.value);
  };

  const dummyFunction = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  //* Will only trigger otp never open verification modal.
  const triggerOnlyHandler = async ({
    mode = "",
    input = "",
    endpoint = "",
  }) => {
    try {
      const response = await triggerMutation({
        url: endpoint,
        body: { mode: mode, payload: input },
        method: "POST",
      });
      if (response?.error) {
        throw new Error();
      }
      setStatus("success");
      toast.success("OTP sent successfully.");
      onChange(
        {
          target: {
            value: input,
          },
        },
        input,
      );
      return 1;
    } catch {
      setStatus("failure");
      toast.error("Failed to send OTP.");
      onChange(
        {
          target: {
            value: "failure",
          },
        },
        "failure",
      );
      return 0;
    }
  };

  const triggerOTPHandler = e => {
    e.preventDefault();
    e.stopPropagation();
    if (triggerOnly) {
      triggerOnlyHandler({ endpoint, mode, input });
    } else {
      toggleModal();
    }
  };

  const otpVerifiedHandler = data => {
    setStatus("success");
    setWarningText("");
    onAsyncSuccess();
    onChange(
      {
        target: {
          value: input,
        },
      },
      input,
    );
    if (data?.refetch_proposal_data) {
      dispatch(
        setProposalRefetchModal({
          visible: true,
          title:
            "We have updated some of your details based on your CKYC. you can check later in the Proposer Details section.",
        }),
      );
    }
    if (data?.refetch_proposal_data_confirmation_pop_up) {
      dispatch(
        setProposalRefetchConfirmationModal({
          visible: true,
          title: `
              ${
                data?.refetch_proposal_data_confirmation_msg.length > 0
                  ? data?.refetch_proposal_data_confirmation_msg
                  : "We will update information based on your CKYC."
              }
               Click 'YES' to continue with updated information or click 'NO' to continue with current information.
              `,
        }),
      );
    }
  };

  const failedToVerifyHandler = () => {
    setStatus("failure");
    setWarningText("Failed to verify the OTP.");
    onAsyncFailure();
    onChange(
      {
        target: {
          value: "failure",
        },
      },
      "failure",
    );
  };

  let displayOnButton = buttonText;
  if (status === "success") {
    displayOnButton = "Verified";
  }
  if (status === "failure") {
    displayOnButton = "Retry";
  }

  return (
    <>
      <InputContainer status={status}>
        <div className="input_container">
          <Input
            id={name}
            placeholder={placeholder}
            onChange={inputChangeHandler}
            value={input}
            onFocus={() => setFocused(true)}
          />
          <Button
            className="success_btn"
            disabled={disableSave}
            onClick={status === "success" ? dummyFunction : triggerOTPHandler}
            loader={isLoading}
          >
            {displayOnButton}
          </Button>
        </div>

        <Label mandatory={checkValidation?.required === true}>
          {label || ""}
        </Label>

        {error ||
          (inputErrorUtility?.exist ? (
            <p className="formbuilder__error">
              {error || inputErrorUtility?.toShow}
            </p>
          ) : (
            <></>
          ))}
        {warningText ? (
          <p className="formbuilder__warning">{warningText}</p>
        ) : (
          <></>
        )}
      </InputContainer>
      {showModal ? (
        <OtpVerificationModal
          onClose={toggleModal}
          details={{
            mode,
            otp_verification_mode,
            endpoint,
            payload: input,
            customOTPVerification: true,
            successCallback: otpVerifiedHandler,
            failureCallback: failedToVerifyHandler,
          }}
        />
      ) : (
        <></>
      )}
    </>
  );
};

const InputContainer = styled.div`
  margin-top: 0.3rem !important;
  position: relative;
  margin-bottom: 2px !important;
  .input_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .success_btn {
    height: 55px;
    min-width: 150px;
    ${({ status }) => {
      status === "success" && "background:green";
    }};
  }
`;

const Input = styled.input`
  list-style: none;
  -webkit-user-select: text;
  list-style-type: none;
  -webkit-user-select: initial;
  -khtml-user-select: initial;
  -moz-user-select: initial;
  -ms-user-select: initial;
  user-select: initial;
  &:before,
  &:after {
    -webkit-user-select: initial;
    -khtml-user-select: initial;
    -moz-user-select: initial;
    -ms-user-select: initial;
    user-select: initial;
  }
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  margin: 0;
  text-transform: ${props => props.textTransform};
  font-family: inherit;
  line-height: inherit;
  overflow: visible;
  outline: none;
  box-shadow: none;
  transition: all 0.3s ease-in-out;
  touch-action: manipulation;
  width: 100%;
  border: ${props => (props.error ? "solid 1px #c7222a" : "solid 1px #ced4da")};
  height: 55px;
  font-size: 14px;
  cursor: ${props => {
    return props.isHovering && props.readOnly ? "not-allowed" : "pointer";
  }};
  color: #939393;
  position: relative;
  padding: 0 25px;
  border-right: unset;
  &:focus {
    border-color: ${props => (props.error ? "#c7222a" : "solid 1px  #393939")};
    color: black;
  }
  &:before {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  &:after {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  @media (max-width: 767px) {
    height: 52px;
    padding: 0 16px;
    font-size: 14px;
  }
`;

const Label = styled.label`
  text-align: left;
  list-style: none;
  list-style-type: none;
  user-select: none;
  box-sizing: border-box;
  touch-action: manipulation;
  display: inline-block;
  font-size: 14px !important;
  color: #000;
  line-height: 14px;
  position: absolute;
  left: 20px;
  top: 0;
  margin: 0;
  max-width: 95%;
  background: #fff;
  transition: all 0.3s ease-in-out;
  font-weight: 600;
  padding: 0 5px;
  transform: translateY(-60%);

  ${({ mandatory }) => {
    return mandatory ? "&::after {  content: '*'; color: red;}" : "";
  }}

  @media (max-width: 1200px) {
    font-size: 13px !important;
  }
  @media (max-width: 1100px) {
    font-size: 12px !important;
  }
  @media (max-width: 1050px) {
    font-size: 12px !important;
  }
  @media (max-width: 767px) {
    left: 10px;
    font-size: 14px;
  }
`;

export default OTPTextField;
