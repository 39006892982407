import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// REACT ICONS
import { IoReload } from "react-icons/io5";
// CUSTOM HOOKS
import { useCart, useTheme } from "src/customHooks";
import useRiders from "src/pages/ProductDetails/Hooks/useRiders";
import { useGetEnquiry } from "src/customHooks/useGetCommonQueries";
// UTILS
import { matchCurrentRoute } from "src/utils/productDetailsPageUtils";
import { setRiderActionBtnClicked } from "src/pages/ProductDetails/productDetails.slice";
// DESIGN SYSTEM
import FHTypography from "src/styles/Typography";
// STYLED COMPONENTS
import {
  LoadingErrorWrapperStyled,
  RiderCardWrapperStyled,
  RiderSkeletonLoaderStyled,
} from "./RiderSection.style";
// CUSTOM COMPONENTS
import RiderCard from "./RiderCard";
import FreeRiderCard from "./FreeRiderCard";
import SectionWrapper from "src/pages/ProductDetails/components/Sections/SectionWrapper";

export default function RiderSection({ riderSectionRef }) {
  const { groupCode } = useGetEnquiry();
  const { getCartEntry } = useCart();

  const quote = getCartEntry(groupCode);
  return (
    <Riders
      quote={quote}
      groupCode={groupCode}
      riderSectionRef={riderSectionRef}
      showRiderActions={true}
    />
  );
}

export function Riders({
  quote,
  groupCode,
  riderSectionRef,
  showRiderActions,
}) {
  const dispatch = useDispatch();
  const isOnProposalOrLaterRoute = matchCurrentRoute({
    matcher: "gte",
    route: "proposal",
  });
  const heading = isOnProposalOrLaterRoute
    ? "Selected Optional Covers"
    : "Customize Your Plan";
  const { riderActionBtnClicked } = useSelector(state => state.productPage);

  const {
    isLoading,
    isError,
    refetch,
    apiRiders,
    riders,
    freeRiders,
    selectedFreeRider,
    handleRiderChange,
    handleFreeRiderChange,
  } = useRiders({
    quote,
    groupCode,
    skipToken: !showRiderActions,
  });

  //* used for render riderCard on productDetail and seeDetail after pd route
  const riderRenderLength = isOnProposalOrLaterRoute
    ? riders.length > 0
    : riders.length > 0 && apiRiders.length > 0;

  const loadingRenderLength = isOnProposalOrLaterRoute
    ? riders.length === 0
    : apiRiders.length > 0 && riders.length === 0;

  useEffect(() => {
    if (riderActionBtnClicked) {
      setTimeout(() => {
        dispatch(setRiderActionBtnClicked(false));
      }, 2250);
    }
    return () => {};
  }, [riderActionBtnClicked]);

  if (isLoading || loadingRenderLength)
    return (
      <SectionWrapper
        heading="Customize Your Plan"
        subHeading="You can add 'Optional Covers' to your health insurance plans. 'Optional Covers' also known as 'Add-Ons' provide additional benefits that would be paid for in addition to the base insurance premium."
        id="additional-riders"
      >
        <RiderSkeletonLoader />
      </SectionWrapper>
    );

  if (isError)
    return (
      <SectionWrapper
        heading="Customize Your Plan"
        subHeading="You can add 'Optional Covers' to your health insurance plans. 'Optional Covers' also known as 'Add-Ons' provide additional benefits that would be paid for in addition to the base insurance premium."
        id="additional-riders"
        sectionRef={riderSectionRef}
      >
        <LoadingError refetch={refetch} />
      </SectionWrapper>
    );

  const totalRiderCount = freeRiders?.length
    ? riders?.length - 1
    : riders?.length;

  const riderDivideLength = Math.ceil(totalRiderCount / 2);

  const col1Rider = riders?.slice(0, riderDivideLength);
  const col2Rider = riders.slice(riderDivideLength);

  if (riderRenderLength) {
    return (
      <SectionWrapper
        id="additional-riders"
        heading={heading}
        subHeading="You can add 'Optional Covers' to your health insurance plans. 'Optional Covers' also known as 'Add-Ons' provide additional benefits that would be paid for in addition to the base insurance premium."
        sectionRef={riderSectionRef}
      >
        <RiderCardWrapperStyled>
          <div className="rider-column">
            {freeRiders?.length > 0 && (
              <FreeRiderCard
                freeRiders={freeRiders}
                selectedFreeRider={selectedFreeRider}
                onChange={handleFreeRiderChange}
              />
            )}
            {col1Rider?.map((rider, index) => (
              <RiderCard
                key={rider?.id}
                rider={rider}
                onChange={handleRiderChange}
                showRiderActions={showRiderActions}
                riderActionBtnClicked={index === 0 && riderActionBtnClicked}
              />
            ))}
          </div>
          <div className="rider-column">
            {col2Rider?.map((rider, index) => (
              <RiderCard
                key={rider?.id}
                rider={rider}
                onChange={handleRiderChange}
                showRiderActions={showRiderActions}
                riderActionBtnClicked={index === 0 && riderActionBtnClicked}
              />
            ))}
          </div>
        </RiderCardWrapperStyled>
      </SectionWrapper>
    );
  }

  return (
    <SectionWrapper
      id="additional-riders"
      heading={heading}
      subHeading="'Optional Covers' also known as 'Add-Ons' provide additional benefits that would be paid for in addition to the base insurance premium."
      sectionRef={riderSectionRef}
    >
      <FHTypography variant="h4" weight="md" colorType="subHeading">
        {isOnProposalOrLaterRoute
          ? "No Optional Covers selected for this plan."
          : "No Optional Covers available for this plan."}
      </FHTypography>
    </SectionWrapper>
  );
}

const RiderSkeletonLoader = () => {
  return (
    <RiderSkeletonLoaderStyled>
      {[1, 2].map(index => (
        <div className="rider-card" key={index}>
          <div className="rider-premium-button">
            <div className="checkbox"></div>
          </div>
          <div className="rider-info-and-option">
            <div className="rider-name"></div>
            <div className="rider-description"></div>
            <div className="rider-options-wrapper">
              <div className="rider-option"></div>
              <div className="rider-option"></div>
            </div>
          </div>
        </div>
      ))}
    </RiderSkeletonLoaderStyled>
  );
};

const LoadingError = ({ refetch }) => {
  const { colors } = useTheme();
  return (
    <LoadingErrorWrapperStyled primary_color={colors.primary_color}>
      <FHTypography variant="h5" weight="base">
        Something went wrong while getting riders!
      </FHTypography>
      <button onClick={refetch}>
        <IoReload />
        <FHTypography variant="h6" weight="md">
          Retry
        </FHTypography>
      </button>
    </LoadingErrorWrapperStyled>
  );
};
