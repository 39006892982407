import { useDispatch, useSelector } from "react-redux";
import useProposalSchema from "src/customHooks/useProposalSchema";
import { setActiveIndex } from "./ProposalSections.slice";
import useKYCDetails from "./useKYCDetails";

const useFormNavigation = () => {
  const { activeIndex } = useSelector(state => state.proposalPage);

  const { kycVerified } = useKYCDetails();
  const dispatch = useDispatch();
  const { listOfForms, schemaExist } = useProposalSchema();

  const activeForm = listOfForms[activeIndex];

  const formIndexGenerator = (formName = "") => {
    return listOfForms.findIndex(nameOfForm => nameOfForm === formName);
  };

  const navigateTo = ({
    formName = "Proposer Details / Insured Details / Medical Details / Other Details / Port Details / KYC Details",
    back = false,
  }) => {
    if (back) {
      return dispatch(setActiveIndex(activeIndex === 0 ? 0 : activeIndex - 1));
    }
    if (schemaExist) {
      return dispatch(setActiveIndex(formIndexGenerator(formName)));
    }
    return;
  };

  const getUnfilledForm = updatedProposalData => {
    return listOfForms.find(key => {
      //? To skip the KYC Details form in case of KYC is verified.
      if (key === "KYC Details") {
        return kycVerified === true ? false : !updatedProposalData[key];
      }
      return !updatedProposalData[key];
    });
  };

  return {
    formIndexGenerator,
    navigateTo,
    activeForm,
    getUnfilledForm,
  };
};

export default useFormNavigation;
