import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import { FWEIGHT, ROUNDED } from "src/styles/styleGuide";
import { useTheme } from "src/customHooks";
import { RiLoader4Fill } from "react-icons/ri";

const ContinueBtn = ({ onClick, disabled = false, btnId }) => {
  const { isLoading } = useSelector(state => state.proposalPage);
  const { colors } = useTheme();

  return (
    <BtnStyled
      btnId={btnId}
      onClick={isLoading ? () => {} : onClick}
      noIcon
      disabled={disabled}
      primaryColor={colors.primary_color}
    >
      Continue {isLoading && <RiLoader4Fill className="rotate" size={20} />}
    </BtnStyled>
  );
};

export default ContinueBtn;

const BtnStyled = styled.button`
  width: 200px;
  letter-spacing: 1px;
  font-size: 14px;
  font-weight: ${FWEIGHT.semi};
  border-radius: ${ROUNDED.base};
  border: 1px solid ${props => props.primaryColor} !important;
  padding: 12px 16px;
  background-color: ${props => props.primaryColor};
  color: rgb(255, 255, 255);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;

  &:hover {
    filter: brightness(0.9);
  }
  &:disabled {
    cursor: default;
    opacity: 0.8;
  }
`;
