import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//  CUSTOM HOOKS
import useGetRiderQueryOptions from "./useGetRiderQueryOptions";
import { useFrontendBoot } from "src/customHooks/useGetCommonQueries";
import { useFeatureOptions, useGetRiders, useRider } from "src/customHooks";
// UTILS
import {
  ridersValidations,
  getSelectedRiders,
  selectCartRiders,
  selectQuoteRiders,
  filterInvalidRiders,
  matchCurrentRoute,
} from "src/utils/productDetailsPageUtils";
import {
  setFeatureDependentRiders,
  setRiders as setReduxRider,
  setRidersLoading,
} from "src/pages/ProductDetails/productDetails.slice";
import { setSeeDetailsQuoteAction } from "src/slices/utility.slice";

let isSIChanged = false;

export default function useRiders({ groupCode, quote, skipToken }) {
  const dispatch = useDispatch();
  const { journeyType, subJourneyType } = useFrontendBoot();

  const setRiders = riders => dispatch(setReduxRider(riders));
  const { riders } = useSelector(state => state.productPage);

  const { getSelectedFeatureOptionsAlias, getMandatoryFeatureOption } =
    useFeatureOptions(groupCode);
  const selectedFeatureOptions = getSelectedFeatureOptionsAlias(groupCode);

  const { getSelectedRiders: getCartRiders, replaceRiders: updateCartRiders } =
    useRider(groupCode);
  const { selectedFeatureAlias } = getMandatoryFeatureOption();
  const isOnPdOrLaterRoute = matchCurrentRoute({
    matcher: "gte",
    route: "productdetails",
  });

  const riderQueryOptions = useGetRiderQueryOptions({ groupCode, riders });
  const { data, isLoading, isFetching, isError, refetch } = useGetRiders(
    quote,
    groupCode,
    {
      queryOptions: riderQueryOptions,
    },
    skipToken,
  );

  dispatch(setRidersLoading(isLoading || isFetching));

  const handleFeatureDependentRiders = ({ riderAlias, featureAlias }) => {
    dispatch(setFeatureDependentRiders({ riderAlias, featureAlias }));
  };

  const [prevSumInsured, setPrevSumInsured] = useState(quote?.sum_insured);

  useEffect(() => {
    // Check if the sum_insured has been changed
    if (+prevSumInsured !== +quote?.sum_insured) {
      isSIChanged = true;
    }
    setPrevSumInsured(quote?.sum_insured);
  }, [quote?.sum_insured, prevSumInsured]);

  useEffect(() => {
    if (isOnPdOrLaterRoute) {
      updateCartRiders(getSelectedRiders(riders));
    } else {
      dispatch(
        setSeeDetailsQuoteAction({
          ...quote,
          [`${journeyType}_riders`]: riders,
        }),
      );
    }
  }, [riders]);

  useEffect(() => {
    const cartRiders = selectCartRiders(getCartRiders());
    const quoteRiders = selectQuoteRiders(quote?.[`${journeyType}_riders`]);
    const initialRiders = isOnPdOrLaterRoute ? cartRiders : quoteRiders;
    if (
      data?.data &&
      matchCurrentRoute({ route: "productdetails", matcher: "lte" })
    ) {
      const currentlySelectedRidersAlias = isSIChanged
        ? []
        : getSelectedRiders(initialRiders)?.map(rider => rider.alias);

      isSIChanged = false;

      const fetchedRiders = data?.data?.map(rider => ({
        ...rider,
        isSelected: currentlySelectedRidersAlias?.includes(rider.alias),
        disable: false,
      }));
      const validatedRiders = ridersValidations({
        riders: fetchedRiders,
        quote,
        subJourneyType,
        selectedFeatureOptions,
      });
      setRiders(validatedRiders);
      return;
    }

    const validatedRiders = ridersValidations({
      riders: initialRiders,
      quote,
      subJourneyType,
      selectedFeatureOptions,
    });
    setRiders(validatedRiders);
  }, [data]);

  const handleRiderChange = useCallback(
    changedRider => {
      const updatedRiders = riders?.map(rider => {
        return rider?.id === changedRider?.id ? changedRider : rider;
      });

      const validatedRiders = ridersValidations({
        riders: updatedRiders,
        subJourneyType,
        selectedFeatureOptions,
        selectedFeatureAlias,
        isOnClickValidations: true,
        changedRider,
        handleFeatureDependentRiders,
      });

      setRiders(validatedRiders);
    },
    [riders],
  );

  const handleFreeRiderChange = useCallback(
    changedRider => {
      const updatedRiders = riders?.map(rider => {
        if (rider?.id === changedRider?.id) {
          return changedRider;
        }
        return rider.is_rider_free
          ? { ...rider, isSelected: false, is_free_rider_selected: false }
          : rider;
      });

      const selectedFreeRiderAlias = updatedRiders?.find(
        rider => rider?.is_rider_free && rider?.is_free_rider_selected,
      )?.alias;

      //   validate not_available_with case for free rider
      const validatedFreeRider = updatedRiders?.map(rider => {
        if (rider?.not_available_with?.includes(selectedFreeRiderAlias)) {
          return { ...rider, isSelected: false, is_free_rider_selected: false };
        }
        return rider;
      });

      setRiders(validatedFreeRider);
    },
    [riders],
  );

  const displayRiders = riders?.map(filterInvalidRiders).filter(Boolean);

  const freeRiders = riders?.filter(rider => rider.is_rider_free);
  const selectedFreeRider = freeRiders?.filter(
    rider => rider.is_free_rider_selected,
  );

  return {
    isLoading,
    isFetching,
    isError,
    refetch,
    apiRiders: data?.data || [],
    riders: displayRiders,
    freeRiders,
    selectedFreeRider: selectedFreeRider?.[0],
    handleRiderChange,
    handleFreeRiderChange,
  };
}
