import { useParams } from "react-router-dom";
// CUSTOM HOOKS
import { useFeatureOptions, useTheme } from "src/customHooks";
// UTILS
import { capitalize } from "src/utils/helper";
// CUSTOM COMPONENTS
import { CartDetailRow, CollapseCartSection } from ".";
import { amount, containsNumber } from "src/utils/string.utility";
import FHTypography from "src/styles/Typography";
import styled from "styled-components";
import { FLEX_ROW, ROUNDED } from "src/styles/styleGuide";
import { matchCurrentRoute } from "src/utils/productDetailsPageUtils";

export function flattenFeatureObject(obj) {
  let flattenedObj = {};
  for (let key in obj) {
    if (typeof obj[key] === "object" && obj[key] !== null) {
      let nestedObj = flattenFeatureObject(obj[key]);
      for (let nestedKey in nestedObj) {
        flattenedObj[nestedKey] = nestedObj[nestedKey];
      }
    } else {
      flattenedObj[key] = obj[key];
    }
  }
  return flattenedObj;
}

export default function FeaturesList({
  groupCode: gp,
  showFromSelectedList = false,
  ...props
}) {
  const tempGp = +useParams()?.groupCode;
  const { colors } = useTheme();
  const groupCode = +(gp || tempGp);
  const isOnPpOrLaterRoute = matchCurrentRoute({
    matcher: "gte",
    route: "proposal",
  });
  const { feature_options, getSelectedFeatureOptions, isLoading, isFetching } =
    useFeatureOptions(groupCode);

  const selectedFeatureOptions = getSelectedFeatureOptions(groupCode);
  const all_feature_option = feature_options.map(feature => feature.options);
  const featureArray = all_feature_option.map(item => {
    return flattenFeatureObject(item);
  });

  const featureWithKeyValue = flattenFeatureObject(featureArray);
  const featureWithKeyValueObject = {};
  Object.keys(selectedFeatureOptions).map(key => {
    if (selectedFeatureOptions[key] in featureWithKeyValue) {
      featureWithKeyValueObject[key] =
        featureWithKeyValue[selectedFeatureOptions[key]];
    }
  });

  const featureOptionsLength = showFromSelectedList
    ? selectedFeatureOptions.length
    : feature_options.length;

  const scrollToFeatureSection = () => {
    const featureSection = document.querySelector("#Plan-Feature-Options");
    window.scrollTo({
      top: featureSection.offsetTop - (window.innerWidth > 768 ? 88 : 72),
      left: 0,
      behavior: "smooth",
    });
  };

  if ((isLoading || isFetching) && !isOnPpOrLaterRoute)
    return (
      <CollapseCartSection
        initialState={true}
        title="Features"
        contentLoading={true}
      />
    );

  if (featureOptionsLength === 0) return <></>;

  return (
    <CollapseCartSection
      title="Features"
      length={Object.keys(selectedFeatureOptions)?.length}
      contentLoading={(isLoading || isFetching) && isOnPpOrLaterRoute}
    >
      {Object.keys(selectedFeatureOptions || {})?.length == 0 &&
        !isOnPpOrLaterRoute ? (
        <NoRiderSelectedWrapperStyled>
          <FHTypography
            variant="subheading2"
            weight="md"
            colorType="subHeading"
          >
            No Benefits Selected
          </FHTypography>
          <button>
            <FHTypography
              variant="subheading2"
              weight="semi"
              color={colors.primary_color}
              onClick={scrollToFeatureSection}
            >
              View Features
            </FHTypography>
          </button>
        </NoRiderSelectedWrapperStyled>
      ) : (
        <>
          {Object.keys(selectedFeatureOptions).map(feature_option_key => (
            <CartDetailRow
              key={feature_option_key}
              title={capitalize(
                feature_option_key?.startsWith("feature_")
                  ? feature_option_key.split("_").splice(1).join(" ")
                  : feature_option_key.split("_").join(" "),
              )}
              value={
                containsNumber(featureWithKeyValueObject[feature_option_key])
                  ? amount(featureWithKeyValueObject[feature_option_key])
                  : capitalize(
                    featureWithKeyValueObject[feature_option_key]
                      ?.split("_")
                      .join(" "),
                  )
              }
              {...props}
            />
          ))}
        </>
      )}
    </CollapseCartSection>
  );
}

const NoRiderSelectedWrapperStyled = styled.div`
  ${FLEX_ROW}
  justify-content: space-between;
  border: 1px dashed grey;
  border-radius: ${ROUNDED.base};
  padding: 0.75rem 0.5rem;
`;
