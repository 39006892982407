import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./ProductSummary.scss";
import { useRouteMatch } from "react-router-dom";
import { RiArrowUpSLine, RiArrowDownSLine } from "react-icons/ri";
import { FaRegCopy, FaChevronRight } from "react-icons/fa";
import { AiOutlineRightCircle, AiOutlineInfoCircle } from "react-icons/ai";
import {
  useFrontendBoot,
  useGetEnquiry,
} from "src/customHooks/useGetCommonQueries";
import {
  useCart,
  useMembers,
  useTheme,
  useAdditionalDiscount,
  useRider,
  usePortJourneyConfig,
  useFeatureOptions,
} from "src/customHooks";
import {
  capitalize,
  numberToDigitWord,
  premiumWithAddons,
} from "src/utils/helper";
import { amount, containsNumber } from "src/utils/string.utility";
import ICLogoBase from "src/components/Common/ICLogo";
import useSeeDetailsUtility from "src/customHooks/useSeeDetailsUtility";
import { brokerAppPhase, brokerTenant } from "src/utils/tenantSettings";
import { SeeDetailsBtnStyled } from "src/pages/ProductDetails/components/ReviewCart/components/BasePlanDetails";
import { CollapseCartSection } from "src/pages/ProductDetails/components/ReviewCart/components";
import { FLEX_COL, SHADES } from "src/styles/styleGuide";
import FHTypography from "src/styles/Typography";
import {
  PriceStyled,
  CartGraphicStyled,
  HeadingContainerStyled,
  HeadingStyled,
  CollapseIconStyled,
  SummarySectionStyled,
  SummaryContainerStyled,
  TotalPremiumTextStyled,
  MemberTextStyled,
  ProductInfoStyled,
  ICDetailsContainerStyled,
  SummaryDataContainerStyled,
  SummaryDataStyled,
  AdditionalDetailsTile,
  AdditionalDetailsValue,
  AddOnDataContainer,
  AddOnProductContainer,
  AddOnProductDetails,
  GstTextContainer,
  AddOnTitleStyled,
  IconWrapper,
} from "./ProductSummary.style";
import FormContainer from "./FormContainer";
import styled from "styled-components";
import { setGroupMappedProposalNumber } from "../ProposalSections.slice";

const numToString = value => value.toLocaleString("en-IN");

const ProductSummary = () => {
  const [collapse, setCollapse] = useState(true);
  const { cartEntries, getCartTotalPremium } = useCart();
  const totalPremium = getCartTotalPremium();

  const revisedNetPremium = totalPremium;

  const allAddons = cartEntries
    ?.map(singleCartEntry => singleCartEntry.addons)
    ?.flat();

  const { colors } = useTheme();

  const isProposalSummaryRoute = useRouteMatch({ path: "/proposal_summary" });

  const content = (
    <>
      <CartGraphicStyled primaryShade={colors.primary_shade} />
      <HeadingContainerStyled
        onClick={() => {
          setCollapse(!collapse);
        }}
      >
        <HeadingStyled>Your Plan Details</HeadingStyled>
        <CollapseIconStyled collapse={collapse} data-html2canvas-ignore>
          <FaChevronRight />
        </CollapseIconStyled>
      </HeadingContainerStyled>
      <SummaryContainerStyled collapse={collapse} className="row">
        <div className="no_padding">
          <SummarySectionStyled className="light">
            {cartEntries?.map((item, index) => (
              <CartSummary
                key={index}
                item={item}
                index={index}
                groupCode={item?.group?.id}
                cartEntries={cartEntries}
              />
            ))}
          </SummarySectionStyled>
        </div>
      </SummaryContainerStyled>
    </>
  );

  return (
    <>
      <FormContainer
        containsCart={true}
        isProposalSummaryRoute={
          brokerTenant("fyntune") && isProposalSummaryRoute
        }
      >
        {content}
        <TotalPremiumTextStyled>
          <span>
            Total Premium <br />
            <small>Incl. GST</small>
          </span>
          <PriceStyled id="cartPremium">
            {/* {updatedTenurePremium
              ? amount(updatedTenurePremium)
              : amount(premiumWithAddons(revisedNetPremium, allAddons))} */}
            {amount(premiumWithAddons(revisedNetPremium, allAddons))}
          </PriceStyled>
        </TotalPremiumTextStyled>
      </FormContainer>
    </>
  );
};

export default ProductSummary;

function CartSummary({ item, index, groupCode, cartEntries }) {
  const { data: frontendData, journeyType } = useFrontendBoot();

  const { colors } = useTheme();

  const prevCart = [];

  const dispatch = useDispatch();

  const { getSelectedFeatureValues } = useFeatureOptions();

  const {
    data: { data: enquiryData },
  } = useGetEnquiry();

  const { groups = [], proposals = [] } = enquiryData;

  let groupMappedProposalNumberObj = {};

  const displayFeatureValues = getSelectedFeatureValues(groupCode);

  const displayRiders = useRider(item?.group?.id).getSelectedRiders();

  const [showAddOns, setShowAddOns] = useState(false);

  const [showLoadingAmount, setShowLoadingAmount] = useState(false);

  const { getMembersText } = useMembers();

  const { policyTypes } = useSelector(state => state.quotePage);

  const { isPortPlan } = usePortJourneyConfig();

  const { getSelectedAdditionalDiscounts } = useAdditionalDiscount(
    groupCode,
    true,
  );

  const alias = item?.product?.company?.alias;

  const selectedAdditionalDiscounts = getSelectedAdditionalDiscounts();

  const { groupMappedProposalNumber } = useSelector(
    state => state.proposalPage,
  );

  const { handleUpdateSeeDetailsModal } = useSeeDetailsUtility();

  const proposalNumber = groupMappedProposalNumber?.[groupCode];

  const isProposalSummaryRoute = useRouteMatch({ path: "/proposal_summary" });

  const [copyMsg, setCopyMsg] = useState(false);

  const copyProposalNumber = () => {
    navigator.clipboard.writeText(proposalNumber);
    setCopyMsg(true);
    setTimeout(() => {
      setCopyMsg(false);
    }, 1000);
  };

  const handleSeeDetailsClick = () => {
    handleUpdateSeeDetailsModal({
      payload: item,
      section: "plan-details",
    });
  };

  // * this effect is use for if page is refresh we set proposal number from enquiry instead of again call /companies/proposal api

  useEffect(() => {
    if (!Object.keys(groupMappedProposalNumber).length) {
      proposals?.forEach(item => {
        const match = groups?.find(
          grp => JSON.stringify(grp?.members) === JSON.stringify(item?.members),
        )?.id;
        groupMappedProposalNumberObj = {
          ...groupMappedProposalNumberObj,
          [match]: item?.proposal_no,
        };
      });
      dispatch(setGroupMappedProposalNumber(groupMappedProposalNumberObj));
    }
  }, []);

  if (!item) return <p>Product Not Found....</p>;

  const ridersTax = displayRiders?.reduce((acc, { tax_amount }) => {
    return acc + tax_amount;
  }, 0);

  const loading_amount = item?.loading_amount || {};

  return (
    <>
      <div key={index}>
        <div>
          <MemberTextStyled className="text_title_filter p_modal_title_bg_filters_product">
            {getMembersText({ id: groupCode })}
          </MemberTextStyled>
        </div>
      </div>
      <div className="rider-box_product_pro_medical">
        <ProductInfoStyled>
          <ICDetailsContainerStyled>
            <ICLogoBase alias={alias} />
            {!brokerAppPhase("PROD") ? (
              <SeeDetailsBtnStyled
                primaryColor={colors.primary_color}
                onClick={handleSeeDetailsClick}
              >
                <FHTypography variant="subheading1" weight="md">
                  See Details
                </FHTypography>
                <AiOutlineRightCircle size="16px" />
              </SeeDetailsBtnStyled>
            ) : (
              <></>
            )}
          </ICDetailsContainerStyled>
          <span>{item?.product?.name}</span>
        </ProductInfoStyled>
        <SummaryDataContainerStyled>
          {proposalNumber && (
            <SummaryDataStyled>
              <p className="p_cover_medical_pop">Proposal Number: </p>
              <span className="p_cover_medical_pop_span addon_plan_d_inter_1_product_pro_f_mediacl">
                {copyMsg ? (
                  <span>Copied to Clipboard!</span>
                ) : (
                  <div title={proposalNumber}>
                    {proposalNumber.length > 12
                      ? `${proposalNumber.substring(0, 12)}...`
                      : proposalNumber}
                    <FaRegCopy
                      className="fareg_icon"
                      onClick={copyProposalNumber}
                    />
                  </div>
                )}
              </span>
            </SummaryDataStyled>
          )}
          <SummaryDataStyled>
            <p className="p_cover_medical_pop">Policy Type: </p>
            <span className="p_cover_medical_pop_span addon_plan_d_inter_1_product_pro_f_mediacl">
              {policyTypes[item?.group?.id]}
            </span>
          </SummaryDataStyled>
          <SummaryDataStyled>
            <p className="p_cover_medical_pop">Plan type: </p>
            <span className="p_cover_medical_pop_span addon_plan_d_inter_1_product_pro_f_mediacl">
              {isPortPlan(groupCode)
                ? "Port"
                : journeyType === "health"
                ? "Base Health"
                : cartEntries[0]?.product?.company?.alias === "liberty_general"
                ? "Super Top-Up"
                : "Top Up"}
            </span>
          </SummaryDataStyled>

          {journeyType === "top_up" ? (
            <SummaryDataStyled>
              <p className="p_cover_medical_pop">Deductible: </p>
              <span className="p_cover_medical_pop_span addon_plan_d_inter_1_product_pro_f_mediacl">
                {amount(item?.deductible)}
              </span>
            </SummaryDataStyled>
          ) : null}
          <SummaryDataStyled>
            <p className="p_cover_medical_pop">Cover: </p>
            <span className="p_cover_medical_pop_span addon_plan_d_inter_1_product_pro_f_mediacl">
              {numberToDigitWord(item?.sum_insured)}
            </span>
          </SummaryDataStyled>
          <SummaryDataStyled>
            <p className={`p_cover_medical_pop `}>Premium: </p>
            <span className={`p_cover_medical_pop_span`}>
              ₹{numToString(item.premium)}/{" "}
              {item.tenure > 1 ? item.tenure + " years" : "year"}
            </span>
          </SummaryDataStyled>
          <SummaryDataStyled>
            <p className="p_cover_medical_pop">Policy Term: </p>
            <span className="p_cover_medical_pop_span addon_plan_d_inter_1_product_pro_f_mediacl">
              {item.tenure > 1 ? item.tenure + " years" : "1 year"}
            </span>
          </SummaryDataStyled>
        </SummaryDataContainerStyled>
        {Object.keys(loading_amount).length ? (
          <>
            <hr />
            <AdditionalDetailsTile>
              <BackgroundBorderTitle
                showInfo="Included in Total Premium"
                title="Loading Amount:"
              />
              <IconWrapper>
                {!showLoadingAmount && (
                  <RiArrowDownSLine
                    color="gray"
                    size={"4vh"}
                    onClick={setShowLoadingAmount.bind(null, true)}
                  />
                )}
                {showLoadingAmount && (
                  <RiArrowUpSLine
                    color="gray"
                    size={"4vh"}
                    onClick={setShowLoadingAmount.bind(null, false)}
                  />
                )}
              </IconWrapper>
            </AdditionalDetailsTile>
          </>
        ) : (
          <></>
        )}
        {Object.keys(loading_amount).length && showLoadingAmount ? (
          <div className="row bg_medical_box_row">
            {Object.keys(loading_amount)?.map((member, index) => {
              return loading_amount[member] > 0 ? (
                <HeadingContainerStyled key={index}>
                  <p className="p_cover_medical_pop">{member}</p>
                  <AdditionalDetailsValue className="p_cover_medical_pop_span addon_plan_d_inter_1_product_pro_f_mediacl">
                    ₹ {loading_amount[member]}
                  </AdditionalDetailsValue>
                </HeadingContainerStyled>
              ) : (
                <></>
              );
            })}
          </div>
        ) : (
          <></>
        )}

        <CollapseWrapperStyled>
          {displayRiders.length > 0 ? (
            <CollapseCartSection title="Riders" length={displayRiders?.length}>
              {displayRiders?.map(rider => {
                const totalPremium = rider?.total_premium;
                const taxAmount = rider?.tax_amount || 0;
                return (
                  <HeadingContainerStyled
                    key={rider.total_premium.toString() + rider.name}
                  >
                    <p className="p_cover_medical_pop">{rider.name}</p>
                    <AdditionalDetailsValue className="p_cover_medical_pop_span addon_plan_d_inter_1_product_pro_f_mediacl">
                      {isProposalSummaryRoute && rider?.showOpted
                        ? "Opted"
                        : totalPremium == 0 && taxAmount == 0
                        ? "Included"
                        : `${amount(totalPremium - taxAmount)}`}
                    </AdditionalDetailsValue>
                  </HeadingContainerStyled>
                );
              })}
            </CollapseCartSection>
          ) : (
            <></>
          )}

          {Object.keys(displayFeatureValues)?.length ? (
            <CollapseCartSection
              title="Features"
              length={Object.keys(displayFeatureValues)?.length}
            >
              {Object.keys(displayFeatureValues)?.map(feature_value_key => (
                <HeadingContainerStyled key={feature_value_key}>
                  <p className="p_cover_medical_pop">
                    {capitalize(
                      feature_value_key?.startsWith("feature_")
                        ? feature_value_key.split("_").splice(1).join(" ")
                        : feature_value_key.split("_").join(" "),
                    )}
                  </p>
                  <AdditionalDetailsValue className="p_cover_medical_pop_span addon_plan_d_inter_1_product_pro_f_mediacl">
                    {containsNumber(displayFeatureValues[feature_value_key])
                      ? amount(displayFeatureValues[feature_value_key])
                      : capitalize(
                          displayFeatureValues[feature_value_key]
                            .split("_")
                            .join(" "),
                        )}
                  </AdditionalDetailsValue>
                </HeadingContainerStyled>
              ))}
            </CollapseCartSection>
          ) : (
            <></>
          )}

          {selectedAdditionalDiscounts &&
          selectedAdditionalDiscounts?.length > 0 ? (
            <CollapseCartSection title="Discounts: ">
              {selectedAdditionalDiscounts?.map((singleDiscountObj, index) => (
                <DiscountDetail
                  key={index}
                  title={singleDiscountObj?.name}
                  groupCode={groupCode}
                  additionalDiscount={singleDiscountObj}
                ></DiscountDetail>
              ))}
            </CollapseCartSection>
          ) : (
            <></>
          )}
        </CollapseWrapperStyled>
      </div>

      {item.addons.length ? (
        <>
          <Hr />
          <AddOnDataContainer>
            <BackgroundBorderTitle title="Add-ons Coverages:" />
            <IconWrapper>
              {!showAddOns && (
                <RiArrowDownSLine
                  size={"4vh"}
                  color="grey"
                  onClick={setShowAddOns.bind(null, true)}
                />
              )}
              {showAddOns && (
                <RiArrowUpSLine
                  size={"4vh"}
                  color="grey"
                  onClick={setShowAddOns.bind(null, false)}
                />
              )}
            </IconWrapper>
          </AddOnDataContainer>
        </>
      ) : (
        <></>
      )}

      {showAddOns &&
        item?.addons?.map((addOns, addOnIndex) => (
          <div
            className="rider-box_product_pro_medical"
            key={addOns.product.name + addOnIndex.toString()}
          >
            <AddOnProductContainer className="row_display_pro_review">
              <AddOnProductDetails>
                <span>
                  <img
                    src={
                      frontendData.companies[addOns.product.company.alias].logo
                    }
                    className="img_top_m_custom_medical"
                    alt="logo"
                    loading="lazy"
                  />
                </span>

                <span> {addOns.product.name}</span>
              </AddOnProductDetails>
            </AddOnProductContainer>
            <hr />

            <SummaryDataWrapper>
              <SummaryDataStyled>
                <p className="p_cover_medical_pop">Cover: </p>
                <span className="p_cover_medical_pop_span addon_plan_d_inter_1_product_pro_f_mediacl">
                  ₹ {numToString(addOns.sum_insured)}
                </span>
              </SummaryDataStyled>
              <SummaryDataStyled>
                <p className="p_cover_medical_pop">Premium: </p>
                <span className="p_cover_medical_pop_span addon_plan_d_inter_1_product_pro_f_mediacl">
                  ₹
                  {numToString(
                    prevCart[index]
                      ? prevCart[index].addons[addOnIndex].total_premium
                      : addOns.premium,
                  )}
                  / year
                </span>
              </SummaryDataStyled>
              {addOns.product.insurance_type.alias === "top_up" && (
                <SummaryDataStyled>
                  <p className="p_cover_medical_pop">Deductable: </p>
                  <span className="p_cover_medical_pop_span addon_plan_d_inter_1_product_pro_f_mediacl">
                    ₹ {numToString(addOns.deductible)}
                  </span>
                </SummaryDataStyled>
              )}
            </SummaryDataWrapper>
          </div>
        ))}
      <GstTextContainer primaryColor={colors.primary_color}>
        <p className="p_cover_medical_pop">{"GST"}</p>
        <span className="p_cover_medical_pop_span addon_plan_d_inter_1_product_pro_f_mediacl">
          {amount(item?.service_tax + ridersTax)}
        </span>
      </GstTextContainer>
    </>
  );
}

const DiscountDetail = ({ groupCode, title, additionalDiscount }) => {
  const { getDiscountAmount } = useAdditionalDiscount(groupCode, true);

  const discountAmount = getDiscountAmount(additionalDiscount);
  return (
    <HeadingContainerStyled>
      <p className="p_cover_medical_pop">{title}</p>
      <AdditionalDetailsValue className="p_cover_medical_pop_span addon_plan_d_inter_1_product_pro_f_mediacl">
        -&nbsp;{amount(discountAmount)}
      </AdditionalDetailsValue>
    </HeadingContainerStyled>
  );
};

function BackgroundBorderTitle({ title, showInfo = "" }) {
  const { colors } = useTheme();

  return (
    <AddOnTitleStyled primaryColor={colors.primary_color}>
      <span>
        {title}
        {showInfo ? (
          <span className="info-icon">
            <AiOutlineInfoCircle />
            <span className="info-message">{showInfo}</span>
          </span>
        ) : (
          <></>
        )}
      </span>
    </AddOnTitleStyled>
  );
}

const SummaryDataWrapper = styled.div`
  margin-top: -9px;
`;
const Hr = styled.hr`
  position: relative;
  top: -15px;
`;

// styled component top wrap riders, features and discount dropdown
const CollapseWrapperStyled = styled.div`
  ${FLEX_COL}
  gap: 0.75rem;
  margin-block: 0.75rem;

  & > div {
    border-top: 1px solid ${SHADES.LIGHT_GRAY};
  }
`;
