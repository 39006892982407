import { useTheme } from "src/customHooks";
import { useFrontendBoot } from "src/customHooks/useGetCommonQueries";
import {
  FLEX_ROW_CENTER,
  FONT,
  FWEIGHT,
  ROUNDED,
  SHADES,
} from "src/styles/styleGuide";
import styled from "styled-components";

function EditTab({ setEditStep, editStep }) {
  const { subJourneyType } = useFrontendBoot();
  const { colors } = useTheme();
  return (
    <EditTabContainerStyled className={FONT.h6} colors={colors}>
      <button
        data-is-active={editStep === 1 && "active"}
        onClick={() => setEditStep(1)}
      >
        Members
      </button>
      <button
        data-is-active={editStep === 2 && "active"}
        onClick={() => setEditStep(2)}
      >
        Pincode
      </button>
      {subJourneyType === "port" && (
        <button
          data-is-active={editStep === 3 && "active"}
          onClick={() => setEditStep(3)}
        >
          Expiry date
        </button>
      )}
    </EditTabContainerStyled>
  );
}

const EditTabContainerStyled = styled.div`
  background-color: ${SHADES.LIGHT_GRAY};
  padding: 0 1rem;
  ${FLEX_ROW_CENTER};
  gap: 2rem;
  border-top: 1px solid ${SHADES.DARK_GRAY};
  border-bottom: 1px solid ${SHADES.DARK_GRAY};

  button {
    font-weight: ${FWEIGHT.md};
    color: ${SHADES.SUB_HEADING};
    padding: 0.5rem 1rem;
    position: relative;

    &[data-is-active="active"] {
      color: ${({ colors }) => colors.primary_color};
      ::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        border-radius: ${ROUNDED.full};
        background-color: ${({ colors }) => colors.primary_color};
      }
    }
  }
`;

export default EditTab;
