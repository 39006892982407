import { useSelector } from "react-redux";
import { useCart } from "src/customHooks";
import { generateAge } from "./formUtils";

const useFormFieldVisibility = () => {
  const { cartEntries } = useCart();
  const { proposalData } = useSelector(state => state.proposalPage);

  const visibilityManagerOR = ({
    visibleOn,
    currentFormValues,
    member,
    parent,
    item,
  }) => {
    let visibility = false;

    let checkKeys = Object.keys(visibleOn);

    checkKeys.forEach(key => {
      if (
        key === "special_visibility" &&
        typeof visibleOn?.[key] === "object"
      ) {
        //* Structure
        /* const special_visibility = {
          [`<where to check>`]: {
            [`<what to check>`]: [`<which value "what to check" should have>`],
          },
        }; */
        const specialVisibilityOBj = visibleOn?.[key];
        if (specialVisibilityOBj?.["cart"]) {
          const keysToMatchInCart = Object.keys(specialVisibilityOBj?.["cart"]);
          const result = cartEntries?.every(cartEntry => {
            return keysToMatchInCart.some(
              keyInCart =>
                cartEntry?.[keyInCart] ===
                specialVisibilityOBj?.["cart"]?.[keyInCart],
            );
          });
          visibility = result;
        }
      } else if (typeof visibleOn?.[key] === "string") {
        const arrayOfMatchValues = visibleOn[key]
          .split("|")
          .map(singleMatchValue => singleMatchValue.trim());

        if (visibleOn[key].includes(">")) {
          let valueToProcess = visibleOn[key].split(">")[1];

          if (+currentFormValues[key] > valueToProcess) {
            visibility = true;
          }
        }

        if (parent) {
          //* Mainly used in case of medical questions sub questions.
          if (arrayOfMatchValues?.[0]?.includes("*")) {
            //* "*" match value indicates length matching.
            if (currentFormValues?.[parent]?.[member]?.[key]) {
              const lengthMatch =
                currentFormValues?.[parent]?.[member]?.[key]?.length;

              visibility = lengthMatch > arrayOfMatchValues?.[0]?.length;
            }
          } else if (key === "dob") {
            //* Setting visibility as per the members selected age e.g 18+, -18.
            if (arrayOfMatchValues[0].includes("+")) {
              const valueToProcess = arrayOfMatchValues[0]; //* check value of dob.
              const entryAge = valueToProcess.split("+")[0];
              const insuredData = proposalData?.["Insured Details"];
              const memberData = insuredData?.[member];
              const memberDOB = memberData?.["dob"];
              const result = generateAge({ dob: memberDOB }) > entryAge;
              visibility = result;
            }
            if (arrayOfMatchValues[0].includes("-")) {
              const valueToProcess = arrayOfMatchValues[0]; //* check value of dob.
              const exitAge = valueToProcess.split("-")[1];
              const insuredData = proposalData?.["Insured Details"];
              const memberData = insuredData?.[member];
              const memberDOB = memberData?.["dob"];
              const result = generateAge({ dob: memberDOB }) < exitAge;
              visibility = result;
            }
          } else {
            visibility =
              currentFormValues?.[parent]?.[member]?.[key] ===
              arrayOfMatchValues[0];
          }
        } else {
          //* "*" match value indicates length matching.
          if (
            arrayOfMatchValues?.[0]?.includes("*") &&
            currentFormValues?.[key]
          ) {
            if (
              arrayOfMatchValues?.[0].length ===
              currentFormValues?.[key]?.length
            ) {
              visibility = true;
            }
          }
          if (key.includes("date") && currentFormValues?.[key]) {
            visibility = true;
          } else if (
            arrayOfMatchValues.includes(currentFormValues?.[key] + "")
          ) {
            visibility = true;
          } /* else {
                //* Enable it to work as AND
                visibility = false;
              } */
        }
      } else if (typeof visibleOn?.[key] === "number") {
        visibility = visibleOn?.[key] === currentFormValues?.[key];
      } else if (
        typeof visibleOn[key] === "object" &&
        member &&
        visibleOn[key]
      ) {
        visibility = visibilityManagerOR({
          visibleOn: visibleOn[key],
          value: currentFormValues[key][member],
          item,
        });
      }
    });
    return visibility;
  };

  const hiddenOnManagerOR = ({
    hiddenOn,
    currentFormValues,
    member,
    parent,
    item,
  }) => {
    let hidden = false;

    let checkKeys = Object.keys(hiddenOn);

    checkKeys.forEach(key => {
      if (key === "special_visibility" && typeof hiddenOn?.[key] === "object") {
        //* Structure
        /* const special_visibility = {
          [`<where to check>`]: {
            [`<what to check>`]: [`<which value "what to check" should have>`],
          },
        }; */
        const specialVisibilityOBj = hiddenOn?.[key];
        if (specialVisibilityOBj?.["cart"]) {
          const keysToMatchInCart = Object.keys(specialVisibilityOBj?.["cart"]);
          const result = cartEntries?.every(cartEntry => {
            return keysToMatchInCart.some(
              keyInCart =>
                cartEntry?.[keyInCart] ===
                specialVisibilityOBj?.["cart"]?.[keyInCart],
            );
          });
          hidden = result;
        }
      } else if (typeof hiddenOn?.[key] === "string") {
        const arrayOfMatchValues = hiddenOn[key]
          .split("|")
          .map(singleMatchValue => singleMatchValue.trim());

        if (parent) {
          //* Mainly used in case of medical questions sub questions.
          if (arrayOfMatchValues?.[0]?.includes("*")) {
            //* "*" match value indicates length matching.
            if (currentFormValues?.[parent]?.[member]?.[key]) {
              const lengthMatch =
                currentFormValues?.[parent]?.[member]?.[key]?.length;

              hidden = lengthMatch > arrayOfMatchValues?.[0]?.length;
            }
          } else if (key === "dob") {
            //* Setting visibility as per the members selected age e.g 18+, -18.
            if (arrayOfMatchValues[0].includes("+")) {
              const valueToProcess = arrayOfMatchValues[0]; //* check value of dob.
              const entryAge = valueToProcess.split("+")[0];
              const insuredData = proposalData?.["Insured Details"];
              const memberData = insuredData?.[member];
              const memberDOB = memberData?.["dob"];
              const result = generateAge({ dob: memberDOB }) > entryAge;
              hidden = result;
            }
            if (arrayOfMatchValues[0].includes("-")) {
              const valueToProcess = arrayOfMatchValues[0]; //* check value of dob.
              const exitAge = valueToProcess.split("-")[1];
              const insuredData = proposalData?.["Insured Details"];
              const memberData = insuredData?.[member];
              const memberDOB = memberData?.["dob"];
              const result = generateAge({ dob: memberDOB }) < exitAge;
              hidden = result;
            }
          } else {
            hidden =
              currentFormValues?.[parent]?.[member]?.[key] ===
              arrayOfMatchValues[0];
          }
        } else {
          //* "*" match value indicates length matching.
          if (
            arrayOfMatchValues?.[0]?.includes("*") &&
            currentFormValues?.[key]
          ) {
            if (
              arrayOfMatchValues?.[0]?.length ===
              currentFormValues?.[key]?.length
            ) {
              hidden = true;
            }
          }
          if (key.includes("date") && currentFormValues?.[key]) {
            hidden = true;
          } else if (
            arrayOfMatchValues.includes(currentFormValues?.[key] + "")
          ) {
            hidden = true;
          } /* else {
                //* Enable it to work as AND
                visibility = false;
              } */
        }
      } else if (typeof hiddenOn[key] === "object" && member && hiddenOn[key]) {
        hidden = hiddenOnManagerOR({
          visibleOn: hiddenOn[key],
          value: currentFormValues[key][member],
          item,
        });
      }
    });

    return hidden;
  };

  const renderField = ({
    item,
    currentFormValues,
    member,
    callBack = () => {},
  }) => {
    if (item?.visibleOn || item?.hiddenOn) {
      //? when item?.visibleOn is present and hiddenOn is missing.
      if (item?.visibleOn && !item?.hiddenOn) {
        let show = visibilityManagerOR({
          visibleOn: item?.visibleOn,
          currentFormValues,
          member,
          parent: item?.parent,
          proposalData,
          item,
        });

        callBack(show);

        return show;
      }

      //? when item?.hiddenOn is present and visibleOn is missing.
      if (!item?.visibleOn && item?.hiddenOn) {
        let show = hiddenOnManagerOR({
          hiddenOn: item?.hiddenOn,
          currentFormValues,
          member,
          parent: item?.parent,
          proposalData,
          item,
        });

        callBack(!show);

        return !show;
      }

      //? when item?.visibleOn and hiddenOn is present.
      if (item?.visibleOn && item?.hiddenOn) {
        let resultVisibility;
        const visible = visibilityManagerOR({
          visibleOn: item?.visibleOn,
          currentFormValues,
          member,
          parent: item?.parent,
          proposalData,
          item,
        });

        const hidden = hiddenOnManagerOR({
          hiddenOn: item?.hiddenOn,
          currentFormValues,
          member,
          parent: item?.parent,
          proposalData,
          item,
        });

        resultVisibility = visible && !hidden;

        callBack(resultVisibility);

        return resultVisibility;
      }
    }

    //? when both item?.visibleOn and item?.hiddenOn is missing.

    //* IT MAY ONLY BE PRESENT IN MEDICAL QUESTIONS SECTION.(STRICTLY NOT PRESENT IN OTHER SECTIONS)
    if (item?.render) {
      {
        /* WHEN <HEART ATTACK MQ> IS "Y" */
      }
      const when = item?.render?.when;

      const is = item?.render?.is;

      const isNot = item?.render?.isNot;

      if (item?.render === "noDependency") {
        return true;
      }

      if (when && Array?.isArray(is)) {
        if (when?.includes(">")) {
          const fieldsHierarchy = when.split(">");

          const field = fieldsHierarchy[0];

          const subField = fieldsHierarchy[1];

          const checkQuestionValuesExists = !!(
            currentFormValues?.[field] &&
            currentFormValues?.[field]?.[member] &&
            currentFormValues?.[field]?.[member]?.[subField]?.length
          );

          return checkQuestionValuesExists;
        }
        return false;
      }

      if (item?.parent && member && !when?.includes("||")) {
        if (
          currentFormValues[item?.parent] &&
          currentFormValues[item?.parent]?.members instanceof Object &&
          currentFormValues[item?.parent]?.members[member]
        ) {
          if (when !== "") {
            if (is && typeof is === "string" && is?.includes(">")) {
              const valueToCheck = +is.split(">")[1];
              return (
                currentFormValues[item?.parent]?.members?.[member] &&
                currentFormValues[item?.parent]?.[member] &&
                currentFormValues[item?.parent]?.[member]?.[when] &&
                +currentFormValues[item?.parent]?.[member]?.[when] >
                  valueToCheck
              );
            }
            return (
              currentFormValues[item?.parent]?.members?.[member] &&
              currentFormValues[item?.parent]?.[member] &&
              currentFormValues[item?.parent]?.[member]?.[when] &&
              currentFormValues[item?.parent]?.[member]?.[when] === is
            );
          }
          return true;
        } else {
          return false;
        }
      } else {
        if (is && is.constructor === Object) {
          const { minAge, maxAge } = is;

          const today = new Date();

          const age =
            today.getFullYear() - currentFormValues?.[when]?.split("-")[2];

          if (minAge && age < minAge && minAge !== -1) {
            return true;
          }

          if (maxAge && age > maxAge && maxAge !== -1) {
            return true;
          }
        }

        if (typeof is === "object" && is instanceof Array) {
          const [min, max] = is;

          if (currentFormValues[when] >= min && min !== -1) {
            return true;
          }

          if (currentFormValues[when] <= max && max !== -1) {
            return true;
          }
        }

        if (when?.includes("||")) {
          const temp = when?.split("||");

          let check = false;

          check = temp?.some(
            data =>
              currentFormValues[data] &&
              currentFormValues[data][`is${data}`] === is,
          );

          if (check) {
            return true;
          } else {
            return false;
          }
        } else if (when?.includes(".")) {
          let temp = when?.split(".");

          if (
            temp?.[2] &&
            currentFormValues[temp[0]]?.[temp[1]]?.[temp[2]] === is
          ) {
            return true;
          } else if (
            currentFormValues &&
            currentFormValues[temp[0]] &&
            currentFormValues[temp[0]][temp[1]] === is
          ) {
            return true;
          } else {
            return false;
          }
        }

        if (when?.includes("||")) {
          const temp = when?.split("||");

          let check = false;

          check = temp?.some(
            data =>
              currentFormValues?.[data] &&
              currentFormValues?.[data][`is${data}`] === is,
          );

          return check;
        }

        if (is && typeof is === "string") {
          if (is?.includes("|")) {
            const isValues = is?.split("|");

            let check = false;

            check = isValues?.some(
              isValue =>
                currentFormValues?.[when] &&
                currentFormValues?.[when] === isValue,
            );

            return check;
          }
        }

        if (isNot && currentFormValues?.[when] !== isNot) {
          return true;
        }

        if (currentFormValues?.[when] === is) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      return true;
    }
  };

  // to disable fields based on the keys enableOn or disableOn
  const disableField = ({ item, currentFormValues }) => {
    let disable = false;
    if (item?.enableOn) {
      Object.keys(item?.enableOn).forEach(key => {
        let accepted = item?.enableOn[key]?.split("|");
        if (accepted?.some(doc => doc === currentFormValues[key])) {
          disable = false;
        } else {
          disable = true;
        }
      });
    } else if (item?.disableOn) {
      Object.keys(item?.disableOn).forEach(key => {
        let accepted = item?.disableOn[key]?.split("|");
        if (accepted?.some(doc => doc === currentFormValues[key])) {
          disable = true;
        }
      });
    }

    return disable;
  };

  // to clear the field once a new document is selected
  const clearField = ({ item, currentFormValues }) => {
    let clear;
    if (item?.clearField) {
      Object.keys(item?.clearField).forEach(key => {
        let accepted = item?.clearField[key]?.split("|");
        if (accepted?.some(doc => doc === currentFormValues[key])) {
          clear = currentFormValues[key];
        } else {
          clear = "";
        }
      });
    } else {
      clear = "";
    }

    return clear;
  };

  return {
    renderField,
    disableField,
    clearField,
  };
};
export default useFormFieldVisibility;
