import { useEffect } from "react";
import moment from "moment";

const useInsuredDetails = (
  name,
  schema,
  proposalData,
  values,
  membersDataFromGreetingPage,
  groups,
  setValues,
  defaultValue,
  enquiriesData,
) => {
  const getPanelDescContent = item => {
    let result = [];
    if (values && name === "Insured Details") {
      values[item] &&
        Object.keys(values[item]).forEach(key => {
          if (key === "dob" && values[item][key]) {
            let updatedKey = values[item][key].split("-");
            const year = updatedKey[2] || values[item][key];
            updatedKey = `${year}`;
            result[1] = updatedKey;
          } else if (key === "name" && values[item][key]) {
            result[0] = `${values[item]["title"]}. ${values[item][key]}`;
          } else if (key !== "title") {
            // result[2] = `${values[item][key]}`;
          }
        });

      result = result.filter(r => r);
    }
    return result.join(", ");
  };

  const getMemberAge = item => {
    if (values && name === "Insured Details") {
      let dob = values[item]?.dob;
      if (dob?.length === 4) {
        membersDataFromGreetingPage?.map(member => {
          if (member?.type === item) {
            dob = moment().subtract(member?.age, "years");
          }
        });
      } else {
        dob = moment(dob, "DD-MM-YYYY");
      }
      let age = null;
      if (moment(dob).isSameOrBefore(moment().subtract(1, "year"))) {
        let ageInYears = moment().diff(dob, "years");
        age = `${ageInYears} ${ageInYears === 1 ? "year" : "years"}`;
      } else if (moment(dob).isSameOrBefore(moment().subtract(1, "month"))) {
        let ageInMonths = moment().diff(dob, "months");
        age = `${ageInMonths} ${ageInMonths === 1 ? "month" : "months"}`;
      } else {
        let ageInDays = moment().diff(dob, "days");
        age = ageInDays
          ? `${ageInDays} ${ageInDays === 1 ? "day" : "days"}`
          : "";
      }
      return age;
    }
  };
  const findGroupMembersCount = member => {
    groups.forEach(group => {
      return group.members.includes(member.toLowerCase())
        ? group.members.count
        : 0;
    });
  };

  const titlePicker = member => {
    member = member.toLowerCase();
    if (member.includes("mother")) {
      return "mrs";
    } else if (
      member.includes("father") ||
      member.includes("son") ||
      member.includes("brother")
    ) {
      return "mr";
    } else if (member.includes("daughter") && !member.includes("in_law")) {
      if (
        enquiriesData.data.input.members.find(arr => {
          if (arr.type === member) {
            return arr;
          }
        })?.age < 18
      ) {
        return "ms";
      }
    } else if (member === "spouse") {
      let { gender: genderOfSelf } = enquiriesData.data.input;
      return genderOfSelf === "M" ? "mrs" : "mr";
    }
  };

  //   --------------------------------------------------------------------------------------------------------------------------------
  // -------------------------------------------   SIDE EFFECTS FOR INSURED DETAILS  ------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------------------------------------

  useEffect(() => {
    if (
      name === "Insured Details" &&
      Object.values(schema).length &&
      proposalData["Proposer Details"]
    ) {
      let prefilledValues = {};

      if (schema) {
        let currentYear = new Date().getUTCFullYear();

        Object.keys(schema).forEach(memberType => {
          if (memberType === "self") {
            let tempObj = { ...defaultValue?.[memberType] };
            schema["self"].forEach(item => {
              if (
                (proposalData["Proposer Details"][item.name] &&
                  (!proposalData["Insured Details"] ||
                    !proposalData["Insured Details"][memberType][item.name])) ||
                (proposalData["Proposer Details"][item.name] &&
                  proposalData["Insured Details"][memberType][item.name] &&
                  proposalData["Insured Details"][memberType][item.name] !==
                    proposalData["Proposer Details"][item.name])
              )
                tempObj = {
                  ...tempObj,
                  [item.name]: proposalData["Proposer Details"][item.name],
                };
            });
            prefilledValues[memberType] =
              values && values[memberType]
                ? { ...values[memberType], ...tempObj }
                : tempObj;
          } else {
            let memberAge = membersDataFromGreetingPage.find(
              member => member.type === memberType,
            )?.age;
            let memberAgeInDays = membersDataFromGreetingPage.find(
              member => member.type === memberType,
            )?.days;
            let estimatedMemberDOB;
            if (
              `${memberAge}`.includes("Month") ||
              `${memberAge}`.includes(".")
            ) {
              let current = new Date();

              estimatedMemberDOB = `${current.getUTCFullYear()}`;
            } else if (memberAgeInDays) {
              estimatedMemberDOB = `${moment()
                .subtract(memberAgeInDays, "days")
                .format("DD-MM-YYYY")}`;
            } else {
              estimatedMemberDOB = `${currentYear - parseInt(memberAge)}`;
            }
            let title = titlePicker(memberType);
            prefilledValues[memberType] = {
              ...(values && values.hasOwnProperty(memberType)
                ? values[memberType]
                : {}),
              dob: estimatedMemberDOB,
              title,
              ...defaultValue?.[memberType],
            };
          }
          if (
            !proposalData["Insured Details"] ||
            !proposalData["Insured Details"][memberType]?.insured_marital
          ) {
            if (findGroupMembersCount(memberType) > 1)
              prefilledValues[memberType] = {
                ...prefilledValues[memberType],
                insured_marital: "2",
              };
            else
              prefilledValues[memberType] = {
                ...prefilledValues[memberType],
                insured_marital: "1",
              };
          }
        });

        setValues({
          ...prefilledValues,
        });
      }
    }
    return () => {};
  }, []);

  return {
    getPanelDescContent,
    getMemberAge,
    disableInsuredDetails:
      enquiriesData?.data?.renewal_policy?.extra?.insuredDetailsReadOnly,
  };
};

export default useInsuredDetails;
