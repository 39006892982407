import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useUpdateEnquiry, useUrlEnquiry, useTheme } from "src/customHooks";
import { useFrontendBoot } from "src/customHooks/useGetCommonQueries";
import useInputNavigation from "src/pages/InputPage/Hooks/useInputNavigation";
import {
  addErrorToMemberAction,
  addToSelectedMembersAction,
  setDisplayMembersListAction,
  setMembersErrorMessageAction,
} from "src/pages/InputPage/greetingPage.slice";
import { ageStringToYear } from "src/utils/string.utility";
import {
  countMultiSelectMember,
  getChildCount,
  getLastMultiMemberIndex,
  getOrderedMultiSelectMembers,
  isMemberSelected,
} from "../addMembers.utility";
import toast from "react-hot-toast";
import { useEventTracking } from "src/customHooks/useEventTracking";

export default function useAddMembers() {
  const { getUrlWithEnquirySearch } = useUrlEnquiry();
  const { goToNextInputForm } = useInputNavigation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { updateEnquiry, isLoading: addMembersLoading } = useUpdateEnquiry();

  const { data, journeyType, settings } = useFrontendBoot();
  const { multiindividual_visibilty } = settings || {};
  const { colors } = useTheme();
  const { webEngageTrackEvent } = useEventTracking();
  const {
    selectedMembers,
    membersError,
    memberErrorMessage,
    displayMembersList,
  } = useSelector(state => state.greetingPage);
  const allMembersList = data.members || [];

  // STATE/s

  //EFFECT/s
  useEffect(() => {
    if (!displayMembersList.length) {
      const primaryMembersList = allMembersList.filter(
        member => member.is_primary,
      );
      dispatch(setDisplayMembersListAction(primaryMembersList));
    }
    return () => {};
  }, [allMembersList]);

  // FUNCTION/s ========================

  // function that handles count of multi select members
  // and display them according to there count
  function handleMultiSelectMember({
    member,
    actionType,
    reAlignMembers = false,
  }) {
    let currentMember = member;

    if (reAlignMembers === true) {
      currentMember = displayMembersList.find(memberUnit =>
        member.code.includes(memberUnit.code),
      );
    }

    // retrieve the count of multi select members for base-member
    const memberCount = countMultiSelectMember({
      member: currentMember,
      allMembersList: displayMembersList,
    });
    // retrieve the index of last multi member like ( son3)
    const lastMultiMemberIndex = getLastMultiMemberIndex({
      membersList: displayMembersList,
      baseMember: currentMember,
      memberCount: memberCount,
    });

    const childCount = getChildCount({ membersList: selectedMembers });
    const newArray = [...displayMembersList];

    // add currentMember to display list and selected members
    if (actionType === "add") {
      if (childCount.length >= settings.broker_wise_max_child_count) {
        toast("Max child count reached!", {
          id: "max-child-count",
          position: "top-center",
          style: {
            background: colors.primary_color,
            color: "#fefefe",
          },
        });
        dispatch(addErrorToMemberAction(true));
        return;
      }
      newArray.splice(lastMultiMemberIndex + 1, 0, {
        ...currentMember,
        code: `${currentMember.code}${memberCount + 1}`,
        display_name: `${currentMember.display_name} ${memberCount + 1}`,
      });
      handleAddMember({
        displayName: `${currentMember.display_name} ${memberCount + 1}`,
        memberType: `${currentMember.code}${memberCount + 1}`,
        age: "",
        max_age: currentMember.max_age,
        min_age: currentMember.min_age,
        is_primary: currentMember.is_primary,
      });
      dispatch(setDisplayMembersListAction(newArray));
    }

    // remove currentMember from display list and selected members
    if (actionType === "remove") {
      dispatch(addErrorToMemberAction(false));
      if (memberCount === 1) return;
      newArray.splice(lastMultiMemberIndex, 1);
      dispatch(setDisplayMembersListAction(newArray));
      // update the redux state of selected members
      const updatedSelectedMembers = selectedMembers.filter(
        memberUnit =>
          memberUnit.code !==
          `${currentMember.code}${memberCount > 1 ? memberCount : ""}`,
      );

      dispatch(addToSelectedMembersAction(updatedSelectedMembers));
    }
  }

  // function that will update display members list
  function handleUpdateDisplayMembersList({ updatedList }) {
    const noRegex = /^[A-Za-z]+$/;

    dispatch(addErrorToMemberAction(false));

    const updatedDisplayMemberList = allMembersList.filter(
      memberUnit => memberUnit.is_primary,
    );

    // get multi select children adn arrange then accordingly
    // in display list
    const multiMembers = updatedList?.filter(
      memberUnit => !noRegex.test(memberUnit.code),
    );
    const newALLMembersList = getOrderedMultiSelectMembers({
      multiMembers,
      allMembersList: updatedDisplayMemberList,
    });

    //get all non primary members and add them to display list
    const updatedListWithNoPrimary = updatedList.filter(
      memberUnit => !memberUnit.is_primary,
    );

    dispatch(
      setDisplayMembersListAction([
        ...newALLMembersList,
        ...updatedListWithNoPrimary,
      ]),
    );
  }

  // It will remove member from selected members list when toggled
  // through check box
  function removeMember({ memberType, multiRemove = false }) {
    dispatch(addErrorToMemberAction(false));

    // if multi count member remove button clicked
    if (multiRemove === true) {
      const updatedDisplayMembersList = displayMembersList.filter(
        member => member.code !== memberType,
      );
      dispatch(setDisplayMembersListAction(updatedDisplayMembersList));
      const updatedSelectedMembers = selectedMembers.filter(
        member => member.code !== memberType,
      );
      dispatch(addToSelectedMembersAction(updatedSelectedMembers));
      return;
    }

    // update display list of members and hide non primary members
    const updatedDisplayMembersList = displayMembersList.filter(
      member => member.code !== memberType || member.is_primary,
    );
    dispatch(setDisplayMembersListAction(updatedDisplayMembersList));

    // update the redux state of selected members
    const updatedSelectedMembers = selectedMembers.filter(
      member => member.code !== memberType,
    );
    dispatch(addToSelectedMembersAction(updatedSelectedMembers));
  }

  // this will remove all members
  // used in input page on back button click
  function removeAllMembers() {
    dispatch(addErrorToMemberAction(false));
    // update display list of members and hide non primary members
    const updatedDisplayMembersList = displayMembersList.filter(
      member => member.is_primary,
    );
    dispatch(setDisplayMembersListAction(updatedDisplayMembersList));
    dispatch(addToSelectedMembersAction([]));
  }

  // When we click on agg drop down of any insure member
  // this function will be called
  const handleAddMember = ({
    memberType,
    age,
    max_age,
    min_age,
    displayName,
    displayCal = false,
    days = 0,
    is_primary = false,
  }) => {
    dispatch(addErrorToMemberAction(false));
    const memberObject = {
      ageString: age,
      age: ageStringToYear(age),
      type: memberType,
      days: days,
      code: memberType,
      display_name: displayName,
      display_calender: displayCal,
      max_age,
      min_age,
      is_primary,
    };

    const childCount = getChildCount({ membersList: selectedMembers });
    // As required in backend we need to change son to son1
    if (memberType === "son") {
      memberObject.type = "son1";
    }
    if (memberType === "daughter") {
      memberObject.type = "daughter1";
    }

    if (isMemberSelected({ memberType, selectedMembers })) {
      const updatedSelectedMembers = selectedMembers.filter(
        member => member.code !== memberType,
      );

      dispatch(
        addToSelectedMembersAction([...updatedSelectedMembers, memberObject]),
      );
      return;
    }
    if (childCount.length >= settings.broker_wise_max_child_count) {
      if (memberType === "daughter" || memberType === "son") {
        toast("Max child count reached!", {
          id: "max-child-count",
          position: "top-center",
          style: {
            background: colors.primary_color,
            color: "#fefefe",
          },
        });
        return;
      }
    }

    dispatch(addToSelectedMembersAction([...selectedMembers, memberObject]));
  };

  // function that check if any member is selected or having empty age to display error
  function checkEmptyAgeMembers() {
    const nonAgedMembers = selectedMembers.filter(member => member.age === "");

    if (nonAgedMembers.length) {
      dispatch(setMembersErrorMessageAction("Select age for Insured Member !"));
      dispatch(addErrorToMemberAction(true));
      return false;
    }

    // Extra validation where don't allow child without self
    const isChildSelected = selectedMembers.find(
      member => member.code.includes("son") || member.code.includes("daughter"),
    );
    const isSelfOrSpouseSelected = selectedMembers.find(
      member => member.code === "self" || member.code === "spouse",
    );
    if (isChildSelected && !isSelfOrSpouseSelected) {
      dispatch(setMembersErrorMessageAction("Please select self or spouse!"));
      dispatch(addErrorToMemberAction(true));
      return false;
    }

    return true;
  }

  // When we click on continue button of add members form
  // this function will be called
  function handleSubmitMembers() {
    if (!checkEmptyAgeMembers()) {
      return;
    }

    // lets add values to object to send to backend
    let dataToSend = {
      members: selectedMembers,
    };
    if (selectedMembers.length === 1) {
      dataToSend.plan_type = "I";
    }
    if (
      (journeyType === "top_up" || +multiindividual_visibilty === 0) &&
      selectedMembers.length > 1
    ) {
      dataToSend.plan_type = "F";
    }

    webEngageTrackEvent("Health Insurance Quote Initiated", dataToSend);
    // lets call update enquiry query
    updateEnquiry(dataToSend)
      .then(async res => {
        if (res.error) {
          const { please_note } = res.error.data.errors || {
            please_note: ["Something went wrong! Check your network request."],
          };
          dispatch(setMembersErrorMessageAction(please_note[0]));
          dispatch(addErrorToMemberAction(true));
        }

        // extract groups array from response and move forward to next page
        if (res.data) {
          const { groups } = res?.data?.data || { groups: [] };

          if (groups.length && selectedMembers.length === 1) {
            history.push(
              getUrlWithEnquirySearch(`/input/location-${groups[0].id}`),
            );
          } else {
            if (journeyType === "top_up") {
              history.push(
                getUrlWithEnquirySearch(`/input/location-${groups[0].id}`),
              );
              return;
            }
            goToNextInputForm({ currentInputForm: "members" });
          }
        }
      })
      .catch(err => {
        console.error(err);
      });

    dispatch(addErrorToMemberAction(false));
  }

  return {
    allMembersList,
    displayMembersList,
    selectedMembers,
    membersError,
    memberErrorMessage,
    addMembersLoading,
    handleAddMember,
    handleMultiSelectMember,
    removeMember,
    removeAllMembers,
    handleSubmitMembers,
    handleUpdateDisplayMembersList,
  };
}
