import React from "react";
import "styled-components/macro";
import "./Checkbox.css";

import { useTheme } from "src/customHooks";
import styled from "styled-components";
import { MAX } from "src/styles/responsiveStyle";

const Checkbox2 = ({ title, onChange, checked, showTitle = true }) => {
  const { colors } = useTheme();

  const PrimaryColor = colors.primary_color;

  return (
    <CheckBoxStyled PrimaryColor={PrimaryColor}>
      <div className="round">
        <input
          type="checkbox"
          id={title}
          onChange={onChange}
          checked={checked}
        />
        <label htmlFor={title}> </label>
        {showTitle && title && (
          <span className="title" htmlFor={title}>
            {title}
          </span>
        )}
      </div>
    </CheckBoxStyled>
  );
};

export default Checkbox2;

const CheckBoxStyled = styled.div`
  position: relative;

  ${MAX.xs} {
    padding: 0px 5px;
  }

  div {
    & input {
      &:checked + label {
        background-color: ${props => props.PrimaryColor} !important;
        border-color: ${props => props.PrimaryColor} !important;
      }
    }
  }
  .title {
    left: 21px;
    font-size: 13px;
    font-weight: 600;
    top: 0px;
    position: relative;
  }
`;
