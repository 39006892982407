import React, { createRef, useEffect, useState } from "react";
import styled from "styled-components";
import Dropdown from "../../../components/Dropdown";
import down from "./../../../assets/images/down-arrow.svg";
import up from "./../../../assets/images/up-arrow.svg";
import "styled-components/macro";
import { FWEIGHT, ROUNDED } from "src/styles/styleGuide";

const feet = {
  12: "1 feet",
  24: "2 feet",
  36: "3 feet",
  48: "4 feet",
  60: "5 feet",
  72: "6 feet",
  84: "7 feet",
  96: "8 feet",
};

const inches = {
  0: "0 inches",
  1: "1 inches",
  2: "2 inches",
  3: "3 inches",
  4: "4 inches",
  5: "5 inches",
  6: "6 inches",
  7: "7 inches",
  8: "8 inches",
  9: "9 inches",
  10: "10 inches",
  11: "11 inches",
};

const SearchableHeight = ({
  onChange,
  value,
  error,
  readOnly,
  height,
  borderR,
  checkValidation,
}) => {
  const [combinedValue, setCombinedValue] = useState({
    feet: Math.floor(value / 12) * 12 || "",
    inches: value % 12 || "",
  });

  const {
    dropdownRef: feetDropdownRef,
    inputRef: feetInputRef,
    showDropDown: showFeetDropDown,
    setSearchQuery: setSearchQueryFeet,
    options: feetOptions,
    searchQuery: searchQueryFeet,
    inputClickHandler: inputFeetClickHandler,
    inputBlurHandler: inputFeetBlurHandler,
    handleSearchQueryChange: handleSearchQueryFeetChange,
    changeHandler: feetChangeHandler,
  } = useHeightDropDown({
    combinedValue,
    initialValue: combinedValue?.feet,
    rawOptions: feet,
    setCombinedValue,
    key: "feet",
  });

  const {
    dropdownRef: inchesDropdownRef,
    inputRef: inchesInputRef,
    showDropDown: showInchesDropDown,
    setSearchQuery: setSearchQueryInches,
    options: inchesOptions,
    searchQuery: searchQueryInches,
    inputClickHandler: inputInchesClickHandler,
    inputBlurHandler: inputInchesBlurHandler,
    handleSearchQueryChange: handleSearchQueryInchesChange,
    changeHandler: inchesChangeHandler,
  } = useHeightDropDown({
    combinedValue,
    initialValue: combinedValue?.inches,
    rawOptions: inches,
    setCombinedValue,
    key: "inches",
  });

  useEffect(() => {
    if (value) {
      setCombinedValue({
        feet: Math.floor(value / 12) * 12,
        inches: value % 12,
      });

      setSearchQueryFeet(feet[Math.floor(value / 12) * 12] || "");
      setSearchQueryInches(inches[value % 12] || "");
    }
    return () => {};
  }, [value]);

  const [first, setFirst] = useState(true);

  useEffect(() => {
    if (!first) {
      onChange(
        {
          target: {
            value:
              parseInt(combinedValue.feet) + parseInt(combinedValue.inches) ||
              undefined,
          },
        },
        parseInt(combinedValue.feet) + parseInt(combinedValue.inches) ||
          undefined,
      );
    } else setFirst(false);
  }, [combinedValue]);

  return (
    <>
      <SelectContainer>
        <Wrapper error={error}>
          <SearchInput
            value={searchQueryFeet}
            ref={feetInputRef}
            onChange={handleSearchQueryFeetChange}
            placeholder={"- Select -"}
            error={error}
            disabled={readOnly}
            onClick={inputFeetClickHandler}
            onBlur={inputFeetBlurHandler}
            height={height}
            borderR={borderR}
          />
          <Dropdown
            hideDefaultControl
            onChange={feetChangeHandler}
            options={feetOptions}
            ref={feetDropdownRef}
            searchQuery={searchQueryFeet}
            showDropdown={showFeetDropDown}
            dropdownCss={""}
          />
          <Label height={height} mandatory={checkValidation?.required}>
            Height
          </Label>
        </Wrapper>
        <Wrapper error={error}>
          <SearchInput
            value={searchQueryInches}
            ref={inchesInputRef}
            onChange={handleSearchQueryInchesChange}
            placeholder={"- Select -"}
            error={error}
            disabled={readOnly}
            onClick={inputInchesClickHandler}
            onBlur={inputInchesBlurHandler}
            height={height}
            borderR={borderR}
          />
          <Dropdown
            hideDefaultControl
            onChange={inchesChangeHandler}
            options={inchesOptions}
            ref={inchesDropdownRef}
            searchQuery={searchQueryInches}
            showDropdown={showInchesDropDown}
            dropdownCss={""}
          />
          <Label height={height} mandatory={checkValidation?.required}>
            Inches
          </Label>
        </Wrapper>
      </SelectContainer>
      <FormBuilderErrorStyled className="formbuilder__error">
        {error}
      </FormBuilderErrorStyled>
    </>
  );
};

export default SearchableHeight;

const Wrapper = styled.div`
  width: 50%;
`;

const FormBuilderErrorStyled = styled.p`
  position: relative;
  top: -11px;
`;

const SelectContainer = styled.div`
  display: flex;
  margin-top: ${props => {
    return !props.height ? "0.3rem !important" : "9px !important";
  }};
  position: relative;

  margin-bottom: ${props => {
    return !props.height ? "12px !important" : "9px !important";
  }};

  @media (max-width: 767px) {
    margin-bottom: 12px !important;
    margin-top: ${props => !props.height && "15px !important"};
  }
`;

const SearchInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 14px;
  font-weight: 300;
  padding: 12px 0;
  border-radius: ${ROUNDED.base};
  background: ${props => (props.disabled ? "" : `url(${down}) no-repeat 98%`)};
  cursor: pointer;

  &:disabled {
    cursor: not-allowed !important;
    pointer-events: all !important;
  }
  outline: none;

  border: ${props => !props.height && "1px solid #ced4da"};

  border: ${props => props.error && "solid 1px #c7222a"};

  border-right: ${props => props.borderR && "1px solid #ced4da"};

  color: #090909;
  text-align: center;
  padding-right: 26%;
  &:focus {
    border: ${props => props.error && "solid 1px #c7222a"};
    color: black;
    background: url(${up}) no-repeat 98%;
  }
`;

const Label = styled.label`
  text-align: left;
  display: inline-block;
  font-size: 13px !important;
  color: #000;
  margin: 0;
  background: #fff;
  padding: 0 4px;

  position: absolute;
  margin-left: 8px;
  top: 0;
  transform: translateY(-50%);
  font-weight: ${FWEIGHT.semi};

  ${({ mandatory }) => {
    return mandatory ? "&::after {  content: '*'; color: red;}" : "";
  }}
`;

const useHeightDropDown = ({
  initialValue = "",
  rawOptions = { key: "value" },
  setCombinedValue = () => {},
  combinedValue = { feet: "", inches: "" },
  key = "feet",
}) => {
  const dropdownRef = createRef();

  const inputRef = createRef();

  const [showDropDown, setShowDropDown] = useState(false);

  const [searchQuery, setSearchQuery] = useState(initialValue || "");

  const options = Object.keys(rawOptions).map(key => ({
    label: rawOptions[key],
    value: key,
  }));

  const handleSearchQueryChange = e => {
    const checkValue = +e.target.value % 10;
    if (checkValue >= 0 && checkValue <= 9) {
      setSearchQuery(e.target.value.trimStart());
    }
  };

  const inputClickHandler = () => {
    setShowDropDown(true);
    setSearchQuery("");
  };

  const inputBlurHandler = () => {
    setShowDropDown(false);

    setSearchQuery(
      rawOptions[
        key === "feet" ? Math.floor(initialValue / 12) * 12 : initialValue % 12
      ] || "",
    );
  };

  const changeHandler = ({ value }) => {
    inputRef.current.blur();

    setShowDropDown(false);

    setCombinedValue(prev => {
      if (key == "feet") {
        return {
          ...prev,
          feet: value,
          inches: !combinedValue.inches ? "0" : combinedValue.inches,
        };
      } else {
        return { ...prev, inches: value };
      }
    });
  };

  return {
    dropdownRef,
    inputRef,
    showDropDown,
    searchQuery,
    handleSearchQueryChange,
    changeHandler,
    inputClickHandler,
    inputBlurHandler,
    options,
    setSearchQuery,
  };
};
