import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCart, useTheme, useToggle } from "src/customHooks";
import {
  useGetEnquiry,
  useFrontendBoot,
} from "src/customHooks/useGetCommonQueries";
import useProposalSchema from "src/customHooks/useProposalSchema";
import { brokerTenant } from "src/utils/tenantSettings";
import "styled-components/macro";
import EditMemberFilter from "../../quotePage/components/filters/EditMemberFilter";
import InsuredDetails from "./InsuredDetails";
import KYCDetails from "./KYCDetails";
import MedicalDetails from "./MedicalDetails";
import NomineeDetails from "./NomineeDetails";
import PortDetails from "./PortDetails";
import ProposerDetails from "./ProposerDetails";
import ProposalLoader from "./components/ProposalLoader";
import ProposalNotFound from "./components/ProposalNotFound";
import CKycInfoPopup from "src/pages/ProductDetails/components/ReviewCardPopup/CKycInfoPopup";
// import ClosedPanel from "./components/ClosedPanel";
import { MainTitle, Section } from "./ProposalForm.style";
import RenewBuyLoader from "./components/RenewBuyLoader";
import useFormNavigation from "./useFormNavigation";
// import useKYCDetails from "./useKYCDetails";
import { useResponsiveCondition } from "src/pos/hooks";
import { QuestionIconStyled } from "src/components/Common/Popups/InfoWithImagePopup";
import { BsQuestionLg } from "react-icons/bs";
import {
  setBmiFailBlock,
  setKYCRedirectionURL,
  setProposalSectionHeight,
} from "./ProposalSections.slice";
import FormContainer from "./components/FormContainer";
import ProposalTabs from "./components/ProposalTabs";
import CustomToolTip from "src/components/Common/CustomTooltip";
import { IoMdInformationCircleOutline } from "react-icons/io";
// import { DEVICES } from "src/styles/responsiveStyle";

/* import GroupOTPVerificationModal from "src/components/GroupOTPVerificationModal/GroupOTPVerificationModal";
import toast from "react-hot-toast";
import FormRenderingModal from "src/components/FormRenderingModal/FormRenderingModal"; */

const ProposalForm = ({ isContinueClicked, setIsContinueClicked }) => {
  const { proposalData, showErrorPopup, failedBmiBlockJourney, bmiFailBlock } =
    useSelector(state => state.proposalPage);
  const dispatch = useDispatch();
  const { schemaData, listOfForms, sectionVisibility } = useProposalSchema();
  const { subJourneyType } = useFrontendBoot();
  const { data: enquiryData } = useGetEnquiry();
  const { colors } = useTheme();
  const { cartData } = useCart();
  // const { kycVerified } = useKYCDetails();
  const { activeForm, navigateTo } = useFormNavigation();
  /*  const [triggerMutation, mutationQuery] = useCallApiMutation(); */
  const defaultData = proposalData[activeForm];
  const { width, breakpoint } = useResponsiveCondition(1225);

  const [activateLoader, setActivateLoader] = useState(false);
  const [proposerDeactivate, setProposerDeactivate] = useState(true);

  const proposalSectionHeight = useRef(null);
  const ckycInfoModal = useToggle();
  useEffect(() => {
    if (
      cartData.data.some(obj => !!obj?.can_proceed_with_proposal) &&
      cartData.data.every(obj => !!obj?.redirection_urls?.redirection_url)
    ) {
      let redirectionObj = cartData.data.map(item => ({
        redirection_url: item.redirection_urls.redirection_url,
        group_id: item.group.id,
        meta_data: item.redirection_urls?.meta_data || null,
        redirection_url_via_form:
          item?.redirection_urls?.redirection_url_via_form || false,
      }));
      dispatch(setKYCRedirectionURL(redirectionObj));
    }
    return () => {};
  }, [cartData]);

  //? To bring the current active form section to viewport.
  useEffect(() => {
    dispatch(
      setProposalSectionHeight(proposalSectionHeight?.current?.clientHeight),
    );
    const element = document.getElementById(activeForm);
    const offset = element?.getBoundingClientRect()?.top - 100 + window.scrollY;
    window.scroll({
      top: offset,
      behavior: "smooth",
    });
  }, [activeForm]);

  //? To reopen Insured Details Section if BMI failed.
  useEffect(() => {
    if (failedBmiBlockJourney) {
      setBmiFailBlock(failedBmiBlockJourney);
      navigateTo({ formName: "Insured Details" });
    } else setBmiFailBlock(false);
  }, [failedBmiBlockJourney]);

  //! To stop loader on cancel cta error popup (use case :to be found!)
  useEffect(() => {
    if (!showErrorPopup.show) {
      setActivateLoader(false);
    }
    return () => {};
  }, [showErrorPopup]);

  const allowEditMembers = () => {
    const renewalExtras = enquiryData?.data?.renewal_policy?.extra;
    return renewalExtras?.is_allow_edit_members;
  };

  if (activateLoader) {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    return brokerTenant("renew_buy") ? (
      <RenewBuyLoader />
    ) : (
      <ProposalLoader message={"Submitting Proposal, Please Wait"} />
    );
  }
  if (!listOfForms.length) {
    <ProposalNotFound />;
  }
  return (
    <Section ref={proposalSectionHeight}>
      {width < breakpoint ? <ProposalTabs listOfForms={listOfForms} /> : <></>}
      {/* ------------------------ PROPOSER FORM SECTION ------------------------ */}
      {sectionVisibility("Proposer Details") &&
      activeForm === "Proposer Details" &&
      proposerDeactivate ? (
        <FormContainer id={"Proposer Details"}>
          <MainTitle {...colors} verticalMargin>
            {activeForm}&nbsp;
            {!brokerTenant("tmibasl") && (
              <span className="premium_payer_size"> (Premium payer) </span>
            )}
            <CustomToolTip
              description="Policy holder who is responsible for paying policy premium to
                  the Insurance Company"
              showInfoIcon={false}
            >
              {!brokerTenant("tmibasl") && (
                <span className="premium__payer">
                  <IoMdInformationCircleOutline size={19} />
                </span>
              )}
            </CustomToolTip>
          </MainTitle>

          <ProposerDetails
            key={activeForm}
            formSchema={schemaData ? Object.values(schemaData[activeForm]) : []}
            name={activeForm}
            defaultValue={defaultData}
            setActivateLoader={setActivateLoader}
            listOfForms={listOfForms}
            isContinueClicked={isContinueClicked === "Proposer Details"}
            setIsContinueClicked={setIsContinueClicked}
          />
          {/* ) : (
            <ClosedPanel
              onClick={() => {
                navigateTo({ formName: "Proposer Details" });
                setProposerDeactivate(true);
              }}
              canBeOpened={
                editableForms["Proposer Details"] &&
                sectionVisibility("Proposer Details") &&
                activeIndex >= 0
              }
              title="Proposer Details"
            />
          )} */}
        </FormContainer>
      ) : (
        <></>
      )}

      {/* ------------------------ KYC FORM SECTION ------------------------ */}
      {sectionVisibility("KYC Details") && activeForm === "KYC Details" ? (
        <FormContainer id={"KYC Details"}>
          <MainTitle {...colors} verticalMargin>
            {activeForm}
            <QuestionIconStyled
              onClick={() => ckycInfoModal.on()}
              colors={colors}
              kycSection={true}
            >
              <BsQuestionLg size={18} />
            </QuestionIconStyled>
          </MainTitle>
          <KYCDetails
            key={activeForm}
            formSchema={schemaData ? Object.values(schemaData[activeForm]) : []}
            rawSchema={schemaData || {}}
            name={activeForm}
            setActivateLoader={setActivateLoader}
            defaultValue={defaultData}
            setProposerDeactivate={setProposerDeactivate}
            listOfForms={listOfForms}
            isContinueClicked={isContinueClicked === "KYC Details"}
            setIsContinueClicked={setIsContinueClicked}
          />
          {/* ) : (
            <ClosedPanel
              onClick={navigateTo.bind(null, { formName: "KYC Details" })}
              canBeOpened={
                editableForms["KYC Details"] &&
                sectionVisibility("KYC Details") &&
                activeIndex >= 1
              }
              title="KYC Details"
            />
          )} */}
        </FormContainer>
      ) : (
        <></>
      )}

      {/* ------------------------ INSURED FORM SECTION ------------------------ */}
      {sectionVisibility("Insured Details") &&
      activeForm === "Insured Details" ? (
        <FormContainer id={"Insured Details"}>
          <MainTitle {...colors} verticalMargin>
            <span>{activeForm}</span>
            {activeForm === "Insured Details" &&
              subJourneyType === "renewal" &&
              allowEditMembers() && (
                <EditMemberFilter redirectToQuotes={false} />
              )}
          </MainTitle>
          <InsuredDetails
            key={activeForm}
            formSchema={schemaData ? schemaData[activeForm] : {}}
            rawSchema={schemaData || {}}
            name={activeForm}
            setActivateLoader={setActivateLoader}
            defaultValue={defaultData}
            listOfForms={listOfForms}
            isContinueClicked={isContinueClicked === "Insured Details"}
            setIsContinueClicked={setIsContinueClicked}
          />
          {/* ) : (
            <ClosedPanel
              onClick={() => {
                proposalData["Proposer Details"] &&
                  navigateTo({ formName: "Insured Details" });
              }}
              canBeOpened={
                editableForms["Insured Details"] &&
                sectionVisibility("Insured Details") &&
                activeIndex >= 2
              }
              title="Insured Details"
            />
          )} */}
        </FormContainer>
      ) : (
        <></>
      )}

      {ckycInfoModal.isOn && (
        <CKycInfoPopup
          onClose={ckycInfoModal.off}
          isModalOpen={ckycInfoModal.isOn}
        />
      )}
      {/* ------------------------ MEDICAL FORM SECTION ------------------------ */}
      {sectionVisibility("Medical Details") &&
      activeForm === "Medical Details" &&
      !bmiFailBlock ? (
        <FormContainer id={"Medical Details"}>
          <MainTitle {...colors} verticalMargin>
            {activeForm}
          </MainTitle>
          <MedicalDetails
            key={activeForm}
            formSchema={schemaData ? schemaData[activeForm] : {}}
            rawSchema={schemaData || {}}
            name={activeForm}
            setActivateLoader={setActivateLoader}
            defaultValue={defaultData}
            listOfForms={listOfForms}
            isContinueClicked={isContinueClicked === "Medical Details"}
            setIsContinueClicked={setIsContinueClicked}
          />
          {/* ) : (
            <ClosedPanel
              onClick={() => {
                proposalData["Insured Details"] &&
                  !bmiFailBlock &&
                  navigateTo({ formName: "Medical Details" });
              }}
              canBeOpened={
                editableForms["Medical Details"] &&
                sectionVisibility("Medical Details") &&
                activeIndex >= 3
              }
              title="Medical Details"
            />
          )} */}
        </FormContainer>
      ) : (
        <></>
      )}

      {/* ------------------------ NOMINEE/OTHER FORM SECTION ------------------------ */}
      {sectionVisibility("Other Details") && activeForm === "Other Details" ? (
        <FormContainer id={"Other Details"}>
          <MainTitle {...colors} verticalMargin>
            Nominee Details
          </MainTitle>
          <NomineeDetails
            key={activeForm}
            formSchema={schemaData ? schemaData[activeForm] : {}}
            rawSchema={schemaData || {}}
            name={activeForm}
            setActivateLoader={setActivateLoader}
            defaultValue={defaultData}
            listOfForms={listOfForms}
            isContinueClicked={isContinueClicked === "Other Details"}
            setIsContinueClicked={setIsContinueClicked}
          />
          {/* ) : (
            <ClosedPanel
              onClick={() => {
                !bmiFailBlock &&
                  proposalData["Medical Details"] &&
                  navigateTo({ formName: "Other Details" });
              }}
              canBeOpened={
                editableForms["Other Details"] &&
                sectionVisibility("Other Details") &&
                activeIndex >= 4
              }
              title="Nominee Details"
            />
          )} */}
        </FormContainer>
      ) : (
        <></>
      )}

      {/* ------------------------ PORT FORM SECTION ------------------------ */}
      {sectionVisibility("Port Details") && activeForm === "Port Details" ? (
        <FormContainer id={"Port Details"}>
          <MainTitle {...colors} verticalMargin>
            Port Details
          </MainTitle>
          <PortDetails
            key={activeForm}
            formSchema={schemaData ? schemaData[activeForm] : {}}
            rawSchema={schemaData || {}}
            name={activeForm}
            setActivateLoader={setActivateLoader}
            defaultValue={defaultData}
            listOfForms={listOfForms}
            isContinueClicked={isContinueClicked === "Port Details"}
            setIsContinueClicked={setIsContinueClicked}
          />
          {/* ) : (
            <ClosedPanel
              onClick={() => {
                proposalData["Other Details"] &&
                  navigateTo({ formName: "Port Details" });
              }}
              canBeOpened={
                editableForms["Port Details"] &&
                sectionVisibility("Other Details")
              }
              title="Port Details"
            />
          )} */}
        </FormContainer>
      ) : (
        <></>
      )}
      {/*   {proposalSectionsUtility?.groupOTPVerificationModal?.isOn ? (
        <GroupOTPVerificationModal
          onClose={proposalSectionsUtility?.groupOTPVerificationModal?.off}
          onOTPVerify={groupOtpVerificationHandler}
          otpVerificationQuery={mutationQuery}
        />
      ) : (
        <></>
      )}
      {proposalSectionsUtility?.formRenderingModal?.isOn ? (
        <FormRenderingModal
          title="Please submit the following details."
          onFormSubmit={formPopupSubmitHandler}
          formSubmitQuery={mutationQuery}
        />
      ) : (
        <></>
      )} */}
    </Section>
  );
};

export default ProposalForm;
