import { useEffect, useState } from "react";
import { BsInfoCircle } from "react-icons/bs";
import { getMembersName } from "src/components/CartSummaryModal/cartSummary.utility";
import { Button } from "src/components/Common/Button/ContinueButton";
import { useCart, useTheme } from "src/customHooks";
import { useGetEnquiry } from "src/customHooks/useGetCommonQueries";
import useProposalSchema from "src/customHooks/useProposalSchema";
import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";
import FHTypography from "src/styles/Typography";
import { FLEX_COL_CENTER } from "src/styles/styleGuide";
import { useCallApiMutation, api } from "src/api/api";
import toast from "react-hot-toast";
import { setProposalRefetchConfirmationModal } from "../ProposalSections/ProposalSections.slice";

const Link = ({
  redirectionLink = "",
  newTab = false,
  label = "",
  description = "",
  groupId = null,
  showStatus = false,
  error,
}) => {
  const { colors } = useTheme();
  const { schemaRefetch } = useProposalSchema();
  const {
    data: {
      data: { name, cart_items },
    },
  } = useGetEnquiry();
  const { cartData } = useCart();
  const { proposalData } = useSelector(state => state.proposalPage);
  const fullName = proposalData["Proposer Details"]
    ? proposalData["Proposer Details"]?.name
    : name;
  const currentCartItem =
    cartData?.data?.find(cart => cart?.group?.id === groupId) || {};
  const [triggerMutation, { isLoading }] = useCallApiMutation();
  const dispatch = useDispatch();
  const [partialKycGroup, setPartialKycGroup] = useState([]);
  const [showStatusButton, setShowStatusButton] = useState(false);
  const redirect = () => {
    if (showStatus) {
      setShowStatusButton(true);
    }
    if (newTab) {
      window.open(redirectionLink);
    } else {
      window.location.assign(redirectionLink);
    }
  };
  let helpDescription = redirectionLink
    ? description
    : "The link required to redirect you to insurance company's site seems to be missing.";

  const checkVerification = async e => {
    e.preventDefault();
    e.stopPropagation();
    try {
      const response = await triggerMutation({
        url: `ckyc_redirection_get_status?cart_item=${currentCartItem?.id}`,
        method: "GET",
      });
      if (response?.error) {
        throw new Error();
      }
      const {
        verification_status,
        refetch_proposal_data_confirmation_pop_up,
        refetch_proposal_data_confirmation_msg = "",
        message,
      } = response?.data;

      if (verification_status) {
        toast.success(message, {
          position: "top-center",
        });

        if (refetch_proposal_data_confirmation_pop_up) {
          dispatch(
            setProposalRefetchConfirmationModal({
              visible: true,
              title: `
              ${
                refetch_proposal_data_confirmation_msg.length > 0
                  ? refetch_proposal_data_confirmation_msg
                  : "We will update information based on your CKYC."
              }
               Click 'YES' to continue with updated information or click 'NO' to continue with current information.
              `,
              updateProposalData: true,
              currentCartId: currentCartItem?.id,
            }),
          );
        } else {
          dispatch(api.util.invalidateTags(["Cart"]));
        }
      } else if (message) {
        toast.error(message, {
          position: "top-center",
        });
      }
    } catch {
      toast.error("Failed to generate verification status. please retry", {
        position: "top-center",
      });
    }
  };

  useEffect(() => {
    if (cart_items.length > 1) {
      // to check if kyc is completed for this group
      cart_items.map((item, index) => {
        if (
          item.group.id === groupId &&
          !!cart_items[index]?.can_proceed_with_proposal
        ) {
          setPartialKycGroup(item.group.members);
        }
      });
    }

    return () => {};
  }, []);

  if (partialKycGroup.length) {
    // show partial kyc successful
    return (
      <p
        css={`
          font-size: 1rem;
          font-weight: 600;
          text-align: center;
        `}
      >
        Hello {name}, KYC has been verified for{" "}
        <span
          css={`
            text-transform: capitalize;
          `}
        >
          {getMembersName(partialKycGroup)}.
        </span>{" "}
        <br />
        Please proceed for KYC verification required for the remaining members.
      </p>
    );
  }

  if (showStatusButton) {
    return (
      <VerificationStatusContainer>
        <FHTypography weight="semi" variant="h6">
          Hello {fullName}, click on “Check Verification Status” if you have
          completed the verification through{" "}
          {currentCartItem?.product?.company?.name || "Insurance"} website.
        </FHTypography>
        <Button loader={isLoading} onClick={e => checkVerification(e)}>
          Check Verification Status
        </Button>
      </VerificationStatusContainer>
    );
  }

  return (
    <>
      {helpDescription ? <HelpIcon title={helpDescription} /> : <></>}
      {redirectionLink ? (
        <LinkOuter onClick={redirect} {...colors}>
          <span>{label}</span>
        </LinkOuter>
      ) : (
        <LinkNotFoundOuter onClick={schemaRefetch}>
          <FlexDiv>
            <span className="title">The redirection link not found.</span>
            <Button
              css={`
                border-radius: 0.5rem;
                height: 30px;
                z-index: 3;
                margin-top: 0.5rem;
              `}
            >
              Refetch Link
            </Button>
          </FlexDiv>
        </LinkNotFoundOuter>
      )}
      {error ? (
        <p
          css={`
            margin-top: 1rem;
          `}
          className="formbuilder__error"
        >
          {error}
        </p>
      ) : (
        <></>
      )}
    </>
  );
};

const LinkOuter = styled.section`
  width: 100%;
  min-height: 100px;
  box-shadow: 0 0 0.5rem ${({ primary_color }) => primary_color};
  cursor: pointer;
  position: relative;
  display: grid;
  border-radius: 1rem;
  place-items: center;
  transition: 200ms linear;

  &:hover {
    transform: scale(1.025);
  }

  & > span {
    text-align: center;
    font-size: 1rem;
    font-weight: 600;
  }
`;
const LinkNotFoundOuter = styled.section`
  width: 100%;
  min-height: 100px;
  box-shadow: 0 0 0.5rem ${({ primary_color }) => primary_color};
  cursor: not-allowed;
  position: relative;
  display: grid;
  border-radius: 1rem;
  place-items: center;
  opacity: 0.7;

  & .title {
    text-align: center;
    font-size: 1rem;
    font-weight: 600;
  }
`;

const HelpIcon = styled(BsInfoCircle)`
  position: absolute;
  top: 185px;
  right: 35px;
  cursor: help;
  z-index: 3;
`;

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const VerificationStatusContainer = styled.div`
  ${FLEX_COL_CENTER};
  text-align: center;

  & button {
    margin-top: 1rem;
  }
`;

export default Link;
