import { createRef, useCallback, useEffect, useState } from "react";
import { BsCheckLg } from "react-icons/bs";
import { FaTimes } from "react-icons/fa";
import Backdrop from "src/components/Common/Backdrop";
import { useTheme, useToggle } from "src/customHooks";
import styled from "styled-components/macro";
import down from "./../../../assets/images/down-arrow.svg";
import up from "./../../../assets/images/up-arrow.svg";

const MultiSelectMedicalDropdown = ({
  disabled,
  label,
  options = {},
  limit = 4,
  onChange = () => {},
  value,
  error,
}) => {
  const inputRef = createRef();

  const dropDownToggle = useToggle(false);

  const [selectedValues, setSelectedValues] = useState(() => {
    return value || [];
  });

  const [searchQuery, setSearchQuery] = useState("");

  const [showWarning, setShowWarning] = useState(false);

  const searchQueryChangeHandler = e => {
    setSearchQuery(e.target.value.trimStart());
  };

  const onDisableOptionClick = useCallback(() => {
    setShowWarning(true);
    dropDownToggle.off();
  }, []);

  const multiSelectChangeHandler = ({
    command = "add",
    option = { value: "" },
  }) => {
    inputRef.current.focus();

    const commandsHandler = {
      add: ({ value }) => {
        setSelectedValues(current => {
          if (current.length === limit) {
            return [...current];
          }
          return [...current, value];
        });
      },

      remove: ({ value }) => {
        setSelectedValues(current =>
          current.filter(sl_value => sl_value !== value),
        );
      },

      toggle: ({ value }) => {
        if (selectedValues.find(sl_value => sl_value === value)) {
          setSelectedValues(current =>
            current.filter(sl_value => sl_value !== value),
          );
        } else {
          setSelectedValues(current => {
            if (current.length === limit) {
              return [...current];
            }
            return [...current, value];
          });
        }
      },
    };

    commandsHandler[command](option);
  };

  useEffect(() => {
    onChange(
      {
        target: { value: selectedValues },
      },
      selectedValues,
    );
  }, [JSON.stringify(selectedValues)]);

  useEffect(() => {
    //* Removal of warning in 3 seconds
    if (showWarning) {
      setTimeout(() => {
        setShowWarning(current => !current);
      }, 3000);
    }
    return () => {};
  }, [showWarning]);

  const formattedOptions = Object.keys(options)
    .map(key => ({
      label: options[key],
      value: key,
      selected: !!selectedValues.find(sl_value => sl_value === key),
      disable:
        selectedValues.length === limit &&
        !selectedValues.find(sl_value => sl_value === key),
    }))
    .filter(el => el.label.toLowerCase().includes(searchQuery.toLowerCase()));

  const inputBlurHandler = () => {
    dropDownToggle.off();

    setSearchQuery(
      formattedOptions
        .filter(option => selectedValues.includes(option.value))
        .map(optionObj => optionObj.label)
        .join(", "),
    );
  };

  const inputFocusHandler = useCallback(() => {
    dropDownToggle.on();
    setSearchQuery("");
  }, []);

  const dropDownWithMultiSelectProps = {
    formattedOptions,
    multiSelectChangeHandler,
    onDisableOptionClick,
  };

  const selectedDisplayProps = {
    options,
    selectedValues,
  };

  useEffect(() => {
    if (value.length) {
      return () => {
        onChange(
          {
            target: { value: [] },
          },
          [],
        );
      };
    }
    return () => {};
  }, []);
  return (
    <SelectContainer>
      <SearchInput
        type="text"
        ref={inputRef}
        disabled={disabled}
        placeholder={label}
        value={searchQuery}
        onFocus={inputFocusHandler}
        onChange={searchQueryChangeHandler}
      />
      {showWarning ? (
        <p className="formbuilder__warning">{`You can select upto ${limit} options.`}</p>
      ) : (
        <></>
      )}
      {dropDownToggle.isOn ? (
        <>
          <Backdrop
            zIndex={1}
            bgColor="transparent"
            onClick={inputBlurHandler}
          />
          <DropDownWithMultiSelect {...dropDownWithMultiSelectProps} />
        </>
      ) : (
        <></>
      )}
      <SelectedDisplay
        multiSelectChangeHandler={multiSelectChangeHandler}
        {...selectedDisplayProps}
      />
      {error && <p className="formbuilder__error">{error}</p>}
    </SelectContainer>
  );
};

const DropDownWithMultiSelect = ({
  multiSelectChangeHandler = () => {},
  formattedOptions = [{}],
  onDisableOptionClick,
}) => {
  const {
    colors: { primary_color, primary_shade },
  } = useTheme();

  return (
    <>
      <section
        css={`
          border-radius: 4px;
          background: #fff;
          border: 1px solid grey;
          min-height: 40px;
          max-height: 120px;
          overflow-y: auto;
          position: absolute;
          width: 100%;
          z-index: 2;
          margin-top: 5px;
        `}
      >
        {formattedOptions.map(option => (
          <div
            key={`${option?.label}-${option?.value}`}
            css={`
              font-size: 12px;
              padding: 2px 10px;
              &:hover {
                background: ${option.disable ? "" : primary_shade};
                cursor: pointer;
              }
              display: flex;
              align-items: center;
              justify-content: space-between;
              opacity: ${option.disable ? 0.7 : 1};
            `}
            onClick={
              option.disable
                ? onDisableOptionClick
                : multiSelectChangeHandler.bind(null, {
                    command: "toggle",
                    option,
                  })
            }
          >
            <>{option?.label}</>
            {option.selected ? (
              <BsCheckLg size={"0.8rem"} color={primary_color} />
            ) : (
              <></>
            )}
          </div>
        ))}
      </section>
    </>
  );
};

const SelectedDisplay = ({
  multiSelectChangeHandler,
  selectedValues = [],
  options = [],
}) => {
  const {
    colors: { primary_shade, primary_color },
  } = useTheme();

  return selectedValues.length ? (
    <section
      css={`
        display: flex;
        flex-wrap: wrap;
        gap: 0.8rem;
        margin-top: 1.5rem;
      `}
    >
      {selectedValues.map(value => (
        <div
          key={`${value}`}
          css={`
            min-width: 120px;
            padding: 10px 10px;
            box-sizing: border-box;
            text-align: center;
            border-radius: 0.5rem;
            color: ${primary_color};
            box-shadow: 0 0 0 2px ${primary_color};
            background: ${primary_shade};
            font-weight: 600;
            position: relative;
          `}
        >
          {options[value]}
          <FaTimes
            css={`
              position: absolute;
              top: 0;
              right: 0;
              transform: translate(50%, -50%);
              background: #ffffff;
              border: 2px solid ${primary_color};
              border-radius: 50%;
              cursor: pointer;
            `}
            onClick={multiSelectChangeHandler.bind(null, {
              command: "remove",
              option: { value },
            })}
          />
        </div>
      ))}
    </section>
  ) : (
    <></>
  );
};

const SelectContainer = styled.div`
  margin-top: 0.3rem !important;
  position: relative;
  margin-bottom: 12px !important;
  @media (max-width: 767px) {
    margin-bottom: 12px !important;
    margin-top: 15px !important;
  }
`;

const SearchInput = styled.input`
  appearance: none;
  background: ${props => (props.disabled ? "" : `url(${down}) no-repeat 98%`)};
  cursor: pointer;
  list-style: none;
  &:disabled {
    cursor: not-allowed !important;
    pointer-events: all !important;
  }
  list-style-type: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  margin: 0;
  font-family: inherit;
  line-height: inherit;
  overflow: visible;
  outline: none;
  box-shadow: none;
  transition: border 0.3s ease-in-out;
  touch-action: manipulation;
  width: 100%;
  border: 1px solid #ced4da;
  border: ${props => props.error && "solid 1px #c7222a"};
  height: 55px;
  border-right: ${props => props.borderR && "1px solid #ced4da"};
  font-size: 14px;
  color: #090909;
  position: relative;
  padding: 0 25px;
  &:focus {
    border: ${props => props.error && "solid 1px #c7222a"};
    color: black;
    background: url(${up}) no-repeat 98%;
  }

  @media (max-width: 767px) {
    font-size: 14px;
    height: 62px;
    padding: 0 16px;
    border-radius: 6px;
  }
`;

export default MultiSelectMedicalDropdown;
