import { useEffect, useRef, useState } from "react";
// import { debounce } from "lodash";
import { every } from "lodash";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Button } from "src/components/Common/Button/ContinueButton";
import {
  useMembers,
  usePortJourneyConfig,
  useTheme,
  useUpdateEnquiry,
  useUrlEnquiry,
} from "src/customHooks";
import { useFrontendBoot } from "src/customHooks/useGetCommonQueries";
import FHTypography from "src/styles/Typography";
import styled from "styled-components";
import "styled-components/macro";
import { api, useGetLocationDetailsQuery } from "../../../api/api";
import CustomProgressBar from "../../../components/ProgressBar";
import TextInput from "../../../components/TextInput";
import {
  setEditStep,
  setShowEditMembers,
} from "../../NewQuotePage/QuotePageSlice/quote.slice";
import { ErrorMessage } from "./FormComponents";
import { FONT } from "src/styles/styleGuide";

function LocationForm({ edit = false, posContent }) {
  const { colors } = useTheme();

  const {
    data: { popularcities },
    journeyType,
  } = useFrontendBoot();

  const { currentForm, groupCode: groupId } = useParams();

  const { showPortSectionOnEditMembers } = usePortJourneyConfig();

  const {
    groups: allGroups,
    getGroupLocation,
    getNextGroup,
    getPreviousGroup,
    getMembersText,
    getGroupMembers,
    getGroup,
  } = useMembers();

  const groupCode = parseInt(currentForm?.split("-")[1] || groupId);

  const currentGroup = getGroup(groupCode);

  const groups = allGroups?.filter(group => group?.type === currentGroup?.type);

  const groupWithoutLocation = groups?.find(group => !group?.pincode);

  const [currentGroupCode, setCurrentGroupCode] = useState(
    currentGroup?.type === "all"
      ? groups[0]?.id
      : groupWithoutLocation?.id || groupCode,
  );

  const { updateEnquiry, ...updateEnquiryQuery } = useUpdateEnquiry();

  const [error, setError] = useState(null);

  const [loading, setLoading] = useState(false);

  const [input, setInput] = useState({});

  const dispatch = useDispatch();

  const getInitialSelectedCity = () => {
    const location = getGroupLocation(currentGroupCode);
    return location;
  };

  const [selectedCity, setSelectedCity] = useState(getInitialSelectedCity);

  const history = useHistory();

  const { getUrlWithEnquirySearch } = useUrlEnquiry();

  const nextGroup = getNextGroup(currentGroupCode);

  const previousGroup = getPreviousGroup(currentGroupCode);

  const inputRef = useRef(null);

  const [fetchedCities, setFetchedCities] = useState([]);

  useEffect(() => {
    setSelectedCity(getInitialSelectedCity);
    reset();
  }, [currentGroupCode]);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const goBack = () => {
    if (previousGroup) {
      setCurrentGroupCode(prev => prev - 1);
    } else {
      dispatch(setEditStep(1));
    }
  };

  const submit = ({ pincode }) => {
    updateEnquiry({
      is_pincode_search: false,
      pincode,
      groupCode: currentGroupCode,
    }).then(res => {
      const errors = res.some(res => !!res.error);

      const { groups: updatedGroups } = res[1]?.data?.data;

      if (errors) return;
      if (edit) {
        if (nextGroup) {
          if (
            !groupWithoutLocation ||
            groupWithoutLocation?.id === currentGroupCode
          ) {
            if (!groupWithoutLocation) {
              dispatch(api.util.invalidateTags(["custom_quotes"]));
              return dispatch(setShowEditMembers(false));
            }
            dispatch(api.util.invalidateTags(["custom_quotes"]));

            localStorage.setItem("groups", JSON.stringify(updatedGroups));
            setCurrentGroupCode(next => next + 1);
          } else {
            setCurrentGroupCode(prev => prev + 1);
          }
        } else {
          if (
            groupWithoutLocation &&
            groupWithoutLocation?.id !== currentGroupCode
          ) {
            setCurrentGroupCode(groupWithoutLocation?.id);
          } else if (showPortSectionOnEditMembers) {
            dispatch(setEditStep(3));
          } else {
            dispatch(api.util.invalidateTags(["custom_quotes"]));
            return dispatch(setShowEditMembers(false));
          }
        }
      } else {
        let nextPath = getUrlWithEnquirySearch(
          `/input/location-${currentGroupCode + 1}`,
        );
        if (!nextGroup) {
          if (journeyType === "top_up")
            nextPath = getUrlWithEnquirySearch(`/input/deductible`);
          else nextPath = getUrlWithEnquirySearch(`/input/medicalHistory`);
        }
        history.push(nextPath);
      }
    });
  };

  const handlePopularCityChange = city => {
    setSelectedCity({ ...city, city: city.name });
    !edit && submit(city);
  };

  const checkCitySelected = city =>
    selectedCity && selectedCity.name === city.name;

  const handleSubmit = () => {
    if (!selectedCity) {
      locationSearchQuery.length >= 6 && !error
        ? setError("Please select a City from options")
        : setError("Please enter a valid Pincode or City");
      return;
    }
    return submit(selectedCity);
  };

  const {
    reset,
    value: locationSearchQuery,
    ...locationInput
  } = useLocationInput("");

  const handleLocationChange = location => {
    setSelectedCity(location);
    !edit && submit(location);
  };

  const clearCity = () => {
    setSelectedCity(null);
    setInput(prev => ({ ...prev, [currentGroupCode]: "" }));
    reset();
  };

  const handleSearchQueryChange = evt => {
    locationInput.onChange(evt);
    setSelectedCity(null);
    setInput(prev => ({ ...prev, [currentGroupCode]: evt.target.value }));
  };

  const handleKeyboardEvents = e => {
    if (fetchedCities) {
      if (e.key == "Enter") {
        setSelectedCity(fetchedCities[0]);
        submit(fetchedCities[0]);
      }
    }
    return;
  };

  const membersText = getMembersText({ id: parseInt(currentGroupCode) });

  const groupMembers = getGroupMembers(parseInt(currentGroupCode));

  const isSelf = groupMembers?.some(member => member.code === "self");

  return (
    <LocationEditContainerStyled colors={colors} edit={edit}>
      {edit && (
        <>
          <div>
            <FHTypography variant="h6" weight="semi">
              Tell Us Where {isSelf ? "You" : `Your ${membersText}`}{" "}
              {isSelf ? "Live" : "Lives"}
            </FHTypography>
          </div>
          <div className="member_name">
            {groups?.map(group => (
              <GroupWrapper
                key={group?.id}
                active={group?.id === currentGroupCode}
                color={colors.primary_color}
                onClick={() => setCurrentGroupCode(group?.id)}
              >
                <Group
                  active={group?.id === currentGroupCode}
                  color={colors.primary_color}
                  shade={colors.secondary_shade}
                >
                  {group?.members.join(", ")}
                </Group>
                <span className="pointer"></span>
              </GroupWrapper>
            ))}
          </div>
        </>
      )}

      {!edit && (
        <>
          <FHTypography
            variant="h6"
            weight="semi"
            colorType="heading"
            dangerouslySetInnerHTML={{
              __html: posContent.question
                ? posContent.question
                : `Tell Us Where ${isSelf ? "You" : "Your" + membersText}
            ${isSelf ? "Live" : "Lives"}`,
            }}
          />
        </>
      )}
      {!edit && <CustomProgressBar progress={4} total={5} />}
      <div className="location_input_container">
        <TextInput
          inputRef={inputRef}
          clear={clearCity}
          label={"Enter Pincode or City"}
          name="location"
          id="location"
          value={
            selectedCity?.city ||
            locationSearchQuery ||
            input[currentGroupCode] ||
            ""
          }
          onChange={handleSearchQueryChange}
          onKeyDown={handleKeyboardEvents}
          maxLength={35}
          styledCss={
            edit &&
            `width: 70%; margin-left: auto; margin-right: auto; @media(max-width: 768px) {width: 100%;}`
          }
        />
        {error?.groupCode === currentGroupCode && (
          <div className={edit && "error_msg"}>
            <FHTypography variant="body1" weight="base" type="error">
              {error?.error}
            </FHTypography>
          </div>
        )}
        {loading && (
          <FHTypography
            className="location__loading__state"
            variant="body1"
            color="#808080"
          >
            Fetching locations...
          </FHTypography>
        )}
        {!selectedCity && (
          <div className={edit && "location_options_container"}>
            <LocationOptions
              setFetchedCities={setFetchedCities}
              selected={selectedCity}
              setLoading={setLoading}
              onChange={handleLocationChange}
              searchQuery={locationSearchQuery}
              showError={!error}
              setError={setError}
              groupCode={currentGroupCode}
              css={
                edit &&
                `width: 70%; margin-left: auto; margin-right: auto; @media(max-width: 768px) {width: 100%;}`
              }
            />
          </div>
        )}

        {edit && getGroup(currentGroupCode)?.pincode ? (
          <div className="location_exist_msg">
            <FHTypography variant="subheading1" weight="md">
              You have already entered the Pincode/City. Do you want to edit?
            </FHTypography>
          </div>
        ) : (
          <></>
        )}
      </div>
      {!edit && (
        <div className="popular_city_container">
          <FHTypography variant="h6" weight="semi">
            Popular Cities
          </FHTypography>
          <div className="popular_cities">
            {popularcities?.map(city => (
              <PopularCity
                key={city.name}
                onChange={handlePopularCityChange}
                checked={checkCitySelected(city)}
                city={city}
                selectedCity={selectedCity}
              />
            ))}
          </div>
        </div>
      )}

      <div className="locationCTA">
        <div>
          <button className="go_back" onClick={goBack}>
            <FHTypography variant="h3" weight="semi">
              Back
            </FHTypography>
          </button>
          <Button
            loaderPrimaryColor
            disabled={!selectedCity?.pincode}
            onClick={handleSubmit}
            arrow
            loader={updateEnquiryQuery.isLoading}
            css={`
              height: 58px;
              width: 100%;
              max-width: 172px;
              font-size: 20px;
              font-weight: 400;

              @media (max-width: 480px) {
                font-size: 13px;
                height: 40px;
                width: 100%;
                max-width: 120px;
                padding: 5px 11px;
                font-weight: normal;
              }
            `}
          >
            Continue
          </Button>
        </div>
      </div>
    </LocationEditContainerStyled>
  );
}

export default LocationForm;

export function PopularCity({
  city,
  onChange,
  selectedCity,
  checked = false,
  disabled = false,
}) {
  const { name } = city;

  const { colors } = useTheme();

  const handleChange = evt => {
    const { checked } = evt.target;
    if (!checked) return;
    onChange && onChange(city);
  };

  return (
    <div>
      <input
        id={name}
        name={name}
        className="visually-hidden"
        type="radio"
        checked={checked}
        onChange={handleChange}
        css={`
          & + label {
            ${selectedCity?.city === name &&
            `
            box-shadow: ${colors.primary_color} 0px 0px 1px 1px;
            background-color: #fff;
            border-color: transparent;
            color: ${colors.primary_color};
            `}
          }
          & + label:hover,
          &:checked + label {
            box-shadow: ${colors.primary_color} 0px 0px 1px 1px;
            background-color: #fff;
            border-color: transparent;
            color: ${colors.primary_color};
          }
        `}
        disabled={disabled}
      />
      <label
        htmlFor={name}
        className={`${FONT.subheading2}`}
        css={`
          cursor: pointer;
          background-color: ${colors.secondary_shade};
          border: 1px solid ${colors.secondary_shade};
          border-radius: 2em;
          font-weight: 600;
          letter-spacing: 1px;
          padding: 0.7rem 1rem;
        `}
      >
        {name}
      </label>
    </div>
  );
}

function isNumber(value) {
  return !isNaN(value);
}

const isValidCharacter = value => {
  return /^[a-zA-Z]$/.test(value);
};

const isSpecialCharacter = value => {
  return /^[@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]$/.test(value);
};

function useLocationInput(initialValue = "") {
  const [value, setValue] = useState(initialValue);

  const onChange = evt => {
    const { value: givenValue } = evt.target;

    // PUTTING VALIDATION BY RECOGNIZING THE FIRST INPUT AS NUMBER.
    if (isNumber(givenValue[0])) {
      const isValidPincode = every([
        isNumber(givenValue),
        givenValue.length <= 6,
        /^[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/.test(givenValue),
      ]);
      if (!isValidPincode) return;
    }
    // PUTTING VALIDATION BY RECOGNIZING THE FIRST INPUT AS ALPHABET.
    if (isValidCharacter(givenValue[0])) {
      const isValidCityName = /^[a-zA-Z]*$/.test(givenValue);
      if (!isValidCityName) return;
    }
    if (isSpecialCharacter(givenValue[0])) {
      return;
    }
    setValue(givenValue);
  };

  const reset = () => setValue("");

  return { value, reset, onChange };
}

function LocationOptions({
  setLoading,
  setFetchedCities,
  searchQuery = "",
  selected,
  onChange,
  showError = true,
  groupCode,
  setError = () => {},
}) {
  const [mouseEntered, setMouseEntered] = useState(false);

  let skip = true;

  if (searchQuery.length >= 3) skip = false;

  const { colors } = useTheme();

  const { isFetching, isUninitialized, data } = useGetLocationDetailsQuery(
    { search: searchQuery },
    { skip },
  );

  useEffect(() => {
    if (isFetching) {
      searchQuery.length === 3 ? setLoading(true) : setLoading(false);
    } else {
      if (searchQuery.length >= 3) {
        setLoading(false);
        setFetchedCities(data);
      } else {
        setLoading(false);
        setFetchedCities(null);
      }
    }
    return () => {};
  }, [isFetching, searchQuery]);

  if (isUninitialized) return null;

  if (isFetching) return <p></p>;

  if (showError && data && !data.length) {
    const errorMsg = "Please enter a valid Pincode or City";
    setError({ error: errorMsg, groupCode });
    return <ErrorMessage>{errorMsg}</ErrorMessage>;
  }

  const handleChange = location => {
    onChange && onChange(location);
  };

  const checkSelected = location => selected && selected.city === location.city;

  return (
    <div>
      <CityDropDownStyles
        colors={colors}
        mouseEntered={mouseEntered}
        onMouseEnter={() => {
          setMouseEntered(true);
        }}
        onMouseLeave={() => {
          setMouseEntered(false);
        }}
      >
        {data?.map(location => (
          <Location
            location={location}
            onChange={handleChange}
            isSelected={checkSelected(location)}
            key={location.city}
          />
        ))}
      </CityDropDownStyles>
    </div>
  );
}

function Location({ location, isSelected = false, onChange }) {
  const handleClick = () => {
    onChange && onChange(location);
  };

  return (
    <li role="option" aria-selected={isSelected} onClick={handleClick}>
      {location.city}
    </li>
  );
}

const CityDropDownStyles = styled.ul`
  background-color: white;
  box-shadow: rgba(189, 213, 218, 0.57) 0px 2px 3px 0px;
  border-radius: 4px;
  margin-top: 2px;
  overflow: hidden;

  li {
    cursor: pointer;
    list-style: none;
    padding: 0.6em;
    &:first-child {
      background-color: ${props =>
        !props.mouseEntered && props.colors.secondary_color};
      color: ${props => (!props.mouseEntered ? "white" : "black")};
    }
    &:hover {
      color: white;
      background-color: ${props => props.colors.secondary_color};
    }
  }
`;

const Group = styled.div`
  width: 130px;
  white-space: nowrap;
  text-transform: capitalize;
  overflow: hidden !important;
  text-overflow: ellipsis;
  font-size: 0.9em;
  font-weight: 600;
  position: relative;
  color: ${props => (props.active ? "#fff" : "black")};
  padding: 0.75rem 0.8rem;
  background: ${props => (props.active ? props.color : props.shade)};
  border-radius: 1000px;
  text-align: center;
  transition: width 4s;

  &:hover {
    min-width: 130px;
    width: max-content;
    white-space: wrap;
    overflow: none;
    text-overflow: none;
  }
`;

const GroupWrapper = styled.div`
  position: relative;
  cursor: pointer;

  &:first-child {
    margin-left: auto;
  }
  &:last-child {
    margin-right: auto;
  }

  & span {
    content: "";
    width: 15px;
    height: 10px;
    clip-path: polygon(0 0, 50% 100%, 100% 0);
    -webkit-clip-path: polygon(0 0, 50% 100%, 100% 0);
    background: ${props => props.color};
    display: ${props => (props.active ? "block" : "none")};
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 90%);
  }
`;

const LocationEditContainerStyled = styled.div`
  padding: 1rem;
  & > div:first-of-type {
    text-align: center;
    margin: 1rem 0 2em;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  .member_name {
    display: flex;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
    &::-webkit-scrollbar {
      display: none;
    }
    @media (max-width: 768px) {
      margin: 0 auto;
      overflow: auto;
      height: 80px;
      text-align: center;
    }
  }
  .location_input_container {
    margin-top: 1em;
    position: relative;
    .location__loading__state {
      width: 70%;
      margin: 3px auto;
    }
  }
  .error_msg {
    width: 70%;
    margin: 3px auto;
  }
  .location_options_container {
    width: 70%;
    position: absolute;
    z-index: 99;
    left: 50%;
    top: 180%;
    transform: translate(-50%, -50%);
  }
  .location_exist_msg {
    width: 70%;
    margin: 5px auto;
    color: ${props => props.colors.secondary_color};

    @media (max-width: 768px) {
      margin-bottom: 1em;
      text-align: center;
    }
  }
  .popular_city_container {
    margin-top: 1rem;
  }
  .popular_cities {
    display: flex;
    flex-wrap: wrap;
    gap: 0.49em;
    margin: 10px 0;
    padding-right: 10px;
  }
  .locationCTA {
    width: ${props => props.edit && "70%"};
    margin: ${props => props.edit && "auto"};
    margin-top: 2.5rem;
    @media (max-width: 768px) {
      width: ${props => props.edit && "100%"};
    }
    & > div:first-of-type {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: ${props => (props.edit ? "0" : "0px 28px")};
      @media (max-width: 480px) {
        padding: 0 17px;
      }
    }
  }
  .go_back {
    color: #000;
    height: 58px;
    width: 172px;
    background: unset;
    padding: 10px 11px;
    color: rgb(37, 56, 88);
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 600;
    gap: 3px;
    cursor: pointer;

    @media (max-width: 480px) {
      background: rgb(239, 243, 245);
      color: rgb(70, 86, 113);
      font-size: 13px;
      height: 40px;
      max-width: 120px;
      width: 100%;
      padding: 0;
    }
  }
`;
