import { useEffect, useState } from "react";
import { REDIRECT_OUTSIDE } from "src/components/Common/Popups/InfoPopup/InfoPopup.helpers";
import useInfoPopup from "src/components/Common/Popups/InfoPopup/useInfoPopup";
import { useFrontendBoot } from "src/customHooks/useGetCommonQueries";
import { useCheckPolicies } from "src/customHooks/useCheckPolicies";
import useUrlQuery from "src/customHooks/useUrlQuery";
import { sellerTypeOtpOptions } from "src/utils/helper";
import { envAppBaseURL } from "src/utils/tenantSettings";
import { getDeadLineInSec } from "../PolicyVerification/utility";
import { useCompanies, useUpdateEnquiry } from "src/customHooks";
import { webEngageTrackProposalEvent } from "src/utils/eventTracking";

const singlePay = id => {
  const form = document.createElement("form");
  form.method = "POST";
  form.action = envAppBaseURL() + "payments";
  form.style.display = "none";
  const input = document.createElement("input");
  input.name = "proposal_id";
  input.value = id;
  form.appendChild(input);
  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
};

const multiPay = id => {
  const form = document.createElement("form");
  form.method = "POST";
  form.action = envAppBaseURL() + "payments";
  form.style.display = "none";
  const input = document.createElement("input");
  input.name = "enquiry_id";
  input.value = id;
  form.appendChild(input);
  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
};

const usePaymentsOverallUtility = () => {
  const { policiesData, policiesDataExist } = useCheckPolicies();

  const { data: frontendBootData } = useFrontendBoot();

  const enquiryId = useUrlQuery().get("enquiryId");

  const { handleOpenInfoPopup } = useInfoPopup();
  //* Terms and Conditions Utilities ------------------------------------------------------------------------
  const [showICTAndC, setShowICTAndC] = useState(false);

  const toggleShowICTAndC = () => setShowICTAndC(current => !current);

  //? The one appears at the bottom left.
  const [iCTAndCChecked, setICTAndCChecked] = useState(false);

  const [isCISChecked, setIsCISChecked] = useState(true);

  const toggleICTAndCChecked = () => setICTAndCChecked(current => !current);

  //? The one appears as a separate section below proposal forms.
  const [brokerTAndCChecked, setBrokerTAndCChecked] = useState(true);

  const [updatedProposalId, setUpdatedProposalId] = useState("");

  const { getCompanyLogo } = useCompanies();

  const allTAndCSatisfied =
    iCTAndCChecked && brokerTAndCChecked && isCISChecked;

  useEffect(() => {
    //? Reopening the payment modal after coming back from the payment page.
    if (
      policiesDataExist &&
      policiesData?.length > 1 &&
      policiesData?.some(item => item?.payment_status === "success")
    ) {
      togglePaymentModal();
      setICTAndCChecked(true);
    }
    return () => {};
  }, [policiesDataExist]);

  const termsAndConditionsUtility = {
    showICTAndC,
    iCTAndCChecked,
    brokerTAndCChecked,
    toggleShowICTAndC,
    toggleICTAndCChecked,
    setBrokerTAndCChecked,
    isCISChecked,
    setIsCISChecked,
    allTAndCSatisfied,
  };

  //* OTP Utilities --------------------------------------------------------------------------------------------------
  const [showOTPVerificationModal, setShowOTPVerificationModal] =
    useState(false);

  const otpOptions = sellerTypeOtpOptions(frontendBootData?.settings);

  const [otpVerified, setOtpVerified] = useState(otpOptions?.otp === "0");

  const [allowPayment] = useState(otpOptions?.dont_allow_payment === "0");

  const { updateEnquiry, enquiryData } = useUpdateEnquiry();

  const riders = enquiryData?.cart_items
    .flatMap(cartEntry =>
      cartEntry?.[`${frontendBootData?.journeyType}_riders`]?.map(
        rider => rider.name,
      ),
    )
    .join(", ");

  const otpUtility = {
    showOTPVerificationModal,
    setShowOTPVerificationModal,
    otpVerified,
    setOtpVerified,
    allowPayment,
  };

  //* Policy Expiration Utilities --------------------------------------------------------------------------------------------------
  const [policyExpired, setPolicyExpired] = useState(false);

  const policyExpiredUtility = {
    policyExpired,
    setPolicyExpired,
  };

  //* Payment Modal/Button Utilities -------------------------------------------------------------------------
  const [showPaymentModal, setShowPaymentModal] = useState(false);

  const togglePaymentModal = () => setShowPaymentModal(current => !current);

  const onPayNowClick = mobile => {
    const proposalId = updatedProposalId
      ? updatedProposalId
      : policiesData?.[0]?.proposal_id;
    const isAnyExpired = policiesData?.find(
      status => getDeadLineInSec(status?.created_at) < 0,
    );

    let redirectionObject = {
      ...REDIRECT_OUTSIDE,
    };
    if (frontendBootData.settings?.alert_message_on_external_redirection_text) {
      redirectionObject["description"] =
        frontendBootData.settings.alert_message_on_external_redirection_text;
    }

    if (isAnyExpired) return setPolicyExpired(true);

    if (!(allTAndCSatisfied || mobile)) return;

    if (frontendBootData?.settings?.journey_type === "single") {
      if (otpVerified) {
        //? If there are multiple policies we open payment modal otherwise Payment Gateway.
        policiesData?.length > 1
          ? togglePaymentModal()
          : handleOpenInfoPopup({
              ...redirectionObject,
              onClick: () => {
                singlePay(proposalId);
              },
            });
        webEngageTrackProposalEvent({
          stage: "Pay Now",
          cartEntries: enquiryData?.cart_items,
          riders,
          enquiriesData: { data: enquiryData },
          totalPremium: policiesData?.reduce(
            (sum, policy) => sum + +policy?.total_premium,
            0,
          ),
          getCompanyLogo,
        });
      } else {
        setShowOTPVerificationModal(true);
      }
    } else {
      handleOpenInfoPopup({
        ...redirectionObject,
        onClick: () => {
          multiPay(enquiryId);
        },
      });
    }
  };

  //* Restrict user payment if the enquiry status is changed -------------------------------------------------------------------------
  const updateEnquiryOnGoBack = () => {
    updateEnquiry({
      lead_stage: "proposal_drafted",
    });
  };

  useEffect(() => {
    if (otpVerified) {
      onPayNowClick();
    }

    return () => {};
  }, [otpVerified]);

  return {
    showPaymentModal,
    togglePaymentModal,
    onPayNowClick,
    termsAndConditionsUtility,
    otpUtility,
    policyExpiredUtility,
    updateEnquiryOnGoBack,
    setUpdatedProposalId,
  };
};

export default usePaymentsOverallUtility;
