import { BiExport } from "react-icons/bi";
import { FiUser, FiUsers } from "react-icons/fi";
import { HiOutlineDocumentText } from "react-icons/hi";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { MdOutlineMedicalServices } from "react-icons/md";
import { RiUserFollowLine } from "react-icons/ri";
import { useSelector } from "react-redux";
import { useTheme } from "src/customHooks";
import useProposalSchema from "src/customHooks/useProposalSchema";
import { useResponsiveCondition } from "src/pos/hooks";
import { VIEW, MAX } from "src/styles/responsiveStyle";
import {
  FLEX_ROW_CENTER,
  PALETTE,
  ROUNDED,
  SHADES,
  SHADOW,
} from "src/styles/styleGuide";
import styled from "styled-components";
import useFormNavigation from "../useFormNavigation";
import useKYCDetails from "../useKYCDetails";
import FHTypography from "src/styles/Typography";

const ProposalTabs = ({ listOfForms }) => {
  const { colors } = useTheme();
  const { kycVerified } = useKYCDetails();
  const { proposalData } = useSelector(state => state.proposalPage);

  function checkKycEditable() {
    if (listOfForms.includes("KYC Details")) {
      if (kycVerified === true) {
        return proposalData["Proposer Details"];
      } else {
        return proposalData["KYC Details"];
      }
    } else {
      return proposalData["Proposer Details"];
    }
  }

  return (
    <ProposalTabsContainerStyled primaryColor={colors.primary_color}>
      {listOfForms.map((item, index) => {
        return (
          <FormTab
            kycVerified={kycVerified}
            checkKycEditable={checkKycEditable}
            key={item}
            formName={item}
            tabIndex={index}
            isFinalElement={listOfForms.length - 1 === index}
          />
        );
      })}
    </ProposalTabsContainerStyled>
  );
};

const FormTab = ({
  kycVerified,
  checkKycEditable,
  formName,
  tabIndex,
  isFinalElement = false,
}) => {
  const { colors } = useTheme();
  const { activeForm, navigateTo } = useFormNavigation();
  const { proposalData, activeIndex, bmiFailBlock, proposalSectionHeight } =
    useSelector(state => state.proposalPage);
  const { breakpoint, width } = useResponsiveCondition(1024);
  const { sectionVisibility } = useProposalSchema();

  const editableForms = {
    "Proposer Details": {
      isEditable: true,
      icon: <FiUser size={width > 480 ? 25 : 18} />,
    },
    "KYC Details": {
      isEditable: proposalData["Proposer Details"],
      icon: <HiOutlineDocumentText size={width > 480 ? 25 : 18} />,
    },
    "Insured Details": {
      isEditable: checkKycEditable(),
      icon: <FiUsers size={width > 480 ? 25 : 18} />,
    },
    "Medical Details": {
      isEditable:
        proposalData["Insured Details"] &&
        !bmiFailBlock &&
        kycVerified === true,
      icon: <MdOutlineMedicalServices size={width > 480 ? 25 : 18} />,
    },
    "Other Details": {
      isEditable:
        proposalData["Medical Details"] &&
        !bmiFailBlock &&
        kycVerified === true,
      icon: <RiUserFollowLine size={width > 480 ? 25 : 18} />,
    },
    "Port Details": {
      isEditable:
        proposalData["Other Details"] && !bmiFailBlock && kycVerified === true,
      icon: <BiExport size={width > 480 ? 25 : 18} />,
    },
  };

  const canBeOpened =
    editableForms[activeForm].isEditable &&
    sectionVisibility(activeForm) &&
    activeIndex >= tabIndex;

  const navigationHandler = () => {
    if (canBeOpened) navigateTo({ formName });
    else
      window.scrollTo({
        top: proposalSectionHeight,
        behavior: "smooth",
      });
  };

  return (
    <>
      <TabContainer
        width={width}
        primaryColor={colors.primary_color}
        formName={formName}
        canBeOpened={canBeOpened}
        className={
          activeForm === formName ? "active" : canBeOpened ? "completed" : ""
        }
      >
        <div onClick={navigationHandler} className="tab">
          <div className="section-icon">
            {editableForms[formName].icon}
            {activeForm !== formName && canBeOpened ? (
              <IoMdCheckmarkCircleOutline
                size={width > 480 ? 22 : 18}
                className="completedCheck"
              />
            ) : (
              <></>
            )}
          </div>
          {(width > 768 && width <= breakpoint && activeForm === formName) ||
          width > breakpoint ||
          (width >= breakpoint && formName !== activeForm) ? (
            <FHTypography variant="h5" weight="md" className="section-name">
              {formName}
            </FHTypography>
          ) : (
            <></>
          )}
        </div>
      </TabContainer>
      {!isFinalElement ? (
        <div
          className={`
            ${activeForm !== formName && canBeOpened ? "completed-bar" : ""}
              progress-bar
          `}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default ProposalTabs;

const ProposalTabsContainerStyled = styled.div`
  ${FLEX_ROW_CENTER};
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 0 20px;
  background-color: #f7f7f7;
  width: calc(100% + 60px);
  margin-left: -30px;
  position: sticky;
  top: 0;
  z-index: 99;

  ${MAX.md} {
    margin-bottom: 0;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  ${MAX.lg} {
    width: 100%;
    margin-left: 0;
    padding: 0;
  }

  .active {
    .tab {
      .section-icon {
        background: ${props => props.primaryColor};
        svg {
          color: #fff;
        }
      }
      .section-name {
        color: ${props => props.primaryColor};
      }
      .mobile-indicator {
        color: ${props => props.primaryColor};
      }
    }
  }

  .completed {
    .tab {
      .section-icon {
        background: ${PALETTE.success.proposal};
        svg {
          color: #fff;
        }
      }
      .section-name {
        color: ${PALETTE.success.proposal};
      }
    }
  }
`;

const TabContainer = styled.div`
  position: relative;
  .tab {
    ${FLEX_ROW_CENTER}
    height: 100%;
    padding: ${props => (props.width < 768 ? "0.3rem 1rem" : "1rem")};
    cursor: ${props => (props.canBeOpened ? "pointer" : "not-allowed")};
    position: relative;

    & h5 {
      ${MAX.sm} {
        font-size: 0.7rem;
        text-align: center;
        margin-top: 5px;
      }
    }

    ${MAX.sm} {
      flex-direction: column;
    }

    .section-icon {
      position: relative;
      height: 3rem;
      width: 3rem;
      ${FLEX_ROW_CENTER}
      border-radius: ${ROUNDED.full};
      background: ${SHADES.LIGHT_GRAY};

      svg {
        color: ${SHADES.DIM_GRAY};
      }

      .completedCheck {
        padding: 1px;
        position: absolute;
        bottom: -4px;
        right: -6px;
        background: #fff;
        color: ${PALETTE.success.proposal} !important;
        border-radius: ${ROUNDED.full};
        box-shadow: ${SHADOW.base};
      }

      @media (max-width: 480px) {
        height: 2rem;
        width: 2rem;
        margin: 0;
      }

      ${VIEW.md} {
        margin-right: 1rem;
      }
    }

    .section-name {
      color: ${SHADES.DIM_GRAY};
    }
  }

  & ~ .progress-bar {
    flex: 1;
    height: 2px;
    background: ${SHADES.DARK_GRAY};
  }

  & ~ .completed-bar {
    background: ${PALETTE.success.proposal} !important;
  }
`;
