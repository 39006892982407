import SpinLoader from "src/components/Common/SpinLoader/SpinLoader";
import styled from "styled-components";

const ProposalLoader = ({ message = "" }) => {
  return (
    <MessageWrapper>
      <p>{message}</p>
      <SpinLoader proposalPage={true} />
    </MessageWrapper>
  );
};

const MessageWrapper = styled.div`
  text-align: center;
  margin-top: 100px;
  width: 100%;
`;

export default ProposalLoader;
