//  CUSTOM HOOK
import { usePortJourneyConfig, useTheme } from "src/customHooks";
// UTILS
import { amount, numToLakh } from "src/utils/string.utility";
// STYLED COMPONENTS
import {
  PlanDetailLabelStyled,
  PlanDetailStyled,
  PlanDetailValueStyled,
  PlanDetailsWrapperStyled,
} from "src/pages/ProductDetails/components/CartMobile/CartMobile.style";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const planTypes = {
  M: "Multi Individual",
  I: "Individual",
  F: "Family Floater",
};

export default function PlanDetails({ cartEntry, journeyType, policy_number }) {
  const { colors } = useTheme();
  const { tenure, sum_insured, premium, deductible } = cartEntry;
  const plantype = cartEntry?.plantype || cartEntry?.group?.plan_type;
  const { isPortPlan } = usePortJourneyConfig();
  const groupCode = +useParams()?.groupCode;
  const planDetailsArray = [
    {
      label: "Policy Type",
      value: planTypes[plantype],
    },
    {
      label: "Plan Type",
      value: isPortPlan(groupCode)
        ? "Port"
        : journeyType === "health"
        ? "Base Health"
        : process.env.REACT_APP_TOPUP_TITLE || "Top Up",
    },
    { label: "Cover", value: `₹ ${numToLakh(sum_insured)}` },
    journeyType === "top_up" && {
      label: "Deductible",
      value: `₹ ${numToLakh(deductible)}`,
    },
    { label: "Premium", value: amount(premium) },
    {
      label: "Tenure",
      value: `${tenure} ${tenure === 1 ? "year" : "years"}`,
    },
  ].filter(Boolean);

  if (policy_number) {
    planDetailsArray.push({
      label: "Policy Number",
      value: policy_number,
    });
  }

  return (
    <PlanDetailsWrapperStyled colors={colors} data-journey-type={journeyType}>
      {planDetailsArray.map(({ label, value }, index) => (
        <PlanDetailStyled key={index}>
          <PlanDetailLabelStyled>{label}</PlanDetailLabelStyled>
          <PlanDetailValueStyled>{value}</PlanDetailValueStyled>
        </PlanDetailStyled>
      ))}
    </PlanDetailsWrapperStyled>
  );
}
