import { useDispatch, useSelector } from "react-redux";
import { api } from "src/api/api";
import { Button } from "src/components/Common/Button/ContinueButton";
import useProposalSchema from "src/customHooks/useProposalSchema";
import {
  getProposalData,
  setProposalRefetchModal,
} from "../../ProposalSections.slice";
import Modal from "./../../../../../components/Common/Popups/ReusableModal/Modal";
import styled from "styled-components";
const ProposalRefetchModal = () => {
  const dispatch = useDispatch();
  const { schemaRefetch } = useProposalSchema();
  const { proposalRefetchModal } = useSelector(state => state.proposalPage);

  const okClickHandler = () => {
    schemaRefetch();
    dispatch(getProposalData());
    dispatch(api.util.invalidateTags(["Cart"]));
    dispatch(
      setProposalRefetchModal({
        visible: false,
        title: "",
      }),
    );
  };
  return (
    <Modal
      isModalOpen={proposalRefetchModal?.visible}
      hideCloseBtn={true}
      width="fit-content"
      height="fit-content"
    >
      <span>{proposalRefetchModal?.title}</span>

      <ButtonStyled>
        <Button onClick={okClickHandler} width="100%">
          OK
        </Button>
      </ButtonStyled>
    </Modal>
  );
};
export default ProposalRefetchModal;

const ButtonStyled = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 12px;
`;
