import { useReducer } from "react";
import { Button } from "src/components/Common/Button/ContinueButton";
import Dropdown from "src/components/Dropdown";
import { getReactSelectOption } from "src/utils/helper";
import { Box, Error, FlexEndBox } from "../FormRenderingModal.style";
import FileInput2 from "src/pages/ProposalPage/components/FileInput2/FileInput2";

const dropdownStateReducer = (state, { type = "", value = "" }) => {
  if (type === "update") {
    return {
      ...state,
      value,
      error: value ? "" : "Please select a valid value.",
      isValid: !!value,
    };
  }
  if (type === "touched") {
    return { ...state, touched: true };
  }
};
const fileStateReducer = (state, { type = "", value = "" }) => {
  if (type === "update") {
    return {
      ...state,
      value,
      error: value ? "" : "Please select a valid file.",
      isValid: !!value,
    };
  }
  if (type === "touched") {
    return { ...state, touched: true };
  }
};

const dropdownOptions = [
  {
    code: "dl",
    display_name: "Driving License",
  },
  {
    code: "voterid",
    display_name: "Voter ID",
  },
  {
    code: "passport",
    display_name: "Passport",
  },
  {
    code: "aadharcard",
    display_name: "Aadhar Card",
  },
];

const FormRenderer = ({ onFormSubmit = () => {}, formSubmitQuery = {} }) => {
  const [dropdownState, dropdownStateDispatcher] = useReducer(
    dropdownStateReducer,
    {
      value: null,
      error: "This filed is required.",
      touched: false,
      isValid: false,
    },
  );
  const [fileState, fileStateDispatcher] = useReducer(fileStateReducer, {
    value: null,
    error: "This filed is required.",
    touched: false,
    isValid: false,
  });

  const dropdownChangeHandler = ({ value }) => {
    dropdownStateDispatcher({
      type: "update",
      value,
    });
  };

  const fileChangeHandler = base64 => {
    fileStateDispatcher({
      type: "update",
      value: base64,
    });
  };

  const dropdownTouchHandler = () => {
    dropdownStateDispatcher({
      type: "touched",
    });
  };

  const fileTouchHandler = () => {
    fileStateDispatcher({ type: "touched" });
  };

  const handleSubmit = (validatedCallback = () => {}) => {
    return e => {
      e.preventDefault();
      e.stopPropagation();
      if (dropdownState?.isValid && fileState?.isValid) {
        validatedCallback({
          dropDown: dropdownState?.value,
          file: fileState?.value,
        });
      } else {
        dropdownTouchHandler();
        fileTouchHandler();
        return;
      }
    };
  };

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <Box>
        <Dropdown
          options={dropdownOptions.map(getReactSelectOption)}
          onChange={dropdownChangeHandler}
          onBlur={dropdownTouchHandler}
          searchable={false}
        />
        {dropdownState?.touched && dropdownState?.error ? (
          <Error>{dropdownState?.error}</Error>
        ) : (
          <></>
        )}
      </Box>
      <Box>
        <FileInput2
          onChange={fileChangeHandler}
          label="Please upload your document"
        />
        {fileState?.touched && fileState?.error ? (
          <Error>{fileState?.error}</Error>
        ) : (
          <></>
        )}
      </Box>

      <FlexEndBox>
        <Button type="submit" loader={formSubmitQuery?.isLoading}>
          Submit
        </Button>
      </FlexEndBox>
    </form>
  );
};

export default FormRenderer;
