/* eslint-disable no-confusing-arrow */
/*
Title:- ProgressBar.js

Description:- Renders custom progress bar

Components Used:- None

Used In:- 
- BasicDetails/index.jsx
- LocationForm/index.jsx
- RenewalDetailsForms/index.jsx
- PlanTypeForm/index.jsx
- PortabilityForm/index.jsx
- MembersForm/index.jsx
- QuestionsPage.js
*/

import React from "react";

import { useFrontendBoot } from "src/customHooks/useGetCommonQueries";
import { useTheme } from "../customHooks";
import styled from "styled-components";
import { brokerTenant } from "src/utils/tenantSettings";

const CustomProgressBar = ({ progress, total }) => {
  const { colors } = useTheme();
  const { settings } = useFrontendBoot();
  return (
    <ProgressBarWrapperStyled>
      <ProgressBarStyled
        progress={progress}
        total={total}
        settings={settings}
        colors={colors}
      ></ProgressBarStyled>
    </ProgressBarWrapperStyled>
  );
};

const ProgressBarWrapperStyled = styled.div`
  max-width: 130px;
  background: #e9ecef;
  border-radius: 10px;
  display: flex;
  align-items: center;
  font-size: 11px;
  margin-bottom: 20px;
  margin-top: 10px;
  @media all and (min-height: 600px) and (max-height: 700px) {
    margin-bottom: 14px !important;
  }
`;

const ProgressBarStyled = styled.div`
  width: ${props =>
    props.progress && props.total
      ? (props.progress / props.total) * 100 + "%"
      : "0%"};

  height: 9px;
  background: ${props => {
    return props.settings?.color_visibilty?.gradients &&
      (brokerTenant("tmibasl") || brokerTenant("fyntune"))
      ? `linear-gradient(to right, ${Object.keys(
          props.settings?.color_visibilty?.gradients,
        )?.map(color => {
          return props.settings?.color_visibilty?.gradients[color];
        })})`
      : props.colors.secondary_color;
  }};
  border-radius: 10px;
  transition: width 0.5s ease-in-out;
`;

export default React.memo(CustomProgressBar);
