import { useDispatch } from "react-redux";
// CUSTOM HOOKS
import {
  useTheme,
  useAdditionalDiscount,
  useFeatureOptions,
  useRider,
  riderPremiumValue,
} from "src/customHooks";
// UTILS
import { amount } from "src/utils/string.utility";
import { capitalize } from "lodash";
import { setRiderActionBtnClicked } from "src/pages/ProductDetails/productDetails.slice";
import { flattenFeatureObject } from "src/pages/ProductDetails/components/ReviewCart/components/FeaturesList";
// DESIGN SYSTEM
import FHTypography from "src/styles/Typography";
// STYLED COMPONENTS
import {
  ProductDetailContainerStyled,
  ProductDetailHeadingStyled,
  ProductInfoCardContainerStyled,
  ProductInfoCardStyled,
  ProductInfoNameStyled,
  ProductInfoValueStyled,
  NoDetailSelectedWrapperStyled,
} from "src/pages/ProductDetails/components/CartMobile/CartMobile.style";
import useGetRouteMatch from "src/customHooks/useGetRouteMatch";

function RiderDetailSection({ groupCode, cartMobileToggle }) {
  const { colors } = useTheme();
  const dispatch = useDispatch();
  const { getSelectedRiders } = useRider(groupCode);

  const selectedRiders = getSelectedRiders() || [];
  const { isProposalRoute } = useGetRouteMatch();

  const scrollToRiderSection = () => {
    const riderSection = document.querySelector("#additional-riders");
    cartMobileToggle.off();
    window.scrollTo({
      top: riderSection.offsetTop - (window.innerWidth > 768 ? 88 : 72),
      left: 0,
      behavior: "smooth",
    });
    dispatch(setRiderActionBtnClicked(true));
  };

  if (selectedRiders?.length === 0) {
    if (isProposalRoute) {
      return <></>;
    }

    return (
      <ProductDetailContainerStyled
        data-cart-mobile-open={cartMobileToggle.isOn}
      >
        <ProductDetailHeadingStyled colors={colors}>
          Selected Riders
        </ProductDetailHeadingStyled>
        <NoDetailSelectedWrapperStyled>
          <FHTypography
            variant="subheading1"
            weight="md"
            colorType="subHeading"
          >
            No Riders Selected
          </FHTypography>
          <button>
            <FHTypography
              variant="subheading1"
              weight="semi"
              color={colors.primary_color}
              onClick={scrollToRiderSection}
            >
              View Riders
            </FHTypography>
          </button>
        </NoDetailSelectedWrapperStyled>
      </ProductDetailContainerStyled>
    );
  }

  return (
    <ProductDetailContainerStyled data-cart-mobile-open={cartMobileToggle.isOn}>
      <ProductDetailHeadingStyled colors={colors}>
        Selected Riders
      </ProductDetailHeadingStyled>
      <ProductInfoCardContainerStyled>
        {selectedRiders?.map((rider, index) => (
          <ProductInfoCardStyled key={rider.id + index}>
            <ProductInfoNameStyled>{rider.name}</ProductInfoNameStyled>
            {rider?.is_rider_default || rider?.is_rider_free ? (
              <ProductInfoValueStyled>
                {riderPremiumValue({ rider })}
              </ProductInfoValueStyled>
            ) : (
              rider?.total_premium > 0 && (
                <ProductInfoValueStyled>
                  {riderPremiumValue({ rider })}
                </ProductInfoValueStyled>
              )
            )}
          </ProductInfoCardStyled>
        ))}
      </ProductInfoCardContainerStyled>
    </ProductDetailContainerStyled>
  );
}

function FeatureDetailSection({ groupCode, cartMobileToggle }) {
  const { colors } = useTheme();
  const { feature_options, getSelectedFeatureOptions } =
    useFeatureOptions(groupCode);

  const selectedFeatureOptions = getSelectedFeatureOptions(groupCode);

  const all_feature_option = feature_options.map(feature => feature.options);

  const featureArray = all_feature_option.map(item => {
    return flattenFeatureObject(item);
  });

  const featureWithKeyValue = flattenFeatureObject(featureArray);

  const featureWithKeyValueObject = {};
  Object.keys(selectedFeatureOptions).map(key => {
    if (selectedFeatureOptions[key] in featureWithKeyValue) {
      featureWithKeyValueObject[key] =
        featureWithKeyValue[selectedFeatureOptions[key]];
    }
  });

  const scrollToFeatureSection = () => {
    const featureSection = document.querySelector("#Plan-Feature-Options");
    cartMobileToggle.off();

    window.scrollTo({
      top: featureSection.offsetTop - (window.innerWidth > 768 ? 88 : 72),
      left: 0,
      behavior: "smooth",
    });
  };

  if (feature_options?.length === 0) return null;

  if (Object.keys(selectedFeatureOptions)?.length === 0)
    return (
      <ProductDetailContainerStyled
        data-cart-mobile-open={cartMobileToggle.isOn}
      >
        <ProductDetailHeadingStyled colors={colors}>
          Selected Features
        </ProductDetailHeadingStyled>
        <NoDetailSelectedWrapperStyled>
          <FHTypography
            variant="subheading1"
            weight="md"
            colorType="subHeading"
          >
            No Benefits Selected
          </FHTypography>
          <button>
            <FHTypography
              variant="subheading1"
              weight="semi"
              color={colors.primary_color}
              onClick={scrollToFeatureSection}
            >
              View Features
            </FHTypography>
          </button>
        </NoDetailSelectedWrapperStyled>
      </ProductDetailContainerStyled>
    );

  return (
    <ProductDetailContainerStyled data-cart-mobile-open={cartMobileToggle.isOn}>
      <ProductDetailHeadingStyled colors={colors}>
        Selected Features
      </ProductDetailHeadingStyled>
      <ProductInfoCardContainerStyled>
        {Object.keys(selectedFeatureOptions)?.map(feature_option_key => (
          <ProductInfoCardStyled key={feature_option_key}>
            <ProductInfoNameStyled>
              {capitalize(feature_option_key?.split("_")?.slice(1).join(" "))}
            </ProductInfoNameStyled>
            <ProductInfoValueStyled>
              {capitalize(featureWithKeyValueObject[feature_option_key])}
            </ProductInfoValueStyled>
          </ProductInfoCardStyled>
        ))}
      </ProductInfoCardContainerStyled>
    </ProductDetailContainerStyled>
  );
}

function DiscountDetailSection({ groupCode, cartMobileToggle }) {
  const { colors } = useTheme();
  const { getSelectedAdditionalDiscounts, getDiscountAmount } =
    useAdditionalDiscount(groupCode);

  const selectedAdditionalDiscounts = getSelectedAdditionalDiscounts();

  if (selectedAdditionalDiscounts?.length === 0) return <></>;

  return (
    <ProductDetailContainerStyled data-cart-mobile-open={cartMobileToggle.isOn}>
      <ProductDetailHeadingStyled colors={colors}>
        Additional Discounts
      </ProductDetailHeadingStyled>
      <ProductInfoCardContainerStyled>
        {selectedAdditionalDiscounts?.map((additionalDiscount, index) => (
          <ProductInfoCardStyled key={additionalDiscount.id + index}>
            <ProductInfoNameStyled>
              {additionalDiscount.name}
            </ProductInfoNameStyled>
            <ProductInfoValueStyled>
              {additionalDiscount?.affectspremium === "decreases" && "-"}
              &nbsp;
              {amount(Math.floor(getDiscountAmount(additionalDiscount)))}
            </ProductInfoValueStyled>
          </ProductInfoCardStyled>
        ))}
      </ProductInfoCardContainerStyled>
    </ProductDetailContainerStyled>
  );
}

export { RiderDetailSection, FeatureDetailSection, DiscountDetailSection };
