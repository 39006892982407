import toast from "react-hot-toast";
import styled from "styled-components/macro";
import { useDispatch } from "react-redux";
import { useCallApiMutation } from "src/api/api";
import { Button } from "src/components/Common/Button/ContinueButton";
import { useTheme } from "src/customHooks";
import {
  setKYCRedirectionURL,
  setProposalRefetchConfirmationModal,
  setProposalRefetchModal,
  setShowErrorPopup,
} from "../../ProposalSections/ProposalSections.slice";
import useAsyncButton from "./useAsyncButton.js";

const AsyncButton = ({
  onChange = "",
  value = "",
  mode = "",
  endpoint = "",
  error = "",
  additionalPayload = [],
  values = {},
  buttonText = "Verify",
  onAsyncSuccess = () => {},
  onAsyncFailure = () => {},
}) => {
  const { status } = useAsyncButton({ initialValue: value });
  const [triggerMutation, { isLoading }] = useCallApiMutation();
  const dispatch = useDispatch();
  const {
    colors: { primary_color },
  } = useTheme();

  const additionalPayloadGenerator = ({
    additionalPayload = [],
    values = {},
  }) => {
    if (additionalPayload.length) {
      const requiredPayload = additionalPayload.filter(
        ({ required }) => required,
      );
      const allRequiredPayloadExist = requiredPayload.every(
        ({ key }) => values?.[key],
      );
      let adPayload = {};
      if (allRequiredPayloadExist) {
        requiredPayload.forEach(({ key }) => (adPayload[key] = values?.[key]));
      }
      return {
        disableVerify: !allRequiredPayloadExist,
        adPayload,
      };
    } else {
      return {
        disableVerify: false,
        adPayload: {},
      };
    }
  };

  const { disableVerify, adPayload } = additionalPayloadGenerator({
    additionalPayload,
    values,
  });

  const disableSave = disableVerify;

  const saveClickHandler = e => {
    e.preventDefault();
    e.stopPropagation();

    triggerMutation({
      url: endpoint,
      body: { payload: "", mode, additionalPayload: adPayload },
      method: "POST",
    }).then(res => {
      if (res?.error) {
        onAsyncFailure();

        if (res?.error?.data?.data?.redirection_url) {
          dispatch(
            setKYCRedirectionURL({
              redirection_url: res?.error?.data?.data?.redirection_url,
              newTab: res?.error?.data?.data?.newTab,
            }),
          );
        }

        if (res?.error?.data?.data?.message) {
          dispatch(
            setShowErrorPopup({
              show: true,
              head: "",
              msg: res?.error?.data?.data?.message,
            }),
          );
        }

        toast.error(`Failed to verify.`, {
          position: "top-center",
        });

        onChange(
          {
            target: {
              value: "failure",
            },
          },
          "failure",
        );
        return;
      }

      const {
        verification_status,
        redirection_url,
        message,
        refetch_proposal_data,
        refetch_proposal_data_confirmation_pop_up,
        refetch_proposal_data_confirmation_msg = "",
      } = res?.data?.data;

      if (verification_status) {
        onAsyncSuccess();
        toast.success(`Verified successfully.`, {
          position: "top-center",
        });
        onChange(
          {
            target: {
              value: "success",
            },
          },
          "success",
        );
        if (refetch_proposal_data) {
          dispatch(
            setProposalRefetchModal({
              visible: true,
              title:
                "We have updated some of your details based on your CKYC. you can check later in the Proposer Details section.",
            }),
          );
        }
        if (refetch_proposal_data_confirmation_pop_up) {
          dispatch(
            setProposalRefetchConfirmationModal({
              visible: true,
              title: `
              ${
                refetch_proposal_data_confirmation_msg.length > 0
                  ? refetch_proposal_data_confirmation_msg
                  : "We will update information based on your CKYC."
              }
               Click 'YES' to continue with updated information or click 'NO' to continue with current information.
              `,
            }),
          );
        }
      } else {
        redirection_url && dispatch(setKYCRedirectionURL(redirection_url));
        onAsyncFailure();
        toast.error(`Failed to verify.`, {
          position: "top-center",
        });
        if (message) {
          dispatch(
            setShowErrorPopup({
              show: true,
              head: "",
              msg: message,
            }),
          );
        }

        onChange(
          {
            target: {
              value: "failure",
            },
          },
          "failure",
        );
      }
    });
  };
  const dummyFunction = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  let displayOnButton = buttonText;
  if (status === "success") {
    displayOnButton = "Verified";
  }
  if (status === "failure") {
    displayOnButton = "Retry";
  }

  return (
    <>
      <InputContainer primaryColor={primary_color}>
        <div className="input_wrapper">
          <Button
            className="input_btn"
            disabled={disableSave}
            onClick={status === "success" ? dummyFunction : saveClickHandler}
            loader={isLoading}
          >
            {displayOnButton}
          </Button>
        </div>

        {error ? <p className="formbuilder__error">{error}</p> : <></>}
      </InputContainer>
    </>
  );
};

const InputContainer = styled.div`
  margin-top: 0.3rem !important;
  position: relative;
  margin-bottom: 2px !important;
  .input_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .input_btn {
    height: 55px;
    width: 100%;
    background: ${({ status, primaryColor }) => {
      status === "success" ? "green" : primaryColor;
    }};
  }
`;

export default AsyncButton;
