export const sizeInMB = sizeInBytes => (sizeInBytes / (1024 * 1024)).toFixed(2);

export function getBase64(
  file = {},
  successCallback = () => {},
  errorCallback = () => {},
) {
  var reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    successCallback(reader.result);
  };
  reader.onerror = function (error) {
    errorCallback(error);
  };
}
