import { useEffect, useState, createRef } from "react";
import styled from "styled-components/macro";
import {
  displayTabsGenerator,
  validatedMinAndMaxDateGenerator,
} from "../../../components/FormBuilder/formUtils";
import { useTheme } from "src/customHooks";
import { Button } from "src/components/Common/Button/ContinueButton";
import BackButton from "../../../components/BackButton";
import { BsCheckLg } from "react-icons/bs";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import "react-datepicker/dist/react-datepicker.css";
import { TextField } from "@mui/material";
import { mobile } from "src/utils/mediaQueries";
import down from "./../../../assets/images/down-arrow.svg";
import up from "./../../../assets/images/up-arrow.svg";
import Dropdown from "../../../components/Dropdown";
import { useSelector } from "react-redux";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

const CustomAccordion = ({
  label = "",
  tabs_config = {},
  values = {},
  member = "",
  options,
  subFields = [],
  onChange = () => {},
  value,
  parent = "",
  relation = "",
  error,
}) => {
  const relationShip = member || relation;

  const {
    colors: { primary_color, primary_shade, secondary_color },
  } = useTheme();

  const tabsToRender = displayTabsGenerator({
    tabs_config,
    values,
    options,
    member: relationShip,
  });

  const [activeTab, setActiveTab] = useState(tabsToRender[0]);

  const [valueContainer, setValueContainer] = useState(value || {});

  const [saveClicked, setSaveClicked] = useState(false);

  useEffect(() => {
    setActiveTab(tabsToRender[0]);
  }, [JSON.stringify(tabsToRender)]);

  const subFieldsChangeHandler = ({ option_id, field_name, value }) => {
    if (option_id in valueContainer) {
      setValueContainer(current => ({
        ...current,
        [option_id]: {
          ...current[option_id],
          [field_name]: value,
        },
      }));
    } else {
      setValueContainer(current => ({
        ...current,
        [option_id]: {
          [field_name]: value,
        },
      }));
    }
  };

  const backPaginationDecider = () => {
    const activeTabIndex = tabsToRender.findIndex(
      tab => tab.value === activeTab.value,
    );

    if (activeTabIndex > 0) {
      return {
        appear: true,
        back: () => {
          setActiveTab(tabsToRender[activeTabIndex - 1]);
        },
      };
    } else {
      return {
        appear: false,
        back: () => {},
      };
    }
  };

  const nextPaginationDecider = () => {
    const activeTabIndex = tabsToRender.findIndex(
      tab => tab.value === activeTab.value,
    );

    if (activeTabIndex === tabsToRender.length - 1) {
      return {
        showSave: true,
        next: () => {
          onChange({ target: { value: valueContainer } }, valueContainer, {
            utilize_parent: parent.split(".")[0],
          });
          setSaveClicked(true);
        },
      };
    } else {
      return {
        showSave: false,
        next: () => {
          onChange({ target: { value: valueContainer } }, valueContainer, {
            utilize_parent: parent.split(".")[0],
          });
          setActiveTab(tabsToRender[activeTabIndex + 1]);
        },
      };
    }
  };

  const errorMsg =
    error === "This field is required."
      ? "Mandatory fields must be saved to continue."
      : error;

  return (
    <AccordionContainer>
      <Question secondary_color={secondary_color}>{label}</Question>
      <TabSection>
        {tabsToRender.map(tabObj => (
          <span
            key={`${tabObj?.label}-${tabObj?.value}`}
            css={`
              min-width: 120px;
              padding: 10px 10px;
              box-sizing: border-box;
              text-align: center;
              border-radius: 0.5rem;
              color: ${primary_color};
              box-shadow: 0 0 0 2px ${primary_color};
              background: ${activeTab.value === tabObj.value
                ? primary_shade
                : "#fff"};
              font-weight: 600;
              cursor: pointer;
            `}
          >
            {tabObj.label}
          </span>
        ))}
      </TabSection>
      <BodySection
        subFields={subFields}
        activeTab={activeTab}
        subFieldsChangeHandler={subFieldsChangeHandler}
        subFieldValues={valueContainer}
        backPaginationDecider={backPaginationDecider}
        nextPaginationDecider={nextPaginationDecider}
        saveClicked={saveClicked}
        relationShip={relationShip}
      />
      {errorMsg && <p className="formbuilder__error">{errorMsg}</p>}
    </AccordionContainer>
  );
};

export default CustomAccordion;

//* CHILDREN ------------------------------------------------------------------------------------------------------------------
const BodySection = ({
  activeTab,
  subFields,
  subFieldsChangeHandler = () => {},
  subFieldValues = {},
  backPaginationDecider = () => {},
  nextPaginationDecider = () => {},
  saveClicked,
  relationShip,
}) => {
  const displayFields = subFields.filter(
    field =>
      visibilityDecider({ activeTab, render: field?.render }) &&
      FormFields?.[field?.type],
  );

  const mandatoryDisplayFields = subFields.filter(
    field => field?.validation?.required,
  );

  return (
    <>
      <BodySectionOuter>
        {displayFields.map(field => {
          const Comp = FormFields[field.type];

          return visibilityDecider({ activeTab, render: field?.render }) ? (
            <Comp
              key={field?.name + activeTab?.value}
              option_id={activeTab?.value}
              subFieldsChangeHandler={subFieldsChangeHandler}
              value={subFieldValues?.[activeTab?.value]?.[field?.name]}
              relationShip={relationShip}
              {...field}
            />
          ) : (
            <></>
          );
        })}
      </BodySectionOuter>
      <Pagination
        backPaginationDecider={backPaginationDecider}
        nextPaginationDecider={nextPaginationDecider}
        saveClicked={saveClicked}
        disableNextOrSave={
          !allMandatoryFilled({
            mandatoryDisplayFields,
            checkValues: subFieldValues[activeTab?.value],
          })
        }
      />
    </>
  );
};

const Pagination = ({
  backPaginationDecider,
  nextPaginationDecider,
  saveClicked,
  disableNextOrSave = false,
}) => {
  return (
    <PaginationButtonSection>
      {backPaginationDecider().appear ? (
        <BackButton
          styledCss={`
          width: unset;
          cursor: pointer;
        `}
          onClick={backPaginationDecider().back}
        >
          Back
        </BackButton>
      ) : (
        <span></span>
      )}

      <Button
        onClick={nextPaginationDecider().next}
        css={`
          min-width: 70px;
        `}
        disabled={disableNextOrSave}
      >
        {nextPaginationDecider().showSave ? (
          saveClicked ? (
            <BsCheckLg size={"0.8rem"} color={"#fff"} />
          ) : (
            "Save"
          )
        ) : (
          "Next"
        )}
      </Button>
    </PaginationButtonSection>
  );
};

//* ---------------------------------------------------------------------------------------------------------------------------

//* FORM-FIELDS ---------------------------------------------------------------------------------------------------------------------------

const InputField = ({
  placeholder = "",
  type = "text",
  label = "",
  validation = {
    required: false,
    min: 0,
    max: 20,
    pattern: /^[0-9a-zA-Z\s-]*$/,
  },
  name = "",
  option_id = "",
  subFieldsChangeHandler = () => {},
  value = "",
}) => {
  return (
    <InputContainer>
      <Input
        type={type}
        name={name}
        id={name + option_id}
        placeholder={placeholder}
        required={validation?.required}
        minLength={validation?.min}
        maxLength={validation?.max}
        pattern={validation?.pattern}
        value={value}
        onChange={e => {
          subFieldsChangeHandler({
            option_id,
            field_name: name,
            value: e.target.value,
          });
        }}
      />

      <Label mandatory={validation?.required}>{label}</Label>
    </InputContainer>
  );
};

const DateField = ({
  label = "",
  name = "",
  placeholder = "",
  option_id = "",
  subFieldsChangeHandler = () => {},
  value = null,
  relationShip,
  setError = () => {},
  validation = {
    required: false,
    max: "",
    min: "",
    rules: [],
  },
}) => {
  const datePickerChangeHandler = dateObj => {
    subFieldsChangeHandler({
      option_id: option_id,
      field_name: name,
      value: dateObj?.format("YYYY-MM-DD"),
    });
  };

  const { proposalData } = useSelector(state => state.proposalPage);

  const { minDate, maxDate } = validatedMinAndMaxDateGenerator({
    rules: Array.isArray(validation?.rules) ? validation?.rules : [],
    relation: relationShip,
    submittedValues: proposalData,
  });

  return (
    <DateContainer>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DesktopDatePicker
          id={name + option_id}
          name={name}
          inputFormat="DD-MM-YYYY"
          placeholderText={placeholder}
          value={value}
          onChange={newVal => datePickerChangeHandler(newVal)}
          minDate={minDate}
          maxDate={maxDate}
          renderInput={params => {
            setError(params.error);
            return (
              <TextField
                autoComplete="off"
                color="primary"
                fullWidth
                helperText={params.error && "Please provide a valid date"}
                {...params}
              />
            );
          }}
        />
      </LocalizationProvider>

      <Label mandatory={validation?.required}>{label}</Label>
    </DateContainer>
  );
};

const DropDownField = ({
  label = "",
  name = "",
  placeholder = "",
  option_id = "",
  subFieldsChangeHandler = () => {},
  value = "",
  options = {},
  validation = {
    required: false,
  },
}) => {
  const dropdownRef = createRef();

  const inputRef = createRef();

  const [showDropDown, setShowDropDown] = useState(false);

  const [searchQuery, setSearchQuery] = useState(options[value] || "");

  useEffect(() => {
    setSearchQuery(options[value]);
  }, [value]);

  const handleSearchQueryChange = e => {
    setSearchQuery(e.target.value.trimStart());
  };

  const inputClickHandler = () => {
    setShowDropDown(true);
    setSearchQuery("");
  };

  const inputBlurHandler = () => {
    setShowDropDown(false);
    setSearchQuery(options[value] || "");
  };

  const formattedSelectOptionsGenerator = (options = { key: "value" }) => {
    return Object.keys(options).map(key => ({
      label: options[key],
      value: key,
    }));
  };

  const changeHandler = ({ value }) => {
    inputRef.current.blur();
    setShowDropDown(false);
    subFieldsChangeHandler({ option_id, field_name: name, value });
  };

  return (
    <SelectContainer>
      <SearchInput
        name={name}
        value={searchQuery}
        onChange={handleSearchQueryChange}
        placeholder={placeholder}
        onClick={inputClickHandler}
        onBlur={inputBlurHandler}
        ref={inputRef}
        readOnly
      />
      <Dropdown
        hideDefaultControl
        onChange={changeHandler}
        options={formattedSelectOptionsGenerator(options)}
        ref={dropdownRef}
        searchQuery={searchQuery}
        showDropdown={showDropDown}
        dropdownCss={""}
      />
      <Label mandatory={validation?.required}>{label}</Label>
    </SelectContainer>
  );
};

const FormFields = {
  text: InputField,
  number: InputField,
  date: DateField,
  select: DropDownField,
};

//* ---------------------------------------------------------------------------------------------------------------------------

//* HELPER ---------------------------------------------------------------------------------------------------------------------------

const visibilityDecider = ({
  activeTab = { label: "", value: "" },
  render = { when: "" },
}) => {
  let visibility = true;

  if (render?.when) {
    visibility = render?.when?.includes(activeTab?.value);
  }

  return visibility;
};

const allMandatoryFilled = ({
  mandatoryDisplayFields = [{}],
  checkValues = {},
}) => {
  return mandatoryDisplayFields?.every(field => checkValues?.[field?.name]);
};

//* ---------------------------------------------------------------------------------------------------------------------------

//* STYLING ---------------------------------------------------------------------------------------------------------------------------

const AccordionContainer = styled.div`
  margin-bottom: 12px !important;
  @media (max-width: 767px) {
    margin-bottom: 12px !important;
    margin-top: 15px;
  }
`;

const BodySectionOuter = styled.section`
  margin-top: 1.5rem;
`;

const TabSection = styled.section`
  display: flex;
  flex-wrap: wrap;
  gap: 0.8rem;
  margin-top: 1.5rem;
`;

const PaginationButtonSection = styled.section`
  padding: 0.5rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const InputContainer = styled.div`
  margin: 1.5rem 0rem !important;
  position: relative;
`;

const Input = styled.input`
  list-style: none;
  -webkit-user-select: text;
  list-style-type: none;
  -webkit-user-select: initial;
  -khtml-user-select: initial;
  -moz-user-select: initial;
  -ms-user-select: initial;
  user-select: initial;

  &:before,
  &:after {
    -webkit-user-select: initial;
    -khtml-user-select: initial;
    -moz-user-select: initial;
    -ms-user-select: initial;
    user-select: initial;
  }
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  margin: 0;
  text-transform: ${props => props.textTransform};
  font-family: inherit;
  line-height: inherit;
  overflow: visible;
  outline: none;
  box-shadow: none;
  transition: all 0.3s ease-in-out;
  touch-action: manipulation;
  width: 100%;
  border: ${props => (props.error ? "solid 1px #c7222a" : "solid 1px #ced4da")};
  height: 55px;
  font-size: 14px;
  cursor: ${props => {
    return props.isHovering && props.readOnly ? "not-allowed" : "pointer";
  }};
  color: #090909;
  position: relative;
  padding: 0 25px;
  &:focus {
    border-color: ${props => (props.error ? "#c7222a" : "solid 1px  #393939")};
    color: black;
  }
  &:before {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  &:after {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  ${mobile} {
    height: 52px;
    padding: 0 16px;
    font-size: 14px;
  }
`;

const DateContainer = styled.div`
  cursor: ${props => (props.readOnly ? "not-allowed" : "pointer")} !important;

  & .react-datepicker__navigation--years-upcoming {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid black;
  }
  & .react-datepicker__navigation--years-previous {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid black;
  }
  margin-top: 0.3rem !important;
  position: relative;
  margin-bottom: 12px !important;
  @media (max-width: 768px) {
    margin-bottom: 12px !important;
  }
  & > div {
    width: 100%;
  }
  & input {
    border: ${props => {
      return props.error ? "solid 1px #c7222a" : "solid 1px #ced4da";
    }};
    height: 55px;
    font-family: inherit;
    line-height: inherit;
    overflow: visible;
    outline: none;
    box-shadow: none;
    transition: all 0.3s ease-in-out;
    touch-action: manipulation;
    width: 100%;
    font-size: 14px;
    color: #090909;
    position: relative;
    padding: 0 25px;
    &:focus {
      border-color: ${props => {
        return props.error ? "#c7222a" : "solid 1px  #393939";
      }};
      color: black;
    }
    @media (max-width: 767px) {
      height: 42px;
      padding: 0 16px;
      border-radius: 6px;
      font-size: 14px;
    }
  }
`;

const Label = styled.label`
  text-align: left;
  list-style: none;
  list-style-type: none;
  user-select: none;
  box-sizing: border-box;
  touch-action: manipulation;
  display: inline-block;
  font-size: 14px !important;
  color: #000;
  line-height: 14px;
  position: absolute;
  left: 20px;
  top: 0;
  margin: 0;
  max-width: 95%;
  background: #fff;
  transition: all 0.3s ease-in-out;
  font-weight: 600;
  padding: 0 5px;
  transform: translateY(-60%);

  ${({ mandatory }) => {
    return mandatory ? "&::after {  content: '*'; color: red;}" : "";
  }}

  @media (max-width: 1200px) {
    font-size: 13px !important;
  }
  @media (max-width: 1100px) {
    font-size: 12px !important;
  }
  @media (max-width: 1050px) {
    font-size: 12px !important;
  }
  ${mobile} {
    left: 10px;
    font-size: 14px;
  }
`;

const Question = styled.p`
  &:after {
    content: "";
    height: 22px;
    width: 6px;
    position: absolute;
    left: -2px;
    top: 2px;
    background-color: ${({ secondary_color }) => secondary_color};
    border-radius: 50px;
    ${mobile} {
      height: calc(100% - 6px);
    }
  }
  font-size: 16px !important;
  text-align: inherit;
  line-height: 27px !important;
  color: #000;
  font-weight: 400 !important;
  position: relative;
  padding-left: 16px;
  font-weight: 600;
`;

const SelectContainer = styled.div`
  margin-top: 0.3rem !important;
  position: relative;
  margin-bottom: 12px !important;
  @media (max-width: 767px) {
    margin-bottom: 12px !important;
    margin-top: 15px !important;
  }
`;

const SearchInput = styled.input`
  appearance: none;
  background: ${props => (props.disabled ? "" : `url(${down}) no-repeat 98%`)};
  cursor: pointer;
  list-style: none;
  &:disabled {
    cursor: not-allowed !important;
    pointer-events: all !important;
  }
  list-style-type: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  margin: 0;
  font-family: inherit;
  line-height: inherit;
  overflow: visible;
  outline: none;
  box-shadow: none;
  transition: border 0.3s ease-in-out;
  touch-action: manipulation;
  width: 100%;
  border: 1px solid #ced4da;
  border: ${props => props.error && "solid 1px #c7222a"};
  height: 55px;

  font-size: 14px;
  color: #090909;
  position: relative;
  padding: 0 25px;
  &:focus {
    border: ${props => props.error && "solid 1px #c7222a"};
    color: black;
    background: url(${up}) no-repeat 98%;
  }

  @media (max-width: 767px) {
    font-size: 14px;
    height: 62px;
    padding: 0 16px;
    border-radius: 6px;
  }
`;

//* ---------------------------------------------------------------------------------------------------------------------------
