import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { getMembersName } from "src/components/CartSummaryModal/cartSummary.utility";
import { Button } from "src/components/Common/Button/ContinueButton";
import { REDIRECT_OUTSIDE } from "src/components/Common/Popups/InfoPopup/InfoPopup.helpers";
import useInfoPopup from "src/components/Common/Popups/InfoPopup/useInfoPopup";
import { useTheme } from "src/customHooks";
import {
  useFrontendBoot,
  useGetEnquiry,
} from "src/customHooks/useGetCommonQueries";
import { useResponsiveCondition } from "src/pos/hooks";
import { FWEIGHT, SHADES } from "src/styles/styleGuide";
import { stringLengthCutter } from "src/utils/string.utility";
import styled from "styled-components/macro";
import {
  setDocUploadUtility,
  setKYCRedirectionURL,
  setRevalidateKYCVerificationUtility,
  setShowErrorPopup,
} from "./ProposalSections.slice";
import {
  FlexDiv,
  RedirectionDisableScreenOuter,
} from "./ProposalSections.styles";
import KYCVerificationStatusModal from "./components/KYCVerifictionStatusModal/KYCVerifictionStatusModal";
import useFormNavigation from "./useFormNavigation";
import { useEventTracking } from "src/customHooks/useEventTracking";

const RedirectionDisabledScreen = ({
  redirectionUtility = [],
  verified = false,
  cartData = [],
  docUpload = false,
}) => {
  const {
    data: { settings },
  } = useFrontendBoot();
  const { colors } = useTheme();
  const [showVerificationModal, setShowVerificationModal] = useState(false);

  const { data: enquiryData = {} } = useGetEnquiry();

  const { handleOpenInfoPopup } = useInfoPopup();

  const toggleVerificationModel = () =>
    setShowVerificationModal(current => !current);

  const { proposalData, kycSection } = useSelector(state => state.proposalPage);
  const revalidateUtility =
    kycSection?.redirectionUtility[0]?.revalidateUtility;
  const fullName = proposalData["Proposer Details"]
    ? proposalData["Proposer Details"]?.name
    : enquiryData?.data?.name;
  const groups = enquiryData?.data?.groups || [];
  const { navigateTo } = useFormNavigation();
  const dispatch = useDispatch();
  const params = useParams();
  const { width, breakpoint } = useResponsiveCondition(600);
  const [showCheckVerificationButton, setShowCheckVerificationButton] =
    useState(false);

  const { webEngageTrackEvent } = useEventTracking();
  // ------------------------- KYC Grouping -----------------------//
  const isPartial =
    !verified && cartData?.some(cart => !!cart?.can_proceed_with_proposal)
      ? true
      : false;

  const kycDoneGroups = !verified
    ? cartData.filter(group => !!group?.can_proceed_with_proposal)
    : [];

  const kycRemainingGroups = !verified
    ? cartData.filter(group => !group?.can_proceed_with_proposal)
    : [];

  const kycDoneICs = kycDoneGroups.map(group => group.product.name).join(", ");

  const kycRemainingICs = kycRemainingGroups
    .map(group => group.product.name)
    .join(", ");

  // groupsToShow contains the data for Tabs in case of Grouping
  let groupsToShow = {};
  groups.map(group => {
    redirectionUtility.map(item => {
      if (group?.id === item?.group_id) {
        let groupUtility = {
          [group?.id]: {
            groupName: getMembersName(group?.members),
            url: item?.redirection_url,
            newTab: item?.newTab,
            metaData: item?.meta_data || null,
            viaForm: item?.redirection_url_via_form,
          },
        };
        groupsToShow = {
          ...groupsToShow,
          ...groupUtility,
        };
      }
    });
  });

  const [activeGroup, setActiveGroup] = useState(Object.keys(groupsToShow)[0]);

  const showErrorPopup = () => {
    //* shows the error popup when detected in url
    const error_popup_msg = params?.error_popup_msg;
    if (error_popup_msg) {
      dispatch(
        setShowErrorPopup({
          show: true,
          head: "",
          message: error_popup_msg,
        }),
      );
    }
  };

  useEffect(showErrorPopup, []);
  const kycStatusMsg = cartData[0]?.is_ckyc_attempted_via_doc_upload
    ? "in progress"
    : "completed";

  const retryHandler = () => {
    dispatch(setKYCRedirectionURL([]));
  };
  const docRetryHandler = () => {
    dispatch(setDocUploadUtility("removeMsg"));
  };

  const docUploadHandler = () => {
    dispatch(setDocUploadUtility(false));
  };

  const redirectionHandler = (url, newTab) => {
    if (
      redirectionUtility.find(group => group.group_id === activeGroup)
        ?.redirection_url_via_form
    ) {
      let form = document.createElement("form");
      form.id = "kycRedirectionForm";
      form.name = "kycRedirectionForm";
      form.action = groupsToShow[activeGroup]?.url;
      form.target = "_blank";
      form.method = "post";
      form.innerHTML = `
      <input type="hidden" name="Aggregator_KYC_Req_No" id="Aggregator_KYC_Req_No" value=${groupsToShow[activeGroup]?.metaData?.Aggregator_KYC_Req_No}>
      <input type="hidden" name="IC_KYC_No" id="IC_KYC_No" value=${groupsToShow[activeGroup]?.metaData?.IC_KYC_No}>
      <input type="hidden" name="Aggregator_Return_URL" id="Aggregator_Return_URL" value=${groupsToShow[activeGroup]?.metaData?.Aggregator_Return_URL}>`;
      // the form must be in the document to submit it
      document.body.append(form);
      form.submit();
      return;
    } else if (newTab) {
      window.open(url);
    } else {
      window.location.href = url;
      return;
    }
  };

  const clickHandler = (e, url, newTab) => {
    e.preventDefault();
    e.stopPropagation();

    setShowCheckVerificationButton(true);
    //* opens the Popup to revalidate the KYC verification
    if (revalidateUtility?.revalidate_kyc_verification_until) {
      dispatch(setRevalidateKYCVerificationUtility({ showPopup: true }));
      if (newTab) {
        window.open(url);
        return;
      }
    } else {
      let redirectionObject = {
        ...REDIRECT_OUTSIDE,
        onClick: () => {
          redirectionHandler(url, newTab);
        },
      };
      if (settings?.alert_message_on_external_redirection_text) {
        redirectionObject["description"] =
          settings.alert_message_on_external_redirection_text;
      }
      handleOpenInfoPopup(redirectionObject);
    }
  };

  const nextClickHandler = () => {
    navigateTo({ formName: "Insured Details" });
    webEngageTrackEvent("KYC Details Submitted");
  };

  const switchGroup = () => {
    const nextGroupID = kycRemainingGroups[0]?.group?.id;
    setActiveGroup(nextGroupID);
  };

  useEffect(() => {
    showErrorPopup();
    if (kycRemainingGroups.length) {
      // automatically open to remaining group tab in case of partial kyc
      switchGroup();
    }
    return () => {};
  }, []);

  return (
    <>
      <RedirectionDisableScreenOuter>
        {Object.keys(groupsToShow).length > 1 ? (
          <Tabs color={colors.primary_color}>
            {Object.keys(groupsToShow).map((group, index) => {
              return (
                <Tab
                  className={group == activeGroup && "active"}
                  key={index}
                  onClick={() => setActiveGroup(group)}
                >
                  {width < breakpoint
                    ? stringLengthCutter(groupsToShow[group].groupName, 10)
                    : groupsToShow[group].groupName}
                </Tab>
              );
            })}
          </Tabs>
        ) : (
          <></>
        )}
        <MainContainerStyled>
          <p className="desc content_center">
            {verified ? (
              <>
                {fullName} your KYC verification is {kycStatusMsg}.
              </>
            ) : isPartial &&
              kycDoneGroups.some(group => group.group.id === +activeGroup) ? (
              // If KYC is partial and KYC is successful for current group
              <>
                Hello {fullName}, KYC has been verified for {kycDoneICs}.<br />{" "}
                Please proceed for KYC verification required for{" "}
                {kycRemainingICs}
              </>
            ) : (
              <>
                Hello {fullName},<br /> Click on “Retry” in case you want to
                revise your details or enter your PAN/Aadhaar Number in case you
                do not have a CKYC Number <b>OR</b>
                <br /> Click on{" "}
                {docUpload ? `“Upload Documents”` : `“Verify Documents”`} for
                completing the CKYC process{" "}
                {docUpload
                  ? `by uploading documents.`
                  : `on ${
                      cartData[0]?.product?.company?.name || "Insurance"
                    } website.`}
              </>
            )}
          </p>
          {groupsToShow[activeGroup]?.url &&
          kycRemainingGroups?.some(group => group.group.id === +activeGroup) ? (
            <>
              <FlexDiv
                primaryColor={colors.primary_color}
                primaryShade={colors.primary_shade}
                className="kycButtons"
              >
                <Button
                  onClick={retryHandler}
                  className="status_btn"
                >{`Retry`}</Button>
                <Button
                  className="status_btn"
                  onClick={e =>
                    clickHandler(
                      e,
                      groupsToShow[activeGroup]?.url,
                      groupsToShow[activeGroup]?.newTab,
                    )
                  }
                >{`Verify Documents`}</Button>
              </FlexDiv>
              {showCheckVerificationButton ? (
                <>
                  <br />
                  <p className="typography--weight-bold">OR</p>
                  <p className="desc">
                    Click on “Check Verification Status” if you have completed
                    the verification through{" "}
                    {cartData[0]?.product?.company?.name || "Insurance"}{" "}
                    website.
                  </p>
                  <Button
                    onClick={toggleVerificationModel}
                    className="rounded_btn"
                  >{`Check Verification Status`}</Button>
                </>
              ) : (
                <></>
              )}
            </>
          ) : kycDoneGroups?.some(({ group }) => group.id === +activeGroup) ? (
            <Button onClick={switchGroup} className="rounded_btn">
              Next
            </Button>
          ) : (
            <></>
          )}
          {docUpload ? (
            <FlexDiv>
              <Button
                onClick={docRetryHandler}
                className="status_btn"
              >{`Retry`}</Button>
              <Button
                onClick={docUploadHandler}
                className="status_btn"
              >{`Upload Documents`}</Button>
            </FlexDiv>
          ) : (
            <></>
          )}
          {verified ? (
            <Button onClick={nextClickHandler} className="rounded_btn">
              Proceed
            </Button>
          ) : (
            <></>
          )}
        </MainContainerStyled>
      </RedirectionDisableScreenOuter>

      <KYCVerificationStatusModal
        isModalOpen={showVerificationModal}
        handleCloseReusableModal={() => setShowVerificationModal(false)}
      />
    </>
  );
};

const MainContainerStyled = styled.main`
  .content_center {
    text-align: center;
  }
  .status_btn {
    border-radius: 0.5rem;
    min-width: 170px;
  }
  .rounded_btn {
    border-radius: 0.5rem;
  }
`;

export const Tabs = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .active {
    color: ${props => props.color};
    :after {
      background-color: ${props => props.color};
    }
  }
`;

export const Tab = styled.p`
  // background-color: ${SHADES.LIGHT_GRAY};
  // border-bottom: 2px solid ${SHADES.DARK_GRAY};
  color: ${SHADES.HEADING};
  font-weight: ${FWEIGHT.md};
  padding: 0.7rem 2rem 0;
  cursor: pointer;
  text-transform: capitalize;
  position: relative;

  @media (max-width: 600px) {
    padding: 0.7rem 1rem 0;
  }
  :after {
    content: "";
    position: absolute;
    top: 115%;
    height: 2px;
    width: 100%;
    left: 0;
    background-color: ${SHADES.DARK_GRAY};
  }
`;
export default RedirectionDisabledScreen;
