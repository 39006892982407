import { Modal } from "react-bootstrap";
import styled from "styled-components";

export const ModalStyled = styled(Modal)`
  z-index: 9999;
  & .modal-dialog {
    max-width: 600px;
  }
`;

export const ModalTitledStyled = styled.div`
  padding: 1.5rem;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    height: 2em;
    width: 0.37em;
    background-color: ${props => props.primaryColor};
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    border-radius: 1em;
  }
  h1 {
    font-weight: 600;
    font-size: 1.27rem;
  }
`;

export const ModalBodyStyled = styled.div`
  padding-top: 0;
  padding-bottom: 0;
`;

export const ModalFooterStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  padding-top: 0;
  gap: 1rem;
`;
