import moment from "moment";
import { validationIndex } from "./formValidations";
export const generateRange = (param, values) => {
  if (!(param instanceof Array) && values?.RelationToProposerCode) {
    const [min, max, text] =
      param[values?.RelationToProposerCode.toLowerCase()];
    let object = {};
    for (let i = min; i <= max; ++i) {
      object[i] = i + " " + text;
    }
    return object;
  }

  const [min, max, text] = param;
  let object = {};
  for (let i = min; i <= max; ++i) {
    object[i] = i + " " + text;
  }
  return object;
};

export const fetchMembers = (when, values) => {
  const variable = when.split(".")[0];
  const members = values[variable].members;

  return Object.keys(members).filter(item => members[item]);
};

export const performValidations = ({
  validate,
  values,
  name,
  subJourneyType,
  additionalOptions,
  proposalData,
  member,
}) => {
  const validationArray = Object.keys(validate);
  for (let i = 0; i < validationArray.length; ++i) {
    let result = validationIndex[validationArray[i]]({
      param: validate[validationArray[i]],
      values,
      name,
      subJourneyType,
      validationArray,
      additionalOptions,
      proposalData,
      member,
    });

    if (result && !result.status) {
      return result.message;
    }
  }
};

export const fillingUtility = (fill, updateValue, checkValue, checkName) => {
  const { fieldName, type } = fill;
  switch (type) {
    case "relation":
      if (checkValue && checkValue.includes("M_"))
        updateValue(prev => {
          return { ...prev, [fieldName]: "M", [checkName]: checkValue };
        });
      break;
    default:
      updateValue(fieldName, null);
  }
};

const checkValue = (str, max) => {
  if (str.charAt(0) !== "0" || str === "00") {
    var num = parseInt(str);
    if (isNaN(num) || num <= 0 || num > max) num = 1;
    str =
      num > parseInt(max.toString().charAt(0)) && num.toString().length === 1
        ? "0" + num
        : num.toString();
  }
  return str;
};

export const checkAllow = (type, event, eventType) => {
  if (type === "onlyAlphabets" && eventType === "down") {
    let key = event.keyCode;

    if (
      (key >= 65 && key <= 90) ||
      key === 8 ||
      key === 9 ||
      key === 20 ||
      key === 16 ||
      key === 13 ||
      key === 37 ||
      key === 39 ||
      key === 46 ||
      key === 190 ||
      key === 32
    ) {
      // something has to come
    } else {
      event.preventDefault();
    }
  }

  if (type === "noSpecial" && eventType === "press") {
    var code = "charCode" in event ? event.charCode : event.keyCode;
    if (
      !(code > 47 && code < 58) && // numeric (0-9)
      !(code > 64 && code < 91) && // upper alpha (A-Z)
      !(code > 96 && code < 123)
    ) {
      // lower alpha (a-z)
      event.preventDefault();
    }
  }

  if (type === "onlyNumbers" && eventType === "press") {
    if (!/^[0-9]$/.test(event.key)) {
      event.preventDefault();
    }
  }

  if (type === "dob" && eventType === "input") {
    event.preventDefault();
    var input = event.target.value;
    if (/\D\/$/.test(input)) input = input.substr(0, input.length - 3);
    var values = input.split("-").map(function (v) {
      return v.replace(/\D/g, "");
    });
    if (values[0]) values[0] = checkValue(values[0], 31);
    if (values[1]) values[1] = checkValue(values[1], 12);
    var output = values.map(function (v, i) {
      return v.length === 2 && i < 2 ? v + "-" : v;
    });
    event.target.value = output.join("").substr(0, 14);
  }

  if (type === "month" && event.which === 8) {
    var val = event.target.value;

    if (val.length == 3 || val.length == 6) {
      event.target.value = val.slice(0, val.length - 1);
    }
  }

  if (type === "month" && eventType === "input") {
    event.preventDefault();
    let input = event.target.value;
    if (/\D\/$/.test(input)) input = input.substr(0, input.length - 3);
    let values = input.split("-").map(function (value) {
      return value.replace(/\D/g, "");
    });
    if (values[0]) values[0] = checkValue(values[0], 12);
    let output;
    output = values.map((v, i) => {
      return v.length === 2 && i < 1 ? v + "-" : v;
    });
    event.target.value = output.join("").substr(0, 9);
  }

  //* Change events will return a Object to control -----------------------------------

  if (type === "onlyNumbers" && eventType === "change") {
    if (
      !/^[0-9][0-9]*$/.test(event.target.value) &&
      event.target.value !== ""
    ) {
      return {
        notAllowed: true,
      };
    } else {
      return {
        notAllowed: false,
      };
    }
  }
};

export const ValueExtractor = (schema, values, member) => {
  if (!schema) return "";
  else if (
    typeof schema === "string" ||
    typeof schema === "boolean" ||
    Array.isArray(schema)
  ) {
    return schema;
  } else {
    let parentKey = Object.keys(schema)[0];
    let childKey = Object.keys(schema[parentKey])[0];
    return schema[parentKey][childKey][values[parentKey][member][childKey]];
  }
};

export const getOldDate = (oldYear, maxDate) => {
  let date = new Date().getDate();
  let month = new Date().getMonth();
  let fullDate = new Date(oldYear, month, date);
  fullDate = fullDate > maxDate ? maxDate : fullDate;
  return fullDate;
};

export const validatedMinAndMaxDateGenerator = ({
  age = [],
  rules = [],
  values = {},
  relation = "",
  submittedValues = {},
  one_day_less = false,
  two_day_less = false,
  ageInDays,
  label,
}) => {
  let newDate = new Date();

  let currentYear = newDate.getFullYear();

  let currentMonth = newDate.getMonth();

  let currentDate = newDate.getDate();

  const ruleValueGenerator = ({ rules = [], pattern = /^$/ }) => {
    return (
      rules
        ?.find(singleRule => {
          const key = singleRule.split(":")[0];
          return pattern.test(key);
        })
        ?.split(":")[1] || ""
    );
  };

  const rulesTypeGenerator = (rules = []) => {
    const typeOfRules = {
      date_format: ruleValueGenerator({
        rules: rules,
        pattern: /^date_format$/,
      }),

      after: ruleValueGenerator({ rules: rules, pattern: /^after$/ }),

      before: ruleValueGenerator({ rules: rules, pattern: /^before$/ }),

      after_or_equal: ruleValueGenerator({
        rules: rules,
        pattern: /^after_or_equal$/,
      }),

      before_or_equal: ruleValueGenerator({
        rules: rules,
        pattern: /^before_or_equal$/,
      }),

      gap_before_in_year: ruleValueGenerator({
        rules: rules,
        pattern: /^gap_before_in_year$/,
      }),

      gap_after_in_year: ruleValueGenerator({
        rules: rules,
        pattern: /^gap_after_in_year$/,
      }),

      gap_after_in_days: ruleValueGenerator({
        rules: rules,
        pattern: /^gap_after_in_days$/,
      }),

      allowed_days_in_future: ruleValueGenerator({
        rules: rules,
        pattern: /^allowed_days_in_future$/,
      }),

      gap_before_in_days: ruleValueGenerator({
        rules: rules,
        pattern: /^gap_before_in_days$/,
      }),

      allowed_days_in_past: ruleValueGenerator({
        rules: rules,
        pattern: /^allowed_days_in_past$/,
      }),

      allowed_years_in_future: ruleValueGenerator({
        rules: rules,
        pattern: /^allowed_years_in_future$/,
      }),

      allowed_days_after_min_date: ruleValueGenerator({
        rules: rules,
        pattern: /^allowed_days_after_min_date$/,
      }),
    };

    return typeOfRules;
  };

  const typeOfRules = rulesTypeGenerator(rules);

  const getDayForMaxDate = (maxDate, age) => {
    let dayForMaxDate = maxDate;
    if (`${age}`.includes(".")) {
      if (two_day_less) {
        dayForMaxDate = moment(maxDate).subtract(2, "days");
      } else if (one_day_less) {
        dayForMaxDate = moment(maxDate).subtract(1, "days");
      }
    }
    return dayForMaxDate.toDate();
  };
  let minDate =
    ageInDays > 0
      ? moment().subtract(89, "days").toDate()
      : age.length && age[1] >= 1
      ? new Date(currentYear - (age[1] + 1), currentMonth, currentDate + 1)
      : "";

  let maxDate =
    ageInDays > 0
      ? moment()
      : age?.length && parseFloat(age[0]) >= 1
      ? moment().subtract(age[0], "years")
      : age[0] === 0.3 //If the age is 3 months, IC allowed age in days is 89, 90 or 91
      ? moment().subtract(91, "days")
      : age[0]
      ? moment().subtract(age[0].toString().split(".")[1], "months")
      : moment();
  maxDate = getDayForMaxDate(maxDate, age);

  if (typeOfRules?.after) {
    if (typeOfRules?.after === "today") {
      minDate = new Date();
    } else if (Object.keys(values)?.includes(typeOfRules?.after)) {
      minDate = moment(values[typeOfRules?.after], "DD-MM-YYYY").toDate();
    }
  }

  if (typeOfRules?.before) {
    if (typeOfRules?.before === "today") {
      maxDate = new Date();
    } else if (Object.keys(values)?.includes(typeOfRules?.before)) {
      let year = values?.[typeOfRules?.before]?.split("-")[2];
      let month = values?.[typeOfRules?.before]?.split("-")[1];
      let date = values?.[typeOfRules?.before]?.split("-")[0];

      maxDate = new Date(year, month - 1, date);
    }
  }

  if (typeOfRules?.after_or_equal) {
    if (typeOfRules?.after_or_equal === "today") {
      minDate = new Date();
    } else if (typeOfRules?.after_or_equal === "member_birth_date") {
      const matchedMemberDOB =
        submittedValues?.["Insured Details"]?.[relation]?.dob;

      if (matchedMemberDOB) {
        let year = matchedMemberDOB?.split("-")[2];
        let month = matchedMemberDOB?.split("-")[1];
        let date = matchedMemberDOB?.split("-")[0];

        minDate = new Date(year, month - 1, date);
      }
    } else if (Object.keys(values)?.includes(typeOfRules?.after_or_equal)) {
      let year = values?.[typeOfRules?.after_or_equal]?.split("-")[2];
      let month = values?.[typeOfRules?.after_or_equal]?.split("-")[1];
      let date = values?.[typeOfRules?.after_or_equal]?.split("-")[0];

      minDate = new Date(year, month - 1, date);
    }
  }

  if (typeOfRules?.before_or_equal) {
    if (typeOfRules?.before_or_equal === "today") {
      maxDate = new Date();
    } else if (Object.keys(values)?.includes(typeOfRules?.before_or_equal)) {
      let year = values?.[typeOfRules?.before_or_equal]?.split("-")[2];
      let month = values?.[typeOfRules?.before_or_equal]?.split("-")[1];
      let date = values?.[typeOfRules?.before_or_equal]?.split("-")[0];

      maxDate = new Date(year, month - 1, date);
    }
  }

  if (typeOfRules?.gap_before_in_year) {
    if (+typeOfRules?.gap_before_in_year) {
      maxDate = new Date(
        maxDate.getFullYear() - typeOfRules?.gap_before_in_year,
        maxDate.getMonth(),
        maxDate.getDate(),
      );
    } else if (Object.keys(values)?.includes(typeOfRules?.gap_before_in_year)) {
      let years = +values?.[typeOfRules?.gap_before_in_year];

      maxDate = new Date(
        maxDate.getFullYear() - years,
        maxDate.getMonth(),
        maxDate.getDate() + 1,
      );
    }
  }

  if (typeOfRules?.gap_after_in_year) {
    if (minDate === "") {
      minDate = new Date();
    }
    if (+typeOfRules?.gap_after_in_year) {
      minDate = new Date(
        minDate.getFullYear() - typeOfRules?.gap_after_in_year,
        minDate.getMonth(),
        minDate.getDate(),
      );
    } else if (Object.keys(values)?.includes(typeOfRules?.gap_after_in_year)) {
      let years = +values?.[typeOfRules?.gap_after_in_year];

      minDate = new Date(
        minDate.getFullYear() + years,
        minDate.getMonth(),
        minDate.getDate(),
      );
    }
  }

  if (typeOfRules?.gap_after_in_days) {
    if (minDate === "") {
      minDate = new Date();
    }
    if (+typeOfRules?.gap_after_in_days) {
      minDate = new Date(
        minDate.getFullYear(),
        minDate.getMonth(),
        minDate.getDate() + +typeOfRules?.gap_after_in_days,
      );
    } else if (Object.keys(values)?.includes(typeOfRules?.gap_after_in_days)) {
      let days = +values?.[typeOfRules?.gap_after_in_days];

      minDate = new Date(
        minDate.getFullYear(),
        minDate.getMonth(),
        minDate.getDate() + days,
      );
    }
  }

  if (typeOfRules?.gap_before_in_days) {
    if (minDate === "") {
      minDate = new Date();
    }
    if (+typeOfRules?.gap_before_in_days) {
      minDate = new Date(
        minDate.getFullYear(),
        minDate.getMonth(),
        minDate.getDate() + -typeOfRules?.gap_before_in_days,
      );
    } else if (Object.keys(values)?.includes(typeOfRules?.gap_before_in_days)) {
      let days = +values?.[typeOfRules?.gap_before_in_days];

      minDate = new Date(
        minDate.getFullYear(),
        minDate.getMonth(),
        minDate.getDate() - days,
      );
    }
  }

  if (typeOfRules?.allowed_days_in_future) {
    if (+typeOfRules?.allowed_days_in_future) {
      maxDate = new Date(
        maxDate.getFullYear(),
        maxDate.getMonth(),
        maxDate.getDate() + +typeOfRules?.allowed_days_in_future,
      );

      if (!minDate) {
        minDate = new Date(currentYear, currentMonth, currentDate);
      }
    } else if (
      Object.keys(values)?.includes(typeOfRules?.allowed_days_in_future)
    ) {
      let days = +values?.[typeOfRules?.allowed_days_in_future];

      maxDate = new Date(
        maxDate.getFullYear(),
        maxDate.getMonth() + 1,
        maxDate.getDate() + days,
      );
    }
  }

  if (typeOfRules?.allowed_years_in_future) {
    if (+typeOfRules?.allowed_years_in_future) {
      maxDate = new Date(
        maxDate.getFullYear() + +typeOfRules?.allowed_years_in_future,
        maxDate.getMonth(),
        maxDate.getDate(),
      );

      if (!minDate) {
        minDate = new Date(currentYear, currentMonth, currentDate);
      }
    } else if (
      Object.keys(values)?.includes(typeOfRules?.allowed_years_in_future)
    ) {
      let years = +values?.[typeOfRules?.allowed_years_in_future];

      maxDate = new Date(
        maxDate.getFullYear() + years,
        maxDate.getMonth() + 1,
        maxDate.getDate(),
      );
    }
  }

  if (typeOfRules?.allowed_days_in_past) {
    if (+typeOfRules?.allowed_days_in_past === 0) {
      // do not allow user to enter past date
      minDate = maxDate;
    } else if (+typeOfRules?.allowed_days_in_past) {
      maxDate = new Date(
        maxDate.getFullYear(),
        maxDate.getMonth(),
        maxDate.getDate() + -typeOfRules?.allowed_days_in_past,
      );
    } else if (
      Object.keys(values)?.includes(typeOfRules?.allowed_days_in_past)
    ) {
      let days = +values?.[typeOfRules?.allowed_days_in_past];

      maxDate = new Date(
        maxDate.getFullYear(),
        maxDate.getMonth(),
        maxDate.getDate() - days,
      );
    }
  }

  if (typeOfRules?.allowed_days_after_min_date) {
    if (
      +typeOfRules?.allowed_days_after_min_date ||
      typeOfRules?.allowed_days_after_min_date === "0"
    ) {
      maxDate = new Date(
        minDate.getFullYear(),
        minDate.getMonth(),
        minDate.getDate() + -typeOfRules?.allowed_days_after_min_date,
      );
    } else if (
      Object.keys(values)?.includes(typeOfRules?.allowed_days_after_min_date)
    ) {
      let days = +values?.[typeOfRules?.allowed_days_after_min_date];

      maxDate = new Date(
        minDate.getFullYear(),
        minDate.getMonth(),
        minDate.getDate() - days,
      );
    }
  }

  if (label === "First Policy Start Date") {
    const policyStartMinDate = new Date(1986, 0, 1);
    minDate = policyStartMinDate.toString();
  }

  return { minDate, maxDate };
};

export const generateAge = ({ dob = "24-06-2008" }) => {
  let tempDOB = "06/24/2008";

  if (!dob.includes("-")) {
    tempDOB = `01/01/${dob}`;
  } else if (dob.includes("-")) {
    tempDOB = `${dob.split("-")[1]}/${dob.split("-")[0]}/${dob.split("-")[2]}`;
  }

  const birthDate = new Date(tempDOB);

  const month_diff = Date.now() - birthDate.getTime();

  const age_dt = new Date(month_diff);

  const year = age_dt.getUTCFullYear();

  const age = Math.abs(year - 1970);

  return +age;
};

export const validNomineeRelationGenerator = ({
  nominee_relation,
  reverseLogic = false,
}) => {
  let nomineeRelation = nominee_relation;

  if (
    (nomineeRelation?.includes("son") ||
      nomineeRelation?.includes("daughter")) &&
    !nomineeRelation?.includes("in_law")
  ) {
    nomineeRelation = reverseLogic
      ? nomineeRelation.slice(0, nomineeRelation.length - 1)
      : `${nomineeRelation}1`;
  }

  return nomineeRelation;
};

export const displayTabsGenerator = ({
  tabs_config = {},
  values = {},
  member = "",
  options = {},
}) => {
  let displayTabs = tabs_config?.tabs;

  if (displayTabs?.length) {
    displayTabs = tabs_config?.tabs;
  } else if (tabs_config?.field && tabs_config?.utilize) {
    const { field, utilize } = tabs_config;

    if (utilize === "values") {
      if (field?.split(">").length === 1) {
        displayTabs = values[field] || [];
      } else if (field?.split(">").length === 2) {
        const fieldsHierarchy = field?.split(">");

        const field_p = fieldsHierarchy[0];

        const subField = fieldsHierarchy[1];

        displayTabs =
          values?.[field_p] &&
          values?.[field_p]?.[member] &&
          values?.[field_p]?.[member]?.[subField];
      }
    }
  } else {
    displayTabs = [];
  }

  if (Object.keys(options)?.length) {
    displayTabs = displayTabs?.map(tab_key => ({
      label: options[tab_key],
      value: tab_key,
    }));
  } else {
    displayTabs = displayTabs?.map(tab_key => ({
      label: tab_key,
      value: tab_key,
    }));
  }

  return displayTabs;
};

export function getDaysInMonth(year, month) {
  return new Date(year, month, 0).getDate();
}
