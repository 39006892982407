import { Button } from "src/components/Common/Button/ContinueButton";
import { RedirectionDisableScreenOuter } from "./ProposalSections.styles";
import { useDispatch } from "react-redux";
import { setRetryKYCSection } from "./ProposalSections.slice";

const KYCVerificationFailedDisableScreen = () => {
  const dispatch = useDispatch();

  return (
    <RedirectionDisableScreenOuter>
      <main>
        <p className="desc">
          Sorry your KYC verification failed , We are unable to complete your
          purchase.
        </p>
        <Button
          onClick={() => dispatch(setRetryKYCSection(true))}
          className="retry_btn"
        >{`Retry`}</Button>
      </main>
    </RedirectionDisableScreenOuter>
  );
};
export default KYCVerificationFailedDisableScreen;
