import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";
import { useState, useEffect } from "react";
function NewDatePicker({
  value,
  placeholder,
  setValue = () => {},
  setError = () => {},
  maxDate,
  minDate,
  readOnly = false,
  dateFormat,
  error,
}) {
  const [format, setFormat] = useState("YYYY");

  const allowInput = () => {
    setFormat("DD-MM-YYYY");
  };

  useEffect(() => {
    if (dateFormat === "DD-MM-YYYY") {
      allowInput();
    }
    return () => {};
  }, [dateFormat]);
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DesktopDatePicker
        allowSameDateSelection
        value={value}
        views={["year", "month", "day"]}
        inputFormat={format}
        maxDate={maxDate}
        minDate={minDate}
        readOnly={readOnly}
        onChange={newValue => {
          if (format !== "DD-MM-YYYY") {
            allowInput();
          }
          setValue(newValue);
        }}
        onOpen={() => {
          allowInput();
          setValue(value);
        }}
        openTo="year"
        renderInput={params => {
          setError(params.error);
          const paramsObj = {
            ...params,
            inputProps: { ...params.inputProps, placeholder },
          };
          return (
            <TextField
              autoComplete="off"
              color="primary"
              fullWidth
              helperText={
                params.error && !error && "Please provide a valid date"
              }
              {...paramsObj}
            />
          );
        }}
      />
    </LocalizationProvider>
  );
}

export default NewDatePicker;
