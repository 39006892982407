import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { api, useLazyGetCartQuery } from "src/api/api";
import styled from "styled-components/macro";
import { setRevalidateKYCVerificationUtility } from "../../ProposalSections.slice";
import Modal from "./../../../../../components/Common/Popups/ReusableModal/Modal";

import Spinner from "src/components/Common/Spinner";
const CartRefetchLoopModal = ({ title = "" }) => {
  const { kycSection } = useSelector(state => state.proposalPage);
  const revalidateUtility =
    kycSection.redirectionUtility[0]?.revalidateUtility || {};
  const { revalidate_kyc_verification_until, showPopup } = revalidateUtility;

  const dispatch = useDispatch();
  const [triggerGetCart] = useLazyGetCartQuery();

  const popupVisibility = revalidate_kyc_verification_until && showPopup;

  useEffect(() => {
    if (popupVisibility) {
      const intervalId = setInterval(() => {
        triggerGetCart().then(res => {
          if (res?.error) return;
          if (
            res?.data?.data.every(
              cartEntry => cartEntry?.can_proceed_with_proposal,
            )
          ) {
            clearInterval(intervalId);
            dispatch(api.util.invalidateTags(["Cart"]));
            dispatch(
              setRevalidateKYCVerificationUtility({
                revalidate_kyc_verification_until: 0,
                showPopup: false,
              }),
            );
          }
        });
      }, 30 * 1000);
    }
    return () => {};
  }, [popupVisibility]);
  return (
    <Modal
      isModalOpen={popupVisibility}
      title={title}
      height="fit-content"
      hideCloseBtn={true}
    >
      <p>
        Please wait while we fetch the status of your KYC verification
        procedure.
      </p>
      <FlexDiv>
        <Spinner />
      </FlexDiv>
    </Modal>
  );
};
const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default CartRefetchLoopModal;
