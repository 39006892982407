import { lazy, Suspense, useEffect, useState } from "react";
import { RiSettingsFill } from "react-icons/ri";
import { Redirect, Route, Switch } from "react-router-dom";
import styled from "styled-components";
import { BaseComponent } from "./components/BaseComponent";
import ChangeBroker from "./components/ChangeBroker";
import OfflineErrorModal from "src/components/OfflineErrorModal";
import InputPage from "./pages/InputPage";

import { brokerAppPhase, brokerTenant } from "./utils/tenantSettings";

const ShortlistedPage = lazy(() => import("./pages/ShortlistedPage"));
const CustomPlanPage = lazy(() => import("./pages/CustomPlanPage"));

const RecommendPlansPage = lazy(() => import("./pages/Recommendation"));

const RecommendPlansResultsPage = lazy(() =>
  import(
    "./pages/Recommendation/RecommendationResult/RecommendationResultPage"
  ),
);

const ComparePage = lazy(() => import("./pages/ComparePage"));

const NotFoundPage = lazy(() =>
  import("./components/Common/NotFoundPage/NotFoundPage"),
);

const NewQuotesPage = lazy(() => import("./pages/NewQuotePage"));

const ProductDetailsPage = lazy(() => import("./pages/ProductDetails"));

import FullScreenLoader from "./components/Common/FullScreenLoader";
import ProposalPage from "./pages/ProposalPage/ProposalPage";

const ProposalSummaryPage = lazy(() =>
  import("./pages/ProposalSummary/ProposalSummary"),
);

const ThankYouPage = lazy(() => import("./pages/ThankYouPage/index"));

function App() {
  const [openChangeBroker, setOpenBrokerChange] = useState(false);
  useEffect(() => {
    if (brokerTenant("radical") && brokerAppPhase("UAT")) {
      document.addEventListener("contextmenu", e => e.preventDefault());
    }
    return () => {
      document.removeEventListener("contextmenu", e => e.preventDefault());
    };
  }, []);
  return (
    <>
      <Suspense fallback={<FullScreenLoader />}>
        <Switch>
          <Route path="/" component={() => <BaseComponent />} exact></Route>
          <Route exact path="/input/:currentForm" component={InputPage} />
          <Route exact path="/recommend/:groupCode/:page">
            {brokerTenant("fyntune") ? (
              <RecommendPlansPage />
            ) : (
              <Redirect to={"/"} />
            )}
          </Route>
          <Route exact path="/recommend-result/:groupCode">
            {brokerTenant("fyntune") ? (
              <RecommendPlansResultsPage />
            ) : (
              <Redirect to={"/"} />
            )}
          </Route>
          <Route path="/quotes/:groupCode">
            <NewQuotesPage />
          </Route>
          <Route exact path="/shortlisted/:groupCode">
            <ShortlistedPage />
          </Route>
          <Route exact path="/custom_plan/:groupCode">
            <CustomPlanPage />
          </Route>
          <Route exact path="/productdetails/:groupCode">
            <ProductDetailsPage />
          </Route>
          <Route exact path="/compare/:groupCode">
            <ComparePage />
          </Route>
          <Route exact path="/proposal">
            <ProposalPage />
          </Route>
          <Route exact path="/proposal_summary">
            <ProposalSummaryPage />
          </Route>
          <Route exact path="/thankyou/">
            <ThankYouPage />
          </Route>

          <Route path="*" exact={true} component={NotFoundPage} />
        </Switch>
        {openChangeBroker && (
          <ChangeBroker handleClose={() => setOpenBrokerChange(false)} />
        )}
        {process.env.NODE_ENV === "development" && (
          <BrokerSettingsStyled
            onClick={() => {
              setOpenBrokerChange(true);
            }}
          >
            <RiSettingsFill />
          </BrokerSettingsStyled>
        )}
      </Suspense>
      <OfflineErrorModal />
    </>
  );
}

const BrokerSettingsStyled = styled.div`
  position: fixed;
  padding: 8px 15px;
  background-color: white;
  box-shadow: 0 0 10px 2px #5555;
  color: #444;
  left: 0;
  bottom: 10px;
  border-radius: 0px 10px 10px 0px;
  font-size: 1.1rem;
  cursor: pointer;

  @media (max-width: 780px) {
    display: none;
  }
`;

export default App;
