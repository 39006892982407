import { VIEW } from "src/styles/responsiveStyle";
import {
  FLEX_COL,
  FLEX_ROW,
  FLEX_ROW_CENTER,
  PALETTE,
  ROUNDED,
  SHADES,
} from "src/styles/styleGuide";
import styled from "styled-components";

const AddMembersFormContainerStyled = styled.div`
  margin-top: 1rem;

  ${VIEW.md} {
    margin-top: 0;
    padding: 1rem;
  }

  .otherMembersBtn {
    padding: 0.7rem 1rem;
    width: 100%;
    ${FLEX_ROW_CENTER};
    gap: 0.6rem;
    background-color: ${SHADES.WHITE};
    border-radius: ${ROUNDED.md};
  }

  .membersForm_actions {
    ${FLEX_COL};
    gap: 1rem;
    margin-top: 1rem;
  }
`;

const MembersListStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1rem;
  ${props => !props.isProductDetailsRoute && "min-height: 200px;"}

  ${VIEW.md} {
    grid-template-columns: repeat(
      ${({ isModalList }) => (isModalList ? 2 : 1)},
      1fr
    );
  }
`;

const MemberTileStyled = styled.div`
  ${FLEX_ROW};
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${SHADES.LIGHT_GRAY};
  border-radius: ${ROUNDED.md};
  padding: 0.3rem 0.5rem;
  position: relative;

  ${({ isBlocked }) =>
    isBlocked &&
    `::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: ${ROUNDED.md};
    background-color: ${SHADES.WHITE};
    opacity: 0.5;
    cursor: not-allowed;
  }`}

  .memberTile_data {
    ${FLEX_ROW_CENTER};
    gap: 0.5rem;

    button {
      :disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }

  .memberTile_multiple {
    ${FLEX_ROW_CENTER};
    gap: 0.3rem;
    color: ${SHADES.GRAY};

    .multiple_button {
      cursor: pointer;
    }

    .multiple_count_member {
      user-select: none;
    }
  }

  .memberTile_action {
    border: 1px solid ${SHADES.LIGHT_GRAY};
    border-radius: ${ROUNDED.full};
    padding: 0.2rem 0.5rem;
    ${({ isError }) => isError() && `border: 1px solid ${PALETTE.error.light};`}
  }
`;

export { AddMembersFormContainerStyled, MemberTileStyled, MembersListStyled };
