import styled from "styled-components";
// CUSTOM HOOKS
import useShareModal from "src/components/ShareModule/hooks/useShareModal";
// DESIGN SYSTEM
import { MAX, VIEW } from "src/styles/responsiveStyle";
import { FLEX_ROW_CENTER } from "src/styles/styleGuide";
import FHTypography from "src/styles/Typography";
// CUSTOM COMPONENTS
import GoBackButton from "src/components/GoBackButton";
import ShareModule from "src/components/ShareModule";
// CONSTANTS
import { SHARE_MODULE_STAGES } from "src/components/ShareModule/shareModule.constant";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { useTheme } from "src/customHooks";

const UpperUtility = () => {
  const { handleOpenShareModule } = useShareModal();
  const { isContinueBtnClick } = useSelector(state => state.schema);
  const { colors } = useTheme();
  return (
    <Section>
      <GoBackButton />
      <InnerBlock>
        <FHTypography variant="h6" weight="semi" colorType="subHeading">
          You are just a few steps away from securing your future
        </FHTypography>
        <ShareModule
          innerText="Share"
          isDropDown={false}
          handleShareClick={() => {
            if (isContinueBtnClick) {
              toast("Please wait, your details are being submitted", {
                position: "top-center",
                id: "toastWarning",
                duration: 4000,
                style: {
                  background: colors.primary_color,
                  color: "#fefefe",
                },
              });
              return;
            }
            handleOpenShareModule({
              stage: SHARE_MODULE_STAGES.proposal,
              step: 2,
              isOpen: true,
              headingIdentifier: "proposal",
            });
          }}
        />
      </InnerBlock>
    </Section>
  );
};

const Section = styled.section`
  display: none;

  ${MAX.lg} {
    margin-top: 1rem;
  }

  ${VIEW.md} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
  }
`;

const InnerBlock = styled.div`
  ${FLEX_ROW_CENTER}
  gap: 0.75rem;
`;

export default UpperUtility;
