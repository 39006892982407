/*
Title:- componenets/PopularCity.jsx

Description: Renders thE most popular cities in the UI

Components Used:- None

Used in:- LoctionForm/index.jsx

*/

import { memo } from "react";
import { useTheme } from "src/customHooks";
import {
  PopularCityInputStyled,
  PopularCityLabelStyled,
} from "../LocationForm/locationForm.styled";
import FHTypography from "src/styles/Typography";

function PopularCity({
  city,
  onChange,
  selectedCity,
  checked = false,
  disabled = false,
}) {
  const { name } = city;

  const { colors } = useTheme();

  const handleChange = evt => {
    const { checked } = evt.target;
    if (!checked) return;
    onChange && onChange(city);
  };
  return (
    <div>
      <PopularCityInputStyled
        id={name}
        name={name}
        className="visually-hidden"
        type="radio"
        checked={checked}
        onChange={handleChange}
        selectedCity={selectedCity}
        colors={colors}
        disabled={disabled}
      />
      <PopularCityLabelStyled htmlFor={name} colors={colors}>
        <FHTypography variant="subheading1" weight="md" colorType="subHeading">
          {name}
        </FHTypography>
      </PopularCityLabelStyled>
    </div>
  );
}

export default memo(PopularCity);
