import React, { useState, Fragment } from "react";
import styled from "styled-components/macro";
import { useTheme } from "src/customHooks";
import {} from "../ProposalSections/ProposalSections.slice";
import { FLEX_COL, FLEX_ROW } from "src/styles/styleGuide";
import { VIEW } from "src/styles/responsiveStyle";
const RadioButtons = ({ label, customOptions, value, onChange }) => {
  const [boolean, setBoolean] = useState(!value ? customOptions[0] : value);

  const { colors } = useTheme();

  return (
    <>
      <RadioContainerStyled className="no-padding-mobile">
        <div className="box_shadow_box_card_medical">
          <RadioRowStyled>
            <div>
              <Question className="radio_question p_propsal_form_r_q_m toggle_question">
                {label}
              </Question>
            </div>
            <div className="middle no-padding mobile-left">
              {customOptions &&
                customOptions?.map(data => (
                  <label key={data}>
                    <input
                      type="radio"
                      name={`is${data}`}
                      onChange={e => {
                        onChange(e);
                        setBoolean(e.target.value);
                      }}
                      value={data}
                      checked={boolean === data}
                    />
                    <div
                      className="front-end box capitalize-mobile"
                      css={`
                        width: ${customOptions?.[0]?.length > 4 &&
                        "144px !important"};
                        line-height: 42px !important;
                        height: 34px !important;
                        background-color: ${boolean === data
                          ? colors.primary_color
                          : colors.primary_shade} !important;
                      `}
                    >
                      <span>{data}</span>
                    </div>
                  </label>
                ))}
            </div>
          </RadioRowStyled>
        </div>
      </RadioContainerStyled>
    </>
  );
};

export default RadioButtons;

const RadioContainerStyled = styled.div`
  padding: 0 15px;
`;

const RadioRowStyled = styled.div`
  ${FLEX_COL} {
    align-items: self-start;
  }

  ${VIEW.md} {
    ${FLEX_ROW} {
      justify-content: space-between;
    }
  }
  .radio_questions {
    margin-bottom: 2.5rem;
  }
`;

const Question = styled.p`
  &:after {
    content: "";
    height: 22px;
    width: 6px;
    position: absolute;
    left: -10px;
    top: 2px;
    background-color: #fecc28;
    border-radius: 50px;
    @media (max-width: 767px) {
      height: calc(100% - 6px);
    }
  }
  @media (max-width: 767px) {
    font-size: 14px !important;
  }
`;
