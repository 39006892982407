/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { whySplitGroupsGif } from "src/assets/gifs";
import { useTheme } from "src/customHooks";
import FHTypography from "src/styles/Typography";
import { DEVICES, VIEW } from "src/styles/responsiveStyle";
import {
  FLEX_COL,
  FLEX_COL_CENTER,
  FLEX_ROW,
  FLEX_ROW_CENTER,
  FONT,
  FWEIGHT,
  ROUNDED,
  SHADES,
  SHADOW,
} from "src/styles/styleGuide";
import styled from "styled-components";
import CustomToolTip from "../../CustomTooltip";

function InfoWithImagePopup({ children }) {
  const { colors } = useTheme();

  // STATE/s

  const [groupSplitChecked, setGroupSplitChecked] = useState(
    sessionStorage.getItem("groupSplitInfoIconClicked"),
  );
  const [isModalOpen, setModalOpen] = useState(false);

  // FUNCTION/s
  function handleGroupSpitInfoAction() {
    setModalOpen(true);
  }

  function handleModalClose() {
    setModalOpen(false);
    sessionStorage.setItem("groupSplitInfoIconClicked", true);
    setGroupSplitChecked(true);
  }

  return (
    <div>
      <CustomToolTip description="Why separate groups?" showInfoIcon={false}>
        <QuestionIconStyled
          onClick={handleGroupSpitInfoAction}
          colors={colors}
          groupSplitChecked={groupSplitChecked}
        >
          {children}
        </QuestionIconStyled>
      </CustomToolTip>

      {isModalOpen && (
        <InfoWithImagePopupStyled colors={colors}>
          <div className="popupContainer">
            <section className="imageSec">
              <img src={whySplitGroupsGif} alt="whySliptGroup" loading="lazy" />
            </section>
            <section className="contentSec">
              <FHTypography variant="h4" weight="semi">
                Buying separate plans for family members will reduce your total
                premium.
              </FHTypography>
              <FHTypography variant="h6" weight="base">
                Premium is calculated basis on the eldest member, which will be
                less for the plan bought separately from the policy for
                parents/parents In law&#39;s.
              </FHTypography>

              <button
                onClick={handleModalClose}
                className={`popupButton ${FONT.subheading1}`}
              >
                OK
              </button>
            </section>
          </div>
        </InfoWithImagePopupStyled>
      )}
    </div>
  );
}

const InfoWithImagePopupStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  ${FLEX_ROW_CENTER};
  background-color: ${SHADES.DIM_GRAY + "55"};
  z-index: 1100;
  padding: 2rem;

  .popupContainer {
    max-width: ${DEVICES.tabletBIG}px;
    width: 100%;
    background-color: #fff;
    ${FLEX_COL};

    box-shadow: ${SHADOW.xl};
    border-radius: ${ROUNDED.xl};
    overflow: hidden;

    .imageSec {
      min-width: 40%;
      background-color: ${SHADES.LIGHT_GRAY};
      ${FLEX_ROW_CENTER};
      img {
        mix-blend-mode: multiply;
        height: 200px;
        object-fit: contain;
      }
    }

    .contentSec {
      padding: 2rem 1rem;
      flex: 1;
      ${FLEX_COL_CENTER};
      gap: 1rem;
      text-align: left;
    }

    .popupButton {
      padding: 0.5rem 3rem;
      background-color: ${({ colors }) => colors.primary_color};
      color: ${SHADES.WHITE};
      font-weight: ${FWEIGHT.md};
      box-shadow: ${SHADOW.base};
      border-radius: ${ROUNDED.md};
      align-self: flex-end;
      margin-top: 2rem;
      transition: all 0.2s ease-in-out;

      :hover {
        filter: brightness(0.9);
      }
    }
  }
  ${VIEW.md} {
    padding: 1rem;
    .popupContainer {
      ${FLEX_ROW};
      .imageSec {
        img {
          height: 300px;
        }
      }
    }
  }
`;

export const QuestionIconStyled = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  position: relative;
  width: 20px;
  height: 20px;
  color: ${({ colors }) => colors.primary_color};
  border-radius: ${ROUNDED.full};
  ${VIEW.md} {
    width: 36px;
    height: 36px;
  }
  margin-left: ${props => (props.kycSection ? "10px" : "0")};
  ::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid ${({ colors }) => colors.primary_color};
    border-radius: ${ROUNDED.full};
    ${({ groupSplitChecked }) => {
      return (
        !groupSplitChecked && "animation: scaleFade 1.2s ease-in-out infinite;"
      );
    }}
    ${({ kycSection }) => {
      return (
        kycSection &&
        `
        animation: scaleFade 1.5s ease-in-out 3;
        animation-fill-mode: forwards;`
      );
    }}
  }
  @keyframes scaleFade {
    from {
      opacity: 1;
      transform: scale(1);
    }
    to {
      opacity: 0;
      transform: scale(1.4);
    }
  }
`;

export default InfoWithImagePopup;
