import React from "react";
import { MAX } from "src/styles/responsiveStyle";
import { ROUNDED, SHADES, SHADOW } from "src/styles/styleGuide";
import styled from "styled-components";

const FormContainer = ({
  containsCart = false,
  isProposalSummaryRoute = false,
  isTenureCard = false,
  children,
}) => {
  const cartStyles = containsCart
    ? `position: ${isProposalSummaryRoute ? "relative" : "sticky"}; top: ${
        isProposalSummaryRoute ? 0 : "6rem"
      }; width: 100%; padding: 10px 20px !important; overflow: hidden;`
    : "";

  const summaryStyles = isProposalSummaryRoute
    ? `
  margin-bottom: 20px;
  @media screen and (max-width: 768px) {
    margin-bottom: 10px;
    padding: 5px 2px;
  }
`
    : "";

  const tenureCardStyles = isTenureCard
    ? `
    margin-top: 1rem;
    width: 100%;
    padding: 10px 20px;
    overflow: hidden;

    @media screen and (max-width: 768px) {
      margin-top: 10px;
    }
`
    : "";

  return (
    <FormContainerStyled
      tenureCardStyles={tenureCardStyles}
      summaryStyles={summaryStyles}
      cartStyles={cartStyles}
    >
      {children}
    </FormContainerStyled>
  );
};

export default FormContainer;

const FormContainerStyled = styled.div`
  height: auto;
  width: 100%;
  padding: 10px;
  color: #000;
  background: #fff;
  margin: auto;
  border-radius: ${ROUNDED.xl};
  border: 1px solid ${SHADES.LIGHT_GRAY};
  box-shadow: ${SHADOW.base};

  ${MAX.lg} {
    border: none;
  }

  ${props => props.summaryStyles}
  ${props => props.tenureCardStyles}
  ${props => props.cartStyles}
`;
