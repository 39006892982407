import { FWEIGHT, ROUNDED } from "src/styles/styleGuide";
import styled from "styled-components/macro";

const BackBtn = ({ onClick, hide }) => {
  return hide ? (
    <span />
  ) : (
    <BackButtonStyled onClick={onClick}>Back</BackButtonStyled>
  );
};

export default BackBtn;

const BackButtonStyled = styled.button`
  width: 200px;
  border: 1px solid #bcbcbc !important;
  letter-spacing: 1px;
  font-size: 14px;
  font-weight: ${FWEIGHT.semi};
  border-radius: ${ROUNDED.base};
  padding: 12px 16px;
  cursor: pointer;
  text-align: center;

  &:hover {
    filter: brightness(0.9);
  }
  &:disabled {
    cursor: default;
    opacity: 0.8;
  }
`;
