import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useRider, useTheme } from "src/customHooks";
import FHTypography from "src/styles/Typography";
import { FLEX_ROW, ROUNDED } from "src/styles/styleGuide";
import styled from "styled-components";
import { CollapseCartSection, RiderDetails } from ".";
import { setRiderActionBtnClicked } from "src/pages/ProductDetails/productDetails.slice";
import { matchCurrentRoute } from "src/utils/productDetailsPageUtils";

function RidersList({ groupCode: gp, showFromSelectedList = false }) {
  const { colors } = useTheme();
  const tempGp = +useParams()?.groupCode;

  const groupCode = +(gp || tempGp);
  const dispatch = useDispatch();
  const { riders } = useSelector(state => state.productPage);
  const { ridersLoading } = useSelector(({ productPage }) => productPage);

  const { getSelectedRiders } = useRider(groupCode);
  const cartRiders = getSelectedRiders();

  const isOnPpOrLaterRoute = matchCurrentRoute({
    matcher: "gte",
    route: "proposal",
  });

  const ridersLength = showFromSelectedList
    ? cartRiders?.length
    : riders?.length;

  const scrollToRiderSection = () => {
    const riderSection = document.querySelector("#additional-riders");
    window.scrollTo({
      top: riderSection.offsetTop - (window.innerWidth > 768 ? 88 : 72),
      left: 0,
      behavior: "smooth",
    });
    dispatch(setRiderActionBtnClicked(true));
  };

  if (ridersLoading && !isOnPpOrLaterRoute)
    return (
      <CollapseCartSection
        initialState={true}
        title="Riders"
        contentLoading={ridersLoading && !isOnPpOrLaterRoute}
      />
    );

  if (ridersLength === 0) return <></>;

  return (
    <CollapseCartSection
      title="Riders"
      length={cartRiders?.length}
      contentLoading={ridersLoading && !isOnPpOrLaterRoute}
    >
      {cartRiders?.length === 0 && !isOnPpOrLaterRoute ? (
        <NoRiderSelectedWrapperStyled>
          <FHTypography
            variant="subheading2"
            weight="md"
            colorType="subHeading"
          >
            No Riders Selected
          </FHTypography>
          <button>
            <FHTypography
              variant="subheading2"
              weight="semi"
              color={colors.primary_color}
              onClick={scrollToRiderSection}
            >
              View Riders
            </FHTypography>
          </button>
        </NoRiderSelectedWrapperStyled>
      ) : (
        cartRiders?.map(rider => <RiderDetails rider={rider} key={rider?.id} />)
      )}
    </CollapseCartSection>
  );
}

export default RidersList;

const NoRiderSelectedWrapperStyled = styled.div`
  ${FLEX_ROW}
  justify-content: space-between;
  border: 1px dashed grey;
  border-radius: ${ROUNDED.base};
  padding: 0.75rem 0.5rem;
`;
