import { memo } from "react";
import styled from "styled-components";

const ProposalNotFound = () => {
  return (
    <MessageWrapper>
      <p>Proposal Page not found against this IC</p>
    </MessageWrapper>
  );
};


const MessageWrapper = styled.div`
  text-align: center ;
`

export default memo(ProposalNotFound);
