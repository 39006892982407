import { components } from "../components/componentSchema";
import FormBuilder from "src/components/FormBuilder/FormBuilder";
import { Form } from "./../ProposalPage.style";
import BackBtn from "../components/Buttons/BackBtn";
import ContinueBtn from "../components/Buttons/ContinueBtn";
import "styled-components/macro";
import { useCart } from "src/customHooks";
import RevisedPremiumPopup from "../../ProductDetails/components/ReviewCart/components/RevisedPremiumPopup";
import useFormNavigation from "./useFormNavigation";
import RedirectionDisabledScreen from "./RedirectionDisabledScreen";
import { useDispatch, useSelector } from "react-redux";
import {
  clearKYCSection,
  setKYCCanContinue,
  setShowErrorPopup,
  setShowRedoKYCPopup,
} from "./ProposalSections.slice";
import KYCVerificationFailedDisableScreen from "./KYCVerificationFailedDisableScreen";
import useKYCDetails from "./useKYCDetails";
import { api } from "src/api/api";
import useProposalSections from "./useProposalSections";
import { useEffect } from "react";
import { setIsContinueBtn } from "../schema.slice";

const KYCDetails = ({
  formSchema = [],
  name = "",
  setActive = () => {},
  setActivateLoader = () => {},
  setBlockTabSwitch = () => {},
  listOfForms = [],
  rawSchema = [],
  isContinueClicked,
  setIsContinueClicked,
}) => {
  const {
    values,
    setValues,
    setValid,
    submit,
    setSubmit,
    triggerSaveForm,
    revisedPremiumPopupUtilityObject,
    setErrorInField,
    setErrors,
  } = useProposalSections({
    setActive,
    name,
    setActivateLoader,
    setBlockTabSwitch,
    listOfForms,
    schema: rawSchema,
    activeFormSchema: formSchema,
  });

  const { navigateTo } = useFormNavigation();

  const dispatch = useDispatch();
  const { cartData } = useCart();
  const { kycVerified, redirectionUtility, canContinue, docUploadUtility } =
    useKYCDetails();

  const { retryKYCSection, showRedoKYCPopup } = useSelector(
    state => state.proposalPage,
  );

  const continueClickHandler = () => {
    dispatch(setIsContinueBtn(true));
    setSubmit(true);
    triggerSaveForm({
      sendedVal: values,
      formName: "KYC Details",
    });
  };
  const backHandler = () => {
    navigateTo({ back: true });
  };

  useEffect(() => {
    if (isContinueClicked) {
      //? To handle continue click on Proposal page Mobile
      continueClickHandler();
      setIsContinueClicked("");
    }
    return () => {};
  }, [isContinueClicked]);

  useEffect(() => {
    if (showRedoKYCPopup) {
      dispatch(
        setShowErrorPopup({
          show: true,
          head: "Please Note",
          msg: "Please verify your KYC again.",
        }),
      );
      dispatch(setShowRedoKYCPopup(false));
    }
    return () => {};
  }, [showRedoKYCPopup]);
  //? To initially clear all the previous saved kyc data in redux.
  useEffect(() => {
    if (!redirectionUtility.every(obj => !!obj?.redirection_url)) {
      dispatch(clearKYCSection());
    }
    return () => {};
  }, []);

  return (
    <>
      <div>
        {redirectionUtility?.redirectionURL ? (
          <RedirectionDisabledScreen
            url={redirectionUtility?.redirectionURL}
            newTab={redirectionUtility?.newTab}
            cartData={cartData?.data}
          />
        ) : docUploadUtility?.showDocFields === true ? (
          /* FOR SHOWING DOCUMENT UPLOAD FIELDS  */
          <RedirectionDisabledScreen
            docUpload={docUploadUtility?.showDocFields}
          />
        ) : kycVerified === true ? (
          <RedirectionDisabledScreen
            verified={kycVerified}
            cartData={cartData?.data}
          />
        ) : redirectionUtility?.some(obj => !!obj?.redirection_url) ? (
          <RedirectionDisabledScreen
            redirectionUtility={redirectionUtility}
            cartData={cartData?.data}
          />
        ) : kycVerified === false && !retryKYCSection ? (
          <KYCVerificationFailedDisableScreen />
        ) : (
          <>
            <Form>
              <FormBuilder
                schema={formSchema}
                formName={name}
                components={components}
                fetchValues={setValues}
                fetchErrors={setErrors}
                fetchValid={setValid}
                submitTrigger={submit}
                setSubmit={setSubmit}
                options={{ defaultValues: values, validateOn: "change" }}
                setErrorInField={setErrorInField}
                isPanelVisible={true}
                onAsyncSuccess={() => {
                  dispatch(setKYCCanContinue(true));
                  dispatch(api.util.invalidateTags(["Cart"]));
                }}
                onAsyncFailure={() => dispatch(setKYCCanContinue(false))}
                onBeforeChange={() => dispatch(setKYCCanContinue(true))}
              />
            </Form>
            <div className="proposal_continue_back_margin container">
              <BackBtn onClick={backHandler} />
              <ContinueBtn
                onClick={continueClickHandler}
                disabled={!canContinue}
              />
            </div>
          </>
        )}
      </div>
      {revisedPremiumPopupUtilityObject.isOnProposal ? (
        <RevisedPremiumPopup
          revisedPremiumPopupUtilityObject={revisedPremiumPopupUtilityObject}
          onClose={revisedPremiumPopupUtilityObject.off}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default KYCDetails;
