import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useFrontendBoot } from "src/customHooks/useGetCommonQueries";
import { performValidations } from "./formUtils";
import useFormFieldVisibility from "./useFormFieldVisibility";

const useFormBuilder = ({
  schema,
  fetchValues,
  defaultValues = {},
  noForAll,
  setNoForAll,
  formName,
  setErrorInField,
  fetchErrors,
  fetchValid,
  isPanelVisible,
  keyStr,
}) => {
  const [blockScrollEffect, setBlockScrollEffect] = useState(true);
  const [values, setValues] = useState(defaultValues || {});
  const [errors, setErrors] = useState({});
  const [isValid, setIsValid] = useState();
  const [updateOnlyForMedical, setUpdateOnlyForMedical] = useState(0);

  const { proposalData } = useSelector(state => state.proposalPage);

  const proposerDetails = proposalData?.["Proposer Details"];
  const insuredDetails = proposalData?.["Insured Details"];

  const { subJourneyType } = useFrontendBoot();
  const { renderField } = useFormFieldVisibility();

  useEffect(() => {
    if (formName === "Medical Details" && updateOnlyForMedical !== 0) {
      let count = 0;
      let maxIndex = 0;
      Object.keys(values).forEach(elem => {
        if (values?.[elem]?.["is" + elem] === "N") {
          count++;
        }
        maxIndex++;
      });
      if (count === maxIndex) {
        setNoForAll(true);
      }
    }
    return () => {};
  }, [updateOnlyForMedical]);

  const updateValue = (name, value, removeOtherValues) => {
    if (formName === "Medical Details" && !value) {
      return;
    }

    if (formName === "Medical Details") {
      setUpdateOnlyForMedical(updateOnlyForMedical + 1);
    }

    if (removeOtherValues) {
      setValues({ [name]: value });
      fetchValues(() => {
        return { [name]: value };
      });
    } else {
      setValues(prev => ({ ...prev, [name]: value }));
      fetchValues(prev => {
        return { ...prev, [name]: value };
      });
    }

    if (value instanceof Object) {
      if (value?.[`is${name}`] && value?.[`is${name}`] === "Y" && noForAll) {
        setNoForAll(0);
      }
    }
  };

  const updateValidateObjSchema = item => {
    return item.visibleOn &&
      renderField({ item, currentFormValues: values, proposalData })
      ? { ...item.validate, required: true }
      : item.validate;
  };

  const disableToggleDecider = ({
    values = {},
    current_item = {},
    special_conditions = {},
  }) => {
    let disable = false;

    if (special_conditions?.disable_on_yes) {
      const { disable_on_yes } = special_conditions;

      const currentItemName = current_item?.name;

      const willDisableCurrentItemsOnYesArray = disable_on_yes[currentItemName];

      const anyWillDisableCurrentItemsOnYesIsTrue =
        willDisableCurrentItemsOnYesArray.some(
          disable_key => values[disable_key]?.[`is${disable_key}`] === "Y",
        );

      disable = anyWillDisableCurrentItemsOnYesIsTrue;
    }

    return disable;
  };

  //TODO: Below two functions must be combined to make one function -------------------------------

  const readOnlyFieldsDetector = ({
    relationName,
    formName,
    fieldName,
    values = proposerDetails,
    options: current_field_options,
  }) => {
    let isReadOnly = false;

    //? DISABLING THE OCCUPATION FIELD FOR SELF IN INSURED DETAILS.
    //* IF OCCUPATION IS ALREADY SELECTED IN PROPOSER DETAILS.
    if (
      formName === "Insured Details" &&
      relationName === "self" &&
      fieldName === "occupation" &&
      values?.["occupation"] &&
      Object.keys(current_field_options).includes(values?.["occupation"])
    ) {
      isReadOnly = true;
    }

    return isReadOnly;
  };

  const checkReadOnly = (name, formName, additionalOptions = {}) => {
    if (formName === "Other Details") {
      let nomineeRelation = values.nominee_relation;
      let dataToCheck = {};
      if (insuredDetails) {
        if (insuredDetails[nomineeRelation] && nomineeRelation === "self") {
          dataToCheck = {
            ...proposerDetails,
            ...insuredDetails["self"],
          };
        } else if (insuredDetails[nomineeRelation]) {
          dataToCheck = insuredDetails[nomineeRelation];
        }
      }
      let nameWithoutNominee =
        name.slice(name.indexOf("_") + 1, name.length) === "contact"
          ? "mobile"
          : name.slice(name.indexOf("_") + 1, name.length);
      if (nameWithoutNominee.includes("address"))
        nameWithoutNominee = Object.keys(dataToCheck).find(key =>
          key.includes(nameWithoutNominee),
        );
      if (name.includes("pincode"))
        nameWithoutNominee = Object.keys(dataToCheck).find(key =>
          key.includes("pincode"),
        );
      if (nameWithoutNominee?.includes("occupation")) {
        if (dataToCheck[nameWithoutNominee]) {
          //* The occupation selected in insured details must lies in the options provided by nominee_occupation
          return !!additionalOptions?.options?.[
            dataToCheck[nameWithoutNominee]
          ];
        } else {
          return false;
        }
      }
      return dataToCheck[nameWithoutNominee] ? true : false;
    }
    return false;
  };

  //TODO: ---------------------------------------------------------------------------------------

  const updateValues = (multipleValues = {}, action) => {
    if (action === "SAVE_AS_IT_IS") {
      setValues(multipleValues);
      fetchValues(() => {
        return multipleValues;
      });
    } else {
      setValues(prev => ({ ...prev, ...multipleValues }));
      fetchValues(prev => {
        return { ...prev, ...multipleValues };
      });
    }
  };

  const insertValue = (parent, member, name, value) => {
    setValues({
      ...values,
      [parent]: {
        ...values[parent],
        [member]: {
          ...(values[parent]?.[member] ? values[parent]?.[member] : {}),
          [name]: value,
        },
      },
    });
    fetchValues(() => {
      return {
        ...values,
        [parent]: {
          ...values[parent],
          [member]: {
            ...(values[parent]?.[member] ? values[parent]?.[member] : {}),
            [name]: value,
          },
        },
      };
    });
  };
  const collectRefs = useRef({});

  useEffect(() => {
    if (
      defaultValues &&
      Object.keys(defaultValues).length &&
      Object.keys(values).length !== Object.keys(defaultValues).length
    ) {
      setValues(prev => ({ ...prev, ...defaultValues }));
    }
    return () => {};
  }, [defaultValues]);

  const triggerValidation = name => {
    let errorsTemp = {};
    let tempIsValid = true;
    if (typeof name === "object") {
      const { parent, member, variableName } = name;
      let findGroup = schema.findIndex(el => el.name === parent);
      let [filteredItem] = Array.isArray(schema[findGroup + 1])
        ? schema[findGroup + 1]?.filter(item => item.name === variableName)
        : [];

      if (filteredItem) {
        name =
          typeof name === "object"
            ? {
                ...name,

                dob:
                  insuredDetails && insuredDetails[name.member]
                    ? insuredDetails[name.member]?.dob
                    : "",
              }
            : name;
        let errorMsg =
          filteredItem.validate &&
          performValidations({
            validate: filteredItem.validate,
            values,
            name,
            subJourneyType,
            additionalOptions: filteredItem.additionalOptions,
            proposalData,
            member,
          });

        if (
          renderField({
            item: filteredItem,
            currentFormValues: values,
            member,
            proposalData,
          })
        ) {
          errorsTemp[parent + member + variableName] = errorMsg;
          if (errorMsg) tempIsValid = false;
        }
      }
    } else if (name) {
      let [filteredItem] = schema.filter(item => item.name === name);

      if (filteredItem) {
        let errorMsg;

        errorMsg =
          filteredItem.validate &&
          performValidations({
            validate: filteredItem.validate,
            values,
            name,
            subJourneyType,
            additionalOptions: filteredItem.additionalOptions,
            proposalData,
          });

        if (
          renderField({
            item: filteredItem,
            currentFormValues: values,
            proposalData,
          })
        ) {
          errorsTemp[filteredItem.name] = errorMsg;

          if (errorMsg) tempIsValid = false;
        }
      }
    } else {
      schema.forEach(item => {
        if (item instanceof Array) {
          item[0].additionalOptions.members.forEach(member => {
            item.forEach(innerItem => {
              let errorMsg;

              if (innerItem?.validate) {
                if (!innerItem.parent?.includes(".")) {
                  errorMsg =
                    innerItem?.validate &&
                    values?.[innerItem.parent] &&
                    values?.[innerItem.parent]?.members?.[member] &&
                    performValidations({
                      validate: innerItem.validate,
                      values,
                      name: {
                        variableName: innerItem.name,
                        parent: innerItem.parent,
                        member,
                      },
                      subJourneyType,
                      additionalOptions: innerItem.additionalOptions,
                      proposalData,
                      member,
                    });

                  if (
                    renderField({
                      item: innerItem,
                      currentFormValues: values,
                      member,
                      proposalData,
                    })
                  ) {
                    errorsTemp[innerItem.parent + member + innerItem.name] =
                      errorMsg;
                    if (errorMsg) tempIsValid = false;
                  }
                }

                if (innerItem.parent?.includes(".")) {
                  const parentsHierarchy = innerItem.parent.split(".");

                  if (values?.[parentsHierarchy[0]]?.[member]) {
                    if (
                      values?.[parentsHierarchy[0]]?.[member]?.[
                        parentsHierarchy[1]
                      ]
                    ) {
                      errorMsg = performValidations({
                        validate: innerItem.validate,
                        values,
                        name: {
                          variableName: innerItem.name,
                          parent: innerItem?.parent?.split(".")[0],
                          member,
                        },
                        subJourneyType,
                        additionalOptions: innerItem.additionalOptions,
                        proposalData,
                        member,
                      });
                    }
                  }

                  if (
                    renderField({
                      item: innerItem,
                      currentFormValues: values,
                      member,
                      proposalData,
                    })
                  ) {
                    errorsTemp[
                      `${innerItem.parent}-${member}${innerItem.name}`
                    ] = errorMsg;
                    if (errorMsg) tempIsValid = false;
                  }
                }
              }
            });
          });
        } else {
          let errorMsg =
            item?.validate &&
            performValidations({
              validate: item.validate,
              values,
              name: item.name,
              subJourneyType,
              additionalOptions: item.additionalOptions,
              proposalData,
            });

          if (item?.visibleOn && item?.type !== "title") {
            if (
              values[Object.keys(item.visibleOn)[0]] ===
              item.visibleOn[Object.keys(item.visibleOn)[0]]
            )
              errorMsg = performValidations({
                validate: item.validate,
                values,
                name: item.name,
                subJourneyType,
                proposalData,
              });
          }

          if (renderField({ item, currentFormValues: values, proposalData })) {
            errorsTemp[item.name] = errorMsg;

            if (errorMsg) tempIsValid = false;
          }
        }
        setIsValid(tempIsValid);
        fetchValid(tempIsValid);
      });
    }

    setErrors({ ...errors, ...errorsTemp });
    fetchErrors({ ...errors, ...errorsTemp });
  };

  const clearField = name => {
    setValues({ ...values, [name]: null });
  };

  useEffect(() => {
    //? Marking all medical question as no when "no for all" checkbox checked.
    if (noForAll && formName === "Medical Details") {
      let tempGroupVal = {};
      schema.forEach(el => {
        if (!Array.isArray(el)) {
          if (el?.additionalOptions?.default_select === "Y") {
            let memberSelectionObject = {};

            el?.additionalOptions?.members?.forEach(member => {
              memberSelectionObject[member] = true;
            });

            tempGroupVal[el?.name] = {
              [`is${el?.name}`]: "Y",
              members: memberSelectionObject,
              isValid: true,
            };
          } else if (el?.additionalOptions?.notAllowedIf === "N") {
            tempGroupVal[el?.name] = {
              [`is${el?.name}`]: "",
              members: {},
              isValid: false,
            };
          } else if (!el?.additionalOptions?.disable_Toggle) {
            tempGroupVal[el?.name] = {
              [`is${el?.name}`]: "N",
              members: {},
              isValid: true,
            };
          }
        }
      });
      if (Object.keys(tempGroupVal).length) {
        updateValues({ ...values, ...tempGroupVal }, "SAVE_AS_IT_IS");
      }
    }
    //? Marking all medical question as initial when "no for all" checkbox un-checked.
    if (noForAll === false && formName === "Medical Details") {
      let tempGroupVal = {};
      schema.forEach(el => {
        if (!Array.isArray(el)) {
          tempGroupVal[el?.name] = {
            [`is${el?.name}`]: "",
            members: {},
            isValid: false,
          };
        }
      });
      if (Object.keys(tempGroupVal).length) {
        updateValues({ ...values, ...tempGroupVal }, "SAVE_AS_IT_IS");
      }
    }

    return () => {};
  }, [noForAll]);

  const scrollToErrors = () => {
    if (isPanelVisible) {
      if (
        Object.values(errors).length &&
        Object.values(errors).some(val => val)
      )
        setErrorInField(true);
      else setErrorInField(false);

      if (blockScrollEffect) {
        let filteredKey = Object.keys(errors).filter(key => errors[key]);

        if (filteredKey.length) {
          let scrollPositions = filteredKey.map(key => {
            let element = document.getElementById(
              formName === "Medical Details" ? key : key + keyStr,
            );
            if (element) {
              let y =
                element.getBoundingClientRect().top - 100 + window.scrollY;
              return y;
            }
          });
          window.scroll({
            top: Math.min(...scrollPositions),
            behavior: "smooth",
          });
        }
      }
    }
  };

  return {
    values,
    updateValue,
    clearField,
    errors,
    triggerValidation,
    collectRefs,
    isValid,
    setValues,
    insertValue,
    setErrors,
    updateValues,
    checkReadOnly,
    updateValidateObjSchema,
    setBlockScrollEffect,
    scrollToErrors,
    readOnlyFieldsDetector,
    disableToggleDecider,
  };
};
export default useFormBuilder;
