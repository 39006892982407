import { useState } from "react";
import { FaTimes } from "react-icons/fa";
import styled from "styled-components/macro";
import { Button } from "src/components/Common/Button/ContinueButton";
import { useTheme } from "src/customHooks";
import { regexStringToRegex } from "src/utils/helper";

const MultiSelectText = ({
  name,
  label,
  placeholder,
  onChange,
  checkValidation = {},
  value,
  limit,
  maxLength,
  minLength,
  validate,
  error,
}) => {
  const [input, setInput] = useState("");

  const defaultSelectedValues = value || [];

  const disableSave = input.length < minLength || input.length > maxLength;

  const disableInput = defaultSelectedValues?.length === limit;

  const inputChangeHandler = e => {
    if (validate?.regex) {
      const providedRegex = regexStringToRegex(validate?.regex);

      providedRegex.test(e.target.value) && setInput(e.target.value);
    } else {
      setInput(e.target.value);
    }
  };

  const saveClickHandler = () => {
    onChange(
      {
        target: {
          value: [...defaultSelectedValues, input],
        },
      },
      [...defaultSelectedValues, input],
    );

    setInput("");
  };

  const removeClickHandler = remove_value => {
    const updatedArrayAfterRemoval = value.filter(
      item => item !== remove_value,
    );

    onChange(
      {
        target: {
          value: [...updatedArrayAfterRemoval],
        },
      },
      [...updatedArrayAfterRemoval],
    );
  };

  return (
    <InputContainer>
      <div className="input_flex">
        <Input
          id={name}
          placeholder={placeholder}
          onChange={inputChangeHandler}
          value={input}
          disabled={disableInput}
        />
        <Button
          className="save_btn"
          disabled={disableSave}
          onClick={saveClickHandler}
        >
          Save
        </Button>
      </div>

      <Label mandatory={checkValidation?.required === true}>
        {label || ""}
      </Label>

      {error && <p className="formbuilder__error">{error}</p>}

      <SelectedDisplay
        selectedValues={defaultSelectedValues}
        removeClickHandler={removeClickHandler}
      />
    </InputContainer>
  );
};

const SelectedDisplay = ({
  selectedValues = [],
  removeClickHandler = () => {},
}) => {
  const {
    colors: { primary_shade, primary_color },
  } = useTheme();

  return selectedValues.length ? (
    <SelectContinerStyled>
      {selectedValues.map(value => (
        <article key={value}>
          <span
            css={`
              min-width: 120px;
              padding: 10px 10px;
              box-sizing: border-box;
              text-align: center;
              border-radius: 0.5rem;
              color: ${primary_color};
              box-shadow: 0 0 0 2px ${primary_color};
              background: ${primary_shade};
              font-weight: 600;
              position: relative;
            `}
          >
            {value}
            <FaTimes
              css={`
                position: absolute;
                top: 0;
                right: 0;
                transform: translate(50%, -50%);
                background: #ffffff;
                border: 2px solid ${primary_color};
                border-radius: 50%;
                cursor: pointer;
              `}
              onClick={removeClickHandler.bind(null, value)}
            />
          </span>
        </article>
      ))}
    </SelectContinerStyled>
  ) : (
    <></>
  );
};

const SelectContinerStyled = styled.section`
  display: flex;
  flex-wrap: wrap;
  gap: 0.8rem;
  margin-top: 1.5rem;
`;

const InputContainer = styled.div`
  margin-top: 0.3rem !important;
  position: relative;
  margin-bottom: 2px !important;
  .input_flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .save_btn {
    height: 55px;
    min-width: 150px;
  }
`;

const Input = styled.input`
  list-style: none;
  -webkit-user-select: text;
  list-style-type: none;
  -webkit-user-select: initial;
  -khtml-user-select: initial;
  -moz-user-select: initial;
  -ms-user-select: initial;
  user-select: initial;
  &:before,
  &:after {
    -webkit-user-select: initial;
    -khtml-user-select: initial;
    -moz-user-select: initial;
    -ms-user-select: initial;
    user-select: initial;
  }
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  margin: 0;
  text-transform: ${props => props.textTransform};
  font-family: inherit;
  line-height: inherit;
  overflow: visible;
  outline: none;
  box-shadow: none;
  transition: all 0.3s ease-in-out;
  touch-action: manipulation;
  width: 100%;
  border: ${props => (props.error ? "solid 1px #c7222a" : "solid 1px #ced4da")};
  height: 55px;
  font-size: 14px;
  cursor: ${props => {
    return props.isHovering && props.readOnly ? "not-allowed" : "pointer";
  }};
  color: #090909;
  position: relative;
  padding: 0 25px;
  border-right: unset;
  &:focus {
    border-color: ${props => (props.error ? "#c7222a" : "solid 1px  #393939")};
    color: black;
  }
  &:before {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  &:after {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  @media (max-width: 767px) {
    height: 52px;
    padding: 0 16px;
    font-size: 14px;
  }
`;

const Label = styled.label`
  text-align: left;
  list-style: none;
  list-style-type: none;
  user-select: none;
  box-sizing: border-box;
  touch-action: manipulation;
  display: inline-block;
  font-size: 14px !important;
  color: #000;
  line-height: 14px;
  position: absolute;
  left: 20px;
  top: 0;
  margin: 0;
  max-width: 95%;
  background: #fff;
  transition: all 0.3s ease-in-out;
  font-weight: 600;
  padding: 0 5px;
  transform: translateY(-60%);

  ${({ mandatory }) => {
    return mandatory ? "&::after {  content: '*'; color: red;}" : "";
  }}

  @media (max-width: 1200px) {
    font-size: 13px !important;
  }
  @media (max-width: 1100px) {
    font-size: 12px !important;
  }
  @media (max-width: 1050px) {
    font-size: 12px !important;
  }
  @media (max-width: 767px) {
    left: 10px;
    font-size: 14px;
  }
`;

export default MultiSelectText;
