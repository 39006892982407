import FHTypography from "src/styles/Typography";

function LocationTab({ location, onChange }) {
  const handleClick = () => {
    onChange && onChange(location);
  };

  return (
    <li
      aria-hidden="true"
      onClick={handleClick}
      id={`auto-${location.city}`}
      data-auto-test-script="tag-id"
    >
      <FHTypography variant="h6" weight="base">
        {location.city}
      </FHTypography>
    </li>
  );
}

export default LocationTab;
