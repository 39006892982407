import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
// REACT ICONS
import { FaPen } from "react-icons/fa";
// CUSTOM HOOKS
import { useMembers, useTheme } from "src/customHooks";
// UTILS
import { setEditMembersModal } from "src/pages/ProductDetails/productDetails.slice";
// DESIGN SYSTEM
import { FLEX_ROW } from "src/styles/styleGuide";
// CUSTOM COMPONENTS
import { MembersList } from "src/components";
import IconButton from "src/components/Common/Button/IconButton";
import toast from "react-hot-toast";

function Members({ groupCode: gp, editable = true }) {
  const tempGp = +useParams()?.groupCode;

  const groupCode = +(gp || tempGp);
  const dispatch = useDispatch();

  const { getGroupMembers } = useMembers();

  const currentGroupMembers = getGroupMembers(groupCode);
  const { ridersLoading } = useSelector(({ productPage }) => productPage);
  const { colors } = useTheme();
  return (
    <MemberListWrapperStyled>
      <MembersList members={currentGroupMembers} />
      {editable && (
        <IconButton
          variant="primary"
          onClick={() => {
            if (ridersLoading) {
              toast("Please wait riders is loading...", {
                position: "top-center",
                id: "rider_loading",
                style: {
                  background: colors.primary_color,
                  color: "#fefefe",
                },
              });
              return;
            }
            dispatch(setEditMembersModal(true));
          }}
          btnId="edit-members-btn"
        >
          <FaPen />
        </IconButton>
      )}
    </MemberListWrapperStyled>
  );
}

export default Members;

const MemberListWrapperStyled = styled.div`
  ${FLEX_ROW}
  width:100%;
  align-items: center;
  justify-content: space-between;
`;
