import { memo } from "react";
import { useFrontendBoot } from "src/customHooks/useGetCommonQueries";
import { useTheme } from "src/customHooks";
import {
  FooterContainerStyled,
  FooterBottomStyled,
  FooterTopStyled,
  FooterInformationStyled,
  SocialLinksStyled,
  HRStyled,
} from "./BajajFooter.styled";

const BajajFooter = () => {
  const {
    colors: { primary_color },
  } = useTheme();
  const { ...frontendBoot } = useFrontendBoot();
  const { alias } = frontendBoot?.data?.tenant || "";
  return (
    <>
      {alias === "bajaj" ? (
        <>
          <HRStyled hrtype={true} />
          <FooterContainerStyled primaryColor={primary_color}>
            <FooterTopStyled>
              <FooterInformationStyled>
                <a href="https://www.bajajcapitalinsurance.com/Default.aspx">
                  <img
                    src="https://www.bajajcapitalinsurance.com/images/logo.png"
                    alt="B2B"
                    width="210"
                    loading="lazy"
                  />
                </a>
                <p>CIN:U67200DL2002PLC117625</p>
                <p>
                  {" "}
                  Bajaj Capital Insurance Broking Limited
                  <br />
                  Bajaj House, 97, Nehru Place, New Delhi -110019
                </p>
                <p>
                  <a href="mailto:care@bajajcapital.com">
                    care@bajajcapital.com
                  </a>
                </p>
                <p>
                  <a href="tel:1800-212-123123">1800 212 123123</a>
                </p>
              </FooterInformationStyled>
              <div>
                <h4>Our Services</h4>
                <ul>
                  <li>
                    <a
                      href="https://www.bajajcapitalinsurance.com/general-insurance/bike/lead-page"
                      target="_blank"
                      rel="noreferrer"
                    >
                      2 Wheeler Insurance
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.bajajcapitalinsurance.com/general-insurance/car/lead-page"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Car Insurance
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.bajajcapitalinsurance.com/general-insurance/health/input/basic-details"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Health Insurance
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://marketing.bajajcapitalinsurance.com/get-term-quote"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Term Life Insurance
                    </a>
                  </li>
                </ul>
              </div>
              <div className="quick__links demo">
                <h4>Quick Links</h4>
                <ul>
                  <li>
                    <a href="https://www.bajajcapitalinsurance.com/AboutUs.aspx">
                      {" "}
                      About{" "}
                    </a>
                  </li>
                  <li>
                    <a href="https://www.bajajcapitalinsurance.com/ImportantPolicies.aspx">
                      Important Policies
                    </a>
                  </li>
                  <li>
                    <a href="https://www.bajajcapitalinsurance.com/BranchPage.aspx">
                      Branch Locator{" "}
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://pos.bajajcapitalinsurance.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Earn with Us
                    </a>
                  </li>

                  <li>
                    Annual Return -
                    <span className="line__break">
                      <a
                        href="https://www.bajajcapitalinsurance.com/assets/documents/2018.pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        2018
                      </a>
                      <a
                        href="https://www.bajajcapitalinsurance.com/assets/documents/2018.pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        | 2019
                      </a>
                      <a
                        href="https://www.bajajcapitalinsurance.com/assets/documents/2020.pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        | 2020
                      </a>
                      <a
                        href="https://www.bajajcapitalinsurance.com/assets/documents/2021.pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        | 2021
                      </a>
                      <a
                        href="https://www.bajajcapitalinsurance.com/assets/documents/2022.pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        | 2022
                      </a>
                    </span>
                  </li>
                  <li>
                    <a href="https://www.bajajcapitalinsurance.com/ClaimCenterNew.aspx">
                      Claim Center
                    </a>
                  </li>
                  <li>
                    <a href="https://www.bajajcapitalinsurance.com/ComplaintNew.aspx">
                      Complaint
                    </a>
                  </li>
                </ul>
              </div>
              <div className="legal__policy">
                <h4>Legal &amp; Compliance</h4>
                <ul>
                  <li>
                    <a href="https://www.bajajcapitalinsurance.com/PrivacyPolicy.aspx">
                      Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a href="https://www.bajajcapitalinsurance.com/TermsConditions.aspx">
                      Terms &amp; Conditions
                    </a>
                  </li>
                  <li>
                    <a href="https://www.bajajcapitalinsurance.com/ImportantPolicies.aspx">
                      Disclaimer
                    </a>
                  </li>

                  <li>
                    <a href="https://www.bajajcapitalinsurance.com/CSRPolicy.aspx">
                      CSR Policy
                    </a>
                  </li>
                  <li>
                    <a href="https://www.bajajcapitalinsurance.com/AntiFraudPolicy.aspx">
                      Anti-Fraud Policy
                    </a>
                  </li>
                </ul>
              </div>
            </FooterTopStyled>
            <SocialLinksStyled>
              <h4>Follow Us</h4>
              <ul>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.facebook.com/bajajcapitalinsurance"
                  >
                    <img
                      src="https://iitpl-mf-designs.s3.ap-south-1.amazonaws.com/Mayur/b2c-insurance/images/facebook-icon.png"
                      alt="facebook"
                      loading="lazy"
                    />
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://twitter.com/Bajaj_insurance"
                  >
                    <img
                      src="https://iitpl-mf-designs.s3.ap-south-1.amazonaws.com/Mayur/b2c-insurance/images/twitter-icon.png"
                      alt="twitter"
                      loading="lazy"
                    />
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.instagram.com/bajaj.capital.insurance/"
                  >
                    <img
                      src="https://iitpl-mf-designs.s3.ap-south-1.amazonaws.com/Mayur/b2c-insurance/images/instagram-icon.png"
                      alt="instagram"
                      loading="lazy"
                    />
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.linkedin.com/company/bajaj-capital-insurance-broking-ltd/"
                  >
                    <img
                      src="https://iitpl-mf-designs.s3.ap-south-1.amazonaws.com/Mayur/b2c-insurance/images/linkden-icon.png"
                      alt="Linkdin"
                      loading="lazy"
                    />
                  </a>
                </li>
              </ul>
            </SocialLinksStyled>
            <FooterBottomStyled primaryColor={primary_color}>
              <div className="ownership">
                <hr />
                <p>
                  This site is owned and operated by Bajaj Capital Insurance
                  Broking Limited [“BCIBL” (CIN:U67200DL2002PLC117625)], an IRDA
                  licensed Composite Broker bearing{" "}
                  <span>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://online.bajajcapitalinsurance.com/Content/PolicyMart/images/Bajaj/Broking%20Licence1.pdf"
                    >
                      License No. 241, License Code CB 042/02,
                    </a>
                  </span>{" "}
                  license dated 09-01-2022 valid till 08-01-2025 (originally
                  licensed by IRDA on 09/01/2004 and renewed thereafter). BCIBL
                  is a member of Insurance Brokers Association of India
                  (Membership No.119). The Prospect’s/visitor’s particulars
                  could be shared with users. The information displayed on this
                  website is of the insurers with whom BCIBL has an agreement.
                  Insurance is the subject matter of solicitation.
                </p>
              </div>
            </FooterBottomStyled>
          </FooterContainerStyled>
          <HRStyled />
          <div className="copyright">
            <p>Copyright © 2022 Bajaj Capital Insurance Broking Limited</p>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default memo(BajajFooter);
