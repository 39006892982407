import React, { useEffect, useState } from "react";
import Toggle from "./Toggle";

//* When a Medical question has sub question this component must be rendered.

const CustomMedicalTable = ({
  label = "",
  members = [],
  customOptions,
  name = "",
  onChange = () => {},
  value,
  values,
  customMembers,
  showMembersIf,
  disable_Toggle,
  restrictMaleMembers,
  disable_toggle_to_NO = false,
  warning,
  message,
  notAllowedIf,
  notAllowed = false,
  noNotAllowed = false,
  noForAll,
  error,
  render = "",
  additionalOptions = {},
  checkValidation = {},
  dependentMembers = "",
  memberAgeNotAllowed = "",
}) => {
  // eslint-disable-next-line no-unused-vars
  const [toggleValue, setToggleValue] = useState(value);
  const initialFilteredMembers = toggleValue?.members
    ? Object.keys(toggleValue.members).filter(
        item => toggleValue.members[item] === true,
      )
    : [];

  const [filteredOption, setFilteredMembers] = useState(initialFilteredMembers);

  const [additionalValues, setAdditionalValues] = useState({});

  // eslint-disable-next-line no-unused-vars
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    let temp = additionalValues;

    Object.keys(additionalValues).forEach(item => {
      if (!filteredOption.includes(item)) {
        delete temp[item];
      }
      setAdditionalValues(temp);
    });
  }, [filteredOption]);

  useEffect(() => {
    if (filteredOption.length) {
      onChange({
        ...value,
        isValid: isValid,
        ["is" + name]: toggleValue[`is${name}`],
        members: toggleValue.members,
      });
    } else {
      onChange("");
    }
  }, [additionalValues, filteredOption, isValid]);

  useEffect(() => {
    if (toggleValue?.members) {
      let temp = Object.keys(toggleValue.members).filter(
        item => toggleValue.members[item] === true,
      );
      setFilteredMembers(temp);
    }

    return () => {};
  }, [toggleValue]);

  return (
    <>
      <Toggle
        label={label}
        members={members}
        name={name}
        value={value}
        onChange={onChange}
        customMembers={customMembers}
        customOptions={customOptions}
        showMembersIf={showMembersIf}
        values={values}
        message={message}
        warning={warning}
        disable_Toggle={disable_Toggle}
        restrictMaleMembers={restrictMaleMembers}
        disable_toggle_to_NO={disable_toggle_to_NO}
        notAllowedIf={notAllowedIf}
        notAllowed={notAllowed}
        noNotAllowed={noNotAllowed}
        additionalOptions={additionalOptions}
        noForAll={noForAll}
        error={error}
        render={render}
        checkValidation={checkValidation}
        dependentMembers={dependentMembers}
        memberAgeNotAllowed={memberAgeNotAllowed}
      />
    </>
  );
};

export default CustomMedicalTable;
