import { Tabs, Tab } from "../ProposalSections/RedirectionDisabledScreen";
import { useTheme, useCart } from "src/customHooks";
import { useState, useEffect } from "react";
import { useResponsiveCondition } from "src/pos/hooks";

import { stringLengthCutter } from "src/utils/string.utility";
import { getMembersName } from "src/components/CartSummaryModal/cartSummary.utility";

const GroupTabs = ({ groups = [], onChange }) => {
  const { colors } = useTheme();
  const [currGroup, setCurrGroup] = useState();
  const {
    cartData: { data },
  } = useCart();
  const { width, breakpoint } = useResponsiveCondition(600);

  const kycRemainingGroups = data.filter(
    group => !group?.can_proceed_with_proposal,
  );

  let groupsToShow = {};
  groups.map(group => {
    data.map(item => {
      if (item?.group.id === group) {
        let groupUtility = {
          [group]: {
            groupName: getMembersName(item.group?.members),
          },
        };
        groupsToShow = {
          ...groupsToShow,
          ...groupUtility,
        };
      }
    });
  });

  useEffect(() => {
    if (kycRemainingGroups.length) {
      setCurrGroup(kycRemainingGroups[0].group.id);
    }
    return () => {};
  }, []);

  useEffect(() => {
    onChange({ target: { value: currGroup } });
  }, [currGroup]);

  return (
    <Tabs color={colors.primary_color}>
      {groups.map(group => {
        return (
          <Tab
            className={group == currGroup && "active"}
            key={group?.id}
            onClick={() => setCurrGroup(group)}
          >
            {width < breakpoint
              ? stringLengthCutter(groupsToShow[group]?.groupName, 10)
              : groupsToShow[group].groupName}
          </Tab>
        );
      })}
    </Tabs>
  );
};

export default GroupTabs;
