import TextField from "@mui/material/TextField";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import enIDLocale from "date-fns/locale/en-IN";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Button } from "src/components/Common/Button/ContinueButton";
import {
  useMembers,
  useTheme,
  useUpdateEnquiry,
  useUrlEnquiry,
} from "src/customHooks";
import {
  useFrontendBoot,
  useGetEnquiry,
} from "src/customHooks/useGetCommonQueries";
import { getFormattedDate } from "src/utils/helper";
import "styled-components/macro";
import { Title } from "./FormComponents";

function PortabilityForm() {
  const { currentForm, groupCode: groupId } = useParams();

  const { subJourneyType, journeyType, settings } = useFrontendBoot();

  const { getUrlWithEnquirySearch } = useUrlEnquiry();

  const { data } = useGetEnquiry();

  const groupCode = parseInt(currentForm?.split("-")[1] || groupId);

  const { getNextGroup, getMembersText, getGroup, getGroupPortExpiryDate } =
    useMembers();

  const [port_expiry_date, set_port_expiry_date] = React.useState(
    getGroupPortExpiryDate(groupCode),
  );

  const currentGroup = getGroup(groupCode);

  useEffect(() => {
    set_port_expiry_date(getGroupPortExpiryDate(currentGroup?.id));
  }, [currentGroup?.id]);

  const [error, setError] = React.useState(null);

  const { updateEnquiry, ...updateEnquiryQuery } = useUpdateEnquiry();

  const nextGroup = getNextGroup(groupCode);

  const { colors } = useTheme();

  const history = useHistory();

  const theme = createTheme({
    palette: {
      primary: {
        main: colors.primary_color,
      },
      secondary: {
        main: colors.secondary_color,
      },
    },
    typography: {
      fontFamily: "Inter",
    },
  });

  const redirectionConfig = {
    top_up({ nextGroup, currentGroup }) {
      if (!nextGroup) {
        return getUrlWithEnquirySearch(`/input/deductible`);
      }
      return getUrlWithEnquirySearch(`/input/location-${currentGroup + 1}`);
    },
    health({ nextGroup, groups, currentGroup }) {
      if (!nextGroup) {
        return settings.medical_history_visibility
          ? getUrlWithEnquirySearch(`/input/medicalHistory`)
          : getUrlWithEnquirySearch(`/quotes/${groups[0].id}`);
      }
      return getUrlWithEnquirySearch(`/input/portability-${currentGroup + 1}`);
    },
  };

  const handleSubmit = async () => {
    try {
      await updateEnquiry({
        groupCode,
        port_expiry_date: getFormattedDate(new Date(port_expiry_date)),
      });

      const { groups } = data?.data;

      const firstGroup = Math.min(
        ...groups?.map(group => {
          return group?.id;
        }),
      );

      let nextPath = redirectionConfig[journeyType]({
        subJourneyType: subJourneyType,
        nextGroup: nextGroup,
        firstGroup: firstGroup,
        groups: groups,
        currentGroup: groupCode,
      });

      history.push(nextPath);
    } catch (error) {
      return;
    }
  };

  const goBack = () => {
    history.goBack();
  };

  let title = "Your existing policy's expiry date.";

  if (getMembersText(currentGroup) !== "Self") {
    title = `Existing policy's expiry date for ${getMembersText(currentGroup)}`;
  }

  return (
    <ThemeProvider theme={theme}>
      <div className="p-3">
        <Title className="mb-4">{title}</Title>
        <PortDatePicker
          value={port_expiry_date}
          setValue={set_port_expiry_date}
          setError={setError}
        />
        <div className="mt-4">
          <div
            className="d-flex justify-content-between align-items-center"
            css={`
              padding: 0 28px;

              @media (max-width: 768px) {
                padding: 0 14px;
              }
            `}
          >
            <button
              onClick={goBack}
              css={`
                color: #000;
                height: 58px;
                width: 172px;
                background: unset;
                padding: 10px 11px;
                color: rgb(37, 56, 88);
                font-weight: 600;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 20px;
                font-weight: 600;
                gap: 3px;

                @media (max-width: 480px) {
                  background: rgb(239, 243, 245);
                  color: rgb(70, 86, 113);
                  font-size: 13px;
                  height: 40px;
                  max-width: 120px;
                  width: 100%;
                  padding: 0;
                }
              `}
            >
              <span>Back</span>
            </button>
            <Button
              loaderPrimaryColor
              disabled={error || !port_expiry_date}
              onClick={handleSubmit}
              arrow
              loader={updateEnquiryQuery?.isLoading}
              css={`
                height: 58px;
                width: 100%;
                max-width: 172px;
                font-size: 20px;
                font-weight: 400;

                @media (max-width: 480px) {
                  font-size: 13px;
                  height: 40px;
                  width: 100%;
                  max-width: 120px;
                  padding: 5px 11px;
                  font-weight: normal;
                }
              `}
            >
              Continue
            </Button>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default PortabilityForm;

export function PortDatePicker({
  value = new Date(),
  setValue = () => {},
  setError = () => {},
}) {
  const minDate = new Date(Date.now());
  const maxDate = new Date(Date.now());
  maxDate.setFullYear(maxDate.getFullYear() + 3); // + 3 years

  return (
    <LocalizationProvider locale={enIDLocale} dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        label="Enter Expiry Date"
        mask="__/__/____"
        value={value}
        ampm={false}
        maxDate={
          new Date(
            `${maxDate.getFullYear()}-${
              maxDate.getMonth() + 1
            }-${maxDate.getDate()}`,
          )
        }
        minDate={
          new Date(
            `${minDate.getFullYear()}-${
              minDate.getMonth() + 1
            }-${minDate.getDate()}`,
          )
        }
        onChange={newValue => {
          setValue(newValue);
        }}
        renderInput={params => {
          setError(params.error);
          return (
            <TextField
              autoComplete="off"
              color="primary"
              fullWidth
              helperText={params.error && "Please provide a valid date"}
              {...params}
            />
          );
        }}
      />
    </LocalizationProvider>
  );
}
