import styled from "styled-components";
import { useTheme } from "src/customHooks";

const Label = ({ heading }) => {
  const { colors } = useTheme();

  return (
    <LabelStyled primaryShade={colors.primary_shade}>{heading}</LabelStyled>
  );
};

export default Label;

const LabelStyled = styled.p`
  background-image: linear-gradient(
    to right,
    ${({ primaryShade }) => primaryShade},
    white
  );
  margin-bottom: 6px;
  font-size: 17px !important;
  color: #3b3838;
  font-weight: 600 !important;
  margin-top: -3px;
  padding: 10px 18px;
  border-radius: 6px;

  height: 40px;
  padding: 12px;
  text-transform: capitalize;
  text-align: justify;
  line-height: 18px;
  color: #000000;

  @media (max-width: 767px) {
    height: 20px;
    font-size: 16px !important;
    padding: 4px 0 0;
    background-image: unset;
  }
`;
