import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import { number2text, validateName } from "src/utils/helper";
import { setShowPlanNotAvail } from "../ProposalSections/ProposalSections.slice";
import { FWEIGHT, ROUNDED } from "src/styles/styleGuide";
import { stringLengthCutter } from "src/utils/string.utility";
import { useResponsiveCondition } from "src/pos/hooks";

const TextInput = ({
  name = "",
  label = "",
  placeholder = "",
  required = false,
  onChange = () => {},
  checkValidation = {},
  error = "",
  onBlur = () => {},
  onKeyDown = () => {},
  notAllowed,
  allValues,
  value,
  onKeyPress = () => {},
  maxLength,
  maxValue,
  textTransform,
  onInput = () => {},
  readOnly,
  innerMember,
  checkAge,
  defaultValue,
  onFocus = () => {},
  allow,
  clearField = "",
  formName = "",
  directUpdateValue = () => {},
  maskInput = false,
  boldText = false,
}) => {
  notAllowed = notAllowed + "";

  const dispatch = useDispatch();

  const age =
    checkAge &&
    parseInt(new Date().getFullYear()) -
      parseInt(
        allValues?.["Insured Details"]?.[innerMember][
          checkAge.split("from")[1]
        ].split("-")[2],
      );

  const [isFocused, setIsFocused] = useState(false);

  const { proposalData } = useSelector(state => state.proposalPage);

  const [, setFallbackValue] = useState("");

  const [, setChanged] = useState(false);

  const regForOnlyDigit = new RegExp("^[0-9]*$");

  const [isHovering, setIsHovering] = useState(false);

  const [disableField, setDisableField] = useState(false);

  const fullName = value || "";

  const forbiddedSymbols = "`~!@#$%^&*()_-+={[}]|:.;',<>?/\"\\".split("");

  const forbiddedSymbolsForEmail = "`~!@#$%^&*()-+={[}]|:.;',<>?/\"\\".split(
    "",
  );

  const { failedAsyncPanNumber = "" } = useSelector(
    state => state.proposalPage,
  );

  const checkPreviousChar = (value, checkValue) => {
    let check = true;

    if (value[0] === checkValue) {
      check = false;
    }
    if (
      check &&
      value[value.length - 1] === checkValue &&
      fullName[fullName.length - 1] === checkValue
    ) {
      check = false;
    }
    return check;
  };

  const checkAllChar = (value, checkValue) => {
    let check = true;
    for (let i in value) {
      if (checkValue.includes(value[i])) {
        check = false;
      }
    }

    return check;
  };

  const { breakpoint, width } = useResponsiveCondition(350);

  // to clear the field once a new document is selected
  useEffect(() => {
    if (clearField) {
      onChange("");
    }
    return () => {};
  }, [clearField]);

  useEffect(() => {
    // to fill pan number in case of Async Text field failure
    if (
      failedAsyncPanNumber &&
      name.includes("pan") &&
      formName === "KYC Details"
    ) {
      directUpdateValue(name, failedAsyncPanNumber);
      setDisableField(true);
    }
    return () => {};
  }, [failedAsyncPanNumber]);

  useEffect(() => {
    if (maskInput) {
      let newValue = value.trim().split(" ")[0];
      onChange({ target: { value: newValue } });
    }
    return () => {};
  }, [maskInput]);

  useEffect(() => {
    // autofill pan number on KYC form if it was entered on Proposer form
    if (
      formName === "KYC Details" &&
      proposalData["Proposer Details"]?.pan_card &&
      name.includes("pan")
    ) {
      directUpdateValue(name, proposalData["Proposer Details"].pan_card);
      setDisableField(true);
    }
    return () => {};
  }, []);

  return (
    <>
      <InputContainer>
        <Input
          type={allow === "onlyNumbers" ? "tel" : "text"}
          placeholder={placeholder}
          isHovering={isHovering}
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
          showStarRed={checkValidation?.required}
          required={required || undefined}
          onChange={e => {
            setChanged(true);
            if (checkValidation && checkValidation["matches"] === "address") {
              let acceptedSpecialChar = ['"', ".", "-", ",", "/", "#", "&"];
              if (
                checkAllChar(
                  e.target.value,
                  forbiddedSymbols.filter(
                    el => !acceptedSpecialChar.includes(el),
                  ),
                )
              ) {
                e.target.value = e.target.value.trimStart();
                onChange(e);
                setFallbackValue(e.target.value);
              }
            } else if (
              checkValidation["matches"] === "name" ||
              name === "nominee_name"
            ) {
              let acceptedSpecialChar = ["."];
              if (
                checkPreviousChar(e.target.value, " ") &&
                checkPreviousChar(e.target.value, ".") &&
                e.target.value
                  .split("")
                  .reduce(
                    (acc, char) => (acc = char === "." ? acc + 1 : acc),
                    0,
                  ) <= 1 &&
                (e.target.value === "" ||
                  !regForOnlyDigit.test(e.target.value)) &&
                e.target.value.length <= 60 &&
                checkAllChar(
                  e.target.value,
                  forbiddedSymbols
                    .filter(el => !acceptedSpecialChar.includes(el))
                    .concat("0123456789".split("")),
                ) &&
                validateName(e.target.value)
              ) {
                onChange(e);
                setFallbackValue(e.target.value);
              }
            } else if (
              checkValidation?.matches === "pincode" ||
              checkValidation?.matches === "aadhar" ||
              checkValidation?.matches === "mobile"
            ) {
              if (
                checkAllChar(
                  e.target.value,
                  forbiddedSymbols.concat(
                    "qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM".split(
                      "",
                    ),
                  ),
                )
              ) {
                if (checkValidation?.matches === "mobile") {
                  if (![0, 1, 2, 3, 4, 5].includes(Number(e.target.value[0]))) {
                    onChange(e);
                    setFallbackValue(e.target.value);
                  } else {
                    return;
                  }
                } else {
                  onChange(e);
                  setFallbackValue(e.target.value);
                }
              }
            } else if (
              checkAllChar(e.target.value, forbiddedSymbols) &&
              checkValidation["matches"] !== "alphanum"
            ) {
              if (name === "annIncome") {
                if (
                  regForOnlyDigit.test(e.target.value) &&
                  e.target.value.length < 10
                ) {
                  onChange(e);
                  setFallbackValue(e.target.value);
                }
              } else if (checkValidation?.matches === "onlyDigits") {
                if (regForOnlyDigit.test(e.target.value)) {
                  onChange(e);
                  setFallbackValue(e.target.value);
                }
              } else if (checkAge) {
                if (parseInt(e.target.value) <= age || e.target.value === "") {
                  onChange(e);
                  setFallbackValue(e.target.value);
                }
              } else if (
                checkValidation?.["matches"] &&
                checkValidation?.["matches"].includes("mobile")
              ) {
                if (
                  ![0, 1, 2, 3, 4, 5].includes(Number(e.target.value[0])) &&
                  e.target.value.length <= 10
                ) {
                  onChange(e);
                  setFallbackValue(e.target.value);
                }
              } else if (checkValidation?.["matches"] === "email") {
                if (
                  e.target.value.length <= 64 &&
                  checkAllChar(
                    e.target.value,
                    forbiddedSymbolsForEmail.filter(
                      el => !"@.".split("").includes(el),
                    ),
                  )
                ) {
                  onChange(e);
                  setFallbackValue(e.target.value);
                }
              } else {
                if (
                  notAllowed &&
                  notAllowed.includes("null") &&
                  ((notAllowed.split("/")[0] !== "null" &&
                    e.target.value < parseInt(notAllowed.split("/")[0])) ||
                    (notAllowed.split("/")[1] !== "null" &&
                      e.target.value > parseInt(notAllowed.split("/")[1])))
                ) {
                  e.target.value = "";
                  dispatch(setShowPlanNotAvail(true));
                } else if (textTransform === "uppercase") {
                  e.target.value = e.target.value.toLocaleUpperCase();
                }
                if (maxLength && e.target.value.length > maxLength) return;
                if (maxValue && e.target.value > maxValue) return;
                onChange(e);
                setFallbackValue(e.target.value);
              }
            } else if (
              checkValidation &&
              (checkValidation["matches"] === "alphanum" ||
                checkValidation["matches"] === "pan" ||
                checkValidation["matches"] === "onlyDigits" ||
                checkValidation["matches"] === "annIncome" ||
                (checkValidation["matches"] &&
                  checkValidation["matches"].includes("mobile")))
            ) {
              if (
                checkPreviousChar(e.target.value, " ") &&
                checkPreviousChar(e.target.value, ".") &&
                checkAllChar(e.target.value, forbiddedSymbols)
              ) {
                if (
                  checkValidation?.["matches"] &&
                  checkValidation?.["matches"].includes("mobile")
                ) {
                  if (![0, 1, 2, 3, 4, 5].includes(Number(e.target.value[0]))) {
                    onChange(e);
                    setFallbackValue(e.target.value);
                  }
                } else {
                  onChange(e);
                  setFallbackValue(e.target.value);
                }
              }
            } else if (checkValidation?.["matches"] === "email") {
              if (
                e.target.value.length <= 64 &&
                checkAllChar(
                  e.target.value,
                  forbiddedSymbolsForEmail.filter(
                    el => !"@.".split("").includes(el),
                  ),
                )
              ) {
                onChange(e);
                setFallbackValue(e.target.value);
              }
            } else {
              if (
                notAllowed &&
                ((notAllowed.split("/")[0] !== "null" &&
                  parseInt(e.target.value) <=
                    parseInt(notAllowed.split("/")[0])) ||
                  (notAllowed.split("/")[1] !== "null" &&
                    parseInt(e.target.value) >=
                      parseInt(notAllowed.split("/")[1])))
              ) {
                e.target.value = "";
                dispatch(setShowPlanNotAvail(true));
              } else if (textTransform === "uppercase") {
                e.target.value = e.target.value.toLocaleUpperCase();
              }
              onChange(e);
              setFallbackValue(e.target.value);
            }
          }}
          onFocus={() => {
            onFocus();
            setIsFocused(true);
          }}
          onBlur={e => {
            onBlur();
            if (
              notAllowed &&
              !notAllowed?.includes("null") &&
              ((notAllowed?.split("/")[0] !== "null" &&
                e.target.value < parseInt(notAllowed?.split("/")[0])) ||
                (notAllowed?.split("/")[1] !== "null" &&
                  e.target.value > parseInt(notAllowed?.split("/")[1])))
            ) {
              e.target.value !== "" && dispatch(setShowPlanNotAvail(true));
              e.target.value = "";
              onChange(e);
              setFallbackValue(e.target.value);
            }
            setIsFocused(false);
          }}
          onInput={onInput}
          onKeyDown={onKeyDown}
          value={value ? value : ""}
          onKeyPress={onKeyPress}
          maxLength={name === "name" ? 60 : maxLength}
          boldText={boldText}
          textTransform={textTransform}
          readOnly={readOnly || disableField}
          error={!isFocused ? error : null}
          defaultValue={defaultValue}
        />

        <Label
          className="floating-label"
          mandatory={checkValidation?.required === true}
        >
          {width < breakpoint ? stringLengthCutter(label, 30) : label}
        </Label>
      </InputContainer>
      {error && <p className="formbuilder__error">{error}</p>}
      {name === "annIncome" && value && !error && (
        <Income>{number2text(value)?.toLowerCase()}</Income>
      )}
    </>
  );
};

export default TextInput;

const Income = styled.div`
  font-size: 12px;
  margin-top: 2px;
  text-transform: capitalize;
`;
const InputContainer = styled.div`
  margin-top: 0.3rem !important;
  position: relative;

  margin-bottom: 2px !important;
`;
const Input = styled.input`
  width: 100%;
  height: 100%;
  font-size: 14px;
  padding: 12px;
  text-transform: ${props => props.textTransform};
  color: #090909;
  border: ${props => (props.error ? "solid 1px #c7222a" : "1px solid #d4d5d9")};
  background-color: transparent;
  font-weight: ${props => (props.boldText ? FWEIGHT.semi : 300)};
  cursor: ${props => {
    return props.isHovering && props.readOnly ? "not-allowed" : "pointer";
  }};
  border-radius: ${ROUNDED.base};

  &:focus {
    color: #000;
  }
  &:focus ~ .floating-label {
    top: 0;
    font-size: 12px;
    color: #000;
    background: #fff;
    font-weight: ${FWEIGHT.semi};

    &::after {
      color: red;
    }
  }

  &:not([${({ value }) => value !== ""}]) ~ .floating-label {
    top: 0;
    font-size: 12px;
    color: #000;
    background: #fff;
    font-weight: ${FWEIGHT.semi};

    &::after {
      color: red;
    }
  }

  &::placeholder {
    opacity: 0;
  }

  &:focus {
    &::placeholder {
      opacity: 1;
    }
  }
`;

const Label = styled.label`
  text-align: left;
  pointer-events: none;
  display: inline-block;
  font-size: 13px !important;
  color: grey;
  position: absolute;
  left: 8px;
  top: 50%;
  margin: 0;
  max-width: 95%;
  transition: top 0.3s ease-in-out;
  transform: translateY(-50%);
  padding: 0 4px;

  ${({ mandatory }) => {
    return mandatory ? "&::after {  content: '*'; }" : "";
  }}
`;
