import { useEffect } from "react";
import { useSelector } from "react-redux";
import Select, { components } from "react-select";
// CUSTOM HOOKS
import { useTheme } from "src/customHooks";
import useGetRouteMatch from "src/customHooks/useGetRouteMatch";
// DESIGN SYSTEM
import { ROUNDED, SHADES, SHADOW } from "src/styles/styleGuide";
import FHTypography from "src/styles/Typography";
import styled from "styled-components";

export default function RiderMembersDropdown({ rider, onChange }) {
  const { colors } = useTheme();
  const { seeDetails } = useSelector(state => state.utilities);
  const {
    isCompareRoute,
    isProductDetailsRoute,
    isProposalRoute,
    isProposalSummaryRoute,
    isThankYouRoute,
  } = useGetRouteMatch();
  const { rider_members, isSelected, disable_members_editing = false } = rider;

  const riderMemberList =
    rider_members?.map(member => ({
      value: member.code,
      label: member.name,
      selected: member.selected,
    })) || [];

  const selectedMembers = riderMemberList?.filter(member => member.selected);
  const defaultSelectedRoute =
    isProposalRoute || isProposalSummaryRoute || isThankYouRoute;

  const disableRiderOption =
    defaultSelectedRoute ||
    (seeDetails.isSeeDetailsModalOpen &&
      (isProductDetailsRoute || isCompareRoute))
      ? true
      : false;
  useEffect(() => {
    // for a selected rider if it has only 1 member set it to true
    if (rider_members?.length == 1 && isSelected) {
      handleChange(riderMemberList);
    }
    return () => {};
  }, [isSelected]);

  function handleChange(values) {
    const finalValue = values.map(val => ({
      name: val.label,
      code: val.value,
      selected: true,
    }));

    if (finalValue?.length == 0) {
      onChange &&
        onChange({ ...rider, isSelected: false, rider_members: finalValue });
    } else {
      onChange &&
        onChange({ ...rider, isSelected: true, rider_members: finalValue });
    }
  }

  if (rider_members == undefined || rider_members?.length <= 1) {
    return <></>;
  }

  return (
    <Select
      value={selectedMembers}
      components={{ Option, Placeholder }}
      options={riderMemberList}
      onChange={handleChange}
      isMulti={true}
      isSearchable={false}
      hideSelectedOptions={false}
      placeholder="Select Rider Members..."
      isDisabled={disable_members_editing || disableRiderOption}
      className="rider-member"
      styles={{
        placeholder: provided => ({
          ...provided,
          color: SHADES.HEADING,
        }),
        menu: provided => ({
          ...provided,
          borderRadius: ROUNDED.md,
          border: `1px solid ${SHADES.LIGHT_GRAY}`,
          overflow: "hidden",
          padding: "0.125rem 0",
          boxShadow: SHADOW.lg,
        }),
        option: (provided, state) => ({
          ...provided,
          padding: "0.75rem",
          textAlign: "left",
          display: "flex",
          gap: "0.5rem",
          cursor: "pointer",
          backgroundColor: state.isSelected ? colors.primary_shade : "#fff",
          ":active": {
            backgroundColor: colors.primary_shade,
          },
          ":hover": {
            backgroundColor: colors.primary_shade,
          },
        }),
        control: base => ({
          ...base,
          border: 0,
          // This line disable the blue border
          boxShadow: "none",
          width: "100%",
          minHeight: "auto",
          backgroundColor: colors.primary_shade,
          padding: "0.25rem 0.5rem",
        }),
        container: provided => ({
          ...provided,
          width: "fit-content",
        }),
        indicatorSeparator: provided => ({
          ...provided,
          display: "none",
        }),
        multiValue: provided => ({
          ...provided,
          backgroundColor: SHADES.LIGHT_GRAY,
          borderRadius: ROUNDED.base,
        }),
        valueContainer: provided => ({
          ...provided,
          padding: "0",
          flexDirection: "row",
          cursor: "pointer !important",
        }),
        dropdownIndicator: (provided, state) => ({
          ...provided,
          padding: 0,
          color: "var(--body_color)",
          cursor: "pointer",
          fontSize: "12px",
          transition: "all .2s ease",
          transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
        }),
        input: provided => ({
          ...provided,
          caretColor: "transparent",
          cursor: "pointer",
          padding: 0,
          margin: 0,
        }),
      }}
    />
  );
}

const Placeholder = props => {
  return (
    <components.Placeholder {...props}>
      <FHTypography variant="h6" colorType="heading">
        {props.children}
      </FHTypography>
    </components.Placeholder>
  );
};

const Option = props => {
  return (
    <components.Option {...props}>
      <Input type="checkbox" checked={props.isSelected} />
      <FHTypography variant="subheading1" weight="md" colorType="heading">
        {props.children}
      </FHTypography>
    </components.Option>
  );
};

const Input = styled.input`
  cursor: pointer;
`;
