import { useEffect, useState } from "react";
import CustomProgressBar from "../../../components/ProgressBar";
import { useMembers, useTheme, useUpdateEnquiry } from "src/customHooks";
import FHTypography from "src/styles/Typography";
import { api } from "../../../api/api";
import "styled-components/macro";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import {
  setEditStep,
  setShowEditMembers,
} from "../../NewQuotePage/QuotePageSlice/quote.slice";
import { Button } from "src/components/Common/Button/ContinueButton";

import { getFormattedDate } from "src/utils/helper";
import { useParams } from "react-router-dom";
import { PortDatePicker } from "../Forms/components";
import {
  FLEX_COL_CENTER,
  FLEX_ROW_CENTER,
  SHADES,
} from "src/styles/styleGuide";

function PortExpiryDateForm({ edit = false, posContent }) {
  const { colors } = useTheme();

  const { currentForm, groupCode: groupId } = useParams();

  const {
    groups: allGroups,
    getGroupPortExpiryDate,
    getNextGroup,
    getPreviousGroup,
    getMembersText,
    getGroupMembers,
    getGroup,
  } = useMembers();

  const groupCode = parseInt(currentForm?.split("-")[1] || groupId);

  const currentGroup = getGroup(groupCode);

  const groups = allGroups?.filter(group => group?.type === currentGroup?.type);

  const [currentGroupCode, setCurrentGroupCode] = useState(
    currentGroup?.type === "all" ? groups[0]?.id : groupCode,
  );

  const [port_expiry_date, set_port_expiry_date] = useState(
    getGroupPortExpiryDate(currentGroupCode),
  );

  const [loader, setLoader] = useState({
    continueLoader: false,
  });

  const showSkipButton = !port_expiry_date;

  const { updateEnquiry } = useUpdateEnquiry();

  const [error, setError] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    set_port_expiry_date(getGroupPortExpiryDate(currentGroup?.id));
  }, [currentGroup?.id]);

  useEffect(() => {
    set_port_expiry_date(getGroupPortExpiryDate(+currentGroupCode));
  }, [currentGroupCode]);

  const nextGroup = getNextGroup(currentGroupCode);

  const previousGroup = getPreviousGroup(currentGroupCode);

  const goBack = () => {
    if (previousGroup) {
      setCurrentGroupCode(prev => prev - 1);
    } else {
      dispatch(setEditStep(2));
    }
  };

  const submit = async ({ expiryDate, group_id, skip = false }) => {
    setLoader(current => ({
      ...current,
      [skip ? "skipLoader" : "continueLoader"]: true,
    }));

    try {
      await updateEnquiry({
        groupCode: group_id,
        port_expiry_date: expiryDate,
      });

      setLoader(current => ({
        ...current,
        [skip ? "skipLoader" : "continueLoader"]: false,
      }));

      if (edit) {
        if (nextGroup) {
          setCurrentGroupCode(prev => prev + 1);
        } else {
          dispatch(api.util.invalidateTags(["custom_quotes"]));
          return dispatch(setShowEditMembers(false));
        }
      }
    } catch (error) {
      return setLoader(current => ({
        ...current,
        [skip ? "skipLoader" : "continueLoader"]: false,
      }));
    }
  };

  const handleSubmit = () => {
    submit({
      expiryDate: getFormattedDate(port_expiry_date),
      group_id: currentGroupCode,
    });
  };

  const handleSkip = () => {
    if (edit) {
      if (nextGroup) {
        setCurrentGroupCode(prev => prev + 1);
      } else {
        dispatch(api.util.invalidateTags(["custom_quotes"]));
        return dispatch(setShowEditMembers(false));
      }
    }
  };

  const membersText = getMembersText({ id: parseInt(currentGroupCode) });

  const groupMembers = getGroupMembers(parseInt(currentGroupCode));

  const isSelf = groupMembers?.some(member => member.code === "self");

  const setCurrentGroupValues = id => {
    setCurrentGroupCode(id);
  };

  return (
    <PortExpiryContainerStyled colors={colors} edit={edit}>
      {edit && (
        <>
          <div className="group_members ">
            {groups?.map((group, index) => (
              <GroupWrapper
                key={group?.id}
                active={group?.id === currentGroupCode}
                color={colors.primary_color}
                onClick={() => setCurrentGroupValues(group?.id, index)}
              >
                <Group
                  active={group?.id === currentGroupCode}
                  color={colors.primary_color}
                  shade={colors.secondary_shade}
                >
                  {group?.members
                    ?.map(memberText => memberText.split("_").join("-"))
                    .join(",")}
                </Group>
                <span className="pointer"></span>
              </GroupWrapper>
            ))}
          </div>
        </>
      )}

      {!edit && (
        <FHTypography
          variant="h6"
          weight="semi"
          colorType="heading"
          dangerouslySetInnerHTML={{
            __html: posContent.question
              ? posContent.question
              : `Tell Us Where ${isSelf ? "You" : "Your" + membersText}
              ${isSelf ? "Live" : "Lives"}`,
          }}
        />
      )}
      {!edit && <CustomProgressBar progress={4} total={5} />}
      <div className="input_field">
        <PortDatePicker
          value={port_expiry_date}
          setValue={set_port_expiry_date}
          setError={setError}
        />
        {error?.groupCode === currentGroupCode && (
          <div className={edit && "error_msg"}>
            <FHTypography variant="body1" weight="base" type="error">
              {error?.error}
            </FHTypography>
          </div>
        )}

        {edit && (
          <div className="plan_expiry_text">
            <FHTypography variant="h6" weight="semi">
              Tell Us Your {isSelf ? "" : `${membersText}'s `} Plan Expiry Date?
            </FHTypography>
          </div>
        )}
      </div>

      {showSkipButton && (
        <div className="skip_container">
          <FHTypography className="subanswer" variant="body1" weight="semi">
            Don&apos;t wanna port ?
          </FHTypography>

          <FHTypography
            className="skip_text"
            variant="body1"
            weight="semi"
            onClick={handleSkip}
          >
            {"Skip >"}
          </FHTypography>
        </div>
      )}

      <div className="portCTA">
        <div>
          <button className="go_back" onClick={goBack}>
            <FHTypography variant="h3" weight="semi">
              Backs
            </FHTypography>
          </button>
          <Button
            className="back_btn"
            loaderPrimaryColor
            onClick={handleSubmit}
            disabled={error || !port_expiry_date}
            arrow
            loader={loader.continueLoader}
          >
            Continues
          </Button>
        </div>
      </div>
      {/* )} */}
    </PortExpiryContainerStyled>
  );
}

export default PortExpiryDateForm;

export const Group = styled.div`
  width: 130px;
  white-space: nowrap;
  text-transform: capitalize;
  overflow: hidden !important;
  text-overflow: ellipsis;
  font-size: 0.9em;
  font-weight: 600;
  position: relative;
  color: ${props => (props.active ? "#fff" : "black")};
  padding: 0.75rem 0.8rem;
  background: ${props => (props.active ? props.color : props.shade)};
  border-radius: 1000px;
  text-align: center;
  transition: width 4s;

  &:hover {
    min-width: 130px;
    width: max-content;
    white-space: wrap;
    overflow: none;
    text-overflow: none;
  }
`;

export const GroupWrapper = styled.div`
  position: relative;
  cursor: pointer;

  &:first-child {
    margin-left: auto;
  }
  &:last-child {
    margin-right: auto;
  }

  & span {
    content: "";
    width: 15px;
    height: 10px;
    clip-path: polygon(0 0, 50% 100%, 100% 0);
    -webkit-clip-path: polygon(0 0, 50% 100%, 100% 0);
    background: ${props => props.color};
    display: ${props => (props.active ? "block" : "none")};
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 90%);
  }
`;

export const PortExpiryContainerStyled = styled.div`
  padding: 2rem;
  ${props => {
    props.isOnModal ? `${FLEX_COL_CENTER}` : "";
  }};
  .group_members {
    padding: 1rem;
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    align-items: center;
    &::-webkit-scrollbar {
      display: none;
    }
    @media (max-width: 768px) {
      margin: 0 auto;
      overflow: auto;
      height: 80px;
      text-align: center;
    }
  }
  .input_wrapper {
    ${FLEX_ROW_CENTER}
  }
  .input_field {
    width: 30rem;
    position: relative;
    width: ${props => props.edit && "70%"};
    margin: ${props => props.edit && "auto"};
    margin-top: 1em;
    @media (max-width: 768px) {
      width: ${props => props.edit && "100%"};
    }
  }
  .error_msg {
    width: 70%;
    margin: 3px auto;
  }
  .plan_expiry_text {
    margin: 1rem auto 0;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  .skip_container {
    width: 70%;
    margin: 1.5rem auto 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  .subanswer {
    color: #9696b5;
    width: unset;
  }
  .skip_text {
    font-size: 12px;
    font-weight: 600;
    color: ${props => props.colors.primary_color};
    cursor: pointer;
  }
  .portCTA {
    width: ${props => props.edit && "70%"};
    margin: ${props => props.edit && "auto"};
    margin-top: 2.5rem;
    @media (max-width: 768px) {
      width: ${props => props.edit && "100%"};
    }
    & > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: ${props => (props.edit ? "0" : "0px 28px")};
      @media (max-width: 480px) {
        padding: 0 17px;
      }
    }
  }
  .go_back {
    color: #000;
    height: 58px;
    width: 172px;
    background: unset;
    padding: 10px 11px;
    color: rgb(37, 56, 88);
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 600;
    gap: 3px;
    cursor: pointer;

    @media (max-width: 480px) {
      background: rgb(239, 243, 245);
      color: rgb(70, 86, 113);
      font-size: 13px;
      height: 40px;
      max-width: 120px;
      width: 100%;
      padding: 0;
    }
  }
  .back_btn {
    height: 58px;
    width: 100%;
    max-width: 172px;
    font-size: 20px;
    font-weight: 400;

    @media (max-width: 480px) {
      font-size: 13px;
      height: 40px;
      width: 100%;
      max-width: 120px;
      padding: 5px 11px;
      font-weight: normal;
    }
  }
  .back_button {
    width: 8rem;
    padding: 0.8rem 0px;
    border-radius: 0.5rem;
    background-color: rgb(243, 244, 246);
    font-weight: 600;
  }
  .label {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .expriy_edit_head {
    text-transform: capitalize;
  }
  .continue_btn {
    background-color: ${({ colors }) => colors.primary_color};
    color: ${SHADES.WHITE};
  }
`;
