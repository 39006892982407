import { FaChevronUp, FaPen } from "react-icons/fa";
import { useTheme } from "src/customHooks";
import useFormNavigation from "src/pages/ProposalPage/ProposalSections/useFormNavigation";
import { ROUNDED } from "src/styles/styleGuide";
import { getMemberName } from "src/utils/string.utility";
import styled, { keyframes } from "styled-components";

const CustomPanel = ({
  title,
  children,
  formName,
  isFilled,
  values = "",
  memberAge,
  allMembers,
  isSingleSon,
  isSingleDaughter,
  show,
  onClick,
}) => {
  const { activeForm } = useFormNavigation();
  const { colors } = useTheme();

  const PrimaryColor = colors.primary_color,
    SecondaryColor = colors.secondary_color,
    PrimaryShade = colors.primary_shade;
  let EditedName = "";
  if (formName === "Insured Details") {
    EditedName = getMemberName({
      memberCode: title,
      isSingleSon,
      isSingleDaughter,
    });
  } else {
    EditedName = allMembers
      ?.map(member =>
        getMemberName({
          memberCode: member.display_code,
        }),
      )
      .join(", ");
  }

  return (
    <>
      <StyledPanel
        aria-expanded={show}
        add={!show ? true : undefined}
        PrimaryColor={PrimaryColor}
        SecondaryColor={SecondaryColor}
        PrimaryShade={`linear-gradient(90deg, ${PrimaryShade} 0%, white 100%)`}
        isShowMedical={formName === "Medical Details"}
        onClick={onClick}
      >
        <SpanStyled>
          {EditedName}
          {formName === "Insured Details" && memberAge ? (
            <span>{`(${memberAge})`}</span>
          ) : (
            <></>
          )}
        </SpanStyled>
        {formName === "Insured Details" &&
          (show ? (
            <>
              <ChevronWrapper>
                <FaChevronUp />
              </ChevronWrapper>
            </>
          ) : !isFilled ? (
            <>
              <PencilWrapper
                primaryColor={colors.primary_color}
                primaryShade={colors.primary_shade}
              >
                {/* <Values>{values}</Values> */}
                <div className="btn  pencil_btn">
                  <FaPen />
                </div>
              </PencilWrapper>
            </>
          ) : (
            <>
              <PencilWrapper
                primaryColor={colors.primary_color}
                primaryShade={colors.primary_shade}
              >
                <Values>{values}</Values>
                <div className="btn  pencil_btn">
                  <FaPen />
                </div>
              </PencilWrapper>
            </>
          ))}
        {formName !== "Insured Details" &&
          (show ? (
            <>
              <ChevronWrapper>
                <FaChevronUp />
              </ChevronWrapper>
            </>
          ) : !isFilled ? (
            <>
              <ChevronWrapper>
                <i className="fas fa-chevron-down"></i>
              </ChevronWrapper>
            </>
          ) : (
            <>
              <PenStyled
                primaryColor={colors.primary_color}
                primaryShade={colors.primary_shade}
                className="btn "
              >
                <FaPen />
              </PenStyled>
            </>
          ))}
      </StyledPanel>
      <CollapseContainer show={show}>
        {show ? (
          <CollapseContent activeForm={activeForm}>{children}</CollapseContent>
        ) : null}
      </CollapseContainer>
    </>
  );
};

const SpanStyled = styled.span`
  padding-right: 60px;
  > span {
    text-transform: lowercase;
  }
`;

const slideIn = keyframes`
  from {
    max-height: 0;
    overflow: hidden;
  }
  to {
    max-height: 1000px;
    overflow: unset;
  }
`;

const slideOut = keyframes`
  from {
    max-height: 1000px;
    overflow: unset;
  }
  to {
    max-height: 0;
    overflow: hidden;
  }
`;

const CollapseContainer = styled.div`
  width: 100%;
  overflow: hidden;
  animation: ${({ show }) => (show ? slideIn : slideOut)} 0.7s forwards
    ease-in-out;
`;

const CollapseContent = styled.div`
  padding: 0;
  padding-top: ${({ activeForm }) => {
    return activeForm === "Medical Details" ? "36px" : "22px";
  }};
`;

const PencilWrapper = styled.div`
  right: 10px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: ${props => props.isMedical && "116px"};
  background: ${props => (props.isMedical ? "#0a87ff !important" : "")};
  height: ${props => (props.isMedical ? "46px" : "")};
  color: ${props => (props.isMedical ? "white" : "")};
  border-radius: ${props => (props.isMedical ? "50px" : "")};
  @media (max-width: 767px) {
    width: ${props => (props.isMedical ? "80px" : "46px")};
  }

  .pencil_btn {
    width: 30px;
    height: 30px;
    background: ${props => props.primaryShade} !important;
    border-radius: 100%;
    display: flex;
    color: ${props => props.primaryColor} !important;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    padding: 0;
  }

  & span {
    position: absolute;
    font-weight: 400;
    right: 60px;
    top: 50%;
    transform: translateY(-50%);
    @media (max-width: 767px) {
      right: 48px;
    }
  }
  & img {
    position: ${props => props.isMedical && "absolute"};
    right: ${props => props.isMedical && "0px"};
    top: ${props => props.isMedical && "50%"};
    ${props => props.isMedical && "transform: translateY(-50%)"};
    @media (max-width: 767px) {
      height: ${props => props.isMedical && "46px"};
    }
  }
`;

const Values = styled.div`
  background-image: linear-gradient(to right, #eff7ff 33%, #fff 60%);
  padding: 6px 20px;
  border-radius: 34px;
  width: 280px;
  font-weight: 600;
  font-size: 16px;
  color: #000;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 44px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  @media (max-width: 767px) {
    display: none;
  }
`;
const ChevronWrapper = styled.div`
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: rgb(70, 70, 70);
  font-size: 16px;
  & svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  background: #eff7ff;
  border-radius: 50%;
  right: 24px;
`;
const StyledPanel = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${props => props.isShowMedical && !props.add && "8px"};
  padding: ${props => {
    return props.isShowMedical
      ? props.add
        ? "20px 10px"
        : "10px 10px"
      : "20px 40px 22px 45px";
  }};
  margin-left: 15px;
  margin-right: 15px;
  font-size: 22px;
  background-image: ${props =>
    props.isShowMedical && !props.add && props.PrimaryShade};

  color: ${props => props.PrimaryColor} !important;
  position: relative;
  border: ${props => (!props.isShowMedical ? "1px solid #eeeff5" : "none")};
  width: 98%;
  position: relative;
  border-radius: ${ROUNDED.lg};
  box-shadow: ${props => {
    return !props.isShowMedical || props.add
      ? "0 3px 10px rgb(211 220 232 / 60%) !important;"
      : "";
  }};
  font-weight: 600;

  text-transform: capitalize;
  margin-bottom: 22px;
  & span {
    font-size: 22px;
    @media (max-width: 767px) {
      font-size: 1rem;
    }
  }
  &:after {
    ${props => props.isShowMedical && "content:''"}
    content: "";
    height: 38px;
    width: 6px;
    position: absolute;
    left: -2px;
    top: 16px;
    background-color: ${props => props.SecondaryColor};
    border-radius: 50px;
    @media (max-width: 767px) {
      top: 10px;
    }
  }
  @media (max-width: 767px) {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 20px;
    border-radius: ${ROUNDED.lg};
    font-size: 16px;
    margin-bottom: 6px;
  }
`;

const PenStyled = styled.div`
  width: 30px;
  height: 30px;
  background: ${props => props.primaryShade} !important;
  border-radius: 100%;
  display: flex;
  color: ${props => props.primaryColor};
  align-items: center;
  justify-content: center;
  font-size: 13px;
  padding: 0;
`;
export default CustomPanel;
