import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";
import {
  validatedMinAndMaxDateGenerator,
  generateAge,
  getOldDate,
} from "../../../components/FormBuilder/formUtils";
import "./Date.scss";
import NewDatePicker from "./NewDatePicker/NewDatePicker";
import { FWEIGHT } from "src/styles/styleGuide";
import CustomToolTip from "src/components/Common/CustomTooltip";
import { useGetEnquiry } from "src/customHooks/useGetCommonQueries";

const DateComp = ({
  label,
  placeholder,
  checkValidation,
  onChange,
  error,
  onKeyDown,
  value,
  readOnly,
  age = [0, 0],
  rules,
  values,
  relation,
  one_day_less = false,
  two_day_less = false,
  ageInDays = 0,
  tooltip_msg = "",
  directUpdateValue = () => {},
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const onFocus = () => setIsFocused(true);

  const [updateTitle, setUpdateTitle] = useState(true);

  const { enquiryData } = useGetEnquiry();

  useEffect(() => {
    if (
      relation?.includes("daughter") &&
      label === "Date of Birth" &&
      !relation.includes("in_law")
    ) {
      if (generateAge({ dob: values?.dob || value }) < 18) {
        directUpdateValue("title", "ms");
      } else {
        directUpdateValue("title", "");
      }
    }
    return () => {};
  }, [updateTitle]);

  const startRef = useRef();

  const getMoment = val => {
    return val?.length === 4 ? moment(val, "YYYY") : moment(val, "DD-MM-YYYY");
  };

  const { minDate, maxDate } = validatedMinAndMaxDateGenerator({
    age,
    rules,
    values,
    label,
    one_day_less,
    two_day_less,
    ageInDays,
  });

  const onKeyDownHandler = e => {
    setUpdateTitle(!updateTitle);
    if (e.keyCode === 9 || e.which === 9) {
      startRef.current.setOpen(false);
    }
    onKeyDown();
  };

  let oldVal = value;

  if (value?.length === 4) {
    value = "";
  }

  const setDateVal = date => {
    onChange({
      target: {
        value: moment(date).format("DD-MM-YYYY"),
      },
    });
  };

  useEffect(() => {
    if (
      enquiryData?.input?.port_expiry_date &&
      label === "Policy Expiry Date"
    ) {
      setDateVal(enquiryData.input.port_expiry_date);
    }
  }, []);

  return (
    <>
      <InputContainer error={!isFocused ? error : null} readOnly={readOnly}>
        <>
          <NewDatePicker
            minDate={minDate}
            maxDate={maxDate}
            value={
              value &&
              value !== "Invalid date" &&
              value !== "value" &&
              getMoment(value)
                ? getMoment(value)
                : oldVal
                ? getOldDate(oldVal, maxDate)
                : null
            }
            placeholder={placeholder}
            setValue={setDateVal}
            onKeyDown={onKeyDownHandler}
            onFocus={onFocus}
            dateFormat={value ? "DD-MM-YYYY" : "YYYY"}
            readOnly={readOnly}
            error={error}
          />
        </>
        <Label>
          <CustomToolTip
            description={tooltip_msg}
            disabled={!tooltip_msg.length}
            showInfoIcon={tooltip_msg.length}
          ></CustomToolTip>
          <LabelStyled mandatory={checkValidation?.required}>
            {label || ""}
          </LabelStyled>
        </Label>
      </InputContainer>
      {!isFocused && <p className="formbuilder__error">{error}</p>}
    </>
  );
};

export default DateComp;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.3rem !important;
  position: relative;
  & > div {
    width: 100%;
  }
  & input {
    border: ${props => {
      return props.error ? "solid 1px #c7222a" : "1px solid #d4d5d9";
    }};
    cursor: ${props => (props.readOnly ? "not-allowed" : "pointer")} !important;
    width: 100%;
    height: 100%;
    font-size: 14px;
    padding: 12px;
    outline: none;
    color: #090909;
    position: relative;

    &:focus {
      color: #000;
    }
  }
`;

const Label = styled.label`
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px !important;
  color: #000;
  position: absolute;
  left: 8px;
  top: 0;
  margin: 0;
  background: #fff;
  transition: all 0.3s ease-in-out;
  font-weight: ${FWEIGHT.semi};
  transform: translateY(-50%);
  padding: 0 4px;
`;

const LabelStyled = styled.span`
  margin-left: 2px;

  ${({ mandatory }) => {
    return mandatory ? "&::after {  content: '*'; color: red;}" : "";
  }}
`;
