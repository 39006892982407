import { useSelector } from "react-redux";
import { useFrontendBoot } from "src/customHooks/useGetCommonQueries";
import { useCart } from "src/customHooks";
import useProposalSchema from "src/customHooks/useProposalSchema";

const useKYCDetails = () => {
  const { checkKYCVerified } = useCart();
  const { subJourneyType } = useFrontendBoot();
  const { checkFormSectionExist } = useProposalSchema();

  const { redirectionUtility, canContinue } = useSelector(
    ({ proposalPage }) => proposalPage.kycSection,
  );

  const { docUploadUtility } = useSelector(({ proposalPage }) => proposalPage);

  const kycSectionExist =
    subJourneyType === "renewal" ||
    checkFormSectionExist({
      formName: "KYC Details",
    });
  const kycVerified = !kycSectionExist || checkKYCVerified();

  /* useEffect(() => {
    if (checkKYCVerified() === true) {
      toast.success("Your KYC verification successful.", {
        position: "top-center",
      });
    }
  }, []); */

  return {
    kycVerified,
    redirectionUtility,
    canContinue,
    docUploadUtility,
  };
};
export default useKYCDetails;
