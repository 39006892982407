import React from "react";
import { IoArrowForwardSharp } from "react-icons/io5";
import { useTheme } from "../customHooks";
import { Button } from "./Common/Button/ContinueButton";

const StyledButton = ({
  type,
  value,
  noIcon,
  onClick,
  children,
  customClass,
  width,
  height,
  bg,
  color,
  disabled,
  styledCss,
  btnId,
}) => {
  const { colors } = useTheme();

  return (
    <Button
      btnId={btnId}
      type={type}
      data-page="1"
      name="next"
      value={value}
      onClick={onClick}
      className={`styled__button ${customClass}`}
      width={width}
      height={height}
      bg={bg || colors.primary_color}
      color={color}
      css={styledCss}
      disabled={disabled}
    >
      {value ? value : children} {!noIcon && <IoArrowForwardSharp />}
    </Button>
  );
};

export default StyledButton;
