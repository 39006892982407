import { useState } from "react";
// REACT ICONS
import { HiChevronDown } from "react-icons/hi";
// STYLED COMPONENTS
import {
  FeatureDefinitionStyled,
  TabCardStyled,
  TabContentWrapperStyled,
  TabDescriptionStyled,
  TabHeaderStyled,
  TabToggleButtonStyled,
} from "./PlanDetails.style";

export default function FeatureDefinitionMobile({
  description,
  value,
  header,
  short_description,
}) {
  const [toggle, setToggle] = useState(false);

  return (
    <FeatureDefinitionStyled onClick={() => setToggle(!toggle)}>
      <TabCardStyled>
        <div className="info-wrapper">
          <TabHeaderStyled>
            {header}:&nbsp;{value}
          </TabHeaderStyled>
          <TabDescriptionStyled>{short_description}</TabDescriptionStyled>
        </div>
        <TabToggleButtonStyled data-tab-open={toggle}>
          <HiChevronDown />
        </TabToggleButtonStyled>
      </TabCardStyled>
      <TabContentWrapperStyled data-tab-open={toggle}>
        <div>
          <TabDescriptionStyled>{description}</TabDescriptionStyled>
        </div>
      </TabContentWrapperStyled>
    </FeatureDefinitionStyled>
  );
}
