import { useDispatch } from "react-redux";
import { useRedirectLink } from "src/customHooks";
import {
  useFrontendBoot,
  useGetEnquiry,
} from "src/customHooks/useGetCommonQueries";
import httpClient from "../../../api/httpClient";
import {
  setBasePlanTypeChangeAction,
  setJourneyChangeAction,
} from "../../../slices/utility.slice";

export default function useJourneyTypeChange() {
  const dispatch = useDispatch();

  const { enquiryData, currentGroupObject } = useGetEnquiry();
  const { generateLink } = useRedirectLink();
  const {
    data: { tenant },
  } = useFrontendBoot();
  // FUNCTION/s

  // change into port

  const changeJourneyToPort = ({ port_expiry_date }) => {
    const source =
      tenant?.frontend_domains?.find(
        domain =>
          domain?.base_url ===
          `${window.location.origin + process.env.PUBLIC_URL}`,
      )?.source || "port";

    dispatch(
      setJourneyChangeAction({ isLoading: true, newJourneyType: "Port" }),
    );
    const newEnquiryData = {
      email: enquiryData.email,
      gender: enquiryData.input.gender,
      mobile: enquiryData.mobile,
      name: enquiryData.name,
      section: "health",
      members: enquiryData.input.members,
      plan_type: enquiryData.groups[0].plan_type,
      is_pincode_search: false,
      pincode: enquiryData.input.pincode,
      params: { ...enquiryData.input.params, source },
      port_expiry_date,
      type: "port",
    };
    httpClient("enquiries", {
      method: "POST",
      data: newEnquiryData,
    }).then(data => {
      if (data.data) {
        dispatch(setBasePlanTypeChangeAction("port_plan"));
        const newEnquiryId = data?.data?.data?.enquiry_id;
        const newGroupCode = data?.data?.data?.groups[0].id;
        dispatch(
          setJourneyChangeAction({ isLoading: false, newJourneyType: "Port" }),
        );
        httpClient(
          `groups/${newGroupCode}`,
          {
            method: "PUT",
            data: {
              is_pincode_search: false,
              pincode: enquiryData?.input?.pincode,
              extras: {
                filters: {
                  ...currentGroupObject?.extras?.filters,
                  plan_type: { code: "port_plan", display_name: "Port Plan" },
                },
              },
            },
          },
          newEnquiryId,
        ).then(() => {
          generateLink(
            newEnquiryId,
            newGroupCode,
            data?.data?.data?.section,
            "port",
          );
        });
      }
    });
  };

  // Change into topup
  const changeJourneyToTopup = () => {
    let plan_type = enquiryData.groups[0].plan_type;

    if (plan_type === "M") {
      // Change plan type from multi to family when changed from base health to topup
      plan_type = "F";
    }

    dispatch(
      setJourneyChangeAction({ isLoading: true, newJourneyType: "Top-up" }),
    );
    const newEnquiryData = {
      email: enquiryData.email,
      gender: enquiryData.input.gender,
      mobile: enquiryData.mobile,
      name: enquiryData.name,
      section: "top_up",
      members: enquiryData.input.members,
      plan_type,
      deductible: 500000,
      is_pincode_search: false,
      pincode: enquiryData.input.pincode,
      params: { ...enquiryData.input.params, source: "top_up" },
    };

    httpClient("enquiries", {
      method: "POST",
      data: newEnquiryData,
    }).then(data => {
      if (data.data) {
        const newEnquiryId = data?.data?.data?.enquiry_id;
        const newGroupCode = data?.data?.data?.groups[0].id;
        dispatch(
          setJourneyChangeAction({
            isLoading: false,
            newJourneyType: "Top-up",
          }),
        );
        httpClient(
          `groups/${newGroupCode}`,
          {
            method: "PUT",
            data: {
              is_pincode_search: false,
              pincode: enquiryData?.input?.pincode,
            },
          },
          newEnquiryId,
        ).then(() => {
          generateLink(newEnquiryId, newGroupCode, data?.data?.data?.section);
        });
      }
    });
  };
  // Change into health
  const changeJourneyToHealth = () => {
    dispatch(
      setJourneyChangeAction({ isLoading: true, newJourneyType: "Health" }),
    );
    const newEnquiryData = {
      email: enquiryData.email,
      gender: enquiryData.input.gender,
      mobile: enquiryData.mobile,
      name: enquiryData.name,
      section: "health",
      members: enquiryData.input.members,
      plan_type: enquiryData.groups[0].plan_type,
      is_pincode_search: false,
      pincode: enquiryData.input.pincode,
      params: { ...enquiryData.input.params, source: "health" },
    };

    httpClient("enquiries", {
      method: "POST",
      data: newEnquiryData,
    }).then(data => {
      if (data.data) {
        const newEnquiryId = data?.data?.data?.enquiry_id;
        const newGroupCode = data?.data?.data?.groups[0].id;
        dispatch(
          setJourneyChangeAction({
            isLoading: false,
            newJourneyType: "Health",
          }),
        );
        httpClient(
          `groups/${newGroupCode}`,
          {
            method: "PUT",
            data: {
              is_pincode_search: false,
              pincode: enquiryData?.input?.pincode,
            },
          },
          newEnquiryId,
        ).then(() => {
          generateLink(newEnquiryId, newGroupCode, data?.data?.data?.section);
        });
      }
    });
  };

  return {
    changeJourneyToHealth,
    changeJourneyToTopup,
    changeJourneyToPort,
  };
}
