import { MAX } from "src/styles/responsiveStyle";
import { FWEIGHT, ROUNDED } from "src/styles/styleGuide";
import { mobile } from "src/utils/mediaQueries";
import styled from "styled-components/macro";

export const Section = styled.section`
  width: 100% !important;

  & .proposal_continue_back_margin {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem auto;

    ${MAX.md} {
      display: none;
    }
  }
  & .formbuilder__error {
    color: #c7222a;
    font-size: 12px;
    margin-bottom: 0;
  }
  & .formbuilder__warning {
    color: #ff8000;
    font-size: 12px;
    margin-bottom: 0;
  }
  & .formbuilder__success {
    display: flex;
    align-items: center;
    gap: 4px;
    color: #4bb543;
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
`;

export const MainTitle = styled.h2`
  margin-left: 3px;
  margin-bottom: ${({ verticalMargin }) => (verticalMargin ? "10px" : "unset")};
  margin-top: ${({ verticalMargin }) => (verticalMargin ? " 0px" : "unset")};
  font-weight: ${FWEIGHT.semi};
  display: flex;
  align-items: center;
  background: linear-gradient(
    90deg,
    ${({ primary_shade }) => primary_shade} 0%,
    rgb(255 255 255) 100%
  );
  color: ${({ primary_color }) => primary_color};
  font-size: 21px;
  padding: 10px;
  border-radius: ${ROUNDED.lg};
  .premium_payer_size {
    font-size: 19px;
  }
  @media (max-width: 550px) {
    font-size: 1.1rem;
  }
  & > span.premium__payer {
    font-size: 19px;
    align-self: center;
    margin-right: auto;
    margin-left: 10px;
    position: relative;
    &:hover {
      & > span:last-of-type {
        display: inline;
      }
    }
    ${mobile} {
      font-size: 15px;
    }
    & > span:first-of-type {
      margin-left: 5px;
      font-size: 14px;
    }
    & > span:last-of-type {
      display: none;
      color: #ffffff;
      background-color: #000000;
      font-size: 11px;
      width: 200px;
      letter-spacing: 0.5px;
      line-height: 1.5;
      padding: 10px 5px 10px 10px;
      font-weight: 500;
      text-align: center;
      border-radius: 10px;
      position: absolute;
      left: 107%;
      top: 50%;
      transform: translateY(-50%);
      @media (max-width: 550px) {
        font-size: 9px;
        width: 175px;
        padding: 5px;
        left: 50%;
        top: 150%;
        transform: translateX(-50%);
      }
      &::after {
        content: "";
        border: 7px solid transparent;
        border-right-color: #000000;
        position: absolute;
        right: 100%;
        top: 50%;
        transform: translateY(-50%);
        @media (max-width: 550px) {
          top: -24%;
          right: 50%;
          transform: translateX(50%);
          border: 7px solid transparent;
          border-bottom-color: #000000;
        }
      }
    }
  }
`;
