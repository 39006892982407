import { useEffect, useState } from "react";

import { envAppBaseURL } from "../utils/tenantSettings";
import { getUrlQueries } from "src/utils/url.utility";
import { useFrontendBoot } from "./useGetCommonQueries";
const useOtpVerify = details => {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(false);
  const [, setVerify] = useState(false);
  const [, setOtpValue] = useState();
  const [error, setError] = useState({ isError: false, message: "" });
  const [success, setSuccess] = useState({ isSuccess: false, message: "" });
  const urlQueryStrings = new URLSearchParams(window.location.search);

  const { tenantAlias } = useFrontendBoot();

  useEffect(() => {
    if (error.isError) {
      setTimeout(() => {
        setError({ isError: false, message: "" });
      }, 3000);
    }
    if (success.isSuccess) {
      setTimeout(() => {
        setSuccess({ isSuccess: false, message: "" });
      }, 3000);
    }
    return () => {};
  }, [error.isError, success.isSuccess]);
  useEffect(() => {
    if (details?.submitOnly) {
      return () => {};
    } else {
      const EnquiryId = urlQueryStrings.get("enquiryId");
      setLoading(true);
      if (details?.customOTPVerification) {
        let url = `${envAppBaseURL()}${details?.endpoint}`;
        fetch(url, {
          headers: {
            "Enquiry-Id": EnquiryId,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            mode: details.mode,
            payload: details.payload,
          }),
          method: "POST",
        }).then(response => {
          if (response.status === 200) {
            setSuccess({
              isSuccess: true,
              message: "OTP sent successfully!",
            });
            setStatus(true);
            setVerify(false);
            setLoading(false);
          } else {
            setError({
              isError: true,
              message: "OTP generation failed. Please try again later.",
            });
            setVerify(false);
            setLoading(false);
          }
        });
      } else {
        let url = `${envAppBaseURL()}otp_verification/${tenantAlias}/send_otp`;
        fetch(url, {
          headers: {
            "Enquiry-Id": EnquiryId,
            "Content-Type": "application/json",
          },
        }).then(response => {
          if (response.status === 200) {
            setSuccess({
              isSuccess: true,
              message: "OTP sent successfully!",
            });
            setStatus(true);
            setVerify(false);
            setLoading(false);
          } else {
            setError({
              isError: true,
              message: "OTP generation failed. Please try again later.",
            });
            setVerify(false);
            setLoading(false);
          }
        });
      }
    }
  }, []);

  const verifyOtp = otp_value => {
    const EnquiryId = urlQueryStrings.get("enquiryId");

    if (details?.customOTPVerification) {
      fetch(`${envAppBaseURL()}${details?.endpoint}`, {
        headers: {
          "Enquiry-Id": EnquiryId,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          mode: details.otp_verification_mode,
          payload: otp_value,
        }),
        method: "POST",
      })
        .then(response => {
          if (response?.error) {
            throw new Error(response?.error);
          }
          setSuccess({
            isSuccess: true,
            message: "OTP verified successfully!",
          });
          setStatus(true);
          setVerify(false);
          setLoading(false);
          details?.successCallback(response?.data);
        })
        .catch(error => {
          setError({
            isError: true,
            message: "Invalid OTP try again!",
          });
          setVerify(false);
          setLoading(false);
          details?.failureCallback(error);
        });
    } else {
      fetch(
        `${envAppBaseURL()}otp_verification/${tenantAlias}/verify_otp?otp=${otp_value}`,
        {
          headers: {
            "Enquiry-Id": EnquiryId,
            "Content-Type": "application/json",
          },
        },
      ).then(response => {
        if (response.status === 200) {
          setSuccess({
            isSuccess: true,
            message: "OTP verified successfully!",
          });
          setStatus(true);
          setVerify(false);
          setLoading(false);
        } else {
          setError({
            isError: true,
            message: "Invalid OTP try again!",
          });
          setVerify(false);
          setLoading(false);
        }
      });
    }
  };

  const resendOtp = () => {
    const urlQueryStrings = getUrlQueries();
    const EnquiryId = urlQueryStrings?.enquiryId;
    let response = undefined;
    if (details?.customOTPVerification) {
      fetch(`${envAppBaseURL()}${details?.endpoint}`, {
        headers: {
          "Enquiry-Id": EnquiryId,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          mode: details?.mode,
          payload: details.payload,
        }),
        method: "POST",
      }).then(res => {
        return res.status === 200 ? (response = true) : (response = false);
      });
    } else {
      fetch(`${envAppBaseURL()}otp_verification/${tenantAlias}/send_otp`, {
        headers: {
          "Enquiry-Id": EnquiryId,
          "Content-Type": "application/json",
        },
      }).then(res => {
        return res.status === 200 ? (response = true) : (response = false);
      });
    }

    return response;
  };

  return {
    status,
    loading,
    error,
    setError,
    success,
    setOtpValue,
    setVerify,
    verifyOtp,
    resendOtp,
  };
};

export default useOtpVerify;
