import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
// CUSTOM HOOKS
import {
  useCart,
  usePortJourneyConfig,
  useUpdateEnquiry,
} from "src/customHooks";
import {
  useFrontendBoot,
  useGetEnquiry,
} from "src/customHooks/useGetCommonQueries";
// UTILS
import { setPosPopup } from "src/pages/NewQuotePage/QuotePageSlice/quote.slice";
import { getSumInsuredOptions } from "src/pages/SeeDetailsModal/components/SeeDetailsHeader/DesktopSeeDetailsHeader";
import {
  amount,
  numToLakh,
  removeHtmlTagsFromString,
} from "src/utils/string.utility";
// CUSTOM COMPONENTS
import QuoteCardSelect from "src/pages/quotePage/components/QuoteCard/QuoteCardSelect";
import useIsPos from "src/pos/useIsPos";
import { CartDetailRow, plantypes } from ".";
import Members from "./Members";
import { ROUNDED } from "src/styles/styleGuide";
import { brokerTenant } from "src/utils/tenantSettings";
import { useUpdatePosCoverMutation } from "src/api/api";
import useInfoPopup from "src/components/Common/Popups/InfoPopup/useInfoPopup";

export default function BasePlanDetails({
  groupCode: gp,
  revisedPremium = false,
  ...props
}) {
  const { isPosJourney } = useIsPos();
  const tempGp = +useParams()?.groupCode;

  const groupCode = +(gp || tempGp);

  const { getCartEntry, updateCart, updateCartEntry } = useCart();
  const [{ isLoading: isCartLoading }] = updateCart(groupCode);

  const {
    journeyType,
    subJourneyType,
    data: {
      settings: { pos_nonpos_switch_message, restrict_posp_quotes_after_limit },
    },
  } = useFrontendBoot();

  const { handleOpenInfoPopup } = useInfoPopup();

  const cartEntry = getCartEntry(parseInt(groupCode));
  const { enquiryData } = useGetEnquiry();

  const { updateEnquiry, isLoading: isEnquiryLoading } = useUpdateEnquiry();
  const [updates] = useUpdatePosCoverMutation();

  const dispatch = useDispatch();

  const {
    plantype,
    sum_insured,
    deductible,
    tenure,
    premium,
    available_sum_insureds,
    unavailable_message,
    product = {},
    group,
  } = cartEntry;

  const { data } = useGetEnquiry();

  const policy_number = data?.data?.renewal_policy?.prev_policy_no || "";

  const isUnavailable = !!unavailable_message;

  const { isPortPlan } = usePortJourneyConfig();

  const displayPolicyTerm = `${
    tenure + " " + (tenure >= 2 ? "Years" : "Year")
  } `;

  let options = getSumInsuredOptions(available_sum_insureds);

  if (isPosJourney && restrict_posp_quotes_after_limit === `${1}`) {
    options = options.filter(si => si.value <= 500000);
  }

  const handleChange = async option => {
    if (isPosJourney && option.value > 500000) {
      if (brokerTenant("hero")) {
        const res = await updates({ trace_id: enquiryData?.trace_id });
        if (res?.data?.status) {
          localStorage.removeItem("SSO_user");
          localStorage.removeItem("Pos_details");
          await updateEnquiry({
            params: {
              journeyType: "B2C",
              journey_initiated_from_url: `${
                window.location.origin + process.env.PUBLIC_URL
              }`,
              source: journeyType,
            },
          });
          handleOpenInfoPopup({
            title: "",
            description:
              res?.data?.message_for_popup ||
              removeHtmlTagsFromString(pos_nonpos_switch_message),
            hideCloseButton: true,
            buttonText: "OK",
            onClick: () => {
              updateCartEntry(group?.id, { sum_insured: option?.value });
            },
          });
        } else {
          handleOpenInfoPopup({
            title: "",
            description:
              res?.data?.message_for_popup ||
              "Unable to Fetch Data. Please contact your Administrator",
            hideCloseButton: false,
            buttonText: "OK",
          });
        }
        return;
      } else if (pos_nonpos_switch_message) {
        dispatch(setPosPopup(true));
      }
    }
    updateCartEntry(group?.id, { sum_insured: option?.value });
  };

  const coverList = (
    <QuoteCardSelect
      color="#000"
      fontSize={11}
      options={options}
      value={{
        value: sum_insured,
        label: `₹${numToLakh(sum_insured)}`,
      }}
      defaultValue={{
        value: sum_insured,
        label: `₹${numToLakh(sum_insured)}`,
      }}
      onChange={handleChange}
    />
  );

  const planTypeValue = () => {
    switch (true) {
      case isPortPlan(groupCode): {
        return "Port";
      }
      case journeyType === "health": {
        return "Base Health";
      }
      case journeyType === "top_up": {
        return product?.company?.alias === "liberty_general"
          ? "Super Top-Up"
          : process.env.REACT_APP_TOPUP_TITLE || "Top Up";
      }
      default: {
        return "";
      }
    }
  };

  return (
    <BasePlanDetailsContainerStyled {...props}>
      <BasePlanInfoWrapperStyled
        className={revisedPremium ? "revisedPopupStyle" : ""}
      >
        {revisedPremium ? (
          <Members groupCode={groupCode} editable={false} />
        ) : null}
      </BasePlanInfoWrapperStyled>
      {!isUnavailable ? (
        <CartDetailWrapper>
          <CartDetailRow title="Policy Type" value={plantypes[plantype]} />
          {plantype ? (
            <CartDetailRow title="Plan Type" value={planTypeValue()} />
          ) : (
            <></>
          )}
          {journeyType === "top_up" && +deductible ? (
            <CartDetailRow
              title="Deductible"
              value={`₹ ${numToLakh(deductible)}`}
            />
          ) : null}

          {sum_insured &&
            (isCartLoading || isEnquiryLoading ? (
              <CartDetailRow title="Cover" value={<CoverDropdownLoader />} />
            ) : (
              <CartDetailRow
                title="Cover"
                value={
                  !options ||
                  !options?.length ||
                  subJourneyType === "renewal" ||
                  revisedPremium ||
                  options?.length === 1
                    ? `₹ ${numToLakh(sum_insured)}`
                    : coverList
                }
              />
            ))}

          <CartDetailRow title="Policy Term" value={displayPolicyTerm} />
          {!revisedPremium ? (
            <CartDetailRow
              title={subJourneyType === "renewal" ? "Net Premium" : "Premium"}
              value={
                <PremiumAmountText revisedPremium={revisedPremium}>
                  {amount(premium)}
                </PremiumAmountText>
              }
            />
          ) : null}
          {subJourneyType === "renewal" && policy_number ? (
            <CartDetailRow title="Policy Number" value={policy_number} />
          ) : (
            <></>
          )}
        </CartDetailWrapper>
      ) : null}
    </BasePlanDetailsContainerStyled>
  );
}

const BasePlanDetailsContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .revisedPopupStyle {
    align-items: center;
    gap: 1.5rem;
    flex-direction: row;
  }
`;

const BasePlanInfoWrapperStyled = styled.div`
  display: flex;
  justify-content: start;
  flex-direction: column;
  gap: 0.5rem;
  margin: 12px 0px;
  & .logo_with_seedetails {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const SeeDetailsBtnStyled = styled.button`
  display: flex;
  align-items: center;
  gap: 3px;
  color: ${props => props.primaryColor};
`;

const CartDetailWrapper = styled.div`
  margin-top: 0px;
`;

const PremiumAmountText = styled.span`
  text-decoration: ${props => (props.revisedPremium ? "line-through" : "none")};
`;

const CoverDropdownLoader = styled.div`
  width: 3.75rem;
  height: 1rem;
  border-radius: ${ROUNDED.base};
  float: right;

  background: linear-gradient(
      90deg,
      hsla(0, 0%, 100%, 0),
      hsla(0, 0%, 100%, 0.5) 50%,
      hsla(0, 0%, 100%, 0) 80%
    ),
    #f1f1f1;
  background-repeat: repeat-y;
  background-size: 50px 500px;
  background-position: 0 0;
  animation: Animation 1.5s ease-in-out alternate infinite;

  @keyframes Animation {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 100% 100%;
    }
  }
`;
