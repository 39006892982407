import { useHistory } from "react-router-dom";
import {
  useFrontendBoot,
  useGetEnquiry,
} from "src/customHooks/useGetCommonQueries";
import useIsPos from "src/pos/useIsPos";
import { getUrlQueries } from "src/utils/url.utility";

export default function useInputNavigation() {
  const { settings } = useFrontendBoot();
  const { groups } = useGetEnquiry();
  const history = useHistory();
  const { enquiryId } = getUrlQueries();
  const { isPosJourney } = useIsPos();

  const {
    medical_history_visibility,
    filter_quotes_visibility,
    filter_quotes_visibility_pos,
  } = settings || {};

  // Input Form sequence
  let healthInputFormSequence = [
    "basic-details",
    "members",
    "plantype",
    "location",
  ];

  if (medical_history_visibility) {
    healthInputFormSequence.push("medicalHistory");
  }

  // pushing form conditionally as per filter quotes visibility
  if (isPosJourney) {
    if (filter_quotes_visibility_pos) {
      healthInputFormSequence.push("filterQuotes");
    }
  } else {
    if (filter_quotes_visibility) {
      healthInputFormSequence.push("filterQuotes");
    }
  }

  // FUNCTION/s

  const anyNextForm = ({ currentInputForm }) => {
    const currentIndex = healthInputFormSequence.indexOf(currentInputForm);
    const nextIndex = currentIndex + 1;
    const nextInputForm = healthInputFormSequence[nextIndex];
    return nextInputForm;
  };

  const goToNextInputForm = ({ currentInputForm, newEnquiry = "" }) => {
    const nextInputForm = anyNextForm({ currentInputForm });

    if (!nextInputForm) {
      history.push({
        pathname: `/quotes/${groups[0].id}`,
        search: `?enquiryId=${newEnquiry || enquiryId}`,
      });
      return;
    }

    history.push({
      pathname: `/input/${nextInputForm}`,
      search: `?enquiryId=${newEnquiry || enquiryId}`,
    });
  };

  return { goToNextInputForm, anyNextForm };
}
