import { Page } from "src/components/Page";
import { Main } from "./ProposalPage.style";
import MainBody from "./ProposalSections/MainBody";
import FooterSection from "./ProposalSections/components/FooterSection";
import PopupUtility from "./ProposalSections/components/PopupUtility";
import UpperUtility from "./ProposalSections/components/UpperUtility";
import GoBackButton from "src/components/GoBackButton";

const ProposalPageInner = () => {
  return (
    <>
      <Main>
        <UpperUtility />
        <MainBody />
      </Main>
      <FooterSection />
      <PopupUtility />
      {/* {seeDetailsModalOpen && <SeeDetailsModal />} */}
    </>
  );
};

const ProposalPage = () => {
  return (
    <Page id="proposalPage" backButton={<GoBackButton />}>
      <ProposalPageInner />
    </Page>
  );
};

export default ProposalPage;
