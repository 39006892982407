import { useEffect, useState } from "react";
import { getMedicalUrlsRuleEngine } from "./ProposalSections.slice";

const useMedicalQuestions = ({
  schema,
  values,
  setValues,
  name,
  proposalData,
  defaultValue,
  dispatch,
  isVersionRuleEngine,
  medicalUrlsRuleEngine,
  medicalUnderwritingLetters,
}) => {
  let selectedMedical = {};

  Object.keys(values || {}).forEach(element => {
    let count = 0;
    let maxIndex = 0;
    Object.keys(values[element]).forEach(elem => {
      if (values?.[element]?.[elem]?.["is" + elem] === "N") {
        count++;
      }
      maxIndex++;
    });
    count === maxIndex
      ? (selectedMedical[element] = true)
      : (selectedMedical[element] = false);
  });

  //! Three possible values
  //? true : user checked on no for all checkbox.
  //? false : user un-checked on no for all checkbox.
  //? 0 : user marked the medical question as yes.
  const [noForAll, setNoForAll] = useState({});

  const [preparingMQ, setPreparingMQ] = useState(false);

  const [yesSelected, setYesSelected] = useState({});

  const [canProceed, setCanProceed] = useState({
    canProceed: false,
    canProceedArray: {},
  });

  const noForAllHelper = groupKey => {
    let tempGroupVal = {};
    schema[groupKey].forEach(el => {
      if (!Array.isArray(el)) {
        if (el?.additionalOptions?.default_select === "Y") {
          tempGroupVal[el?.name] = {
            [`is${el?.name}`]: "Y",
            members: {},
            isValid: true,
          };
        } else if (el?.additionalOptions?.notAllowedIf === "N") {
          tempGroupVal[el?.name] = {
            [`is${el?.name}`]: "",
            members: {},
            isValid: false,
          };
        } else if (!el?.additionalOptions?.disable_Toggle) {
          tempGroupVal[el?.name] = {
            [`is${el?.name}`]: "N",
            members: {},
            isValid: true,
          };
        }
      }
    });
    return tempGroupVal;
  };

  const checkCanProceed = () => {
    const key = Object.keys(values || {});
    const key2 = Object.keys(noForAll || {});

    if (key.length !== key2.length) {
      let noForAll2 = {};
      Object.keys(values || {}).forEach(element => {
        noForAll2[element] = noForAll[element] || 0;
      });

      setNoForAll({ ...noForAll2 });
    } else {
      let isNotChecked = {};
      let hasYes = {};
      let checkCanProceed = {};

      key2.forEach(item => {
        if (typeof values[item] === "object" && values[item]) {
          if (noForAll[item] !== true) {
            isNotChecked[item] = false;
          } else {
            isNotChecked[item] = true;
          }
          const temp = Object.keys(values[item])?.some(
            data => values?.[item]?.[data]?.[`is${data}`] === "Y",
          );
          if (temp === true) {
            hasYes[item] = true;
          } else {
            hasYes[item] = false;
          }
        } else {
          hasYes[item] = true;
        }
      });

      key.forEach(item => {
        if (hasYes[item] === isNotChecked[item]) {
          checkCanProceed[item] = checkCanProceed[item]
            ? checkCanProceed[item]
            : [];
        }

        Object.keys(values[item]).length &&
          Object.keys(values[item])?.forEach(el => {
            let schemaOfEl = schema[key]?.find(({ name }) => name === el);

            if (schemaOfEl) {
              if (
                values[item][el] &&
                ((!values[item][el][`is${el}`] &&
                  !schemaOfEl?.additionalOptions?.disable_Toggle) ||
                  !values[item][el].isValid)
              )
                checkCanProceed[item] = Array.isArray(checkCanProceed[item])
                  ? [...checkCanProceed[item], el]
                  : [el];
            }
          });
      });

      if (key2.length < 1) {
        isNotChecked = true;
      }

      if (!Object.values(checkCanProceed).some(el => el.length)) {
        setCanProceed({ canProceed: true, canProceedArray: {} });
      } else {
        setCanProceed({
          canProceed: false,
          checkCanProceed,
        });
      }
    }
  };

  const getSchemaOfValue = (key, mqname) => {
    return schema[key]?.find(({ name }) => name === mqname);
  };

  const getMUStatus = member => {
    const matchedMember = Object.keys(medicalUnderwritingLetters)?.find(
      memberName => memberName === member,
    );
    return medicalUnderwritingLetters?.[matchedMember]?.result;
  };

  // -----------------------------------------------------------------------------------------------------------------
  //   -----------------------------  SIDE EFFECTS FOR MEDICAL QUESTIONS---------------------------------------------
  // ----------------------------------------------------------------------------------------------------------------

  useEffect(() => {
    if (name === "Medical Details") {
      if (defaultValue) {
        setValues(defaultValue);
      }
      let ruleEngineGroup = Object.keys(schema).filter(group =>
        isVersionRuleEngine(parseInt(group)),
      );
      if (ruleEngineGroup.length) {
        // if rule engine is true then set other MQ noForAll
        ruleEngineGroup.forEach(group =>
          setValues(prev => ({ ...prev, [group]: noForAllHelper(group) })),
        );

        if (!medicalUrlsRuleEngine) {
          setPreparingMQ(true);
          dispatch(
            getMedicalUrlsRuleEngine(() => {
              setPreparingMQ(false);
            }),
          );
        }
      }
    }
    return () => {};
  }, []);

  useEffect(() => {
    if (name === "Medical Details") {
      const key = Object.keys(values || {});
      let tempObj = JSON.parse(JSON.stringify(values || {}));
      key.forEach(keyValue => {
        schema?.[keyValue]?.forEach(element => {
          if (
            element?.populate &&
            tempObj[keyValue][element.populate.split("/")[0].split("=")[0]] ===
              element.populate.split("/")[0].split("=")[1] &&
            tempObj[keyValue][element.name] !==
              proposalData[element.populate.split("/")[1].split(".")[0]][
                element.populate.split("/")[1].split(".")[1]
              ]
          ) {
            tempObj[keyValue][element.name] =
              proposalData[element.populate.split("/")[1].split(".")[0]][
                element.populate.split("/")[1].split(".")[1]
              ];
          }
        });
      });
      if (JSON.stringify(values) !== JSON.stringify(tempObj)) {
        setValues({ ...tempObj });
      }
    }
    return () => {};
  }, [values]);

  useEffect(() => {
    if (name === "Medical Details") {
      checkCanProceed();

      const keys = Object.keys(values || {});

      let temp = keys.reduce((acc, key) => {
        const filterItems = Object.keys(values[key]).filter(
          el =>
            (!getSchemaOfValue(key, el)?.additionalOptions?.disable_Toggle &&
              !getSchemaOfValue(key, el)?.additionalOptions?.notAllowedIf) ||
            getSchemaOfValue(key, el)?.additionalOptions?.default_select,
        );

        const filteredBoolean = filterItems.some(el => {
          if (el === "royal_sundaram_renewal_medicalQuestion4") {
            return;
          }
          if (el === "kotak_mahindra_question_0_0") {
            return values[key][el][`is${el}`] === "N";
          }
          return values[key][el][`is${el}`] === "Y";
        });

        return {
          ...acc,
          [key]: filteredBoolean,
        };
      }, {});
      setYesSelected(temp);
    }
    return () => {};
  }, [values, noForAll]);

  return {
    noForAll,
    setNoForAll,
    checkCanProceed,
    canProceed,
    yesSelected,
    preparingMQ,
    getMUStatus,
  };
};

export default useMedicalQuestions;
