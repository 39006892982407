import { useState } from "react";
import { regexStringToRegex } from "src/utils/helper";

const useOTPTextField = ({ asyncValidate, value = "" }) => {
  const generateStatus = () => {
    if (value) {
      return value === "failure" ? "failure" : "success";
    } else {
      return "unverified";
    }
  };
  const [input, setInput] = useState(value || "");
  const [focused, setFocused] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [status, setStatus] = useState(generateStatus);
  const [warningText, setWarningText] = useState("");

  const toggleModal = () => setShowModal(current => !current);

  const validationObject = {
    regex: ({ param, value }) => {
      const parsedRegex = regexStringToRegex(param);
      if (parsedRegex.test(value)) {
        return;
      } else {
        return "Enter a valid value.";
      }
    },
    required: ({ param, value }) => {
      if (param) {
        if (value) {
          return;
        } else {
          return "This field is required.";
        }
      } else {
        return;
      }
    },
  };
  const generateError = ({ asyncValidate, value = "" }) => {
    if (asyncValidate) {
      const checkKeys = Object.keys(asyncValidate);
      const errors = checkKeys.map(key =>
        validationObject?.[key]?.({
          value,
          param: asyncValidate?.[key],
        }),
      );
      const pureErrors = errors.filter(error => error);
      return pureErrors;
    } else {
      return [];
    }
  };

  const inputErrorUtility = {
    all: generateError({ asyncValidate, value: input }),
    toShow: generateError({ asyncValidate, value: input }).find(
      validError => validError,
    ),
    exist: generateError({ asyncValidate, value: input })?.length && focused,
  };

  return {
    input,
    showModal,
    status,
    warningText,
    setInput,
    setFocused,
    setStatus,
    setWarningText,
    toggleModal,
    inputErrorUtility,
  };
};
export default useOTPTextField;
