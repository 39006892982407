import styled from "styled-components";
// DESIGN SYSTEM
import { VIEW } from "src/styles/responsiveStyle";
import {
  FLEX_ROW,
  FLEX_ROW_CENTER,
  ROUNDED,
  SHADES,
  SHADOW,
} from "src/styles/styleGuide";

const LoadingErrorWrapperStyled = styled.div`
  ${FLEX_ROW_CENTER}
  border: 1px solid ${SHADES.LIGHT_GRAY};
  border-radius: ${ROUNDED.md};
  box-shadow: ${SHADOW.sm};
  padding: 1rem;
  gap: 1rem;
  p {
    margin: 0;
  }
  button {
    ${FLEX_ROW_CENTER}
    gap:0.5rem;
    padding: 0.75rem 1.125rem;
    color: #fff;
    border-radius: ${ROUNDED.full};
    background: ${({ primary_color }) => primary_color};
  }
`;

const RiderCardWrapperStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1rem;

  .rider-column {
    display: flex;
    flex-flow: column nowrap;
    gap: 1rem;
    & > div {
      width: 100%;
    }
  }

  ${VIEW.md} {
    grid-gap: 1.5rem;
    grid-template-columns: repeat(2, 1fr);
  }
`;

const RiderSkeletonLoaderStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1.25rem;
  ${VIEW.md} {
    grid-gap: 1.5rem;
    grid-template-columns: repeat(2, 1fr);
  }
  .rider-card {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: ${ROUNDED.md};
    border: 1px solid ${SHADES.LIGHT_GRAY};
    .rider-info-and-option {
      display: flex;
      flex-direction: column;
      padding: 0.75rem;
      gap: 1rem;
      width: 100%;
      .rider-name {
        width: 6rem;
        height: 0.75rem;
        border-radius: ${ROUNDED.full};
        ${VIEW.md} {
          height: 1.125rem;
        }
      }
      .rider-description {
        height: 2.5rem;
        border-radius: ${ROUNDED.md};
        ${VIEW.md} {
          height: 3rem;
        }
      }
      .rider-options-wrapper {
        ${FLEX_ROW}
        justify-content: space-between;
        align-items: center;
        gap: 0.5rem;
        .rider-option {
          width: 6.25rem;
          height: 2rem;
          border-radius: ${ROUNDED.md};
          ${VIEW.md} {
            height: 2.25rem;
          }
        }
      }
    }
    .rider-premium-button {
      padding: 0 0.7rem;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      background: ${SHADES.WHITE};
      .checkbox {
        width: 1rem;
        height: 1rem;
        cursor: pointer;
        background-color: #fff;
        border: 1px solid ${SHADES.LIGHT_GRAY};
      }
    }
    .rider-name,
    .rider-description,
    .rider-option,
    .rider-premium {
      background: linear-gradient(
          90deg,
          hsla(0, 0%, 100%, 0),
          hsla(0, 0%, 100%, 0.5) 50%,
          hsla(0, 0%, 100%, 0) 80%
        ),
        #f1f1f1;
      background-repeat: repeat-y;
      background-size: 50px 500px;
      background-position: 0 0;
      animation: Animation 1.5s ease-in-out alternate infinite;
    }

    @keyframes Animation {
      0% {
        background-position: 0 0;
      }
      100% {
        background-position: 100% 100%;
      }
    }
  }
`;

export {
  LoadingErrorWrapperStyled,
  RiderCardWrapperStyled,
  RiderSkeletonLoaderStyled,
};
