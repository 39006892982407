import { useDispatch } from "react-redux";
//  CUSTOM HOOKS
import { api, useGetCartQuery } from "src/api/api";
import { useCart, useQuotesCompare, useUpdateEnquiry } from "src/customHooks";
import { useGetEnquiry } from "src/customHooks/useGetCommonQueries";
import useGetRouteMatch from "src/customHooks/useGetRouteMatch";
import usePaymentsOverallUtility from "src/pages/ProposalSummary/hooks/usePaymentsOverallUtility";
// UTILS
import {
  setActiveIndex,
  setRetryKYCSection,
  setShowRedoKYCPopup,
} from "src/pages/ProposalPage/ProposalSections/ProposalSections.slice";

export default function useGoBackButton() {
  let additionalAction = () => {};

  const {
    isShortlistRoute,
    isCompareRoute,
    isProductDetailsRoute,
    isProposalSummaryRoute,
    isProposalRoute,
  } = useGetRouteMatch();
  const dispatch = useDispatch();
  const { groupCode } = useGetEnquiry();

  // compare page
  const { reset } = useQuotesCompare();
  // product details page
  const { getPreviousGroupProduct } = useCart();
  const { updateEnquiry } = useUpdateEnquiry();
  // proposal page
  let { cartEntries } = useCart();

  const { updateEnquiryOnGoBack = () => {} } = usePaymentsOverallUtility();
  const { refetch: cartRefetch } = useGetCartQuery();
  const companyList = cartEntries?.map(entry => entry?.product?.company?.name);
  //? remove redundant company names
  const finalCompanies = companyList?.filter(
    (item, index) => companyList.indexOf(item) === index,
  );

  if (isShortlistRoute) {
    additionalAction = () => {
      dispatch(api.util.invalidateTags(["shortlist"])); // clearing cache to improve performance
    };
  }

  if (isCompareRoute) {
    additionalAction = reset;
  }

  if (isProductDetailsRoute) {
    const prevGroupProduct = getPreviousGroupProduct(parseInt(groupCode));
    // handleEnquiryStageChange
    additionalAction = () => {
      if (!prevGroupProduct) {
        updateEnquiry({
          lead_stage: "lead_created",
        });
      }
    };
  }

  if (isProposalRoute) {
    additionalAction = () => {
      updateEnquiry({
        lead_stage: "cart_items_added",
      });
    };
  }

  if (isProposalSummaryRoute) {
    additionalAction = () => {
      updateEnquiryOnGoBack();
      dispatch(setActiveIndex(0));
      if (
        finalCompanies?.includes("Star Health & Allied Insurance") ||
        finalCompanies?.includes("Bajaj Allianz General Insurance")
      ) {
        cartRefetch();
        dispatch(setShowRedoKYCPopup(true));
        dispatch(setRetryKYCSection(true));
      }
    };
  }

  return additionalAction;
}
