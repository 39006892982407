import { useSelector } from "react-redux";
import FormRenderer from "./Components/FormRenderer";
import Modal from "../Common/Popups/ReusableModal/Modal";

const FormRenderingModal = ({
  onClose = () => {},
  title = "",
  onFormSubmit = () => {},
  formSubmitQuery = {},
}) => {
  const { formRenderingModal } = useSelector(state => state.proposalPage);
  return (
    <Modal
      isModalOpen={formRenderingModal?.visible}
      width="fitcontent"
      hideCloseBtn={true}
      title={title}
      height="fit-content"
    >
      <FormRenderer
        onFormSubmit={onFormSubmit}
        onClose={onClose}
        formSubmitQuery={formSubmitQuery}
      />
    </Modal>
  );
};
export default FormRenderingModal;
