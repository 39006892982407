import React from "react";
import { FaTimes } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useMembers, useTheme } from "src/customHooks";
import useUrlQuery from "src/customHooks/useUrlQuery";
import { setShowPlanNotAvail } from "../../ProposalSections.slice";
import {
  ButtonWrapper,
  Container,
  Popup,
  PopupWrapper,
  ViewQuotesWrapper,
} from "./index.style";

const PlanUnavailable = () => {
  const { showPlanNotAvail } = useSelector(state => state.proposalPage);

  const history = useHistory();

  const urlQuery = useUrlQuery();

  const { groups = [] } = useMembers();

  const enquiryId = urlQuery.get("enquiryId");

  const { colors } = useTheme();

  const member = groups[0]?.id;

  const dispatch = useDispatch();

  if (!showPlanNotAvail) return <></>;

  if (showPlanNotAvail)
    return (
      <PopupWrapper>
        <Popup>
          <FaTimes
            className="iconstyle"
            onClick={() => {
              dispatch(setShowPlanNotAvail(false));
            }}
          />
          <Container>
            <span>Plan Unavailable</span>
            <p>
              Based on your medical declaration, this plan is not valid for you,
              Please choose a different plan/insurer
            </p>
            <ButtonWrapper color={colors.primary_color}>
              <button
                onClick={() => {
                  dispatch(setShowPlanNotAvail(false));
                }}
              >
                OK
              </button>
            </ButtonWrapper>
            <ViewQuotesWrapper>
              <button
                onClick={() => {
                  history.push(`/quotes/${member}?enquiryId=${enquiryId}`);
                }}
              >
                View Quotes {">"}
              </button>
            </ViewQuotesWrapper>
          </Container>
        </Popup>
      </PopupWrapper>
    );
};

export default PlanUnavailable;
