import React, { useEffect } from "react";
import styled from "styled-components/macro";

const Timer = ({ seconds, setSeconds, minutes, setMinutes, customCss }) => {
  //   const [minutes, setMinutes] = useState(0);
  //  const [seconds, setSeconds] = useState(initialSeconds);
  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  return (
    <TimerWrapperStyled>
      {minutes === 0 && seconds === 0 ? null : (
        <TimerText customCss={customCss}>
          {" "}
          {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
        </TimerText>
      )}
    </TimerWrapperStyled>
  );
};

const TimerText = styled.p`
  font-size: 15px;
  color: #444444;
  margin-bottom: 0;
  ${prop => prop.customCss}
`;

const TimerWrapperStyled = styled.div`
        position : relative;
        font-weight : 900;
        width : 100%;
        height : 100%;
        display : flex ;
        justify-content: center ;
        align-items : center;
`

export default Timer;
