import ProposalCheckBox from "../../../components/Common/ProposalSummary/summaryCheckBox";
import CustomMedicalTable from "./CustomMedicalTable";
import DropDown from "./DropDown";
import Height from "./Height";
import OtherTable from "./OtherTable";
import TextInput from "./TextInput";
import Title from "./Title";
import Toggle from "./Toggle";
import DateComp from "./Date";
import CustomCheckBox from "./customCheckBox";
import RadioButtons from "./RadioButtons";
import CheckBoxGroup from "./CheckBoxGroup";
import BtnGroup from "./BtnGroup";
import TextGroup from "./TextGroup";
import FileInput2 from "./FileInput2/FileInput2";
import QuestionedDropdown from "./QuestionedDropdown";
import QuestionedMedicalTable from "./QuestionedMedicalTable";
import SearchableDropDown from "./SearchableDropDown";
import SearchableHeight from "./SearchableHeight";
import CustomAccordion from "./CustomAccordion";
import MultiSelectText from "./MultiSelectText";
import Confirmation from "./Confirmation";
import Link from "./Link";
import AsyncTextField from "./AsyncTextField/AsyncTextField";
import OTPTextField from "./OTPTextField/OTPTextField";
import AsyncButton from "./AsyncButton/AsyncButton";
import LastNameCheckBox from "./LastNameCheckBox";
import GroupTabs from "./Tabs";
import MultiSelectDropdown from "./MultiSelectDropdown";
import MultiSelectMedicalDropdown from "./MultiSelectMedicalDropdown";
import Label from "./Label";
import AddField from "./AddField";
import { HR } from "src/components/FormBuilder/FormBuilder";

export const components = {
  text: TextInput,
  select: DropDown,
  searchable_select: SearchableDropDown,
  searchable_height: SearchableHeight,
  custom_toggle: Toggle,
  custom_medical: CustomMedicalTable,
  custom_radio: RadioButtons,
  checkBox: ProposalCheckBox,
  checkBox2: CustomCheckBox,
  lastNameCheckbox: LastNameCheckBox,
  title: Title,
  custom_table: OtherTable,
  custom_height: Height,
  date: DateComp,
  checkboxGroup: CheckBoxGroup,
  btn_group: BtnGroup,
  members_text_group: TextGroup,
  file: FileInput2,
  questioned_select: QuestionedDropdown,
  questioned_custom_medical: QuestionedMedicalTable,
  multi_select: MultiSelectMedicalDropdown,
  multi_value_dropdown: MultiSelectDropdown,
  custom_accordion: CustomAccordion,
  multi_select_text: MultiSelectText,
  async_text_field: AsyncTextField,
  confirmation: Confirmation,
  otp_text_field: OTPTextField,
  link: Link,
  tabs: GroupTabs,
  async_button: AsyncButton,
  label: Label,
  add_field: AddField,
  separator: HR,
};
