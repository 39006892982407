import { useParams } from "react-router-dom";
// REACT ICONS
import { RiSearchLine } from "react-icons/ri";
// CUSTOM HOOKS
import { useTheme } from "src/customHooks";
import { useGetEnquiry } from "src/customHooks/useGetCommonQueries";
// STYLED COMPONENTS
import {
  CurrentCityStyled,
  SearchBarOuter,
  SearchBarWrapperStyled,
  SearchInput,
} from "./CashlessHospitals.style";

export default function HospitalSearchBar({ searchText, setSearchText }) {
  const { colors } = useTheme();

  const searchInputChangeHandler = e => {
    setSearchText(e.target.value);
  };
  const data = useGetEnquiry();
  const { groupCode } = useParams();
  const currentCity = data?.data?.data?.groups.find(
    singleGroup => singleGroup.id === +groupCode,
  )?.city;

  return (
    <SearchBarWrapperStyled>
      <CurrentCityStyled colors={colors}>{currentCity}</CurrentCityStyled>
      <SearchBarOuter>
        <SearchInput
          type="text"
          onChange={searchInputChangeHandler}
          placeholder="Search Hospitals"
          value={searchText}
        />
        <RiSearchLine
          size={window.innerWidth < 470 ? "26px" : "28px"}
          color="#777777"
        />
      </SearchBarOuter>
    </SearchBarWrapperStyled>
  );
}
