import { useForm } from "react-hook-form";
import { Button } from "src/components/Common/Button/ContinueButton";
import { useMembers } from "src/customHooks";
import { capitalize } from "src/utils/helper";
import {
  Box,
  Error,
  FlexEndBox,
  Label,
  OTPInput,
} from "../GroupOTPVerificationModal.style";
const OTPVerificationForm = ({
  onVerify = () => {},
  onClose = () => {},
  otpVerificationQuery = {},
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { groups } = useMembers();

  const partitionedGroups = groups.filter(
    group => group?.type === "partitioned",
  );

  return (
    <form onSubmit={handleSubmit(onVerify)}>
      {partitionedGroups.map(group => {
        const name = `otp_input_${group?.id}`;
        return (
          <Box key={group?.id}>
            <Label>{capitalize(group?.members?.join(", "))}</Label>
            <OTPInput
              type="tel"
              name={name}
              inputmode="tel"
              placeholder="* * * * * *"
              {...register(name, {
                required: {
                  value: true,
                  message: "Please enter the OTP.",
                },
                pattern: {
                  value: /^[0-9]{6}$/,
                  message: "Invalid OTP.",
                },
              })}
            />
            {errors?.[name]?.message ? (
              <Error>{errors?.[name]?.message}</Error>
            ) : (
              <></>
            )}
          </Box>
        );
      })}
      <FlexEndBox>
        <Button type="submit" loader={otpVerificationQuery?.isLoading}>
          Verify
        </Button>
        <Button
          type="button"
          onClick={onClose}
          disabled={otpVerificationQuery?.isLoading}
        >
          Cancel
        </Button>
      </FlexEndBox>
    </form>
  );
};
export default OTPVerificationForm;
