import styled from "styled-components";

import { Button } from "src/components/Common/Button/ContinueButton";
import FHTypography from "src/styles/Typography";
import useGetRouteMatch from "src/customHooks/useGetRouteMatch";
import MembersList from "../components/MembersList";
import { VIEW } from "src/styles/responsiveStyle";
import { ROUNDED } from "src/styles/styleGuide";

function EditMemberStep({
  handleAddMemberModal,
  allMembersList,
  removeMemberModal,
  selectedMembersModal,
  membersErrorModal,
  memberErrorMessageModal,
  handleApplyMembersEdit,
  handleSubmitMembersModal,
  createEnquiryQueryResponse,
  updateMemberQueryResponse,
  handleMultiSelectMemberModal,
  handleUpdateMembersEdit,
  onClose,
}) {
  const { isQuoteRoute, isProductDetailsRoute } = useGetRouteMatch();

  return (
    <EditMemberStepContainerStyled
      data-is-product-details-page={!!isProductDetailsRoute}
    >
      <section className="members_modal_content">
        <MembersList
          isModalList={true}
          handleAddMember={handleAddMemberModal}
          membersList={allMembersList}
          removeMember={removeMemberModal}
          selectedMembers={selectedMembersModal}
          handleMultiSelectMember={handleMultiSelectMemberModal}
        />
      </section>
      <section className="members_modal_errorMSG">
        <div className="error_msg_text">
          {membersErrorModal && (
            <FHTypography variant="subheading1" weight="base" type="error">
              {memberErrorMessageModal}
            </FHTypography>
          )}
        </div>
        <Button
          font="h4"
          className="applyButton"
          onClick={() => {
            if (isQuoteRoute) {
              handleApplyMembersEdit({ onClose });
            } else if (isProductDetailsRoute) {
              handleUpdateMembersEdit({ onClose });
            } else {
              handleSubmitMembersModal({ onClose });
            }
          }}
          loader={
            isQuoteRoute || isProductDetailsRoute
              ? createEnquiryQueryResponse.isLoading ||
                updateMemberQueryResponse.isLoading
              : false
          }
        >
          Apply
        </Button>
      </section>
    </EditMemberStepContainerStyled>
  );
}

const EditMemberStepContainerStyled = styled.section`
  position: relative;
  padding-bottom: 3rem;

  &[data-is-product-details-page="true"] {
    padding-bottom: 0;
    .members_modal_errorMSG {
      position: relative;
    }
  }

  ${VIEW.md} {
    padding-bottom: 0;
  }
  .members_modal_content {
    padding: 1rem;
  }

  .members_modal_errorMSG {
    position: fixed;
    bottom: 0;
    width: 100%;

    ${VIEW.md} {
      position: relative;
    }
    .error_msg_text:not(:empty) {
      padding: 0 1rem;
      padding-bottom: 0.5rem;
      background-color: #fff;
    }
  }

  .applyButton {
    width: 100%;
    border-radius: 0;
    ${VIEW.md} {
      border-bottom-left-radius: ${ROUNDED.lg};
      border-bottom-right-radius: ${ROUNDED.lg};
    }
  }
`;

export default EditMemberStep;
