import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import "styled-components/macro";
import { useMembers, useTheme, useUpdateEnquiry } from "../customHooks";

import useUpdateFilters from "../pages/quotePage/components/filters/useUpdateFilters";
import { setPortabilityConfigAction } from "../slices/utility.slice";
import { getFormattedDate } from "../utils/helper";
import { Button } from "./Common/Button/ContinueButton";
import Modal from "../components/Common/Popups/ReusableModal/Modal";
import { PortDatePicker } from "src/pages/InputPage/Forms/components";
import useJourneyTypeChange from "src/pages/NewQuotePage/Hooks/useJourneyTypeChange";

const ConfigurationModel = ({
  type = "",
  onHide = () => {},
  closeModal = () => {},
}) => {
  //* closeModal() "ONLY USED TO CLOSE MODAL".
  //* onHide() "USED TO CLOSE MODAL BUT TAKING SOME FUNCTIONALITY BEFORE IT".
  return (
    <Modal
      onClose={onHide}
      isModalOpen={true}
      hideCloseBtn={true}
      height="fit-content"
      mobileHeight="fit-content"
    >
      <>
        {type === "port_expiry_date" ? (
          <PortExpiryDateForm closeModal={closeModal} />
        ) : (
          <></>
        )}
      </>
    </Modal>
  );
};

export default ConfigurationModel;

const PortExpiryDateForm = () => {
  const { groupCode } = useParams();

  const { getGroupPortExpiryDate } = useMembers();

  const [port_expiry_date, set_port_expiry_date] = useState(
    getGroupPortExpiryDate(groupCode),
  );

  const { changeJourneyToPort } = useJourneyTypeChange();

  const { query: updateFiltersQuery } = useUpdateFilters();

  const [error, setError] = useState(null);

  const { ...updateEnquiryQuery } = useUpdateEnquiry();

  const dispatch = useDispatch();

  const { colors } = useTheme();

  const theme = createTheme({
    palette: {
      primary: {
        main: colors.primary_color,
      },
      secondary: {
        main: colors.secondary_color,
      },
    },
    typography: {
      fontFamily: "Inter",
    },
  });

  const minDate = new Date(Date.now());
  minDate.setDate(minDate.getDate() - 6); // - 6 days

  const maxDate = new Date(Date.now());
  maxDate.setDate(maxDate.getDate() + 60); // + 60 days

  const handleSubmit = () => {
    dispatch(
      setPortabilityConfigAction({
        show: false,
        type: "",
        date: port_expiry_date,
      }),
    );

    changeJourneyToPort({
      port_expiry_date: getFormattedDate(port_expiry_date),
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <div
        css={`
          width: 100%;
        `}
      >
        <span
          css={`
            font-size: 20px;
            font-weight: 600;
            color: black;
            margin-bottom: 0px;
            line-height: 1.5;
          `}
        >
          Port Expiry Date
        </span>
        <br />
        <span
          css={`
            font-size: 12px;
            margin-bottom: 0px;
          `}
        >
          {`Enter your policy's expiry date.`}
        </span>
      </div>
      <div className="mt-4">
        <PortDatePicker
          value={port_expiry_date}
          setValue={set_port_expiry_date}
          setError={setError}
        />
        <div className="mt-4">
          <Button
            loaderPrimaryColor
            disabled={error || !port_expiry_date}
            onClick={handleSubmit}
            arrow
            loader={
              updateEnquiryQuery?.isLoading || updateFiltersQuery?.isLoading
            }
            css={`
              height: 58px;
              width: 100%;
              font-size: 20px;
              font-weight: 400;

              @media (max-width: 480px) {
                font-size: 13px;
                height: 40px;
                width: 100%;
                padding: 5px 11px;
                font-weight: normal;
              }
            `}
          >
            Apply
          </Button>
        </div>
      </div>
    </ThemeProvider>
  );
};
