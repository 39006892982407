import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import useFormFieldVisibility from "src/components/FormBuilder/useFormFieldVisibility";
import {
  useAdditionalDiscount,
  useCart,
  useCompanies,
  useRevisedPremiumModal,
} from "src/customHooks";
import {
  useFrontendBoot,
  useGetEnquiry,
} from "src/customHooks/useGetCommonQueries";
import useUrlQuery from "src/customHooks/useUrlQuery";
import { getTotalPremiumWithDiscount } from "src/utils/helper";
import { api } from "../../../api/api";
import { performValidations } from "../../../components/FormBuilder/formUtils";
import {
  saveProposalData,
  setFailedBmiBlockJourney,
  setFailedBmiData,
  setInsuredDetailsResponse,
  setIsLoading,
  setKYCFailed,
  setMedicalUrlsRuleEngine,
  setShowBMI,
  setShowErrorPopup,
  setUnderWritingStatus,
  submitProposalData,
} from "./ProposalSections.slice";
import useFormNavigation from "./useFormNavigation";
import useKYCDetails from "./useKYCDetails";
import { webEngageTrackProposalEvent } from "src/utils/eventTracking";
import { useEventTracking } from "src/customHooks/useEventTracking";
import toast from "react-hot-toast";

const useProposalSections = ({
  partialLength = 0,
  setActivateLoader = () => {},
  name = "",
  defaultValue = {},
  listOfForms = [],
  activeFormSchema,
}) => {
  const [values, setValues] = useState(defaultValue || {});
  const [errors, setErrors] = useState({});
  const [warnings, setWarnings] = useState({});
  const [show, setShow] = useState(0);
  const [errorInField, setErrorInField] = useState(true);
  const [allDataSubmitted, setAllDataSubmitted] = useState(false);
  const [canProceedToSummary, setCanProceedToSummary] = useState(false);

  const [isValid, setValid] = useState(
    partialLength ? Array(partialLength).fill(false) : false,
  );
  const history = useHistory();
  const queryStrings = useUrlQuery();
  const {
    journeyType,
    subJourneyType,
    data: frontBootData,
  } = useFrontendBoot();
  const { getUnfilledForm, navigateTo } = useFormNavigation();
  const { kycVerified } = useKYCDetails();
  const { data: enquiriesData } = useGetEnquiry();
  const revisedPremiumPopupUtilityObject = useRevisedPremiumModal();

  const {
    cartEntries,
    isVersionRuleEngine,
    getCartEntry,
    updateCart,
    getCartTotalPremium,
  } = useCart();
  const dispatch = useDispatch();
  const { renderField } = useFormFieldVisibility();
  const totalPremium = getCartTotalPremium();
  const { webEngageTrackEvent } = useEventTracking();
  const riders = cartEntries
    .flatMap(cartEntry =>
      cartEntry?.[`${journeyType}_riders`]?.map(rider => rider.name),
    )
    .join(", ");

  const { getCompanyLogo } = useCompanies();

  useEffect(() => {
    //* Resetting errors when new form opens.
    setErrors({});
  }, [name]);

  const groups = enquiriesData.data ? enquiriesData.data.groups : [];
  const enquiryId = queryStrings.get("enquiryId");

  // Get discounts data in case of renewal kyc form trigger
  const { getTotalDiscountAmount } = useAdditionalDiscount(
    groups[0].id,
    subJourneyType !== "renewal",
  );
  const cartEntry = getCartEntry(groups[0].id);
  const [updateCartMutation] = updateCart(groups[0].id);

  const { proposalData, kycSection } = useSelector(state => state.proposalPage);

  const checkAllValid = values => {
    if (values && values instanceof Object && Object.keys(values)?.length)
      return Object.keys(values)?.map(
        group =>
          values[group] &&
          Object.values(values[group])?.every(val => val?.isValid),
      );
    else return [];
  };

  // checks dropDown selected value exist in schema options or not
  const isOptionsValuesValidated = (activeFormSchema, values) => {
    if (Array.isArray(activeFormSchema)) {
      return activeFormSchema
        .filter(
          el =>
            renderField({
              item: el,
              currentFormValues: values,
              proposalData,
            }) &&
            el?.validate?.required &&
            el.type === "select",
        )
        .every(el => Boolean(el.additionalOptions.options[values[el.name]]));
    } else {
      return Object.keys(activeFormSchema).map(key =>
        isOptionsValuesValidated(activeFormSchema[key], values[key]),
      );
    }
  };

  const havingAnyError = (errors, key) => {
    if (key) {
      return Object.values(errors[key]).some(el => Boolean(el));
    } else {
      return Object.keys(errors).map(key => {
        if (errors[key] instanceof Object) {
          return havingAnyError(errors, key);
        } else return Boolean(errors[key]);
      });
    }
  };

  const triggerSpecialConditionsCheck = ({
    formSchema = [],
    values = {},
    formName,
    successCallback = () => {},
  }) => {
    let specialConditionsFieldsRelationMapped = {};
    let warningsRelationsMappedWithOutFormName = {};

    Object.keys(formSchema).map(key => {
      specialConditionsFieldsRelationMapped = {
        ...specialConditionsFieldsRelationMapped,
        [key]: formSchema[key]?.filter(
          field => !!field["additionalOptions"]?.special_conditions,
        ),
      };

      return;
    });

    Object.keys(specialConditionsFieldsRelationMapped).forEach(rel_key => {
      const specialConditionsFieldsArray =
        specialConditionsFieldsRelationMapped[rel_key];

      const mappedValues = values[rel_key];

      specialConditionsFieldsArray.forEach(
        ({ additionalOptions: { special_conditions } }) => {
          const one_mandatory_yes = special_conditions?.one_mandatory_yes;

          if (one_mandatory_yes) {
            const atLeastOneIsYes = one_mandatory_yes.some(
              name_key =>
                mappedValues[name_key.split(".")[0]][
                  `is${name_key.split(".")[0]}`
                ] === "Y",
            );

            if (atLeastOneIsYes) {
              delete warningsRelationsMappedWithOutFormName?.[rel_key];
              return;
            }

            const tempWarningsChildObject = {};

            one_mandatory_yes.map(name_key => {
              //* Check whether parent question is yes or not

              if (mappedValues[name_key.split(".")[1]]) {
                if (
                  mappedValues[name_key.split(".")[1]][
                    `is${name_key.split(".")[1]}`
                  ] === "Y"
                ) {
                  return (tempWarningsChildObject[name_key.split(".")[0]] = {
                    type: `one_mandatory_yes`,
                    message: "At-least one must be taken Yes.",
                  });
                } else {
                  return;
                }
              } else {
                return (tempWarningsChildObject[name_key.split(".")[0]] = {
                  type: `one_mandatory_yes`,
                  message: "At-least one must be taken Yes.",
                });
              }
            });

            if (Object.keys(tempWarningsChildObject).length) {
              warningsRelationsMappedWithOutFormName = {
                ...warningsRelationsMappedWithOutFormName,
                [rel_key]: tempWarningsChildObject,
              };
            } else {
              warningsRelationsMappedWithOutFormName = {
                ...warningsRelationsMappedWithOutFormName,
              };
            }
          }
        },
      );
    });

    if (!Object.keys(warningsRelationsMappedWithOutFormName).length) {
      return successCallback();
    }

    return setWarnings({ [formName]: warningsRelationsMappedWithOutFormName });
  };

  const everyRequiredFilled = (activeFormSchema, values = {}) => {
    if (Array.isArray(activeFormSchema)) {
      const filteredSchema = activeFormSchema.filter(el => {
        return (
          el.validate &&
          (el.validate.required === true ||
            el.validate.selectAtLeastOne === true) &&
          renderField({ item: el, currentFormValues: values, proposalData })
        );
      });

      const result = filteredSchema.every(el => {
        return (
          values[el.name] &&
          !performValidations({
            validate: el.validate,
            values,
            name: el.name,
            subJourneyType,
            additionalOptions: el.additionalOptions,
            proposalData,
          })
        );
      });

      return result;
    } else {
      return Object.keys(activeFormSchema)
        .map(key =>
          everyRequiredFilled(
            activeFormSchema[key],
            values[key] ? values[key] : {},
          ),
        )
        .includes(false)
        ? false
        : true;
    }
  };

  const additionalErrors = {};

  const [submit, setSubmit] = useState(false);

  // UPDATES THE VALUES REST OF THE FORMS
  const setSelfFieldsChange = ({
    checkFor,
    checkFrom,
    updationFor,
    insuredDetails,
    callback,
  }) => {
    if (updationFor === "Other Details") {
      let updatedParent = { ...checkFor };

      Object.keys(checkFor).forEach(key => {
        if (
          Object.keys(insuredDetails).includes(checkFor[key].nominee_relation)
        ) {
          const groupCodeOfmember = groups.find(group =>
            group.members.includes(checkFor[key].nominee_relation),
          ).id;
          let checkFrom2 =
            checkFor[key].nominee_relation.toLowerCase() === "self"
              ? { ...checkFrom, ...insuredDetails.self }
              : insuredDetails[checkFor[key].nominee_relation.toLowerCase()];

          let updatedOtherDetail = { ...checkFor[key] };

          Object.keys(checkFor[key]).forEach(key2 => {
            let nameWithoutNominee = key2.slice(
              key2.indexOf("_") + 1,
              key2.length,
            );

            if (nameWithoutNominee === "contact") nameWithoutNominee = "mobile";
            if (
              nameWithoutNominee.includes("address") ||
              nameWithoutNominee.includes("pincode")
            )
              nameWithoutNominee += "_" + groupCodeOfmember;

            if (
              checkFrom2[nameWithoutNominee] &&
              checkFor[key][key2] !== checkFrom2[nameWithoutNominee]
            )
              updatedOtherDetail[key2] = checkFrom2[nameWithoutNominee];
          });
          updatedParent[key] = updatedOtherDetail;
        }
      });
      triggerSaveForm({
        sendedVal: updatedParent,
        formName: updationFor,
        callback,
      });
    } else if (updationFor === "Insured Details") {
      let updatedObj = { ...checkFor.self };
      let checkForKeys = Object.keys(checkFor.self);
      checkForKeys.forEach(key => {
        if (checkFrom[key] && checkFrom[key] !== checkFor.self[key])
          updatedObj[key] = checkFrom[key];
      });
      // updatedVal = { ...checkFor, self: updatedObj };
      triggerSaveForm({
        sendedVal: { ...checkFor, self: updatedObj },
        formName: updationFor,
        callback,
      });
    } else if (updationFor === "Medical Details") {
      let updatedObj = checkFor;
      triggerSaveForm({
        sendedVal: updatedObj,
        formName: updationFor,
        callback,
      });
    }
  };

  const triggerSaveForm = ({ sendedVal, formName, callback = () => {} }) => {
    if (
      formName === "Insured Details" &&
      !everyRequiredFilled(activeFormSchema, sendedVal)
    ) {
      toast.error(
        "Please complete all required fields before proceeding. Thank you!",
        { position: "top-center" },
      );
    }
    if (formName !== "Medical Details") {
      if (havingAnyError(errors).includes(true)) {
        navigateTo({ formName });
        name !== "Insured Details" &&
          setShow(havingAnyError(errors).indexOf(true));
        return;
      }
      if (!everyRequiredFilled(activeFormSchema, sendedVal)) {
        navigateTo({ formName });
        return;
      }
      let valueIsValidatedOption = isOptionsValuesValidated(
        activeFormSchema,
        sendedVal,
      );

      if (
        formName !== "Proposer Details" &&
        formName !== "KYC Details" &&
        valueIsValidatedOption.includes(false)
      ) {
        navigateTo({ formName });
        setShow(valueIsValidatedOption.indexOf(false));
        return;
      }
    } else if (
      checkAllValid(values) &&
      !checkAllValid(values).every(el => el === true)
    ) {
      navigateTo({ formName });
      return;
    }

    if (
      formName === "Proposer Details" &&
      !havingAnyError(errors).includes(true) &&
      everyRequiredFilled(activeFormSchema, sendedVal)
    ) {
      setSubmit(true);
      dispatch(
        saveProposalData(
          { [formName]: sendedVal },
          ({ prevProposalData, updatedProposalData, responseData }) => {
            const { block_journey, block_message } = responseData;

            if (block_journey) {
              dispatch(
                setShowErrorPopup({
                  show: true,
                  head: "",
                  msg: block_message,
                  showQuotesBtn: true,
                }),
              );
              return;
            }
            if (
              !listOfForms.includes("KYC Details") &&
              prevProposalData["Insured Details"] &&
              prevProposalData["Insured Details"].self
            ) {
              setSelfFieldsChange({
                checkFor: prevProposalData["Insured Details"],
                checkFrom: sendedVal,
                updationFor: "Insured Details",
                dispatch: dispatch,
                callback: () => {
                  revisedPremiumPopupUtilityObject?.getUpdatedCart(() => {});
                },
              });
            } else {
              let unfilledForm = getUnfilledForm(updatedProposalData);
              navigateTo({
                formName: unfilledForm ? unfilledForm : "KYC Details",
              });
            }
          },
        ),
      );
      webEngageTrackEvent("Proposer Details Submitted");
    }
    if (
      formName === "KYC Details" &&
      kycSection?.canContinue &&
      !havingAnyError(errors).includes(true) &&
      everyRequiredFilled(activeFormSchema, sendedVal)
    ) {
      setSubmit(true);
      dispatch(
        saveProposalData(
          { [formName]: sendedVal },
          ({ updatedProposalData }) => {
            let unfilledForm = getUnfilledForm(updatedProposalData);
            navigateTo({
              formName: unfilledForm ? unfilledForm : "Insured Details",
            });
            dispatch(setKYCFailed(false));
            if (subJourneyType === "renewal") {
              dispatch(setIsLoading(true));
              const discounted_total_premium = getTotalPremiumWithDiscount({
                netPremiumWithoutDiscount: cartEntry?.netPremiumWithoutDiscount,
                totalDiscountAmount: getTotalDiscountAmount(),
              });

              updateCartMutation({
                discounted_total_premium,
                generate_proposal: true,
              });
            } else {
              dispatch(api.util.invalidateTags(["Cart"]));
            }
          },
          () => {
            dispatch(setKYCFailed(true));
          },
        ),
      );
    }
    if (
      formName === "Insured Details" &&
      !havingAnyError(errors).includes(true) &&
      everyRequiredFilled(activeFormSchema, sendedVal)
    ) {
      dispatch(
        saveProposalData(
          { [formName]: sendedVal },
          ({ prevProposalData, updatedProposalData, responseData }) => {
            const {
              data,
              failed_bmi,
              block_journey,
              block_message = "",
              is_medical_under_writing,
            } = responseData;

            callback();

            if (block_message.length > 0) {
              dispatch(
                setShowErrorPopup({
                  show: true,
                  head: "",
                  msg: block_message,
                }),
              );
              return;
            }

            revisedPremiumPopupUtilityObject?.getUpdatedCart(() => {
              webEngageTrackEvent("Health Plan Revised Premium Declaration", {
                prevTotalPremium:
                  revisedPremiumPopupUtilityObject?.prevTotalPremium,
                revisedTotalPremium:
                  revisedPremiumPopupUtilityObject?.updatedTotalPremium,
              });
            });

            if (data) {
              dispatch(setInsuredDetailsResponse(data));
              dispatch(setMedicalUrlsRuleEngine(data?.members));
              dispatch(setUnderWritingStatus([]));
            }

            if (block_journey) {
              dispatch(setFailedBmiBlockJourney(block_journey));
            } else {
              dispatch(setFailedBmiBlockJourney(false));
            }

            if (failed_bmi?.health) {
              dispatch(setFailedBmiData(failed_bmi));
              dispatch(setShowBMI(Object.keys(failed_bmi.health).join(", ")));
            }

            if (is_medical_under_writing) {
              dispatch(
                setShowErrorPopup({
                  show: true,
                  head: "",
                  msg: frontBootData?.settings
                    ?.medical_nstp_declaration_message,
                }),
              );
            }

            if (!block_journey) {
              if (prevProposalData["Medical Details"]) {
                setSelfFieldsChange({
                  checkFor: prevProposalData["Medical Details"],
                  checkFrom: sendedVal,
                  updationFor: "Medical Details",
                  dispatch: dispatch,
                  callback: () => {
                    revisedPremiumPopupUtilityObject?.getUpdatedCart(() => {});
                  },
                });
              } else {
                let unfilledForm = getUnfilledForm(updatedProposalData);
                navigateTo({
                  formName: unfilledForm ? unfilledForm : "Medical Details",
                });
              }
            }
          },
        ),
      );
      webEngageTrackProposalEvent({
        stage: formName,
        cartEntries,
        riders,
        enquiriesData,
        totalPremium,
        getCompanyLogo,
      });
    }
    if (
      formName === "Medical Details" &&
      checkAllValid(values).every(el => el === true)
    ) {
      dispatch(
        saveProposalData(
          { [formName]: sendedVal },
          ({ prevProposalData, updatedProposalData }) => {
            revisedPremiumPopupUtilityObject?.getUpdatedCart(() => {});

            callback();

            if (prevProposalData["Other Details"]) {
              setSelfFieldsChange({
                checkFor: prevProposalData["Other Details"],
                checkFrom: updatedProposalData["Proposer Details"],
                updationFor: "Other Details",
                dispatch: dispatch,
                insuredDetails: updatedProposalData["Insured Details"],
                callback: () => {},
              });
            } else {
              navigateTo({ formName: getUnfilledForm(updatedProposalData) });
            }
          },
        ),
      );
    }
    if (
      formName === "Other Details" &&
      !havingAnyError(errors).includes(true) &&
      everyRequiredFilled(activeFormSchema, sendedVal)
    ) {
      dispatch(
        saveProposalData(
          { [formName]: sendedVal },
          ({ updatedProposalData }) => {
            revisedPremiumPopupUtilityObject?.getUpdatedCart(() => {});

            callback();
            if (listOfForms.includes("Port Details")) {
              let unfilledForm = getUnfilledForm(updatedProposalData);
              return navigateTo({
                formName: unfilledForm ? unfilledForm : "Port Details",
              });
            }
            return setAllDataSubmitted(true);
          },
        ),
      );

      webEngageTrackEvent("Other Details Submitted", {
        prevTotalPremium: revisedPremiumPopupUtilityObject?.prevTotalPremium,
        revisedTotalPremium:
          revisedPremiumPopupUtilityObject?.updatedTotalPremium,
      });
    }
    if (
      formName === "Port Details" &&
      !havingAnyError(errors).includes(true) &&
      everyRequiredFilled(activeFormSchema, sendedVal)
    ) {
      dispatch(
        saveProposalData({ [formName]: sendedVal }, () => {
          callback();
          setAllDataSubmitted(true);
        }),
      );
    }
  };

  const proposalSubmissionSuccess = () => {
    dispatch(api.util.resetApiState());
    history.push("/proposal_summary?enquiryId=" + enquiryId);
    setActivateLoader(false);
  };

  const proposalSubmissionFailure = error => {
    if (error?.message) {
      dispatch(
        setShowErrorPopup({
          show: true,
          head: "",
          msg: error.message,
        }),
      );
    }
    if (error?.ckyc_verification_failed) {
      dispatch(api.util.invalidateTags(["Cart"]));
      navigateTo({ formName: "KYC Details" });
    }
  };

  // =================================================================================================================
  // ================================================  SIDE EFFECTS ==================================================
  // =================================================================================================================

  useEffect(() => {
    if (!revisedPremiumPopupUtilityObject.isOn && allDataSubmitted) {
      setCanProceedToSummary(true);
    } else setCanProceedToSummary(false);
  }, [revisedPremiumPopupUtilityObject.isOn, allDataSubmitted]);

  useEffect(() => {
    if (canProceedToSummary) {
      if (kycVerified === true) {
        setActivateLoader(true);
        dispatch(
          submitProposalData({
            next: proposalSubmissionSuccess,
            fail: proposalSubmissionFailure,
            groups: enquiriesData.data.groups,
            enquiryId,
          }),
        );
        return;
      } else {
        dispatch(
          setShowErrorPopup({
            show: true,
            head: "",
            msg: "KYC Verification is mandatory to complete your purchase.",
            handleClose: () => {
              navigateTo({
                formName: "KYC Details",
              });
            },
          }),
        );
      }
    }
    return () => {};
  }, [canProceedToSummary]);

  /* useEffect(() => {
    if (name === "Proposer Details" || name === "KYC Details") {
      Object.values(errors).some(el => el !== undefined)
        ? setBlockTabSwitch(true)
        : setBlockTabSwitch(false);
    } else {
      Object.keys(errors).some(el =>
        Object.values(errors[el] || {}).some(val => val !== undefined),
      )
        ? setBlockTabSwitch(true)
        : setBlockTabSwitch(false);
    }
  }, [errors]); */

  return {
    values,
    setValues,
    isValid,
    setValid,
    setSubmit,
    submit,
    revisedPremiumPopupUtilityObject,
    triggerSaveForm,
    setErrorInField,
    errorInField,
    additionalErrors,
    setErrors,
    errors,
    enquiriesData,
    show,
    setShow,
    cartEntries,
    isVersionRuleEngine,
    everyRequiredFilled,
    warnings,
    triggerSpecialConditionsCheck,
    proposalSubmissionSuccess,
    proposalSubmissionFailure,
  };
};

export default useProposalSections;
