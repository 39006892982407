import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import "styled-components/macro";
import { CartDetailRow, UnavailableMessage } from ".";
import { Button } from "src/components/Common/Button/ContinueButton";
import { useFrontendBoot } from "src/customHooks/useGetCommonQueries";
import { useTheme, useUrlEnquiry } from "src/customHooks";
import { amount, getDisplayPremium } from "src/utils/string.utility";
import {
  setIsPopupOn,
  setProposalData,
  setActiveIndex,
  clearProposalData,
} from "src/pages/ProposalPage/ProposalSections/ProposalSections.slice";
import { setShowEditMembers } from "src/pages/NewQuotePage/QuotePageSlice/quote.slice";
import { DetailsWrap, BtnLoading } from "../ReviewCart.style";
import BasePlanDetails from "./BasePlanDetails";
import DiscountsList from "./DiscountsList";
import RidersList from "./RidersList";
import FeaturesList from "./FeaturesList";
import PanNumberInput from "./PanNumberInput";
import { useSaveProposerPanMutation } from "src/api/api";
import {
  ModalBodyStyled,
  ModalFooterStyled,
} from "./RevisedPremiumPopup.style";
import { useEventTracking } from "src/customHooks/useEventTracking";

import Modal from "./../../../../../components/Common/Popups/ReusableModal/Modal";

const RevisedPremiumPopup = ({ revisedPremiumPopupUtilityObject, onClose }) => {
  const dispatch = useDispatch();

  const isProductDetailsPage = useRouteMatch({ path: "/productdetails" });

  const { groupCode: urlGeneratedGroupCode } = useParams(); //? groupCode only available on product details page.

  const proposerData = useSelector(
    ({ proposalPage }) => proposalPage?.proposalData["Proposer Details"],
  );
  const proposalData = useSelector(
    ({ proposalPage }) => proposalPage?.proposalData,
  );

  const showPanField =
    !isProductDetailsPage &&
    !proposerData?.pan_card &&
    +revisedPremiumPopupUtilityObject?.updatedTotalPremium >= 50000;

  const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]$/;

  const [userPan, setUserPan] = useState("");
  const [panError, setPanError] = useState(false);
  const [saveProposerPan, { isLoading, isSuccess }] =
    useSaveProposerPanMutation();

  const onPanChangeHandler = e => {
    let upperCaseValue = e.toLocaleUpperCase();
    setUserPan(upperCaseValue);
  };

  const popupCloseHandler = () => {
    if (showPanField) {
      saveProposerPan({ pan_no: userPan }).then(() =>
        dispatch(
          setProposalData({
            ...proposalData,
            "Proposer Details": {
              ...proposerData,
              pan_card: userPan,
            },
          }),
        ),
      );
    } else {
      onClose();
      dispatch(setIsPopupOn(false));
    }
  };

  useEffect(() => {
    if (!panRegex.test(userPan)) {
      setPanError(true);
    } else {
      setPanError(false);
    }
  }, [userPan]);

  useEffect(() => {
    if (isSuccess) {
      onClose();
      dispatch(setIsPopupOn(false));
    }
    return () => {};
  }, [isSuccess]);

  return (
    // <ModalStyled show>
    <Modal
      isModalOpen={true}
      height="fit-content"
      hideCloseBtn={true}
      title={revisedPremiumPopupUtilityObject?.title}
    >
      {isProductDetailsPage &&
        revisedPremiumPopupUtilityObject?.updatedCartEntries
          ?.filter(
            updatedCartEntry =>
              +updatedCartEntry?.group?.id === +urlGeneratedGroupCode,
          )
          ?.map((singleCartEntry, index) => (
            <RevisedPlanCard key={index} cartEntry={singleCartEntry} />
          ))}

      {!isProductDetailsPage &&
        revisedPremiumPopupUtilityObject?.updatedCartEntries?.map(
          (singleCartEntry, index) => (
            <RevisedPlanCard key={index} cartEntry={singleCartEntry} />
          ),
        )}
      {showPanField ? (
        <>
          <PanNumberInput
            userPan={userPan}
            setUserPan={onPanChangeHandler}
            error={panError}
          />
          <hr />
        </>
      ) : (
        <></>
      )}

      <RevisedPopupFooter
        isLoading={isLoading}
        revisedPremiumPopupUtilityObject={revisedPremiumPopupUtilityObject}
        onClose={popupCloseHandler}
        disableBtn={showPanField && panError}
      />
    </Modal>
    // </ModalStyled>
  );
};

export default RevisedPremiumPopup;

const RevisedPlanCard = ({ cartEntry }) => {
  const groupCode = cartEntry?.group?.id;

  const unavailable_message = cartEntry?.unavailable_message;

  const revised_message = cartEntry?.revised_message;

  const premium = cartEntry?.premium;

  const { colors } = useTheme();

  return (
    <>
      <ModalBodyStyled>
        <BasePlanDetails groupCode={+groupCode} revisedPremium />
        {!unavailable_message ? (
          <div>
            <CartDetailRow
              title="Revised Premium"
              secondaryColor={colors.secondary_color}
              value={amount(+premium)}
            />
          </div>
        ) : null}
        {unavailable_message ? (
          <UnavailableMessage message={unavailable_message} />
        ) : (
          <div>
            <RidersList groupCode={groupCode} showFromSelectedList={true} />
            <FeaturesList groupCode={groupCode} showFromSelectedList={true} />
            <DiscountsList groupCode={groupCode} />
          </div>
        )}
        {revised_message ? (
          <UnavailableMessage message={revised_message} />
        ) : (
          <></>
        )}
      </ModalBodyStyled>
      <hr />
    </>
  );
};

const RevisedPopupFooter = ({
  isLoading = false,
  revisedPremiumPopupUtilityObject,
  onClose,
  disableBtn = false,
}) => {
  const { groupCode: urlGeneratedGroupCode } = useParams(); //? groupCode changes on product details page.

  const dispatch = useDispatch();

  const { colors } = useTheme();

  const history = useHistory();

  const { getUrlWithEnquirySearch } = useUrlEnquiry();

  const { subJourneyType } = useFrontendBoot();

  const { webEngageTrackEvent } = useEventTracking();

  const { proposalData } = useSelector(state => state.proposalPage);

  const isProductDetailsPage = useRouteMatch({ path: "/productdetails" });

  const updatedCartEntries =
    revisedPremiumPopupUtilityObject?.updatedCartEntries || [];

  const editAgeHandler = () => {
    onClose();
    dispatch(setShowEditMembers(true));
  };
  const onContinueClick = () => {
    if (!isProductDetailsPage) {
      webEngageTrackEvent("Health Plan Revised Premium Accepted", {
        prevTotalPremium: revisedPremiumPopupUtilityObject?.prevTotalPremium,
        revisedTotalPremium:
          revisedPremiumPopupUtilityObject?.updatedTotalPremium,
      });
    }

    onClose();
  };

  const viewQuotesHandler = () => {
    dispatch(setIsPopupOn(false));
    //? set active form to proposer details if the user selects yes on PEP
    if (
      updatedCartEntries.some(
        singleCartEntry =>
          singleCartEntry?.unavailable_message ===
            "According to the pep entered this plan isn't available!" ||
          singleCartEntry?.unavailable_message ===
            "According to the input age entered this plan isn't available for Niva bupa",
      )
    ) {
      dispatch(setActiveIndex(0));
      const newProposalData = Object.fromEntries(
        Object.entries(proposalData).filter(
          ([key]) => key === "Proposer Details",
        ),
      );
      dispatch(clearProposalData());
      dispatch(setProposalData(newProposalData));
    }
    history.replace(
      getUrlWithEnquirySearch(
        `/quotes/${
          urlGeneratedGroupCode ||
          revisedPremiumPopupUtilityObject?.unAvailablePlanInTheCart?.group?.id
        }`,
      ),
    );
  };

  return (
    <ModalFooterStyled>
      {!revisedPremiumPopupUtilityObject?.isAnyPlanUnAvailableInCart && (
        <>
          <DetailsWrap>
            <DetailsWrap.Title className="d_title">
              {isProductDetailsPage
                ? "Previous Premium"
                : "Previous Total Premium"}
            </DetailsWrap.Title>
            <DetailsWrap.Value>
              {isProductDetailsPage
                ? getDisplayPremium({
                    total_premium:
                      +revisedPremiumPopupUtilityObject.getPreviousCartEntryPremium(
                        urlGeneratedGroupCode,
                      ),
                    tenure: 1,
                  })
                : getDisplayPremium({
                    total_premium:
                      +revisedPremiumPopupUtilityObject.prevTotalPremium,
                    tenure: 1,
                  })}
            </DetailsWrap.Value>
          </DetailsWrap>
          <DetailsWrap color={colors.secondary_color}>
            <DetailsWrap.Title className="D_title">
              {isProductDetailsPage
                ? "Revised Premium"
                : "Revised Total Premium"}
            </DetailsWrap.Title>
            <DetailsWrap.Value>
              {isProductDetailsPage
                ? getDisplayPremium({
                    total_premium:
                      +revisedPremiumPopupUtilityObject.getUpdatedCartEntryPremium(
                        urlGeneratedGroupCode,
                      ),
                    tenure: 1,
                  })
                : getDisplayPremium({
                    total_premium:
                      +revisedPremiumPopupUtilityObject.updatedTotalPremium,
                    tenure: 1,
                  })}
            </DetailsWrap.Value>
          </DetailsWrap>
          <DetailsWrap>
            <Button onClick={onContinueClick} disabled={disableBtn}>
              Continue
              {isLoading ? <BtnLoading /> : <></>}
            </Button>
          </DetailsWrap>
        </>
      )}

      {revisedPremiumPopupUtilityObject?.isAnyPlanUnAvailableInCart && (
        <>
          <DetailsWrap>
            {subJourneyType !== "renewal" && (
              <Button onClick={viewQuotesHandler}>View Quotes</Button>
            )}
          </DetailsWrap>
          {isProductDetailsPage && (
            <DetailsWrap>
              <Button onClick={editAgeHandler}>Edit Age</Button>
            </DetailsWrap>
          )}
          {!isProductDetailsPage &&
            revisedPremiumPopupUtilityObject?.unAvailablePlanInTheCart
              ?.isSTP && (
              <DetailsWrap>
                <Button onClick={onClose}>Continue</Button>
              </DetailsWrap>
            )}
        </>
      )}
    </ModalFooterStyled>
  );
};
