import { useState } from "react";
import { regexStringToRegex } from "src/utils/helper";

const useAsyncTextField = ({ asyncValidate, allow, initialValue = "" }) => {
  const generateStatus = () => {
    if (initialValue) {
      return initialValue === "failure" ? "failure" : "success";
    } else {
      return "unverified";
    }
  };
  const [input, setInput] = useState(initialValue || "");
  const [focused, setFocused] = useState(false);
  const [status, setStatus] = useState(generateStatus);
  const [disableField, setDisableField] = useState(false);

  const validationObject = {
    regex: ({ param, value }) => {
      const parsedRegex = regexStringToRegex(param);
      if (parsedRegex.test(value)) {
        return;
      } else {
        return "Enter a valid value.";
      }
    },
    required: ({ param, value }) => {
      if (param) {
        if (value) {
          return;
        } else {
          return "This field is required.";
        }
      } else {
        return;
      }
    },
  };
  const validateAllow = {
    onlyNumbers: ({ value }) => {
      if (!/^[0-9]*$/.test(value)) {
        return "Please enter only numbers";
      } else {
        return;
      }
    },
  };
  const generateError = ({ asyncValidate, allow, value = "" }) => {
    if (asyncValidate) {
      const checkKeys = Object.keys(asyncValidate);
      const errors = checkKeys.map(key =>
        validationObject?.[key]?.({
          value,
          param: asyncValidate?.[key],
        }),
      );
      const pureErrors = errors.filter(error => error);
      return pureErrors;
    }

    if (allow) {
      const errors = validateAllow?.[allow]?.({
        value,
      });
      return [errors];
    }
    return [];
  };

  const inputErrorUtility = {
    all: generateError({ asyncValidate, allow, value: input }),
    toShow: generateError({ asyncValidate, allow, value: input }).find(
      validError => validError,
    ),
    exist:
      generateError({ asyncValidate, allow, value: input })?.length && focused,
  };

  return {
    input,
    focused,
    status,
    setInput,
    setFocused,
    setStatus,
    inputErrorUtility,
    disableField,
    setDisableField,
  };
};
export default useAsyncTextField;
