import { useEffect, useState } from "react";
// REACT ICONS
import { RiExternalLinkFill } from "react-icons/ri";
// CUSTOM HOOKS
import { useTheme } from "src/customHooks";
import { useGetNetworkHospitalsQuery } from "src/api/api";
import useSearchHospitals from "src/pages/SeeDetailsModal/Hooks/useSearchHospitals";
// DESIGN SYSTEM
import FHTypography from "src/styles/Typography";
// STYLED COMPONENTS
import {
  CashlessHospitalStyled,
  NetworkHospitalCardStyled,
} from "./CashlessHospitals.style";
// CUSTOM COMPONENTS
import CardSkeletonLoader from "./CardSkeletonLoader";
import HospitalSearchBar from "./HospitalSearchBar";
import HospitalsDisplay from "./HospitalsDisplay";
import CashlessEverywhere from "./CashlessEverywhere";

export default function CashlessHospitalSection({ quote }) {
  const { colors } = useTheme();
  const [searchText, setSearchText] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const {
    product: { company },
  } = quote;

  const { isLoading, isFetching, isError, data } = useGetNetworkHospitalsQuery(
    company.alias,
  );

  const hospitals = data?.data || [];

  useEffect(() => {
    setIsSearching(searchText.length > 0);
  }, [searchText]);

  const foundHospitals = useSearchHospitals(hospitals, searchText);

  if (isLoading || isFetching) return <CardSkeletonLoader />;

  const networkHospitalUrl = data?.network_hospital_url || "";

  const nearbyHospitals = hospitals.slice(0, 6);

  if (isError)
    return (
      <FHTypography variant="h5" weight="md" colorType="heading">
        Cannot get hospitals.
      </FHTypography>
    );

  return (
    <CashlessHospitalStyled>
      <CashlessEverywhere />
      {hospitals.length > 0 && (
        <>
          <HospitalSearchBar
            searchText={searchText}
            setSearchText={setSearchText}
          />
          {/* Nearby Hospitals list */}
          {!isSearching && (
            <HospitalsDisplay
              hospitalList={nearbyHospitals}
              headerText="Nearby Hospitals"
            />
          )}
          {/* Cashless Hospitals search results */}
          <HospitalsDisplay
            hospitalList={foundHospitals}
            headerText={
              isSearching
                ? `Search results (${foundHospitals.length} hospitals found)`
                : "All hospitals"
            }
          />
        </>
      )}

      {networkHospitalUrl.length > 0 && (
        <NetworkHospitalCardStyled colors={colors}>
          <div className="info-wrapper">
            <FHTypography variant="h5" weight="semi" colorType="subHeading">
              Hospitals Near You
            </FHTypography>
            <FHTypography variant="subheading1" weight="md" colorType="body">
              Click here to view complete list of cashless network hospital
            </FHTypography>
          </div>
          <a
            href={networkHospitalUrl}
            title="Complete list of cashless network hospital"
            target="#"
          >
            <RiExternalLinkFill />
          </a>
        </NetworkHospitalCardStyled>
      )}
    </CashlessHospitalStyled>
  );
}
