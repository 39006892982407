import styled from "styled-components";
// DESIGN SYSTEM
import { VIEW } from "src/styles/responsiveStyle";
import { small } from "src/utils/mediaQueries";
import { FLEX_COL, ROUNDED, SHADES, SHADOW } from "src/styles/styleGuide";

const StyledErrorMessage = styled.p`
  font-size: 1rem;
  text-align: center;
  color: red;
  width: 100%;
`;

const DetailsWrap = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & button {
    border-radius: 9px;
    width: 100%;
  }

  .D_title {
    color: ${({ color }) => color};
  }

  .d_title {
    font-weight: 600;
  }
`;

const BtnLoading = styled.span`
  width: unset;
  height: unset;
  position: relative;
  &:after {
    content: " ";
    display: inline-block;
    width: 22px;
    height: 22px;
    position: absolute;
    top: 0;
    right: -30px;
    border-radius: 50%;
    border: 3px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
`;

DetailsWrap.Title = styled.span`
  font-size: 15px;
  color: rgb(86, 87, 88);
  text-align: center;
  ${small} {
    font-size: 11px;
  }
`;
DetailsWrap.Value = styled.span`
  font-size: 15px;
  color: rgb(86, 87, 88);
  ${small} {
    font-size: 11px;
  }
`;

const ReviewCartWrapperStyled = styled.div`
  position: sticky;
  top: 4.2rem;
  box-shadow: ${SHADOW.md};
  padding: 0.75rem;
  border: 1px solid ${SHADES.WHITE};
  border-radius: ${ROUNDED.md};
  overflow: hidden;
  transition: all 300ms ease-in-out;
  ${VIEW.md} {
    box-shadow: ${SHADOW.lg};
    border-radius: ${ROUNDED.xl};
  }
`;

const ReviewCartHeaderStyled = styled.div`
  ${FLEX_COL}
  position:relative;
`;

const ReviewCartBodyStyled = styled.div`
  ${FLEX_COL}
  position: relative;

  .cart-detail-wrapper {
    margin: 0.4rem 0px;

    ::-webkit-scrollbar {
      width: 2px;
    }

    ${VIEW.md} {
      height: auto;
    }

    ${VIEW.lg} {
      height: 90px;
      overflow-y: auto;
    }
  }
`;

const ReviewDetailsContainerStyled = styled.div`
  ${FLEX_COL}
  gap: 0.75rem;
  margin-block: 0.75rem;

  & > div {
    border-top: 1px solid ${SHADES.LIGHT_GRAY};
  }
`;

const TopCornerCircle = styled.div`
  width: 6rem;
  height: 6rem;
  background: ${props => props.primary_shade};
  top: -54px;
  left: -60px;
  z-index: -1;
  border-radius: 50%;
  position: absolute;
`;

const RenewalButtonWrapperStyled = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-top: 0.75rem;
  & button {
    flex: 1;
  }
  @media (min-width: 1200px) and (max-width: 1640px) {
    flex-direction: column;
  }
`;

export {
  BtnLoading,
  DetailsWrap,
  StyledErrorMessage,
  ReviewCartWrapperStyled,
  ReviewCartHeaderStyled,
  ReviewCartBodyStyled,
  TopCornerCircle,
  ReviewDetailsContainerStyled,
  RenewalButtonWrapperStyled,
};
