import Modal from "../Common/Popups/ReusableModal/Modal";
import { useSelector } from "react-redux";
import OTPVerificationForm from "./Components/OTPVerificationForm";
import FHTypography from "src/styles/Typography";
import styled from "styled-components";

const GroupOTPVerificationModal = ({
  onClose = () => {},
  onOTPVerify = () => {},
  otpVerificationQuery = {},
}) => {
  const { showGroupOTPVerificationModal } = useSelector(
    state => state.proposalPage,
  );
  return (
    <Modal
      isModalOpen={showGroupOTPVerificationModal}
      onClose={onClose}
      height="fit-content"
      width="fit-content"
      hideCloseBtn={true}
    >
      <ModalHeader>
        <FHTypography variant="h4" weight="semi" color="heading">
          Verify OTP
        </FHTypography>
      </ModalHeader>
      <OTPVerificationForm
        onVerify={onOTPVerify}
        onClose={onClose}
        otpVerificationQuery={otpVerificationQuery}
      />
    </Modal>
  );
};
export default GroupOTPVerificationModal;

const ModalHeader = styled.div`
  padding-bottom: 1rem;
`;
