import { useState, memo, useEffect } from "react";
import { useGetLocationDetailsQuery } from "src/api/api";
import FHTypography from "src/styles/Typography";
import { useTheme } from "src/customHooks";
import { CityDropDownStyles } from "../LocationForm/locationForm.styled";
import LocationTab from "./LocationTab";

function LocationOptions({
  searchQuery = "",
  selected,
  onChange,
  setLoading,
  setFetchedCities,
}) {
  const [mouseEntered, setMouseEntered] = useState(false);
  const { colors } = useTheme();

  let skip = true;

  if (searchQuery.length >= 3) skip = false;

  let { isFetching, isUninitialized, data } = useGetLocationDetailsQuery(
    { search: searchQuery },
    { skip },
  );

  useEffect(() => {
    if (isFetching) {
      searchQuery.length === 3 ? setLoading(true) : setLoading(false);
    } else {
      if (searchQuery.length >= 3) {
        setLoading(false);
        setFetchedCities(data);
      } else {
        setLoading(false);
        setFetchedCities(null);
      }
    }
    return () => {};
  }, [isFetching, searchQuery]);

  // FUNCTION/s
  const handleChange = location => {
    onChange && onChange(location);
  };

  const checkSelected = location => {
    return selected && selected.city === location.city;
  };

  if (isUninitialized || isFetching) return null;

  return (
    <div>
      <CityDropDownStyles
        length={data?.length}
        colors={colors}
        mouseEntered={mouseEntered}
        onMouseEnter={() => {
          setMouseEntered(true);
        }}
        onMouseLeave={() => {
          setMouseEntered(false);
        }}
      >
        {data && !data.length && (
          <FHTypography variant="body1" weight="base" type="error">
            Please enter a valid Pincode or City
          </FHTypography>
        )}
        {data?.map(location => (
          <LocationTab
            location={location}
            onChange={handleChange}
            isSelected={checkSelected(location)}
            key={location.city}
          />
        ))}
      </CityDropDownStyles>
    </div>
  );
}

export default memo(LocationOptions);
