import "styled-components/macro";
import "./cbx.css";
import { useTheme } from "src/customHooks";
import styled from "styled-components";

const ProposalCheckBox = ({
  title,
  value,
  onChange,
  extraPadding,
  ...extras
}) => {
  const { colors } = useTheme();

  const PrimaryColor = colors.primary_color;

  return (
    <>
      <Input
        className="inp-cbx"
        id={title}
        type="checkbox"
        checked={value}
        onChange={onChange}
      />
      <Lable
        className="cbx"
        extraPadding={extraPadding}
        PrimaryColor={PrimaryColor}
        htmlFor={title}
      >
        <span
          {...extras}
          className="span"
        >
          <svg width="12px" height="10px">
            <use xlinkHref="#check"></use>
          </svg>
        </span>
      </Lable>

      <svg className="inline-svg">
        <symbol id="check" viewBox="0 0 12 10">
          <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
        </symbol>
      </svg>
    </>
  );
};

const Input = styled.input`
  width: 20px;
`;

const Lable = styled.label`
  padding: ${({ extraPadding }) => {
    extraPadding ? "10px 15px 12px" : "0px 15px 12px";
  }};
  position: ${({ extraPadding }) => {
    extraPadding ? "" : " relative";
  }};
  top: ${({ extraPadding }) => {
    extraPadding ? "0" : "5px";
  }};
  width: unset;

  .span {
    margin-bottom: -1px;
    border-color: ${({ PrimaryColor }) => PrimaryColor} !important;
    width: 22px !important;
    height: 22px !important;
    & svg {
      width: 17px !important;
      height: 17px !important;
      margin-top: -3.5px;
      margin-left: -2.5px;
      stroke: ${({PrimaryColor}) => PrimaryColor } !important;
      @media (max-width: 768px) {
        margin-top: -2px;
      }
    }
    @media (max-width: 1200px) {
      margin-bottom: 0px;
    }
  }
`;

export default ProposalCheckBox;
