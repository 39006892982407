import styled from "styled-components";
// REACT ICONS
import { FaArrowCircleLeft, FaChevronLeft } from "react-icons/fa";
// CUSTOM HOOKS
import { useResponsiveCondition } from "src/pos/hooks";
import useGoBackButton from "./useGoBackButton";
// DESIGN SYSTEM
import { useTheme } from "src/customHooks";
import FHTypography from "src/styles/Typography";
import { FLEX_ROW_CENTER, ROUNDED, SHADES } from "src/styles/styleGuide";
import useCustomHistoryStack from "./useCustomHistoryStack";

export default function GoBackButton({
  showMobileBackText = false,
  displayOnViewport = ["M", "D"],
  ...extras
}) {
  const { colors } = useTheme();
  const { width, breakpoint } = useResponsiveCondition(768);
  const additionalAction = useGoBackButton();
  const { goBack } = useCustomHistoryStack();

  const goBackBtnHandler = () => {
    additionalAction();
    goBack();
  };

  if (width < breakpoint && displayOnViewport.includes("M"))
    return (
      <MobileGoBackButtonStyled
        {...extras}
        colors={colors}
        onClick={goBackBtnHandler}
      >
        <FaArrowCircleLeft />
        {showMobileBackText && (
          <FHTypography variant="h6" weight="md" colorType="subHeading">
            Back
          </FHTypography>
        )}
      </MobileGoBackButtonStyled>
    );

  if (width > breakpoint && displayOnViewport.includes("D"))
    return (
      <GoBackButtonStyled {...extras} onClick={goBackBtnHandler}>
        <span className="back-icon">
          <FaChevronLeft />
        </span>
        <FHTypography variant="h6" weight="semi" colorType="heading">
          Back
        </FHTypography>
      </GoBackButtonStyled>
    );
}

const GoBackButtonStyled = styled.button`
  ${FLEX_ROW_CENTER}
  gap:2rem;
  width: max-content;
  .back-icon {
    ${FLEX_ROW_CENTER}
    background-color: ${SHADES.WHITE};
    width: 40px;
    height: 40px;
    border-radius: ${ROUNDED.full};
    color: ${SHADES.SUB_HEADING};
    font-size: 0.8rem;
  }
`;

const MobileGoBackButtonStyled = styled.button`
  ${FLEX_ROW_CENTER}
  gap: 1rem;
  color: ${props => props.colors.primary_color};
  font-size: 1.25rem;
`;
