import React from "react";
import CustomCheckBox from "./customCheckBox";
import styled from "styled-components";

function CheckBoxGroup({ item, onChange, value, error, innerMember }) {
  const { subQuestion } = item;

  return (
    <CheckBoxWrapperStyled className="w-100">
      {subQuestion.map(item => {
        return (
          <>
            <CustomCheckBox
              name={item.name}
              checkValidation={item.validate}
              innerMember={innerMember}
              width="100%"
              value={value[item.name] ? value[item.name] : "N"}
              onChange={e => {
                onChange("", {
                  ...value,
                  [item.name]: e.target.checked ? "Y" : "N",
                });
              }}
              {...item.additionalOptions}
            />
          </>
        );
      })}
      {error && (
        <MarginWrapperStyled className="formbuilder__error ">
          {error}
        </MarginWrapperStyled>
      )}
    </CheckBoxWrapperStyled>
  );
}

export default CheckBoxGroup;

const CheckBoxWrapperStyled = styled.div`
  width: 100%;
`;

const MarginWrapperStyled = styled.p`
  margin-top: 1rem;
`;
