import { FWEIGHT, SHADES } from "src/styles/styleGuide";

//function that takes min and max age and returns an array of ages with month or year
const getMemberAgeArray = ({ member }) => {
  let ageArray = [];
  let monthCount = parseFloat(member.min_age) * 10;
  if (parseFloat(member.min_age) < 1) {
    while (monthCount < 12) {
      ageArray.push(`${monthCount} months`);
      monthCount++;
    }
    monthCount = 1;
  } else {
    monthCount = +member.min_age;
  }
  for (let i = monthCount; i <= +member.max_age; i++) {
    if (i === 1) {
      ageArray.push(`${i} year`);
    } else {
      ageArray.push(`${i} years`);
    }
  }

  if (member.code.includes("son") || member.code.includes("daughter")) {
    return ["Below 3 months", ...ageArray];
  }
  return ageArray;
};

// It will check if member is selected or not
// then it will return true and display check box as checked
function isMemberSelected({ memberType, selectedMembers }) {
  return selectedMembers.some(member => member.code === memberType) || false;
}

// It will check if member is selected but age is not selected
// then it will return true
function isMemberError({ memberType, selectedMembers }) {
  const memberData = retrieveMemberData({ memberType, selectedMembers });
  return memberData.age === "";
}

// It will return member data from selected members
//  list as per member type
function retrieveMemberData({ memberType, selectedMembers }) {
  return selectedMembers.find(member => member.code === memberType) || {};
}

// Adding validations
function validateMember({ member, selectedMembers, selfGender }) {
  let isBlocked = false;
  let min_age = member.min_age;

  // validation for child, spouse & in-lows where self should be more than 21
  // other wise user can't select member
  const memberMinAge = parseFloat(member.min_age);
  if (
    memberMinAge < 10 ||
    member.code === "spouse" ||
    member.code.includes("in_law")
  ) {
    const self =
      selectedMembers.find(currentMember => currentMember.code === "self") ||
      {};
    if (+self?.age && +self?.age < 21 && selfGender === "M") {
      isBlocked = true;
    }
  }

  // validation for self where if spouse or son or daughter any one
  // selected then self should be more than 21 other wise self start from 18
  if (member.code === "self" && selfGender === "M") {
    const dependMembers =
      selectedMembers.find(
        currentMember =>
          currentMember.code === "spouse" ||
          currentMember.code.includes("son") ||
          currentMember.code.includes("daughter"),
      ) || null;
    if (dependMembers) {
      min_age = "21";
    }
  }

  // validation for spouse if self is female
  // than increase the min_age to more than 21 of spouse
  if (member.code === "spouse" && selfGender === "F") {
    min_age = "21";
  }

  //validation for father, mother, grand father, grand mother, father
  // where if self is more than 21 then increase the age of all to three years

  if (
    member.code === "father" ||
    member.code === "mother" ||
    member.code === "grand_father" ||
    member.code === "grand_mother"
  ) {
    const self =
      selectedMembers.find(currentMember => currentMember.code === "self") ||
      {};
    if (+self?.age >= 21) {
      min_age = +member?.min_age + 3;
    }
  }

  return { isBlocked, min_age };
}

// function that return displayed no of child members
function getChildCount({ membersList }) {
  return membersList.filter(memberValue => {
    if (
      (memberValue.code.includes("son") ||
        memberValue.code.includes("daughter")) &&
      !memberValue.code.includes("_")
    ) {
      return memberValue;
    }
  });
}

function getLastMultiMemberIndex({ membersList, baseMember, memberCount }) {
  return membersList.findIndex(
    memberUnit =>
      memberUnit.code ===
      `${baseMember.code}${memberCount > 1 ? memberCount : ""}`,
  );
}

//Bellow function are used for child multi select configuration and validations
// check if selected member is multi selectable or not
function isMemberMultiSelect({ member }) {
  const memberMinAge = parseFloat(member.min_age);
  const regex = /^[A-Za-z]+$/;
  if (memberMinAge < 10 && regex.test(member.code)) {
    return true;
  }

  return false;
}

// check if member is having multi count eg:  son2, son3
function isMemberMultiCount({ member }) {
  const regex = /\d/;
  if (regex.test(member.code)) {
    return true;
  }

  return false;
}

// count how many members are selected for multi select
function countMultiSelectMember({ member, allMembersList }) {
  const underscoreRegex = /_/;
  const isMultiSelect = isMemberMultiSelect({ member });
  if (!isMultiSelect) return 0;
  const membersCount =
    allMembersList.filter(
      memberUnit =>
        memberUnit.code.includes(member.code) &&
        !underscoreRegex.test(memberUnit.code),
    ) || [];

  return membersCount.length;
}

// function to get selected all members and update to display list
// as per multi selection too
function getOrderedMultiSelectMembers({ multiMembers, allMembersList }) {
  let newALLMembersList = [...allMembersList];
  const sonArray = multiMembers?.filter(memberUnit =>
    memberUnit.code.includes("son"),
  );
  const daughterArray = multiMembers?.filter(memberUnit =>
    memberUnit.code.includes("daughter"),
  );
  const lastSonIndex = newALLMembersList.findIndex(memberUnit =>
    memberUnit.code.includes("son"),
  );
  newALLMembersList.splice(lastSonIndex + 1, 0, ...sonArray);
  const lastDaughterIndex = newALLMembersList.findIndex(memberUnit =>
    memberUnit.code.includes("daughter"),
  );
  newALLMembersList.splice(lastDaughterIndex + 1, 0, ...daughterArray);
  return newALLMembersList;
}

// Styling for Age drop down
const ageDropDownStyle = view => ({
  option: (provided, state) => ({
    ...provided,
    fontSize: view === "D" ? "0.7rem" : "0.5rem",
    padding: "7px",
    textAlign: "left",
    color: state.isSelected ? SHADES.WHITE : SHADES.SUB_HEADING,
  }),
  menuList: provided => ({
    ...provided,
    maxHeight: 100,
    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
  }),
  menu: provided => ({
    ...provided,
    textAlign: "left",
    overflow: "auto",
  }),
  valueContainer: provided => ({
    ...provided,
    padding: 0,
    minWidth: "90px",
  }),
  singleValue: provided => ({
    ...provided,
    color: SHADES.SUB_HEADING,
    fontWeight: FWEIGHT.base,
  }),
  indicatorSeparator: () => ({ display: "none" }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: 0,
    color: SHADES.SUB_HEADING,
    marginLeft: "0.5rem",
    transition: "all .2s ease",
    transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
  }),
  control: provided => ({
    ...provided,
    fontSize: view === "D" ? "0.7rem" : "0.68rem",
    minHeight: "initial",
    width: "max-content",
    padding: "0rem 0.2rem",
    outline: "none",
    border: "0 !important",
    cursor: "pointer",
    color: SHADES.SUB_HEADING,
    // This line disable the blue border
    boxShadow: "0 !important",
    "&:hover": {
      border: "0 !important",
    },
  }),
});

export {
  ageDropDownStyle,
  getMemberAgeArray,
  isMemberSelected,
  isMemberError,
  validateMember,
  getChildCount,
  getLastMultiMemberIndex,
  isMemberMultiSelect,
  isMemberMultiCount,
  countMultiSelectMember,
  retrieveMemberData,
  getOrderedMultiSelectMembers,
};
