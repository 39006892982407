import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
// REACT ICONS
import { FaTimes } from "react-icons/fa";
// import { TbDiscount2 } from "react-icons/tb";
// CUSTOM HOOKS
import {
  useFrontendBoot,
  useGetEnquiry,
} from "src/customHooks/useGetCommonQueries";
import useIsPos from "src/pos/useIsPos";
import useGetRouteMatch from "src/customHooks/useGetRouteMatch";
import { useCompanies, useQuote, useTheme, useToggle } from "src/customHooks";
// UTILS
import { getDisplayPremium, numToLakh } from "src/utils/string.utility";
import { setCartSummaryModalAction } from "src/slices/utility.slice";
import { matchCurrentRoute } from "src/utils/productDetailsPageUtils";
// UTILS
import { calculateTotalPremium, figureToWords } from "src/utils/helper";
// STYLED COMPONENTS
import {
  ButtonStyled,
  DesktopSeeDetailsHeaderWrapperStyled,
  ProductName,
  QuoteInfoHeading,
  QuoteInfoValue,
  QuoteInfoWrapperStyled,
  QuoteDetailLoader,
  IconButtonStyled,
} from "./SeeDetailsHeader.style";
// CUSTOM COMPONENTS
import ICLogoBase from "src/components/Common/ICLogo";
import QuoteCardSelect from "src/pages/quotePage/components/QuoteCard/QuoteCardSelect";
import CustomToolTip from "src/components/Common/CustomTooltip";
import CartSummaryModal from "src/components/CartSummaryModal/CartSummaryModal";
// CONSTANTS
import { plantypes } from "src/pages/ProductDetails/components/ReviewCart/components";
// import useEffectiveCostModal from "src/pages/ProductDetails/Hooks/useEffectiveCostModal";
import { useEventTracking } from "src/customHooks/useEventTracking";
import { useGotoProductDetailsPage } from "src/components";

export default function DesktopSeeDetailsHeader({
  quote,
  sumInsuredChangeHandler,
  deductibleChangeHandler,
  onClose,
  isLoading: isQuoteLoading,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  // const [effectiveCost, setEffectiveCost] = useState([]);
  const { isPosJourney } = useIsPos();
  const { getCompany } = useCompanies();
  const cartSummaryModal = useToggle();
  const { colors } = useTheme();

  const { buyQuote, queryState: buyQuoteQuery } = useQuote();
  const { isQuoteRoute, isShortlistRoute, isCustomPlanRoute } =
    useGetRouteMatch();
  const { groups, currentGroupObject, enquiryData } = useGetEnquiry();
  const { gotoProductPage } = useGotoProductDetailsPage();
  // const { currentTaxSlab, updateTaxSlab } = useEffectiveCostModal();
  const { riders = [] } = useSelector(state => state.productPage);
  const selectedRiders = riders.filter(rider => rider.isSelected);
  const isOnProposalPageOrLater = matchCurrentRoute({
    matcher: "gte",
    route: "proposal",
  });

  const { getCompanyLogo } = useCompanies();
  const { ridersLoading } = useSelector(({ productPage }) => productPage);
  const {
    journeyType,
    data: {
      settings: { restrict_posp_quotes_after_limit, block_csr_visibility },
    },
    // costeffectivecalculation = {},
  } = useFrontendBoot();
  const { webEngageTrackEvent } = useEventTracking();
  // VARIABLE/s
  let deductibleOptions = [];
  let sumInsuredOptions = [];
  const {
    product: { company, name },
    sum_insured,
    total_premium,
    tenure,
    available_sum_insureds = [],
    available_sum_insured_deductibles = [],
    deductible,
  } = quote;

  const netPremium = calculateTotalPremium(
    {
      total_premium,
      health_riders: selectedRiders || [],
      top_up_riders: selectedRiders || [],
    },
    {},
    journeyType,
  );

  const { csr } = getCompany(company.alias);

  // HANDLER/s
  const handleClose = () => {
    onClose();
  };

  const handlePremiumClick = () => {
    webEngageTrackEvent("Health Plans Buy Initiated", {
      "Plan Name": quote?.product?.name,
      "Insurer Name": quote?.product?.company?.name,
      "Custom Plan": false,
      "Policy Type": plantypes[currentGroupObject.plan_type],
      "Insurance Cover": numToLakh(quote?.sum_insured),
      "Premium incl GST": quote?.total_premium,
      "Insurer Logo": [getCompanyLogo(quote?.product?.company?.alias)],
    });

    buyQuote(quote, selectedRiders).then(() => {
      if (groups.length === 1 || currentGroupObject.type === "all") {
        history.push(
          `/productdetails/${currentGroupObject?.id}?enquiryId=${enquiryData?.enquiry_id}`,
        );
        onClose?.();
        return;
      }
      dispatch(setCartSummaryModalAction(true));
      onClose();
    });
  };

  if (journeyType === "top_up") {
    const allDeductibles = available_sum_insured_deductibles?.map(
      item => item.deductible,
    );
    const uniqueDeductibles = [...new Set(allDeductibles)].sort(
      (a, b) => a - b,
    );

    deductibleOptions = getSumInsuredOptions(uniqueDeductibles);

    sumInsuredOptions = getSumInsuredOptions(
      available_sum_insured_deductibles
        .filter(item => item.deductible === deductible)
        .map(item => item.sum_insured),
    );
  } else {
    sumInsuredOptions = getSumInsuredOptions(available_sum_insureds);
  }

  if (isPosJourney && restrict_posp_quotes_after_limit === `${1}`) {
    sumInsuredOptions = sumInsuredOptions?.filter(si => si.value < 500001);
  }

  // comment code for effective cost
  // useEffect(() => {
  //   if (netPremium) {
  //     let effectiveCostObj = [
  //       {
  //         label: `${amount(netPremium - (netPremium / 100) * 5)} (5%)`,
  //         value: 5,
  //       },
  //       {
  //         label: `${amount(netPremium - (netPremium / 100) * 20)} (20%)`,
  //         value: 20,
  //       },
  //       {
  //         label: `${amount(netPremium - (netPremium / 100) * 31)} (31%)`,
  //         value: 31,
  //       },
  //     ];
  //     setEffectiveCost(effectiveCostObj);
  //   }
  // }, [netPremium]);

  return (
    <>
      <DesktopSeeDetailsHeaderWrapperStyled>
        <div className="logo-name">
          <ICLogoBase alias={company?.alias} />
          <ProductName>{name}</ProductName>
        </div>

        <div className="infowrapper">
          <QuoteInfoWrapperStyled colors={colors}>
            {journeyType === "top_up" && (
              <>
                <div>
                  <QuoteInfoHeading>Deductible</QuoteInfoHeading>
                  <QuoteInfoValue>
                    {isQuoteLoading ? (
                      <QuoteDetailLoader />
                    ) : deductibleOptions?.length === 0 || !isQuoteRoute ? (
                      <QuoteInfoValue>
                        ₹ {figureToWords(deductible)}
                      </QuoteInfoValue>
                    ) : (
                      <QuoteCardSelect
                        options={deductibleOptions}
                        value={{
                          value: +deductible,
                          label: `₹ ${figureToWords(deductible)}`,
                        }}
                        fontSize={".8rem"}
                        onChange={deductibleChangeHandler}
                      />
                    )}
                  </QuoteInfoValue>
                </div>
                <span>|</span>
              </>
            )}
            {journeyType === "health" && (
              <>
                <div>
                  <QuoteInfoHeading>Policy Type</QuoteInfoHeading>
                  <span>
                    <QuoteInfoValue>
                      {
                        plantypes[
                          isOnProposalPageOrLater
                            ? quote?.group.plan_type
                            : currentGroupObject?.plan_type
                        ]
                      }
                    </QuoteInfoValue>
                  </span>
                </div>
                <span>|</span>
              </>
            )}
            <div>
              <QuoteInfoHeading>Cover</QuoteInfoHeading>
              {isQuoteLoading ? (
                <QuoteDetailLoader />
              ) : sumInsuredOptions?.length === 0 || !isQuoteRoute ? (
                <QuoteInfoValue>₹ {figureToWords(sum_insured)}</QuoteInfoValue>
              ) : (
                <QuoteCardSelect
                  options={sumInsuredOptions}
                  value={{
                    value: +sum_insured,
                    label: `₹ ${figureToWords(sum_insured)}`,
                  }}
                  fontSize={".8rem"}
                  onChange={sumInsuredChangeHandler}
                />
              )}
            </div>
            <span>|</span>

            <div>
              <QuoteInfoHeading>Total Premium</QuoteInfoHeading>
              <span>
                {ridersLoading || isQuoteLoading ? (
                  <QuoteDetailLoader />
                ) : (
                  <QuoteInfoValue>
                    {getDisplayPremium({ total_premium: netPremium, tenure })}
                  </QuoteInfoValue>
                )}
              </span>
            </div>
            {/* 
            {effectiveCost.length &&
              Object.keys(costeffectivecalculation).length > 0 &&
              Object.values(costeffectivecalculation).some(
                costEffectiveValue => costEffectiveValue,
              ) && (
                <>
                  <span>|</span>
                  <div>
                    <QuoteInfoHeading>
                      Effective Cost
                      <CustomToolTip
                        description={`Under Section 80D You can get tax benefit as per the tax slab of 5%, 20%or 31% . To know more benefits please proceed to product details. By clicking on premium amount.`}
                        showInfoIcon={false}
                      >
                        <span>
                          <TbDiscount2 size={18} />
                        </span>
                      </CustomToolTip>
                    </QuoteInfoHeading>
                    <QuoteCardSelect
                      value={
                        effectiveCost.find(
                          item => item.value === currentTaxSlab,
                        ) || {
                          value: 31,
                          label: `${amount(
                            netPremium - (netPremium / 100) * 31,
                          )} (31%)`,
                        }
                      }
                      options={effectiveCost}
                      onChange={e => updateTaxSlab(e.value)}
                    />
                  </div>
                </>
              )} */}

            {+block_csr_visibility === 0 && (
              <>
                <span>|</span>
                <div>
                  <QuoteInfoHeading>
                    CSR
                    <CustomToolTip
                      description="Claim Settlement Ratio of an Insurer is the number of
                          claims settled against the number of claims filed."
                      showInfoIcon={true}
                    ></CustomToolTip>
                  </QuoteInfoHeading>
                  <QuoteInfoValue>{csr}%</QuoteInfoValue>
                </div>
              </>
            )}
          </QuoteInfoWrapperStyled>

          {journeyType === "top_up" ||
          isQuoteRoute ||
          isShortlistRoute ||
          isCustomPlanRoute ? (
            <ButtonStyled
              onClick={handlePremiumClick}
              font="subheading1"
              loader={buyQuoteQuery.isLoading}
              disabled={
                !isQuoteRoute && !isShortlistRoute && !isCustomPlanRoute
              }
            >
              {journeyType === "top_up"
                ? getDisplayPremium({ total_premium: netPremium, tenure })
                : "Proceed to Buy"}
            </ButtonStyled>
          ) : (
            <></>
          )}

          <IconButtonStyled onClick={handleClose}>
            <FaTimes />
          </IconButtonStyled>
        </div>
      </DesktopSeeDetailsHeaderWrapperStyled>
      {cartSummaryModal.isOn && (
        <CartSummaryModal
          selectedRiders={selectedRiders}
          onClose={cartSummaryModal.off}
          onContinue={gotoProductPage}
          allClose={handleClose}
        />
      )}
    </>
  );
}

export function getSumInsuredOptions(arr = []) {
  return arr?.map(item => ({
    value: item,
    label: `₹ ${figureToWords(item)}`,
  }));
}
