import {
  CartMobileBodyStyled,
  CartMobileFooterStyled,
  CartMobileHeaderStyled,
  CartMobileOverlay,
  CartMobileStyled,
  ToggleCartButtonStyled,
} from "src/pages/ProductDetails/components/CartMobile/CartMobile.style";
import FHTypography from "src/styles/Typography";
import { useTheme, useToggle, useCart, useMembers } from "src/customHooks";
import { FaChevronUp } from "react-icons/fa";
import PlanInfoCard from "src/pages/ProductDetails/components/CartMobile/components/PlanInfoCard";
import { CartProceedButtonStyled } from "src/pages/ProductDetails/components/CartMobile/components/CartProceedButton";
import { useSelector } from "react-redux";
import useFormNavigation from "./useFormNavigation";
import PlanDetails from "src/pages/ProductDetails/components/CartMobile/components/PlanDetails";
import { useFrontendBoot } from "src/customHooks/useGetCommonQueries";
import {
  DiscountDetailSection,
  FeatureDetailSection,
  RiderDetailSection,
} from "src/pages/ProductDetails/components/CartMobile/components/DetailSections";
import useKYCDetails from "./useKYCDetails";
import { capitalize, premiumWithAddons } from "src/utils/helper";
import { useEffect } from "react";
import useHandleBodyScroll from "src/customHooks/useHandleBodyScroll";
import { PriceStyled } from "./components/ProductSummary.style";
import { amount } from "src/utils/string.utility";

const ProposalCartMobile = ({ setIsContinueClicked }) => {
  const { colors } = useTheme();
  const { isLoading } = useSelector(state => state.proposalPage);
  const { activeForm, navigateTo } = useFormNavigation();
  const { journeyType } = useFrontendBoot();
  const cartMobileToggle = useToggle(false);
  const { kycVerified } = useKYCDetails();
  const { cartEntries, getCartTotalPremium } = useCart();
  const { getMembersText } = useMembers();
  const { hideBodyOverflow, unsetBodyOverflow } = useHandleBodyScroll();

  useEffect(() => {
    if (cartMobileToggle.isOn) {
      hideBodyOverflow();
    } else {
      unsetBodyOverflow();
    }
    return () => {};
  }, [cartMobileToggle.isOn]);

  const totalPremium = getCartTotalPremium();

  const revisedNetPremium = totalPremium;

  const allAddons = cartEntries
    ?.map(singleCartEntry => singleCartEntry.addons)
    ?.flat();

  return (
    <>
      <CartMobileOverlay data-cart-mobile-open={cartMobileToggle.isOn} />
      <CartMobileStyled>
        <CartMobileBodyStyled data-cart-mobile-open={cartMobileToggle.isOn}>
          <div>
            <CartMobileHeaderStyled colors={colors}>
              <div className="your-cart-text">
                <FHTypography variant="h4" weight="semi" colorType="heading">
                  Your Plan Details
                </FHTypography>
              </div>
            </CartMobileHeaderStyled>
            <div className="cartbody">
              {/* cart body */}
              {cartEntries.map(cartEntry => (
                <div key={cartEntry.id}>
                  <FHTypography
                    className="membersText"
                    variant="h6"
                    weight="semi"
                    colorType="body"
                  >
                    {capitalize(getMembersText({ id: cartEntry?.group.id }))}
                  </FHTypography>
                  <PlanInfoCard cartEntry={cartEntry} />

                  <PlanDetails
                    cartEntry={cartEntry}
                    journeyType={journeyType}
                  />
                  <RiderDetailSection
                    groupCode={cartEntry.group.id}
                    cartMobileToggle={cartMobileToggle}
                  />
                  <FeatureDetailSection
                    groupCode={cartEntry.group.id}
                    cartMobileToggle={cartMobileToggle}
                  />
                  <DiscountDetailSection
                    groupCode={cartEntry.group.id}
                    cartMobileToggle={cartMobileToggle}
                  />
                </div>
              ))}
            </div>
          </div>
        </CartMobileBodyStyled>
        {/* cart body */}

        {/* footer for any action button*/}
        <CartMobileFooterStyled>
          <div>
            <div className="totalpremium">
              <FHTypography variant="h5" weight="semi" colorType="body">
                Total Premium
              </FHTypography>
              <FHTypography variant="h6" weight="base" colorType="subHeading">
                (inc.GST) :
              </FHTypography>
            </div>

            <div className="amount">
              <PriceStyled id="cartPremium" ismobile>
                {amount(premiumWithAddons(revisedNetPremium, allAddons))}
              </PriceStyled>{" "}
              {cartEntries?.length === 1 ? (
                cartEntries[0]?.tenure === 1 ? (
                  <FHTypography className="year"> /year </FHTypography>
                ) : cartEntries[0]?.tenure === 2 ? (
                  <FHTypography className="year"> /2years </FHTypography>
                ) : cartEntries[0]?.tenure === 3 ? (
                  <FHTypography className="year"> /3years </FHTypography>
                ) : (
                  ""
                )
              ) : (
                " "
              )}
              <div className="multi-plan-text">
                <ToggleCartButtonStyled
                  btnId="toggle-cart"
                  colors={colors}
                  onClick={cartMobileToggle.toggle}
                  data-cart-mobile-open={cartMobileToggle.isOn}
                >
                  <FaChevronUp />
                </ToggleCartButtonStyled>
              </div>
            </div>
          </div>

          {activeForm === "KYC Details" && kycVerified === true ? (
            <CartProceedButtonStyled
              onClick={() => navigateTo({ formName: "Insured Details" })}
            >
              Proceed
            </CartProceedButtonStyled>
          ) : (
            <CartProceedButtonStyled
              loader={isLoading}
              onClick={() => setIsContinueClicked(activeForm)}
              loaderSize={16}
            >
              <FHTypography variant="h4" weight="semi" colorType="white">
                {" "}
                Continue{" "}
              </FHTypography>
            </CartProceedButtonStyled>
          )}
        </CartMobileFooterStyled>
        {/* footer for any action button*/}
      </CartMobileStyled>
    </>
  );
};

export default ProposalCartMobile;
