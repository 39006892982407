import { useEffect } from "react";
import styled from "styled-components";
import { IoIosInformationCircleOutline } from "react-icons/io";
import CustomToolTip from "src/components/Common/CustomTooltip";

const LastNameCheckBox = ({ label, name, onChange, value }) => {
  useEffect(() => {
    onChange({ target: { checked: value } });
  }, []);

  const lastNameDescription =
    "Please check this box if you do not have a last name on your official government ID you will be using for KYC verification";

  return (
    <ContainerStyled>
      <input
        id={`${name}_check`}
        type="checkbox"
        checked={value}
        onChange={onChange}
      />
      <label htmlFor={`${name}_check`}>
        {label}

        <CustomToolTip description={lastNameDescription} showInfoIcon={false}>
          <span>
            <IoIosInformationCircleOutline size={14} />
          </span>
        </CustomToolTip>
      </label>
    </ContainerStyled>
  );
};

export default LastNameCheckBox;

const ContainerStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  .span {
    margin-left: 5px;
  }
  & label {
    font-size: 14px;
  }
`;
