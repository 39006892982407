import styled from "styled-components/macro";
// CUSTOM HOOKS
import { useTheme } from "src/customHooks";
import { useFrontendBoot } from "src/customHooks/useGetCommonQueries";
// UTILS
import { brokerTenant } from "src/utils/tenantSettings";
// DESIGN SYSTEM
import { MAX, VIEW } from "src/styles/responsiveStyle";
import { FLEX_COL } from "src/styles/styleGuide";
import FHTypography from "src/styles/Typography";
// CUSTOM COMPONENTS
import { Button } from "src/components/Common/Button/ContinueButton";

// CONSTANTS - using static image for RB as image link access is not provided
import { ckyc as ckycPopupImage } from "src/assets/images";

import Modal from "../../../../components/Common/Popups/ReusableModal/Modal";
export default function CKycInfoPopup({ onClose }) {
  const {
    settings: { ckycpopupmessage, ckycpopupimage },
  } = useFrontendBoot();

  const { colors } = useTheme();
  const isRB = brokerTenant("renew_buy");
  const isHero = brokerTenant("hero");

  return (
    <Modal
      isModalOpen={true}
      onClose={onClose}
      title="KYC Verification"
      width="fit-content"
      mobileHeight="fit-content"
      height="fit-content"
    >
      <CKycWrapperStyled>
        {ckycpopupmessage ? (
          <CKycInfoWrapperStyled
            isRB={isRB}
            color={colors.primary_color}
            dangerouslySetInnerHTML={{
              __html: ckycpopupmessage,
            }}
          />
        ) : (
          <DefaultMessage />
        )}

        {ckycpopupimage && !(isRB || isHero) && (
          <CKycInfoBgStyled
            src={ckycpopupimage}
            ckyc={ckycpopupimage ? true : false}
          />
        )}
        {/* using static image for RB */}
        {isRB && <CKycInfoBgStyled src={ckycPopupImage} ckyc={isRB} />}
      </CKycWrapperStyled>
      <CKycInfoButtonStyled>
        <Button btnId="proceed-kyc" onClick={onClose}>
          Okay
        </Button>
      </CKycInfoButtonStyled>
    </Modal>
  );
}

const CKycWrapperStyled = styled.div`
  display: flex;

  align-items: center;
  justify-content: space-around;
`;
const CKycInfoWrapperStyled = styled.div`
  font-size: 1rem;
  letter-spacing: 0.3px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  * {
    font-family: inherit !important;
    font-size: inherit !important;
  }

  h2 {
    font-size: 1.2rem;
    font-weight: 600;
    padding: 10px 5px;
  }
  div {
    /* padding: 5px 10px; */
    margin: 5px;
    & ::before {
      content: " * ";
      display: ${props => (props?.isRB ? "inline" : "none")};
      color: ${props => props.color};
      font-size: 1.2rem;
    }
  }
  ${VIEW.md} {
    font-size: 0.8rem;
  }
  ${VIEW.lg} {
    font-size: 0.8rem !important;
  }
  ${MAX.sm} {
    div {
      width: auto !important;
    }
  }
`;
const CKycInfoBgStyled = styled.img`
  width: ${props => (props.ckyc ? "40%" : "0")};
  margin: 0 2%;
  ${MAX.md} {
    display: none;
  }
`;
const CKycInfoButtonStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 10px;
  ${VIEW.lg} {
    margin: auto;
  }
`;

const DefaultMessage = () => {
  return (
    <DefaultMessageWrapperStyled>
      <FHTypography
        variant="h4"
        weight="semi"
        colorType="heading"
        className="info-heading"
      >
        As per IRDAI KYC is mandatory for all the retail policies from 1st
        January 2023.
      </FHTypography>

      <FHTypography variant="subheading1" weight="md">
        <b> Customer Name </b> and <b> Date of Birth </b> should match with the
        document used for CKYC verification ie. <b> PAN card </b>,
        <b> Aadhaar </b> etc.
      </FHTypography>
      <FHTypography variant="subheading1" weight="md">
        All the documents should be clear and have same <b> Customer Name </b>
        and <b> Date of Birth </b> which are uploaded on Insurance portal for
        CKYC verification.
      </FHTypography>
    </DefaultMessageWrapperStyled>
  );
};

const DefaultMessageWrapperStyled = styled.div`
  ${FLEX_COL}
  gap:1rem;

  &,
  .info-heading {
    margin-bottom: 1rem;
  }

  b {
    font-weight: 600;
  }
`;
