import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom.min";
import { useGetLocationDetailsQuery } from "src/api/api";
import { useUpdateEnquiry } from "src/customHooks";
import {
  useFrontendBoot,
  useGetEnquiry,
} from "src/customHooks/useGetCommonQueries";
import { getUrlQueries } from "src/utils/url.utility";

function useEditLocationStep() {
  const { enquiryId } = getUrlQueries();
  const { groups, groupCode } = useGetEnquiry();
  const {
    data: { popularcities },
    subJourneyType,
  } = useFrontendBoot();
  const history = useHistory();
  const isQuotesRoute = useRouteMatch("/quotes");
  const { updateEnquiry, ...updateEnquiryQuery } = useUpdateEnquiry();
  const [activeGroup, setActiveGroup] = useState(null);
  const [groupsName, setGroupsName] = useState([]);

  // STATE/s
  const [pincodeValue, setPincodeValue] = useState("");
  const [pincodeError, setPincodeError] = useState("");
  const { isFetching, data } = useGetLocationDetailsQuery(
    { search: pincodeValue },
    { skip: pincodeValue.length <= 2 || pincodeValue.length > 6 },
  );

  // EFFECT/s

  useEffect(() => {
    if (pincodeError) {
      setTimeout(() => {
        setPincodeError("");
      }, 3000);
    }
    return () => {};
  }, [pincodeError]);

  // Set active group when groups changes
  // if there is no group with pincode then set the first group as active group
  useEffect(() => {
    if (!activeGroup && groups.length) {
      const nonLocationGroup = groups.find(
        group => !group.pincode && group.type !== "all",
      );

      if (nonLocationGroup) {
        setActiveGroup({
          ...nonLocationGroup,
          groupName: getGroupName({ membersArray: nonLocationGroup.members }),
          groupTitle: getGroupName({
            membersArray: nonLocationGroup.members,
            isTitle: true,
          }),
        });
      } else {
        setActiveGroup({
          ...groups[0],
          groupName: getGroupName({ membersArray: groups[0].members }),
          groupTitle: getGroupName({
            membersArray: groups[0].members,
            isTitle: true,
          }),
        });
      }
    }

    // Get all the groups name
    const groupsName = groups.map(group => {
      return {
        ...group,
        groupName: getGroupName({ membersArray: group.members }),
        groupTitle: getGroupName({
          membersArray: group.members,
          isTitle: true,
        }),
      };
    });
    setGroupsName(groupsName);
  }, [groups, activeGroup]);

  // Set pincode value when active group changes
  useEffect(() => {
    if (activeGroup && activeGroup.pincode) {
      const { pincode } = activeGroup;
      setPincodeValue(pincode);
    } else {
      setPincodeValue("");
    }
    return () => {};
  }, [activeGroup]);

  // FUNCTION/s

  function getGroupName({ membersArray = [], isTitle = false }) {
    if (isTitle) {
      return "your " + membersArray.join(", ").split("_").join(" ");
    }
    return membersArray.join(", ").split("_").join(" ");
  }

  async function handleUpdateLocation({
    pincodeValue,
    onClose,
    setEditStep = () => {},
  }) {
    if (pincodeValue === activeGroup.pincode) {
      if (subJourneyType === "port" && isQuotesRoute) {
        setEditStep(3);
        return;
      }
      onClose();
      return;
    }

    if (!pincodeValue) {
      setPincodeError("Please enter pincode!");
      return;
    }

    if (pincodeValue.toString()?.length !== 6 || data?.length === 0) {
      setPincodeError("Invalid pincode!");
      return;
    }

    // if first group updated then update type all group too
    const typeAllGroup = groups?.find(group => group.type === "all");
    if (activeGroup.members.includes("self") && typeAllGroup) {
      await updateEnquiry({
        is_pincode_search: false,
        pincode: pincodeValue,
        groupCode: typeAllGroup.id,
      });
    }

    updateEnquiry({
      is_pincode_search: false,
      pincode: pincodeValue,
      groupCode: activeGroup.id,
    }).then(res => {
      const errors = res.some(res => !!res.error);
      const newGroups = res?.[1].data?.data?.groups || [];

      const nonPincodeGroups = newGroups?.filter(
        group => group.type !== "all" && !group.pincode,
      );

      // if no errors and non empty pincode groups then redirect to first group
      // with updated enquiry id
      if (!errors && !nonPincodeGroups.length) {
        if (subJourneyType === "port" && isQuotesRoute) {
          setEditStep(3);
          return;
        }
        history.push(`/quotes/${activeGroup.id}?enquiryId=${enquiryId}`);
        onClose();
      } else {
        // change the enquiry id in url and redirect to first group
        // with empty pincode
        history.push(`/quotes/${groupCode}?enquiryId=${enquiryId}`);
        setActiveGroup({
          ...nonPincodeGroups[0],
          groupName: getGroupName(nonPincodeGroups[0].members),
        });
      }
    });
  }

  return {
    activeGroup,
    groups,
    groupsName,
    pincodeValue,
    popularcities,
    updateEnquiryQuery,
    isFetching,
    locationData: data,
    pincodeError,
    setPincodeError,
    setPincodeValue,
    setActiveGroup,
    handleUpdateLocation,
  };
}

export default useEditLocationStep;
