// CUSTOM HOOKS
import { useTheme } from "src/customHooks";
// CUSTOM COMPONENTS
import CustomSelect from "src/components/CustomSelect";
import SearchableDropDown from "src/components/SearchableDropDown";
import { useSelector } from "react-redux";
import useGetRouteMatch from "src/customHooks/useGetRouteMatch";

export default function RiderOption({
  option: { key, options, selected_option_value },
  onChange,
  onMultipleChange,
  option_key,
  rider,
  configurationObj,
  isDisabled = false,
}) {
  const { colors } = useTheme();
  const { seeDetails } = useSelector(state => state.utilities);
  const {
    isCompareRoute,
    isProductDetailsRoute,
    isProposalRoute,
    isProposalSummaryRoute,
    isThankYouRoute,
  } = useGetRouteMatch();
  const { dependent_options, options: rider_options, options_selected } = rider;
  const isRiderOptionNested =
    Object.keys(options_selected).filter(option => `${option}_option` === key)
      ?.length == 1;

  const defaultSelectedRoute =
    isProposalRoute || isProposalSummaryRoute || isThankYouRoute;

  const disableRiderOption =
    defaultSelectedRoute ||
    (seeDetails.isSeeDetailsModalOpen &&
      (isProductDetailsRoute || isCompareRoute))
      ? true
      : false;
  const handleChange = evt => {
    if (
      dependent_options &&
      dependent_options[option_key] &&
      onMultipleChange
    ) {
      const updateArray = [
        {
          key,
          selected: evt.value,
          isSelected: true,
        },
      ];

      const dependentOptionsAliasArray = dependent_options[option_key];

      dependentOptionsAliasArray.forEach(d_op_alias => {
        const dependentRiderOptions = rider_options[d_op_alias];

        return updateArray.push({
          key: d_op_alias,
          selected: dependentRiderOptions[evt.value][0],
          isSelected: true,
        });
      });

      return onMultipleChange(updateArray);
    }

    return (
      onChange &&
      onChange({
        key,
        selected: evt.value,
        isSelected: true,
      })
    );
  };

  let optionsList = [];
  let selectedOption = {};

  switch (true) {
    //  for cases where one option is dependent on other option's value
    case isRiderOptionNested: {
      const selectedParentOption = options_selected[`${key.slice(0, -7)}`];
      optionsList = options[selectedParentOption].map(optionKey => ({
        code: optionKey,
        display_name: optionKey,
      }));
      break;
    }

    //  for cases where options is an array of object
    case typeof options[0] === "object": {
      optionsList = options.map(option => ({
        code: Object.keys(option)[0],
        display_name: Object.values(option)[0],
      }));
      break;
    }

    // for cases where options is an array
    case Array.isArray(options): {
      optionsList = Object.keys(options).map(optionKey => ({
        code: options[optionKey],
        display_name: options[optionKey],
      }));
      break;
    }

    // for cases where options is an object
    case typeof options === "object": {
      optionsList = Object.keys(options).map(optionKey => ({
        code: optionKey,
        display_name: options[optionKey],
      }));
      break;
    }
  }

  selectedOption = optionsList.find(
    option => option.code === selected_option_value + "",
  );

  if (!optionsList?.length) return <></>;

  let dropDown = (
    <CustomSelect
      optionsList={optionsList}
      selectedOption={selectedOption}
      handleChange={handleChange}
      isDisabled={isDisabled || disableRiderOption}
    />
  );

  if (Object.keys(configurationObj)) {
    if (configurationObj?.type === "searchable_dropdown") {
      dropDown = (
        <SearchableDropDown
          rawOptions={optionsList}
          selectedOption={{
            value: selectedOption?.code,
            label: selectedOption?.display_name,
            key: selectedOption?.code,
          }}
          setSelectedOption={handleChange}
          inputCss={`
            max-width: 150px !important;
            outline: unset !important;
            background: ${colors.primary_shade};
            font-weight: 600;
            font-size : 14px;
            border-radius : 4px;
            padding: 5px 5px;
            box-sizing : border-box;
            min-height: initial;
            min-width: 90px;
            border: unset;
            color : hsl(0, 0%, 20%);
          `}
          inputType="tel"
          tooltip_text={configurationObj?.tooltip_text}
          isDisabled={isDisabled || disableRiderOption}
        />
      );
    }
    if (configurationObj?.type === "select_dropdown") {
      dropDown = (
        <CustomSelect
          optionsList={optionsList}
          selectedOption={selectedOption}
          handleChange={handleChange}
          tooltip_text={configurationObj?.tooltip_text}
          tooltip_placement="right"
          isDisabled={isDisabled || disableRiderOption}
        />
      );
    }
  }

  return <div>{dropDown}</div>;
}
