import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "styled-components/macro";
import { useLazyGetLocationDetailsQuery } from "../../../api/api";
import FormBuilder from "../../../components/FormBuilder/FormBuilder";
import { callApi } from "../../../components/FormBuilder/FormBuilder.slice";
import BackBtn from "../components/Buttons/BackBtn";
import ContinueBtn from "../components/Buttons/ContinueBtn";
import { components } from "../components/componentSchema";
import { Form } from "./../ProposalPage.style";
import { useGetEnquiry } from "src/customHooks/useGetCommonQueries";
import RevisedPremiumPopup from "../../ProductDetails/components/ReviewCart/components/RevisedPremiumPopup";
import useFormNavigation from "./useFormNavigation";
import useProposalSections from "./useProposalSections";
import { setIsContinueBtn } from "../schema.slice";

const ProposerDetails = ({
  formSchema = [],
  setActive,
  rawSchema = {},
  name,
  defaultValue = {},
  setProposerDeactivate,
  setActivateLoader,
  setBlockTabSwitch = () => {},
  listOfForms,
  isContinueClicked,
  setIsContinueClicked,
}) => {
  const {
    values,
    setValues,
    setValid,
    submit,
    setSubmit,
    triggerSaveForm,
    revisedPremiumPopupUtilityObject,
    setErrorInField,
    setErrors,
  } = useProposalSections({
    setActive,
    name,
    setActivateLoader,
    setBlockTabSwitch,
    listOfForms,
    schema: rawSchema,
    activeFormSchema: formSchema,
  });

  const proposalSelectedDOBRedux = useSelector(
    ({ proposalPage }) => proposalPage?.proposalData["Proposer Details"]?.dob,
  );

  const [lazyLocationTrigger] = useLazyGetLocationDetailsQuery();

  const { navigateTo, formIndexGenerator } = useFormNavigation();
  const dispatch = useDispatch();
  //? const disableContinue = !everyRequiredFilled(schema, values);
  const {
    data: {
      data: {
        input: { gender, members },
        name: proposerName,
        mobile,
        email,
      },
    },
  } = useGetEnquiry();

  // functions

  useEffect(() => {
    //? To handle continue click on Proposal page Mobile
    if (isContinueClicked) {
      setSubmit(true);
      triggerSaveForm({
        sendedVal: values,
        formName: "Proposer Details",
      });
      setIsContinueClicked("");
    }
    return () => {};
  }, [isContinueClicked]);

  useEffect(() => {
    if (name === "Proposer Details") {
      let proposerAge = parseInt(
        members?.filter(i => i.type === "self")[0]?.age,
      );

      let currentYear = new Date().getFullYear();

      let estimatedProposerDOB = `${currentYear - proposerAge}`;

      let prefilledValues = {
        name: proposerName,
        gender,
        mobile,
        email,
        dob: proposalSelectedDOBRedux || estimatedProposerDOB,
        title: gender === "M" ? "mr" : "",
      };

      formSchema.forEach(item => {
        if (item?.value) {
          prefilledValues = { ...prefilledValues, [item?.name]: item?.value };
        }
        if (item.fill && item.name === "pincode") {
          dispatch(
            callApi(item?.fill?.using, {
              [item.name]: item?.value,
              [item.fill.alsoUse]: values[item?.fill?.alsoUse],
            }),
            item.fill,
          );
        }
      });

      if (!Object.keys(prefilledValues).some(key => key.includes("city"))) {
        //* When city is absent;

        const thePinCodeKey = Object.keys(prefilledValues).find(key =>
          key.includes("pincode"),
        );

        const thePinCodeKeyGroup = thePinCodeKey.split("_")[1];

        lazyLocationTrigger({ search: prefilledValues[thePinCodeKey] }).then(
          res => {
            if (res.error) return;

            const initialUpdateValues = {
              ...{
                ...prefilledValues,
                [thePinCodeKeyGroup ? `city_${thePinCodeKeyGroup}` : "city"]:
                  res?.data[0]?.city,
                [thePinCodeKeyGroup ? `state_${thePinCodeKeyGroup}` : "state"]:
                  res?.data[0]?.state,
              },
              ...defaultValue,
            };

            setValues(initialUpdateValues);
          },
        );
      } else {
        const initialUpdateValues = { ...prefilledValues, ...defaultValue };

        setValues(initialUpdateValues);
      }
    }
    return () => {};
  }, []);

  return (
    <>
      <div>
        <Form>
          <FormBuilder
            schema={formSchema}
            formName={name}
            components={components}
            fetchValues={setValues}
            fetchErrors={setErrors}
            fetchValid={setValid}
            submitTrigger={submit}
            setSubmit={setSubmit}
            options={{ defaultValues: values, validateOn: "change" }}
            setErrorInField={setErrorInField}
            isPanelVisible={true}
          />
        </Form>
      </div>
      <div className="proposal_continue_back_margin container">
        <BackBtn
          hide={formIndexGenerator(name) === 0}
          onClick={() => {
            setProposerDeactivate(false);
            navigateTo({ back: true });
          }}
        />
        <ContinueBtn
          btnId="proposer_details_continue_btn"
          onClick={() => {
            dispatch(setIsContinueBtn(true));

            setSubmit(true);
            triggerSaveForm({
              sendedVal: values,
              formName: "Proposer Details",
            });
          }}
          disabled={false}
        />
        {revisedPremiumPopupUtilityObject.isOnProposal && (
          <RevisedPremiumPopup
            revisedPremiumPopupUtilityObject={revisedPremiumPopupUtilityObject}
            onClose={revisedPremiumPopupUtilityObject.off}
          />
        )}
      </div>
    </>
  );
};

export default ProposerDetails;
