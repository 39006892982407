// check for policy is expired or not
export function getDeadLineInSec(createdAtTime) {
  let timeLeftForToday = 10;
  try {
    const deadlineDateExtract = createdAtTime?.split(" ")[0];
    if (deadlineDateExtract && deadlineDateExtract !== "") {
      const parsedTime = new Date(`${deadlineDateExtract}T23:55:59`);
      timeLeftForToday = parsedTime.getTime() - Date.now();
    }
  } catch (error) {
    console.error(error);
  }
  return timeLeftForToday;
}
