import { Button } from "src/components/Common/Button/ContinueButton";
import { memo } from "react";
import Spinner from "src/components/Common/Spinner";
import styled from "styled-components";

const SchemaError = ({ title = "", refetch, isLoading = false }) => {
  return (
    <SchemaErrorSection>
      <div className="error_container">
        <h5>{title}</h5>
        {refetch ? (
          <Button className="error_btn" onClick={refetch}>
            {isLoading ? <Spinner /> : "Retry"}
          </Button>
        ) : (
          <></>
        )}
      </div>
    </SchemaErrorSection>
  );
};

const SchemaErrorSection = styled.section`
  width: 100%;
  min-height: 300px;
  box-shadow: 0 0 4px red;
  border-radius: 0.5rem;
  display: grid;
  place-items: center;
  opacity: 0.8;
  margin-bottom: 1rem;
  .error_container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: center;
  }
  h5 {
    font-weight: 600;
  }
  .error_btn {
    min-width: 100px;
    border-radius: 0.5rem;
  }
`;

export default memo(SchemaError);
