import styled from "styled-components";
// DESIGN SYSTEM
import { MAX, VIEW } from "src/styles/responsiveStyle";
import {
  FLEX_COL,
  FLEX_COL_CENTER,
  FLEX_ROW,
  FLEX_ROW_CENTER,
  ROUNDED,
  SHADES,
} from "src/styles/styleGuide";
import FHTypography from "src/styles/Typography";

const CartMobileOverlay = styled.div`
  position: fixed;
  inset: 0;
  z-index: 999;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0);
  transition: all 300ms ease-in;
  &[data-cart-mobile-open="true"] {
    visibility: visible;
    background-color: rgba(0, 0, 0, 0.5);
  }
  ${VIEW.md} {
    display: none;
  }
`;

const CartMobileStyled = styled.section`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  background: #fff;
  border-top-left-radius: ${ROUNDED.xxl};
  border-top-right-radius: ${ROUNDED.xxl};
  box-shadow: rgba(0, 0, 0, 0.35) 0px -3px 15px;
  overflow: hidden;
  transition: all 300ms ease-in-out;
  ${VIEW.md} {
    display: none;
  }

  .membersText {
    padding: 0.5rem 0.75rem 0;
  }
`;

const CartMobileHeaderStyled = styled.header`
  ${FLEX_ROW_CENTER}
  justify-content:space-between;
  padding: 0.75rem;
  background-color: ${props => props.colors.primary_shade};
  .your-cart-text {
    ${FLEX_COL}
    gap:0.25rem;
  }

  .multi-plan-text {
    ${FLEX_ROW_CENTER};
    gap: 0.5rem;
  }
`;

const CartActionsWrapperStyled = styled.div`
  ${FLEX_ROW_CENTER}
  gap:1rem;
`;

const EditMembersButtonStyled = styled.button`
  background-color: #fff;
  border: 1px solid ${props => props.colors.primary_color};
  ${FLEX_ROW_CENTER}
  justify-content:center;
  padding: 0.75rem 1.5rem;
  border-radius: ${ROUNDED.md};
  h6 {
    color: ${props => props.colors.primary_color};
  }
  svg {
    font-size: 0.75rem;
    color: ${props => props.colors.primary_color};
  }
`;

const ToggleCartButtonStyled = styled.button`
  border: 1px solid ${props => props.colors.primary_shade};
  border-radius: ${ROUNDED.full};
  background-color: ${props => props.colors.primary_shade};
  margin-left: 0.5rem;
  padding: 0.3rem;
  ${FLEX_ROW_CENTER}
  justify-content:center;
  &[data-cart-mobile-open="true"] {
    rotate: 180deg;
  }
  transition: rotate 300ms ease-in-out;
`;

const PlanInfoContainer = styled.div`
  ${FLEX_COL}
  gap:1rem;
  justify-content: space-between;
  padding: 0.75rem;
`;

const PlanMembersWrapperStyled = styled.div`
  ${FLEX_ROW}
  align-items:center;
  justify-content: space-between;
  display: none;
`;

const PlanMembersStyled = styled(FHTypography).attrs(props => ({
  variant: props.variant || "subheading1",
  weight: props.weight || "semi",
  colorType: props.colorType || "subHeading",
}))`
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
`;

const SeeDetailsButtonStyled = styled.button`
  h6 {
    text-decoration: underline;
  }
`;

const PlanInfoCardStyled = styled.div`
  ${FLEX_ROW}
  align-items: center;
  justify-content: space-between;
  gap: 0.75rem;
  padding: 0.75rem;
  .plan-details {
    ${FLEX_COL}
    gap:0.25rem;
    flex: 1;
  }
`;

const ICImageAndSeeDetailsWrapper = styled.div`
  ${FLEX_COL_CENTER}
  gap:0.25rem;
`;

const TotalPremiumWrapper = styled.div`
  ${FLEX_COL}
  ${FLEX_ROW}
  gap:0.4rem;
  ${VIEW.xs} {
    align-items: center;
    gap: 0.5rem;
  }
`;

const TotalPremiumTextWrapper = styled.div`
  ${FLEX_ROW_CENTER}
  gap:0.4rem;
`;

const TotalPremiumLoader = styled.div`
  width: 4rem;
  height: 1.25rem;
  border-radius: ${ROUNDED.base};
  background: linear-gradient(
      90deg,
      hsla(0, 0%, 100%, 0),
      hsla(0, 0%, 100%, 0.5) 50%,
      hsla(0, 0%, 100%, 0) 80%
    ),
    #f1f1f1;
  background-repeat: repeat-y;
  background-size: 30px 500px;
  background-position: 0 0;
  animation: Animation 1.5s ease-in-out alternate infinite;

  @keyframes Animation {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 100% 100%;
    }
  }
`;

const ProceedButtonStyled = styled.button`
  border-radius: ${ROUNDED.md};
  background-color: ${props => props.colors.primary_color};
  padding: 0.6rem 1.25rem;
  height: fit-content;
`;

const CartMobileBodyStyled = styled.div`
  display: grid;
  grid-template-rows: 0fr;
  transition: all 400ms ease-out;
  transform-origin: bottom;

  & > div {
    max-height: 48vh;
    overflow: hidden;
    .cartbody {
      padding: 0 0.75rem;
    }
  }

  &[data-cart-mobile-open="true"] {
    grid-template-rows: 1fr;
    & > div {
      overflow-y: scroll;
    }
  }
`;

const CartMobileFooterStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.75rem;
  padding: 0.625rem 0.75rem;
  border-top: 1px solid ${SHADES.DARK_GRAY};

  ${MAX.xs} {
    grid-template-columns: 2fr 1fr;
  }

  .totalpremium {
    display: flex;
    align-items: center;
    justify-content: start;
  }

  .amount {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 0.3rem;

    .year {
      margin-bottom: 0px;
    }
  }
`;

const PlanDetailsWrapperStyled = styled.div`
  display: grid;
  gap: 1rem;
  margin: 0.5rem 0px 0.5rem;
  padding: 0.75rem;
  background-color: ${props => props.colors.primary_shade};
  border-radius: ${ROUNDED.md};

  &[data-journey-type="health"] {
    grid-template-columns: repeat(3, 1fr);
    gap: 0.5rem;

    @media (min-width: 520px) {
      grid-template-columns: repeat(5, 1fr);
    }

    @media (max-width: 520px) {
      & > div {
        align-items: start;
      }
    }
    & > div > .plan-detail-value {
      ${FLEX_COL_CENTER}
      flex:1;
    }
  }
  &[data-journey-type="top_up"] {
    grid-template-columns: repeat(3, 1fr);

    @media (min-width: 520px) {
      grid-template-columns: repeat(4, 1fr);
    }

    @media (max-width: 520px) {
      & > div {
        align-items: start;
      }
    }
  }
`;

const PlanDetailStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  @media (min-width: 520px) {
    text-align: center;
  }
`;

const PlanDetailLabelStyled = styled(FHTypography).attrs(() => ({
  variant: "h6",
  weight: "md",
  colorType: "heading",
}))``;

const PlanDetailValueStyled = styled(FHTypography).attrs(() => ({
  variant: "subheading1",
  weight: "base",
  colorType: "heading",
  className: "plan-detail-value",
}))``;

const ProductDetailContainerStyled = styled.div`
  ${FLEX_COL}
  gap:0.75rem;
  &[data-cart-mobile-open="true"] {
    margin: 0.5rem 0px 1rem;
  }
`;

const ProductDetailHeadingStyled = styled(FHTypography).attrs(() => ({
  variant: "h5",
  weight: "md",
  colorType: "heading",
}))`
  padding: 0.75rem 0.5rem;
  background-color: ${props => props.colors.primary_shade};
  border-radius: ${ROUNDED.base};
`;

const ProductInfoCardContainerStyled = styled.div`
  ${FLEX_COL}
  padding: 0 0.5rem;
  gap: 1rem;
`;

const ProductInfoCardStyled = styled.div`
  ${FLEX_ROW}
  align-items:center;
  justify-content: space-between;
`;

const ProductInfoNameStyled = styled(FHTypography).attrs(() => ({
  variant: "h6",
  weight: "md",
  colorType: "body",
}))``;

const ProductInfoValueStyled = styled(FHTypography).attrs(() => ({
  variant: "h6",
  weight: "base",
  color: "#000",
}))``;

const NoDetailSelectedWrapperStyled = styled.div`
  ${FLEX_ROW}
  justify-content: space-between;
  border: 1px dashed grey;
  border-radius: ${ROUNDED.base};
  padding: 0.75rem;
`;

export {
  CartMobileOverlay,
  CartMobileStyled,
  CartMobileHeaderStyled,
  CartActionsWrapperStyled,
  EditMembersButtonStyled,
  ToggleCartButtonStyled,
  CartMobileBodyStyled,
  PlanInfoContainer,
  PlanMembersWrapperStyled,
  PlanMembersStyled,
  PlanInfoCardStyled,
  ICImageAndSeeDetailsWrapper,
  ProceedButtonStyled,
  TotalPremiumWrapper,
  SeeDetailsButtonStyled,
  ProductDetailContainerStyled,
  ProductDetailHeadingStyled,
  ProductInfoCardContainerStyled,
  ProductInfoCardStyled,
  ProductInfoNameStyled,
  ProductInfoValueStyled,
  CartMobileFooterStyled,
  PlanDetailsWrapperStyled,
  PlanDetailStyled,
  PlanDetailLabelStyled,
  PlanDetailValueStyled,
  TotalPremiumLoader,
  TotalPremiumTextWrapper,
  NoDetailSelectedWrapperStyled,
};
