import { useParams } from "react-router-dom";
import { useUpdateGroupsMutation } from "src/api/api";
import { useGetEnquiry } from "src/customHooks/useGetCommonQueries";
import useFilters from "./useFilters";

function useUpdateFilters() {
  const [updateGroup, query] = useUpdateGroupsMutation();
  const { getSelectedFilter } = useFilters();

  const { groupCode } = useParams();

  const {
    data: {
      data: { groups },
    },
  } = useGetEnquiry();

  const currentGroup = groups?.find(group => group?.id === parseInt(groupCode));

  const previousFilters = currentGroup?.extras;

  const updateFilters = async (filters, successCallback = () => {}) => {
    updateGroup({
      groupCode,

      extras: {
        filters: {
          ...currentGroup?.extras?.filters,

          plan_type: filters?.baseplantype || getSelectedFilter("baseplantype"),
        },
      },
    }).then(resGroup => {
      if (resGroup?.error) return;
      successCallback();
    });
    return {
      ...groups,
      extras: previousFilters ? { ...previousFilters, ...filters } : filters,
      plan_type: filters?.baseplantype,
    };
  };

  return { updateFilters, query };
}

export default useUpdateFilters;
