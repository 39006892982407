import { useState } from "react";

export default function useDropModule() {
  // STATE/s
  const [showDropDown, setShowDropDown] = useState(false);

  // FUNCTION/s
  function handleToggleDropDown() {
    if (showDropDown) {
      setShowDropDown(false);
    } else {
      setShowDropDown(true);
    }
  }

  return { handleToggleDropDown, showDropDown };
}
