import styled from "styled-components";
import { FWEIGHT, ROUNDED, FLEX_ROW, FLEX_COL } from "src/styles/styleGuide";
import { mobile } from "src/utils/mediaQueries";
import FHTypography from "src/styles/Typography";

export const PriceStyled = styled(FHTypography).attrs(() => ({
  varient: "h6",
  weigth: "semi",
  colorType: "body",
}))`
  font-weight: ${FWEIGHT.semi};
  margin-top: 0px;
  margin-bottom: 0px;
  color: black !important;
`;

export const CartGraphicStyled = styled.span`
  width: 100px;
  height: 100px;
  border-radius: ${ROUNDED.full};
  background: ${props => props.primaryShade};
  position: absolute;
  top: -41px;
  z-index: -3;
  left: -38px;
`;

export const HeadingContainerStyled = styled.div`
  ${FLEX_ROW}
  justify-content: space-between;
`;

export const HeadingStyled = styled.h2`
  font-size: 20px;
  font-weight: ${FWEIGHT.semi};
  position: relative;
  color: #2d3f5e;
  padding: 12px 0px;
  margin-bottom: -1px;
`;

export const CollapseIconStyled = styled.span`
  display: none;
  ${mobile} {
    display: block;
    position: absolute;
    right: 27px;
    top: 19px;
    transform: ${props => `rotate(${props.collapse ? "90deg" : "-90deg"})`};
    transition: transform 0.5s;
  }
`;

export const SummaryContainerStyled = styled.div`
  display: block;
  .no_padding {
    padding: 0;
  }
  ${mobile} {
    display: ${props => (props.collapse ? "block" : "none")};
    transition: all 2s linear;
  }
`;

export const SummarySectionStyled = styled.section`
  padding: 10px !important;
  padding-bottom: 0px !important;
`;

export const TotalPremiumTextStyled = styled.div`
  font-weight: ${FWEIGHT.semi};
  border: 1px dashed #dce2ec;
  display: flex;
  padding: 7px 20px 7px 20px;
  background-color: #f7f7f7;
  justify-content: space-between;
  align-items: center;
  margin: 0 -20px;
  margin-bottom: -10px;

  & span {
    color: #6d798f;
    & small {
      border-bottom: 2px dashed #6d798f;
    }
  }
`;

export const MemberTextStyled = styled.h5`
  display: inline-block;
  text-transform: capitalize;
  color: #616161;
  margin-bottom: 0;
  margin-right: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: ${FWEIGHT.semi};
`;

export const ProductInfoStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin: 12px 0px;
  gap: 0.5rem;

  & span {
    font-size: 16px;
    font-weight: ${FWEIGHT.semi};
    line-height: 1.2;
  }
`;

export const ICDetailsContainerStyled = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SummaryDataContainerStyled = styled.div`
  margin-top: 10px;
  ${FLEX_COL};
`;

export const SummaryDataStyled = styled.div`
  ${FLEX_ROW};
  align-items: end;
  justify-content: space-between;

  & span {
    padding-left: 10px;
  }

  .fareg_icon {
    cursor: pointer;
  }
`;

export const AdditionalDetailsTile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: -5px;
`;

export const AdditionalDetailsValue = styled.span`
  padding-left: 10px;
  white-space: wrap;
`;

export const AddOnDataContainer = styled.div`
  display: flex;
  position: relative;
  top: -17px;
  align-items: center;
  justify-content: center;
`;

export const AddOnProductContainer = styled.div`
  ${mobile} {
    margin-bottom: 10px !important;
  }
`;

export const AddOnProductDetails = styled.div`
  & img {
    height: 45px;
    margin-right: 11px;
    object-fit: contain;
  }
  & span {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.2;
  }
`;

export const GstTextContainer = styled.div`
  ${FLEX_ROW}
  justify-content: space-between;
  padding: 12px 0;
  border-top: 1px solid #ddd;
  margin-top: -10px;
  margin-bottom: 15px;

  & p {
    color: ${props => props.primaryColor};
    font-weight: ${FWEIGHT.semi};
    font-size: 14px;
  }

  & span {
    padding-left: 10px;
  }
`;

export const AddOnTitleStyled = styled.div`
  position: relative;
  width: 100%;
  margin-top: 2px;

  color: ${props => props.primaryColor};

  & > span {
    font-size: 13px;
    font-weight: ${FWEIGHT.semi};
    position: relative;

    & .info-icon {
      position: absolute;
      top: 50%;
      left: 105%;
      transform: translateY(-50%);

      &:hover .info-message {
        display: block;
      }

      & .info-message {
        position: absolute;
        top: 50%;
        left: 20px;
        background: #000;
        color: #fff;
        padding: 5px;
        font-weight: 500;
        font-size: 10px;
        white-space: nowrap;
        transform: translateY(-50%);
        border-radius: 4px;
        display: none;

        &:after {
          content: "";
          position: absolute;
          top: 50%;
          right: 100%;
          transform: translateY(-50%);
          width: 0;
          height: 0;
          border-top: 5px solid transparent;
          border-bottom: 5px solid transparent;

          border-right: 5px solid #000;
        }
      }
    }
  }
`;

export const IconWrapper = styled.div`
  cursor: pointer;
`;
