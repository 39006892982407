import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { api, useCallApiMutation } from "src/api/api";
import styled from "styled-components/macro";
import useProposalSchema from "src/customHooks/useProposalSchema";
import {
  setProposalRefetchConfirmationModal,
  getProposalData,
} from "../../ProposalSections.slice";
import { useTheme } from "src/customHooks";
import { FWEIGHT, ROUNDED } from "src/styles/styleGuide";

import Modal from "./../../../../../components/Common/Popups/ReusableModal/Modal";

// TODO : Currently only used for KYC section, will make dynamic in future.
const ProposalRefetchConfirmationModal = () => {
  const dispatch = useDispatch();
  const { colors } = useTheme();
  const { schemaRefetch } = useProposalSchema();
  const [triggerMutation, { isLoading }] = useCallApiMutation();
  const { proposalRefetchConfirmationModal } = useSelector(
    state => state.proposalPage,
  );

  const yesClickHandler = async () => {
    try {
      if (proposalRefetchConfirmationModal?.updateProposalData) {
        // Used in Niva CKYC redirection
        const response = await triggerMutation({
          url: `ckyc_redirection_update_proposaldata?cart_item=${proposalRefetchConfirmationModal.currentCartId}`,
          method: "GET",
        });

        if (response?.error) {
          throw new Error();
        }
      } else {
        await triggerMutation({
          url: "ckyc-verifications",
          body: {
            payload: "",
            mode: "update_proposer_details_and_freeze",
            additionalPayload: {},
            update_for_proposer_name:
              proposalRefetchConfirmationModal?.forNameUpdate || false,
          },
          method: "POST",
        });
      }
      schemaRefetch();
      dispatch(getProposalData());
      dispatch(api.util.invalidateTags(["Cart"]));
      dispatch(
        setProposalRefetchConfirmationModal({
          visible: false,
          title: "",
        }),
      );
    } catch {
      toast.error("Failed to update the data, please retry.", {
        position: "top-center",
      });
    }
  };
  const noClickHandler = () => {
    dispatch(
      setProposalRefetchConfirmationModal({
        visible: false,
        title: "",
      }),
    );
  };

  return (
    <Modal
      isModalOpen={proposalRefetchConfirmationModal?.visible}
      width="450px"
      height="fit-content"
      mobileHeight="fit-content"
      hideCloseBtn={true}
    >
      <span>{proposalRefetchConfirmationModal?.title}</span>

      <ModalFooter>
        <BtnStyled
          primaryColor={colors.primary_color}
          onClick={yesClickHandler}
          loader={isLoading}
        >
          Yes
        </BtnStyled>
        <BtnStyled
          primaryColor={colors.primary_color}
          onClick={noClickHandler}
          disabled={isLoading}
        >
          No
        </BtnStyled>
      </ModalFooter>
    </Modal>
  );
};
export default ProposalRefetchConfirmationModal;

const BtnStyled = styled.button`
  color: #fff;
  background-color: ${props => props.primaryColor};
  min-width: 100px;
  padding: 10px 0;
  font-weight: ${FWEIGHT.md};
  border-radius: ${ROUNDED.md};
  &:hover:not(:disabled) {
    filter: brightness(0.9);
  }
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: 12px;
`;
