import styled from "styled-components";

const PanNumberInput = ({ userPan, setUserPan, error }) => {
  return (
    <PanContainerStyled>
      <PanWarningStyled className="typography--variant-subheading2">
        PAN Card no is mandatory for premium above or equal to ₹ 50,000
      </PanWarningStyled>
      <PanFields>
        <PanInputStyled
          value={userPan}
          onChange={e => setUserPan(e.target.value)}
          maxLength={10}
          placeholder="Enter PAN"
        />
        <PanLabelStyled>Enter PAN</PanLabelStyled>
      </PanFields>
      {error && userPan.length ? (
        <PanErrorStyled>Please enter valid PAN Number</PanErrorStyled>
      ) : (
        <></>
      )}
    </PanContainerStyled>
  );
};

export default PanNumberInput;

const PanContainerStyled = styled.div`
  margin: 0 1rem;
`;
const PanWarningStyled = styled.div`
  margin-bottom: 1rem;
`;
const PanFields = styled.div`
  position: relative;
`;
const PanInputStyled = styled.input`
  height: 45px;
  font-size: 14px;
  border: solid 1px #ced4da;
  border-radius: 3px;
  color: #939393;
  padding-right: 5px;
  padding-left: 15px;
  touch-action: manipulation;

  &:focus {
    color: black;
  }
`;
const PanLabelStyled = styled.label`
  text-align: left;
  font-size: 13px !important;
  color: #000;
  position: absolute;
  left: 10px;
  top: 0;
  margin: 0;
  max-width: 95%;
  background: #fff;
  font-weight: 600;
  padding: 0 5px;
  transform: translateY(-50%);
`;

const PanErrorStyled = styled.p`
  color: #c7222a;
  font-size: 12px;
  margin-bottom: 0;
`;
