import { MAX } from "src/styles/responsiveStyle";
import {
  FLEX_COL_CENTER,
  FLEX_ROW,
  ROUNDED,
  SHADES,
  SHADOW,
} from "src/styles/styleGuide";
import styled from "styled-components";

const MembersModalContainerStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1100;
  ${FLEX_COL_CENTER};
  height: 100vh;
  width: 100vw;

  &[data-is-product-details-page="true"] {
    height: auto;
    max-height: calc(100vh - 0.5rem);
    top: 0.25rem;
    ${MAX.md} {
      padding: 0.5rem;
    }
  }
`;

const MembersModalStyled = styled.div`
  max-width: 800px;
  width: 100%;
  background-color: white;
  border-radius: ${ROUNDED.lg};
  box-shadow: ${SHADOW.md};
  z-index: 1;

  ${MAX.md} {
    max-height: 100vh;
    height: auto;
    overflow-y: auto;
  }

  .members_modal_head {
    ${FLEX_ROW};
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    background-color: ${SHADES.WHITE};
    border-top-left-radius: ${ROUNDED.lg};
    border-top-right-radius: ${ROUNDED.lg};
  }
`;

export { MembersModalContainerStyled, MembersModalStyled };
