import styled from "styled-components/macro";

export const ViewQuotesWrapper = styled.div`
  text-align: center;
  margin-top: 12px;
  & button {
    display: inline-block;
    border-bottom: 1px dashed #0a87ff;
    outline: none;
    box-shadow: none;
    background: none;
    color: ${prop => prop.color};
  }
`;
export const PopupWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99999;
`;
export const Popup = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  box-shadow: rgb(0 0 0 / 50%) 0px 5px 15px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  max-width: 400px;
  border-radius: 8px;
  text-align: right;

  @media (max-width: 767px) {
    width: 100%;
    max-width: 320px;
    padding: 15px;
  }

  .iconstyle {
    margin: 20px 20px 0 0;
    cursor: pointer;
  }
`;
export const CloseButton = styled.button`
  width: 20px;
  height: 20px;
  position: absolute;
  right: 12px;
  top: 12px;
  border: 1px solid #dce2ea;
  border-radius: 50%;
  font-size: 14px;
  text-align: left !important;
  & i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
export const Container = styled.div`
  padding: 10px 50px 40px 50px;
  text-align: left !important;

  & p {
    font-weight: 100;
  }
  & span {
    text-transform: capitalize;
    font-weight: 600;
  }

  @media (max-width: 767px) {
    padding: 12px 16px;
    width: 95%;
  }
`;
export const ButtonWrapper = styled.div`
  margin: 0 auto;
  text-align: center;
  margin-top: 30px;

  & button {
    padding: 5px 20px;
    color: #fff;
    border-radius: 8px;
    display: inline-block;
    background-color: ${prop => prop.color};
  }
`;
