import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "styled-components/macro";
import FormBuilder from "../../../components/FormBuilder/FormBuilder";
import { useFrontendBoot } from "src/customHooks/useGetCommonQueries";
import {
  useMembers,
  useTheme,
  useCart,
  useRenewalsConfig,
} from "src/customHooks";
import { useGetRenewalSumInsuredsQuery } from "../../../api/api";
import BackBtn from "../components/Buttons/BackBtn";
import ContinueBtn from "../components/Buttons/ContinueBtn";
import { components } from "../components/componentSchema";
import {
  getMedicalUnderwritingStatus,
  setShowErrorPopup,
  setIsPopupOn,
} from "../ProposalSections/ProposalSections.slice";
import Panel from "./../components/AccordionPanel/Panel";
import { Form } from "./../ProposalPage.style";
import { DisableScreen } from "./ProposalSections.styles";
import useMedicalQuestions from "./useMedicalQuestions";
import RevisedPremiumPopup from "../../ProductDetails/components/ReviewCart/components/RevisedPremiumPopup";
import ProposalLoader from "./components/ProposalLoader";
import NoForAllMQ from "./components/NoForAllMQ";
import useFormNavigation from "./useFormNavigation";
import useProposalSections from "./useProposalSections";
import styled from "styled-components";
import { useEventTracking } from "src/customHooks/useEventTracking";
import NivaRule from "./components/RuleEngine/NivaRule";
import toast from "react-hot-toast";
import { setIsContinueBtn } from "../schema.slice";

const MedicalDetails = ({
  formSchema = {},
  rawSchema = {},
  name,
  defaultValue,
  setActivateLoader,
  setBlockTabSwitch = () => {},
  listOfForms,
  isContinueClicked,
  setIsContinueClicked,
}) => {
  const dispatch = useDispatch();

  const [medicalContinueClick, setMedicalContinueClick] = useState(false);

  const { getGroupMembers } = useMembers();

  const { colors } = useTheme();

  const PrimaryColor = colors.primary_color;

  const [initColor, setInitColor] = useState(PrimaryColor);

  const { cartEntries } = useCart();

  const {
    proposalData,
    showErrorPopup,
    insuredDetailsResponse,
    underWritingStatus,
    medicalUrlsRuleEngine,
    medicalUnderwritingLetters,
  } = useSelector(state => state.proposalPage);

  const isDisabled = underWritingStatus?.some(
    res =>
      res?.final_result?.application_result === "Rejected" ||
      res?.final_result?.application_result === "NotSubmitted",
  );

  const fullName = proposalData["Proposer Details"]?.name;

  const {
    data: frontBootData,
    renewal_policy_status,
    subJourneyType,
    journeyType,
  } = useFrontendBoot();

  const firstCartEntry = cartEntries && cartEntries[0];

  const { webEngageTrackEvent } = useEventTracking();
  const {
    values,
    setValues,
    isValid,
    setValid,
    submit,
    setSubmit,
    additionalErrors,
    revisedPremiumPopupUtilityObject,
    setErrorInField,
    triggerSaveForm,
    setErrors,
    show,
    setShow,
    isVersionRuleEngine,
    warnings,
    triggerSpecialConditionsCheck,
  } = useProposalSections({
    name,
    defaultValue,
    partialLength: Object.keys(formSchema).length,
    setActivateLoader,
    schema: rawSchema,
    setBlockTabSwitch,
    listOfForms,
    activeFormSchema: formSchema,
  });

  const {
    noForAll,
    setNoForAll,
    checkCanProceed,
    canProceed,
    yesSelected,
    preparingMQ,
    getMUStatus,
  } = useMedicalQuestions({
    schema: formSchema,
    values,
    setValues,
    name,
    proposalData,
    defaultValue,
    dispatch,
    isVersionRuleEngine,
    medicalUrlsRuleEngine,
    insuredDetailsResponse,
    underWritingStatus,
    medicalUnderwritingLetters,
  });

  const { data } = useGetRenewalSumInsuredsQuery(
    {
      product_id: firstCartEntry?.product?.id,
      groupCode: firstCartEntry?.group?.id,
      tenure: firstCartEntry?.tenure,
      journeyType,
      subJourneyType,
    },
    { skip: subJourneyType === "new" || subJourneyType === "port" },
  );

  const { navigateTo } = useFormNavigation();

  const ruleEngineValidity = !Object.keys(medicalUrlsRuleEngine).some(group => {
    return (
      getMUStatus(group) === "NotSubmitted" || getMUStatus(group) === undefined
    );
  });

  const defaultSumInsured = data?.data?.find(
    singleOption => singleOption?.default_si,
  )?.sum_insured;

  const { allowsQuickPay } = useRenewalsConfig();

  const disabledMedicalQuestions = allowsQuickPay(
    defaultSumInsured,
    firstCartEntry?.sum_insured,
  ); //? disable medical questions if sum_insured is not upgraded

  useEffect(() => {
    if (disabledMedicalQuestions)
      Object.keys(formSchema).map(item => {
        setNoForAll({
          ...noForAll,
          [item]: true,
        });
      });
    return () => {};
  }, [disabledMedicalQuestions]); //? set no for all medical question while disabling medical questions

  useEffect(() => {
    if (
      medicalContinueClick &&
      medicalUrlsRuleEngine &&
      underWritingStatus.length &&
      ruleEngineValidity
    ) {
      if (
        underWritingStatus
          .map(({ final_result: { members } }) =>
            members.map(({ result }) => result),
          )
          ?.flat()
          ?.includes("Manual_UV") ||
        underWritingStatus
          .map(({ final_result: { members } }) =>
            members.map(({ result }) => result),
          )
          ?.flat()
          ?.includes("MER")
      ) {
        dispatch(
          setShowErrorPopup({
            show: true,
            head: "",
            msg: "Your application will be reviewed by our Underwriting team.",
          }),
        );
      }
      triggerSaveForm({ sendedVal: values, formName: name });
    } else if (
      medicalContinueClick &&
      !isValid.includes(undefined) &&
      !isValid.includes(false) &&
      !showErrorPopup?.show
    ) {
      triggerSpecialConditionsCheck({
        formSchema,
        values,
        formName: name,
        successCallback: () =>
          triggerSaveForm({ sendedVal: values, formName: name }),
      });
    } else if (isValid.includes(false)) {
      setShow(isValid.indexOf(false));
    }
    setMedicalContinueClick(false);
  }, [
    isValid,
    medicalContinueClick,
    showErrorPopup,
    underWritingStatus,
    ruleEngineValidity,
  ]);

  useEffect(() => {
    //? To handle continue click on Proposal page Mobile
    if (isContinueClicked) {
      handleContinue();
      setIsContinueClicked("");
    }
    return () => {};
  }, [isContinueClicked]);

  const getRuleEngineStatus = () => {
    //? Rule engine check for medical questions
    checkCanProceed();
    dispatch(
      getMedicalUnderwritingStatus(() => {
        revisedPremiumPopupUtilityObject?.getUpdatedCart();
      }),
    );
  };

  const handleContinue = () => {
    dispatch(setIsContinueBtn(true));
    setInitColor("#c7222a");
    setSubmit("Medical");
    if (canProceed?.canProceed) {
      Object.values(yesSelected).includes(true) &&
        frontBootData?.settings?.medical_nstp_declaration_message &&
        !cartEntries?.some(entry => entry?.is_stp) &&
        dispatch(
          setShowErrorPopup({
            show: true,
            head: "",
            msg: frontBootData?.settings?.medical_nstp_declaration_message,
            onCloseCallBack: () => {
              setMedicalContinueClick(true);
              dispatch(setIsPopupOn(false));
            },
          }),
        );

      setMedicalContinueClick(true);

      webEngageTrackEvent("Medical Details Submitted", {
        prevTotalPremium: revisedPremiumPopupUtilityObject?.prevTotalPremium,
        revisedTotalPremium:
          revisedPremiumPopupUtilityObject?.updatedTotalPremium,
      });
    } else {
      setShow(
        Object.keys(formSchema).indexOf(
          Object.keys(canProceed.checkCanProceed).find(
            key =>
              canProceed.checkCanProceed[key] &&
              canProceed.checkCanProceed[key].length,
          ),
        ),
      );
    }
  };

  const handleBack = () => {
    navigateTo({ back: true });
  };

  return preparingMQ ? (
    <ProposalLoader message="Preparing Medical Questions." />
  ) : (
    <div>
      {Object.keys(formSchema).map((item, index) => {
        return (
          <>
            <Panel
              allMembers={getGroupMembers(parseInt(item))}
              formName={name}
              isFilled={
                Object.keys(values && values[item] ? values[item] : {}).length
              }
              key={index}
              title={`${item}`}
              show={show === index}
              onClick={() => setShow(prev => (prev === index ? false : index))}
            >
              {isVersionRuleEngine(parseInt(item)) ? (
                process.env.REACT_APP_PHASE === "UAT" ? (
                  <NivaRule
                    onClick={getRuleEngineStatus}
                    item={item}
                    getMUStatus={getMUStatus}
                  />
                ) : (
                  <NivaRule
                    onClick={getRuleEngineStatus}
                    item={item}
                    getMUStatus={getMUStatus}
                  />
                )
              ) : (
                <QuestionsStyled>
                  {renewal_policy_status?.medicalQuestionsReadOnly ||
                  disabledMedicalQuestions ? (
                    <DisableScreen />
                  ) : (
                    <></>
                  )}
                  <NoForAllMQ
                    noForAll={noForAll}
                    setNoForAll={setNoForAll}
                    item={item}
                    initColor={initColor}
                    yesSelected={yesSelected}
                  />
                  <Form
                    onSubmit={e => {
                      e.preventDefault();
                    }}
                    css={`
                      @media (max-width: 570px) {
                        word-break: break-all;
                      }
                    `}
                  >
                    <FormBuilder
                      isInsuredDetails
                      keyStr={item}
                      lastName={fullName?.split(" ").slice(-1)}
                      schema={formSchema[item]}
                      components={components}
                      fetchValues={(res = () => {}) => {
                        setValues(prev => {
                          return { ...prev, [item]: res(prev?.[item]) };
                        });
                      }}
                      fetchErrors={res => {
                        setErrors(prev => ({ ...prev, [item]: res }));
                      }}
                      setErrorInField={setErrorInField}
                      fetchValid={res => {
                        let valid = isValid;
                        valid[index] = res;
                        setValid(valid);
                      }}
                      options={{
                        defaultValues: values?.[item] || {},
                        validateOn: "change",
                      }}
                      formName={name}
                      additionalErrors={additionalErrors[item]}
                      setSubmit={setSubmit}
                      submitTrigger={submit}
                      isPanelVisible={show === index}
                      noForAll={noForAll[item]}
                      proposalData={proposalData}
                      canProceed={!yesSelected[item] ? canProceed : ""}
                      yesSelected={yesSelected[item]}
                      warnings={warnings[name] || {}}
                      setNoForAll={value => {
                        setNoForAll({ ...noForAll, [item]: value });
                      }}
                      preFilledDataBase={defaultValue ? defaultValue[item] : {}}
                    />
                  </Form>
                </QuestionsStyled>
              )}
            </Panel>
          </>
        );
      })}

      <div className="proposal_continue_back_margin container">
        <BackBtn onClick={handleBack} />

        <ContinueBtn
          onClick={
            isDisabled
              ? () => {
                  toast.error(
                    "Can't proceed while medical details are not submitted",
                    {
                      position: "top-center",
                      id: "notAccepted",
                      style: {
                        color: "white",
                        backgroundColor: PrimaryColor,
                      },
                    },
                  );
                }
              : handleContinue
          }
        />
      </div>

      {revisedPremiumPopupUtilityObject.isOnProposal && (
        <RevisedPremiumPopup
          revisedPremiumPopupUtilityObject={revisedPremiumPopupUtilityObject}
          onClose={revisedPremiumPopupUtilityObject.off}
        />
      )}
    </div>
  );
};

export default MedicalDetails;

const QuestionsStyled = styled.div`
  background: #ffff;
`;
