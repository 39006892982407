import { FLEX_COL_CENTER, FLEX_ROW_CENTER } from "src/styles/styleGuide";
import styled from "styled-components";

export const UnderWritingDecisionTable = styled.div`
  width: 100%;
  border: 1px dashed #ddd;
  margin: 20px 0px 10px;
  .head_section {
    font-weight: 600;
    border-bottom: 1px dashed #ddd;
  }
  .section_column {
    padding: 15px 0px 15px 40px;
    width: 50%;
  }
  .check_status_btn {
    width: 200px;
    margin: 10px 0;
    @media (max-width: 400px) {
      width: 150px;
    }
  }

  .click_btn {
    background-color: #ecf6ff;
    font-family: "Dax", sans-serif;
    font-weight: 400;
    border-radius: 50px;
    margin: 0 8px;
    font-size: 14px;
    padding: 11px;
    :visited {
      background: ${({ primary_color }) => primary_color} !important;
      color: white;
    }
  }
  .medical_container {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
`;

export const DisableScreen = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: white;
  top: -12px;
  left: 0px;
  z-index: 99;
  opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: not-allowed;
  .display_onHover {
    display: none;
    font-size: 20px !important;
  }
  :hover {
    .display_onHover {
      display: block;
    }
  }
`;

export const RedirectionDisableScreenOuter = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: not-allowed;
  display: grid;
  place-items: center;

  & > main {
    text-align: center;
    padding: 5rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: 600px) {
      padding: 3rem 0;
    }
  }

  .desc {
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
  }
`;

export const FlexDiv = styled.div`
  ${FLEX_ROW_CENTER}
  gap: 1rem;

  @media (max-width: 450px) {
    ${FLEX_COL_CENTER}
  }
`;
