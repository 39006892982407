import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useMembers, useTheme } from "src/customHooks";
import FHTypography from "src/styles/Typography";
import { VIEW } from "src/styles/responsiveStyle";
import { PALETTE, ROUNDED, SHADES } from "src/styles/styleGuide";
import styled from "styled-components";
import RejectedPopUp from "./components/RejectedPopup";
import { RiLoader4Fill } from "react-icons/ri";
import CustomToolTip from "src/components/Common/CustomTooltip";
import { TooltipIconProposal } from "src/assets/svg/tooltip-icon";

// function for replace underScore to space
export const relationString = member => {
  if (member && member.includes("_in_law")) {
    member = member.replaceAll("_", " ");
    return member;
  }
  return member;
};
const NivaRule = ({ onClick, item, getMUStatus }) => {
  // custom hooks
  const { colors } = useTheme();
  // redux state
  const {
    medicalUrlsRuleEngine,
    medicalUnderwritingLetters,
    isStatusBtnInitiate,
  } = useSelector(state => state.proposalPage);

  const { getGroupMembers } = useMembers();
  const currentGroupMembersRaw = getGroupMembers(item?.split("_")?.[0]);
  const currentGroupMembers = currentGroupMembersRaw.map(item => item?.code);
  // pre defined colors for status column
  const statusColors = {
    Rejected: { background: "#FECCCE", color: "#dc2626" },
    Accepted_STD: { background: "#CDFFDB", color: PALETTE.success.main },
    Loading: { background: "#CDECFE", color: "#0ea5e9" },
    NotSubmitted: { background: "#f7fab7", color: "#facc15" },
    Manual_UV: { background: "#f7fab7", color: "#facc15" },
  };

  // useEffect for refetch the status
  useEffect(() => {
    return;
  }, [medicalUnderwritingLetters]);

  return (
    <>
      <RejectedPopUp
        getMUStatus={getMUStatus}
        item={item}
        currentGroupMembers={currentGroupMembers}
        medicalUrlsRuleEngine={medicalUrlsRuleEngine}
        medicalUnderwritingLetters={medicalUnderwritingLetters}
      />
      <RuleEngineContainer>
        <RuleEngineContent id="rule_engine_header">
          <div className="members pad">
            <FHTypography variant="subheading1">Members</FHTypography>
          </div>
          <div className="header">
            <FHTypography variant="subheading1" colorType="heading">
              Status
            </FHTypography>
          </div>
          <div className="header">
            <FHTypography variant="subheading1" colorType="heading">
              Medical underwriting
            </FHTypography>
          </div>
        </RuleEngineContent>

        {currentGroupMembers &&
          currentGroupMembers?.map((members, indx) => (
            <RuleEngineContent key={indx}>
              <div className="members">
                <FHTypography variant="subheading1" colorType="subheading">
                  {relationString(members)}
                </FHTypography>
              </div>
              <StatusStyled
                status={
                  statusColors[
                    medicalUnderwritingLetters[members]?.result ||
                      "NotSubmitted"
                  ]
                }
              >
                <FHTypography variant="subheading2" className="status_col">
                  {medicalUnderwritingLetters?.[members]?.result ===
                  "Loading" ? (
                    <MUSummaryButton colors={colors}>
                      <a
                        href={
                          medicalUnderwritingLetters?.[members]
                            ?.medical_question_url
                        }
                        className="click_btn"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Click here
                      </a>

                      <CustomToolTip
                        description="Please return to this page to continue journey after answering medical questions for each Insured member."
                        showInfoIcon={false}
                        position="right"
                      >
                        <span>
                          <TooltipIconProposal />
                        </span>
                      </CustomToolTip>
                    </MUSummaryButton>
                  ) : (
                    <FHTypography variant="subheading2" className="status_col">
                      {(medicalUnderwritingLetters[members]?.result ===
                        "Loading" &&
                        "Loading") ||
                        (medicalUnderwritingLetters[members]?.result ===
                          "Accepted_STD" &&
                          "Accepted") ||
                        medicalUnderwritingLetters[members]?.result ||
                        "Not Submitted"}
                    </FHTypography>
                  )}
                </FHTypography>
              </StatusStyled>
              <SubmitLinkStyled
                href={medicalUrlsRuleEngine[members]?.medical_question_url}
                target="_blank"
                status={
                  medicalUnderwritingLetters[members]?.result || "NotSubmitted"
                }
              >
                {(medicalUnderwritingLetters[members]?.result ===
                  "Accepted_STD" &&
                  "Submitted") ||
                  (medicalUnderwritingLetters[members]?.result === "Rejected" &&
                    "Re-Submit Now") ||
                  "Submit Now"}
              </SubmitLinkStyled>
            </RuleEngineContent>
          ))}

        <div>
          <RuleEngineFooter>
            <div className="pad"></div>
            <div></div>
            <StatusCheckBtn colors={colors} onClick={onClick}>
              Check Status
              {isStatusBtnInitiate && (
                <RiLoader4Fill className="rotate status_loader" size={14} />
              )}
            </StatusCheckBtn>
          </RuleEngineFooter>
        </div>
      </RuleEngineContainer>
    </>
  );
};

export default NivaRule;

export const RuleEngineContainer = styled.div`
  border: 1px solid ${SHADES.LIGHT_GRAY};
  width: 100%;
  height: fit-content;
  border-radius: 10px;
`;

export const RuleEngineContent = styled.div`
  width: 100%;
  border-bottom: 1px solid ${SHADES.LIGHT_GRAY};
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  text-align: center;
  grid-column-gap: 20px;
  align-content: center;
  padding: 5px 0;
  &:last-child {
    border-bottom: none;
  }
  .pad {
    padding: 15px 0;
  }
  .members {
    text-transform: capitalize;
    justify-self: start;
    align-self: center;
    margin-left: 5px;

    ${VIEW.xs} {
      margin-left: 10px;
    }
    ${VIEW.sm} {
      margin-left: 40px;
    }
  }
  .header {
    align-self: center;
  }
  .status_col {
    letter-spacing: 1px;
  }
`;

const StatusStyled = styled.div`
  min-width: 130px;
  color: ${({ status }) => status.color};
  display: flex;
  align-items: center;
  justify-self: center;
  align-self: center;
`;

const SubmitLinkStyled = styled.a`
  font-size: 12px;
  pointer-events: ${({ status }) => (status === "NotSubmitted" ? "" : "none")};
  color: ${({ status }) => (status === "NotSubmitted" ? "727372" : "#E4E5E4")};
  text-decoration: underline;
  align-self: center;
  padding: 5px 0;
`;

const MUSummaryButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  .click_btn {
    color: #000;
    background-color: #ecf6ff;
    font-family: "Dax", sans-serif;
    font-weight: 400;
    border-radius: 50px;
    margin: 0 8px;
    font-size: 14px;
    padding: 11px;
  }
  span {
    color: #000;
  }
`;

const StatusCheckBtn = styled.button`
  font-size: 0.8rem;
  background: ${({ colors }) => colors.primary_color};
  color: #fff;
  border-radius: ${ROUNDED.md};
  padding: 0.3rem 1rem;
  height: 1.8rem;
  align-self: center;
  justify-self: center;
  display: flex;
  height: fit-content;
  align-items: center;
  .status_loader {
    margin-left: 2px;
  }
`;

const RuleEngineFooter = styled(RuleEngineContent)`
  padding: 10px 0;
`;
