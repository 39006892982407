import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { FaTimes } from "react-icons/fa";
import { useTheme, useMembers } from "src/customHooks";
import useUrlQuery from "src/customHooks/useUrlQuery";
import styled from "styled-components/macro";
import { ViewQuotesWrapper } from "./PopupUtility/index.style";
import { useGetCartQuery } from "src/api/api";
import useFormNavigation from "../useFormNavigation";

const ErrorPopup = ({
  head,
  msg,
  htmlProps,
  handleClose = () => {},
  showQuotesBtn = false,
  tataAigCKYCDetailsNotMatching = false,
}) => {
  const { showErrorPopup } = useSelector(({ proposalPage }) => proposalPage);

  const { colors } = useTheme();

  const { navigateTo } = useFormNavigation();

  const history = useHistory();

  const urlQuery = useUrlQuery();

  const enquiryId = urlQuery.get("enquiryId");

  const { groups = [] } = useMembers();

  const { refetch, isSuccess } = useGetCartQuery();

  const handleButtonClick = () => {
    if (tataAigCKYCDetailsNotMatching) {
      refetch();

      if (isSuccess) {
        navigateTo({ formName: "Proposer Details" });
      }
    }
    handleClose();
    showErrorPopup?.onCloseCallBack && showErrorPopup.onCloseCallBack();
  };
  return (
    <PopupWrapper>
      <Popup>
        <FaTimes
        className="Fatimes"
          onClick={handleClose}
        ></FaTimes>

        <Container>
          {head && <span>{head?.replace("_", " ")}</span>}
          {htmlProps && <div dangerouslySetInnerHTML={{ __html: htmlProps }} />}
          {msg && <div dangerouslySetInnerHTML={{ __html: msg }}></div>}
          <ButtonWrapper colors={colors}>
            <button className="btn ok_btn" onClick={handleButtonClick}>
              OK
            </button>
          </ButtonWrapper>
          {showQuotesBtn ? (
            <ViewQuotesWrapper color={colors?.primary_color}>
              <button
                onClick={() => {
                  history.push(
                    `/quotes/${groups[0]?.id}?enquiryId=${enquiryId}`,
                  );
                }}
              >
                View Quotes {">"}
              </button>
            </ViewQuotesWrapper>
          ) : (
            <></>
          )}
        </Container>
      </Popup>
    </PopupWrapper>
  );
};

const PopupWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99999;
`;
const Popup = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  box-shadow: rgb(0 0 0 / 50%) 0px 5px 15px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  max-width: 400px;
  border-radius: 8px;
  text-align: right;

  @media (max-width: 767px) {
    width: 100%;
    max-width: 320px;
    padding: 15px;
  }

  .Fatimes {
      margin: 10px 10px 0 0;
      cursor: pointer;
      font-weight: bold;
  }
`;

const Container = styled.div`
  padding: 10px 50px 40px 50px;
  text-align: left !important;
  word-break: break-word;
  padding: 25px;
  & p {
    font-weight: 100;
  }
  & span {
    text-transform: capitalize;
    font-weight: 600;
  }

  @media (max-width: 767px) {
    padding: 12px 16px;
    width: 95%;
  }
`;
const ButtonWrapper = styled.div`
  margin: 0 auto;
  text-align: center;
  margin-top: 30px;
  .ok_btn {
    background: ${({ colors }) => colors?.primary_color} !important;
  }
  & button {
    padding: 5px 20px;
    color: #fff;
    border-radius: 8px;
    display: inline-block;
    background-color: ${prop => prop.color};
  }
`;

export default ErrorPopup;
