import Modal from "src/components/Common/Popups/ReusableModal/Modal";
import TextInput from "../../../components/TextInput";
import {
  Info,
  TextFieldContainer,
  KYCVerificationFieldContainer,
  ButtonStyled,
} from "./KYCVerificationStatusModal.style";
import { useState } from "react";
import { api, useCallApiMutation } from "src/api/api";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { setProposalRefetchModal } from "../../ProposalSections.slice";

const KYCVerificationStatusModal = ({
  isModalOpen,
  handleCloseReusableModal,
}) => {
  const dispatch = useDispatch();
  const [triggerMutation, { isLoading, isError }] = useCallApiMutation();
  const {
    kycSection: { ckyc_reference_id },
  } = useSelector(state => state.proposalPage);
  const [input, setInput] = useState(ckyc_reference_id || "");
  const onChange = e => {
    setInput(e.target.value.trim());
  };
  const checkVerificationStatus = async e => {
    e.stopPropagation();
    try {
      const response = await triggerMutation({
        url: "ckyc-manual-status-verification",
        method: "POST",
        body: {
          unique_transaction_id: input,
        },
      });
      if (response?.error) {
        throw new Error();
      }
      const { verification_status, refetch_proposal_data, message } =
        response?.data?.data;
      if (verification_status) {
        toast.success("KYC Status Verification successful.", {
          position: "top-center",
        });
        setTimeout(() => {
          dispatch(api.util.invalidateTags(["Cart"]));
        }, 2000);
        if (refetch_proposal_data) {
          dispatch(
            setProposalRefetchModal({
              visible: true,
              title:
                "We have updated some of your details based on your CKYC. you can check later in the Proposer Details section.",
            }),
          );
        }
      } else if (message && typeof message === "string") {
        toast.error(message, {
          position: "top-center",
        });
      } else {
        throw new Error();
      }
    } catch {
      toast.error("Failed to generate verification status. please retry", {
        position: "top-center",
      });
    }
  };
  return (
    <Modal
      title="Check CKYC Verification Status"
      isModalOpen={isModalOpen}
      onClose={handleCloseReusableModal}
      height="fit-content"
      mobileHeight="fit-content"
      width="600px"
    >
      <KYCVerificationFieldContainer>
        <TextFieldContainer>
          <TextInput
            value={input}
            onChange={onChange}
            placeholder="Enter the reference id."
            boldText={true}
          />
          <Info title="Enter the KYC verification ID generated through the Insurance company's website." />
        </TextFieldContainer>
        <ButtonStyled
          loader={isLoading}
          onClick={checkVerificationStatus}
          disabled={!input}
        >
          {isError ? "Retry" : "Check"}
        </ButtonStyled>
      </KYCVerificationFieldContainer>
    </Modal>
  );
};
export default KYCVerificationStatusModal;
