import { createRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetEnquiry } from "src/customHooks/useGetCommonQueries";
import { getAge, getReactSelectOption } from "src/utils/helper";
import { stringLengthCutter } from "src/utils/string.utility";
import styled from "styled-components";
import Dropdown from "../../../components/Dropdown";
import { callApi } from "../../../components/FormBuilder/FormBuilder.slice";
import { generateAge } from "../../../components/FormBuilder/formUtils";
import { setShowErrorPopup } from "../ProposalSections/ProposalSections.slice";
import down from "./../../../assets/images/down-arrow.svg";
import up from "./../../../assets/images/up-arrow.svg";
import useAppropriateOptions from "./useAppropriateOptions";
import { FWEIGHT, ROUNDED } from "src/styles/styleGuide";
import { useResponsiveCondition } from "src/pos/hooks";
import CustomToolTip from "src/components/Common/CustomTooltip";

const DropDownP = ({
  name,
  values,
  label,
  onChange,
  height = false,
  borderR = false,
  options = { value: "key" },
  value,
  selectedValues,
  allValues = {},
  error,
  asyncOptions,
  readOnly,
  checkValidation,
  deleteValue = () => {},
  directUpdateValue = () => {},
  invalidateOption,
  fill = false,
  relation,
  innerMember,
  hideMemberOption = {},
  formName,
  notAllowed = {},
  disable,
  message,
  tooltip_msg = "",
}) => {
  const dispatch = useDispatch();

  const { selectOption } = useAppropriateOptions({
    values,
    allValues,
    asyncOptions,
    options,
    label,
    name,
    directUpdateValue,
    value,
    fill,
    deleteValue,
  });

  const dropdownRef = createRef();

  const inputRef = createRef();

  const [showDropDown, setShowDropDown] = useState(false);

  const [searchQuery, setSearchQuery] = useState(selectOption[value] || "");

  const { breakpoint, width } = useResponsiveCondition(768);

  useEffect(() => {
    if (name === "pincode") {
      setSearchQuery(value || "");
    } else {
      setSearchQuery(selectOption?.[value] || "");
    }
  }, [value]);

  const proposalSelectedDOBRedux = useSelector(
    ({ proposalPage }) => proposalPage?.proposalData["Proposer Details"]?.dob,
  );

  const {
    data: {
      data: {
        input: { members },
      },
    },
  } = useGetEnquiry();

  let proposerAgeTemp = parseInt(
    members?.filter(i => i.type === "self")[0]?.age,
  );

  let currentYear = new Date().getFullYear();

  let estimatedProposerDOB = `${currentYear - proposerAgeTemp}`;

  const proposerAge = getAge(
    selectedValues?.dob || proposalSelectedDOBRedux || estimatedProposerDOB,
  );

  useEffect(() => {
    if (value) {
      if (values && values[name] !== selectOption[value] && !fill) {
        directUpdateValue(name, value);
      } else if (!selectOption[value]) deleteValue();
    }
    if (fill && name.includes("town")) {
      if (values[name] && fill) {
        dispatch(
          callApi(fill.using, {
            [name]: values[name],
            [fill.alsoUse]: values[fill.alsoUse],
          }),
          fill,
        );
      }
    }

    return () => {};
  }, [value]);

  useEffect(()=> {
    showDropDown? inputRef.current.focus() : inputRef.current.blur()
  }, [showDropDown])
  label = label || "- Select -";

  const rawLabel = label;

  label = checkValidation?.required ? `${label}*` : label;

  const isDisabled =
    (value && Object.keys(selectOption).length === 1 && !asyncOptions) ||
    readOnly;

  const changeHandler = ({ label, value }) => {
    if (notAllowed[value]) {
      dispatch(
        setShowErrorPopup({
          show: true,
          head: "",
          msg: notAllowed[value],
        }),
      );
      return;
    } else if (Array.isArray(notAllowed) && notAllowed.includes(value)) {
      dispatch(
        setShowErrorPopup({
          show: true,
          head: "",
          msg: message.stp_block_message,
        }),
      );
      return;
    }

    inputRef.current.blur();

    if (
      invalidateOption &&
      invalidateOption?.option &&
      invalidateOption.option === value
    ) {
      dispatch(
        setShowErrorPopup({
          show: true,
          head: "",
          msg: invalidateOption.message,
        }),
      );
      setSearchQuery("");
    } else {
      setShowDropDown(false);
      onChange({ target: { name: label, value } }, value);
    }
  };

  const handleSearchQueryChange = e => {
    setSearchQuery(e.target.value.trimStart());
  };

  const inputClickHandler = () => {
    setShowDropDown(prev => !prev);
    setSearchQuery("");
  };

  const inputBlurHandler = () => {
    setShowDropDown(false);
    setSearchQuery(selectOption[value] || "");
  };

  const validOptionsGenerator = (selectOption = {}) => {
    let rawOptions = Object.keys(selectOption).map(key => ({
      display_name: selectOption[key],
      code: key,
    }));

    rawOptions = rawOptions.map(item => ({
      ...item,
      display_name: stringLengthCutter(item.display_name, 80),
    }));

    //! ------ VALIDATIONS ------

    if (
      selectedValues?.title &&
      selectedValues.title === "mrs" &&
      label === "Marital Status*"
    ) {
      //? Martial status should render all except single.
      rawOptions = rawOptions.filter(item => item?.code !== "single");
    } else if (
      selectedValues?.title &&
      selectedValues?.title === "mr" &&
      label === "Marital Status*" &&
      proposerAge < 21
    ) {
      //? Martial status render only single.
      rawOptions = rawOptions.filter(item => item?.code === "single");
    } else if (
      selectedValues?.title &&
      selectedValues?.title === "mr" &&
      label === "Occupation*"
    ) {
      //? Occupation should not render "House wife".
      rawOptions = rawOptions.filter(item => item?.code !== "House wife");
    } else if (
      formName === "Insured Details" &&
      relation.includes("daughter") &&
      label === "Title*" &&
      generateAge({ dob: values?.dob }) < 18
    ) {
      rawOptions = rawOptions.filter(item => !item?.code?.includes("mrs"));
    } else if (
      allValues?.["Proposer Details"]?.marital_status === "single" &&
      name === "nominee_relation"
    ) {
      //? Nominee relation should not render "in laws" for single proposer.
      rawOptions = rawOptions.filter(
        item =>
          !item?.code?.endsWith("_in_law") &&
          !item?.code?.includes("son") &&
          !item?.code?.includes("daughter") &&
          !item?.code?.includes("spouse"),
      );
    } else if (Object.keys(hideMemberOption).length) {
      // hide specific options of MQ dropdown according to members
      if (
        Object.keys(hideMemberOption).some(member => member === innerMember)
      ) {
        rawOptions = rawOptions.filter(
          item => item?.code !== hideMemberOption[innerMember],
        );
      }
    }

    return rawOptions;
  };

  return (
    <SelectContainer height={height}>
      {rawLabel.length >= 30 && (
        <OuterLabelContainer>
          <CustomToolTip
            disable={!tooltip_msg.length}
            description={tooltip_msg}
            showInfoIcon={tooltip_msg.length}
            className="tooltip"
          ></CustomToolTip>
          <OuterLabel mandatory={checkValidation?.required}>
            {rawLabel}
          </OuterLabel>
        </OuterLabelContainer>
      )}
      <SearchInput
        value={
          width < breakpoint && width > 400
            ? searchQuery
            : stringLengthCutter(searchQuery, 15)
        }
        onChange={handleSearchQueryChange}
        placeholder={"- Select -"}
        disabled={isDisabled}
        error={error}
        onClick={inputClickHandler}
        onBlur={inputBlurHandler}
        height={height}
        borderR={borderR}
        ref={inputRef}
        showDropDown={showDropDown}
        readOnly
      />
      <Dropdown
        hideDefaultControl
        onChange={changeHandler}
        options={validOptionsGenerator(selectOption).map(getReactSelectOption)}
        ref={dropdownRef}
        searchQuery={searchQuery}
        showDropdown={showDropDown}
        disable={disable}
        dropdownCss={""}
      />
      {rawLabel.length < 30 && (
        <Label>
          <CustomToolTip
            disable={!tooltip_msg.length}
            description={tooltip_msg}
            showInfoIcon={tooltip_msg.length}
          ></CustomToolTip>
          <LabelStyled mandatory={checkValidation?.required}>
            {rawLabel}
          </LabelStyled>
        </Label>
      )}
      {error && <p className="formbuilder__error">{error}</p>}
    </SelectContainer>
  );
};

export default DropDownP;

const SelectContainer = styled.div`
  margin-top: ${props => {
    return !props.height ? "0.3rem !important" : "9px !important";
  }};
  position: relative;

  margin-bottom: ${props => {
    return !props.height ? "12px !important" : "9px !important";
  }};

  @media (max-width: 767px) {
    margin-bottom: 12px !important;
    margin-top: ${props => !props.height && "15px !important"};
  }
`;

const SearchInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 14px;
  padding: 12px;
  border: 1px solid #d4d5d9;
  background: ${props => (props.disabled ? "" : `url(${down}) no-repeat 98%`)};
  background: ${props => (props.showDropDown ? `url(${up}) no-repeat 98%` : `url(${down}) no-repeat 98%`)};
  font-weight: 300;
  border-radius: ${ROUNDED.base};
  cursor: pointer;
  &:disabled {
    cursor: not-allowed !important;
    pointer-events: all !important;
  }
  -webkit-tap-highlight-color: transparent;
  outline: none;
  transition: border 0.3s ease-in-out;
  border: ${props => !props.height && "1px solid #ced4da"};

  border: ${props => props.error && "solid 1px #c7222a"};

  border-right: ${props => props.borderR && "1px solid #ced4da"};

  color: #090909;
  -webkit-text-fill-color: #090909;
  opacity: 1;
  position: relative;
  &:focus {
    border: ${props => props.error && "solid 1px #c7222a"};
    color: black;
    background: url(${up}) no-repeat 98%;
  }
`;

const Label = styled.label`
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px !important;
  color: #000;
  position: absolute;
  left: 8px;
  top: 0;
  margin: 0;
  background: #fff;
  transition: all 0.3s ease-in-out;
  font-weight: ${FWEIGHT.semi};
  transform: translateY(-50%);
  padding: 0 4px;
`;

const LabelStyled = styled.span`
  margin-left: 2px;

  ${({ mandatory }) => {
    return mandatory ? "&::after {  content: '*'; color: red;}" : "";
  }}
`;

export const OuterLabelContainer = styled.div`
  display: flex;
  & :nth-child(1) {
    align-items: center;
  }
`;

export const OuterLabel = styled.label`
  text-align: left;
  font-size: 13px !important;
  color: #000;
  font-weight: ${FWEIGHT.semi};
  ${({ mandatory }) => {
    return mandatory ? "&::after {  content: '*'; color: red;}" : "";
  }}
`;
