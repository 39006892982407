import { useState } from "react";
import { RiPencilFill } from "react-icons/ri";
import { useTheme } from "src/customHooks";
import MembersModal from "src/pages/InputPage/Forms/NewAddMembersForm/MembersModal";
import FHTypography from "src/styles/Typography";
import styled from "styled-components/macro";

const EditMemberFilter = ({ redirectToQuotes = true }) => {
  const { colors } = useTheme();

  //STATE/s
  const [showEditModal, setShowEditModal] = useState(false);

  // HANDLER/s
  function handleToggleEditModal() {
    setShowEditModal(prev => !prev);
  }

  return (
    <>
      <EditDetailsStyled
        colors={colors}
        onClick={handleToggleEditModal}
        tabIndex={0}
        role="button"
      >
        {redirectToQuotes && <div className="rounded" />}

        <FHTypography
          className="details_text"
          variant="h6"
          colorType="subHeading"
          weight="semi"
        >
          Edit Details
        </FHTypography>

        <div className="details_icon">
          <RiPencilFill />
        </div>
      </EditDetailsStyled>
      {showEditModal && (
        <MembersModal
          isModalOpen={showEditModal}
          title="Edit Member Details"
          onClose={handleToggleEditModal}
        />
      )}
    </>
  );
};

export default EditMemberFilter;

const EditDetailsStyled = styled.div`
  display: flex;
  align-items: center;
  .rounded {
    background-color: ${props => props.colors.secondary_color};
    width: 0.37em;
    height: 2.1em;
  }
  .details_text {
    margin: 0 7px;
  }
  .details_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: ${props => props.colors.primary_color};
    background-color: white;
    width: 2.1em;
    height: 2.1em;
    svg {
      font-size: 1.2rem;
    }
  }
`;
