import styled from "styled-components/macro";

export const Box = styled.section`
  margin-bottom: 0.5rem;
`;
export const FlexEndBox = styled.section`
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
`;

export const Label = styled.label`
  font-size: 1rem;
  font-weight: 600;
`;

export const Error = styled.p`
  margin: unset;
  font-size: 0.8rem;
  color: red;
`;
