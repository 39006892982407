import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import styled from "styled-components/macro";
import { useTheme } from "src/customHooks";
import { BsInfoCircle } from "react-icons/bs";
import CustomToolTip from "src/components/Common/CustomTooltip";

const Confirmation = ({
  label = "",
  value = "",
  onChange = () => {},
  error,
}) => {
  const { colors } = useTheme();
  const confirmationChangeHandler = event => {
    onChange(event, event.target.value);
  };

  const tooltipText =
    "Central KYC Registry is a centralized repository of KYC records. Once KYC documents are submitted by an individual, they are registered in the repository with a unique CKYC number. The CKYC number can be quoted instead of submitting physical KYC documents for any financial transaction.";
  return (
    <>
      <StyledSection {...colors}>
        <Question>
          <span>{label}</span>
          <CustomToolTip description={tooltipText} showInfoIcon={false}>
            <span>
              <BsInfoCircle />
            </span>
          </CustomToolTip>
        </Question>
        <StyledToggleButtonGroup
          color="primary"
          exclusive
          value={value}
          onChange={confirmationChangeHandler}
          aria-label="Platform"
        >
          <StyledToggleButton value="Y">YES</StyledToggleButton>
          <StyledToggleButton value="N">NO</StyledToggleButton>
        </StyledToggleButtonGroup>
      </StyledSection>

      {error ? <p className="formbuilder__error">{error}</p> : <></>}
    </>
  );
};

const StyledSection = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  & .css-d9c359-MuiButtonBase-root-MuiToggleButton-root.Mui-selected,
  & .css-1cmnqml.Mui-selected {
    color: #fff !important;
    font-weight: 600;
    background: ${({ primary_color }) => primary_color} !important;
  }

  @media (max-width: 500px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Question = styled.p`
  display:flex;
  margin: unset;
  font-size: 1rem;
  font-weight: 600;

  & svg {
    margin-left: 5px;
    cursor: pointer;
  }

  @media (max-width: 500px) {
    font-size: 0.9rem;
    margin-bottom: 10px;
  }
`;
// const CustomTooltip = styled.div`
//   position: relative;
//   margin-left: 10px;
//   background-color: rgba(0, 0, 0, 0.9);
//   padding: 10px;
//   color: white;
//   border-radius: 3px;
//   width: 25rem;
//   font-size: 0.8rem;
//   z-index: 1060;

//     &:before {
//       content: "";
//       position: absolute;
//       top: 50%;
//       left: 0;
//       transform: translate(-100%, -50%);
//       width: 0;
//       height: 0;
//       border-top: 10px solid transparent;
//       border-bottom: 10px solid transparent;

//       border-right: 10px solid rgba(0, 0, 0, 0.9);
//     }
//   }
// `;
const StyledToggleButtonGroup = styled(ToggleButtonGroup)``;

const StyledToggleButton = styled(ToggleButton)`
  min-width: 120px;
  border-color: #000 !important;
  color: #000 !important;
`;

export default Confirmation;
