import styled from "styled-components/macro";
import { BsInfoCircle } from "react-icons/bs";
import { FLEX_ROW_CENTER } from "src/styles/styleGuide";
import { Button } from "src/components/Common/Button/ContinueButton";

export const KYCVerificationFieldContainer = styled.div`
  ${FLEX_ROW_CENTER};
  height: 55px;
`;

export const TextFieldContainer = styled.div`
  width: 80%;
  height: 100%;
  position: relative;

  & > div {
    margin: 0 !important;
    height: 100%;
  }
`;

export const Info = styled(BsInfoCircle)`
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  z-index: 100;
  cursor: helper;
`;

export const ButtonStyled = styled(Button)`
  border-radius: 0;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  min-width: 20%;
  height: 100%;
`;
