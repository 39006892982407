import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { Button } from "src/components/Common/Button/ContinueButton";
import { useTheme, useUrlEnquiry } from "src/customHooks";
import { useGetEnquiry } from "src/customHooks/useGetCommonQueries";
import Modal from "./../../../../../../components/Common/Popups/ReusableModal/Modal";
import { RuleEngineContainer, relationString } from "../NivaRule";
import FHTypography from "src/styles/Typography";
import styled from "styled-components";
import { ROUNDED, SHADES } from "src/styles/styleGuide";

const RejectedPopUp = ({
  getMUStatus = () => {},
  currentGroupMembers,
  medicalUrlsRuleEngine,
  medicalUnderwritingLetters,
}) => {
  const { colors } = useTheme();
  const {
    data: {
      data: { groups },
    },
  } = useGetEnquiry();

  const history = useHistory();
  const { getUrlWithEnquirySearch } = useUrlEnquiry();

  const anyRejectedStatus = medicalUrlsRuleEngine
    ? Object.keys(medicalUrlsRuleEngine)
        .filter(member => {
          return currentGroupMembers?.includes(member?.toLowerCase());
        })
        .some(member => getMUStatus(member) === "Rejected")
    : false;

  let rejectedMember = Object.keys(medicalUnderwritingLetters)?.filter(
    item => medicalUnderwritingLetters[item]?.result === "Rejected",
  );

  const viewQuotesHandler = () => {
    history.replace(getUrlWithEnquirySearch(`/quotes/${groups[0]?.id}`));
  };
  return (
    <Modal
      isModalOpen={anyRejectedStatus}
      title="RULE ENGINE REJECTION"
      height="fit-content"
      hideCloseBtn={true}
    >
      <RuleEngineContainer>
        <ModalContent>
          <FHTypography variant="h6" colorType="subheading">
            {rejectedMember?.length === 1 ? "Member" : "Members"}
          </FHTypography>
          <FHTypography variant="h6" colorType="subheading">
            Status
          </FHTypography>
          <FHTypography variant="h6" colorType="subheading">
            Details
          </FHTypography>
        </ModalContent>
        {rejectedMember?.map((member, indx) => (
          <ModalContent key={indx}>
            <div>
              <FHTypography
                variant="subheading1"
                colorType="subheading"
                className="members"
              >
                {relationString(member)}
              </FHTypography>
            </div>
            <FHTypography variant="subheading1" colorType="subheading">
              {medicalUnderwritingLetters[member]?.result}
            </FHTypography>
            <CheckBtnStyled
              href={medicalUnderwritingLetters[member]?.medical_question_url}
              target="_blank"
              colors={colors}
            >
              <FHTypography variant="body1" colorType="subheading">
                Click here
              </FHTypography>
            </CheckBtnStyled>
          </ModalContent>
        ))}
      </RuleEngineContainer>

      <Button
        className="w-100"
        css={`
          border-radius: 9px;
          margin-top: 15px;
        `}
        onClick={viewQuotesHandler}
      >
        View Quotes
      </Button>
    </Modal>
  );
};

export default RejectedPopUp;

const ModalContent = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  border-bottom: 1px solid ${SHADES.LIGHT_GRAY};
  justify-items: center;
  width: 100%;
  padding: 0.7rem 0;
  &:last-child {
    border-bottom: none;
  }
  .members {
    text-transform: capitalize;
  }
`;

const CheckBtnStyled = styled.a`
  background: ${({ colors }) => colors.primary_color};
  padding: 0.3rem 0.5rem;
  border-radius: ${ROUNDED.md};
`;
