import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { memo } from "react";

const PortDatePicker = ({
  value = new Date(),
  setValue = () => {},
  setError = () => {},
}) => {
  const minDate = new Date(Date.now());
  const maxDate = new Date(Date.now());
  maxDate.setFullYear(maxDate.getFullYear() + 3); // + 3 years

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        label="Enter Expiry Date"
        value={value}
        inputFormat="dd-MM-yyyy"
        maxDate={
          new Date(
            `${maxDate.getFullYear()}-${
              maxDate.getMonth() + 1
            }-${maxDate.getDate()}`,
          )
        }
        minDate={
          new Date(
            `${minDate.getFullYear()}-${
              minDate.getMonth() + 1
            }-${minDate.getDate()}`,
          )
        }
        onChange={newValue => {
          setValue(newValue);
        }}
        renderInput={params => {
          setError(params.error);
          return (
            <TextField
              autoComplete="off"
              color="primary"
              fullWidth
              helperText={params.error && "Please provide a valid date"}
              {...params}
            />
          );
        }}
      />
    </LocalizationProvider>
  );
};

export default memo(PortDatePicker);
