import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "src/components/Common/Button/ContinueButton";
// CUSTOM HOOKS
import {
  useAdditionalDiscount,
  useCart,
  usePortabilityJourneyConfig,
  useTotalPremiumLoader,
  useUpdateEnquiry,
} from "src/customHooks";
import useUrlQuery from "src/customHooks/useUrlQuery";
// UTILS
import {
  featureOptionsValidValue,
  getTotalPremiumWithDiscount,
} from "src/utils/helper";
// DESIGN SYSTEM
import { ROUNDED } from "src/styles/styleGuide";
// CUSTOM COMPONENTS
import styled from "styled-components";
import ConfigurationModel from "src/components/ConfigurationModel";

export default function CartProceedButton({ groupCode }) {
  const url = useUrlQuery();
  const history = useHistory();
  const { updateCart, getCartEntry } = useCart();
  const { query: additionalDiscountsQuery, getTotalDiscountAmount } =
    useAdditionalDiscount(groupCode);
  const { getNextGroupProduct } = useCart();

  const { updateEnquiry, enquiryData } = useUpdateEnquiry();
  const [updateCartMutation, query] = updateCart(groupCode);

  const cartEntry = getCartEntry(groupCode);
  const isTotalPremiumLoading = useTotalPremiumLoader(cartEntry);
  const nextGroupProduct = getNextGroupProduct(parseInt(groupCode));
  const { disableReviewCartButton } = usePortabilityJourneyConfig(groupCode);

  const [configurationModalState, setConfigurationModalState] = useState({
    show: false,
    type: "",
  });

  const urlQueryStrings = new URLSearchParams(window.location.search);
  const enquiryId = urlQueryStrings.get("enquiryId");
  const currentGroup =
    localStorage.getItem("groups") &&
    JSON.parse(localStorage.getItem("groups")).find(group => group?.id);

  const disableProceedCartButton =
    cartEntry.unavailable_message ||
    query.isLoading ||
    additionalDiscountsQuery.isLoading ||
    additionalDiscountsQuery.isFetching ||
    isTotalPremiumLoading ||
    disableReviewCartButton;

  const handleClick = async () => {
    const discounted_total_premium = getTotalPremiumWithDiscount({
      netPremiumWithoutDiscount: cartEntry?.netPremiumWithoutDiscount,
      totalDiscountAmount: getTotalDiscountAmount(),
    });

    const featureOptions = featureOptionsValidValue(cartEntry?.feature_options);

    if (enquiryData?.newGroupUpdateRequest) {
      await Promise.all([
        updateEnquiry(enquiryData?.newGroupUpdateRequest),
        updateCartMutation({
          discounted_total_premium,
          feature_options: featureOptions,
        }),
      ]);

      if (nextGroupProduct) {
        const enquiryId = url.get("enquiryId");
        return history.push({
          pathname: `/productdetails/${nextGroupProduct?.group?.id}`,
          search: `enquiryId=${enquiryId}&pincode=${currentGroup?.pincode}&city=${currentGroup?.city}`,
        });
      }
      return history.push(`/proposal?enquiryId=${enquiryId}`);
    } else {
      updateCartMutation({
        discounted_total_premium,
        feature_options: featureOptions,
      }).then(() => {
        if (nextGroupProduct) {
          const enquiryId = url.get("enquiryId");
          return history.push({
            pathname: `/productdetails/${nextGroupProduct?.group?.id}`,
            search: `enquiryId=${enquiryId}&pincode=${currentGroup?.pincode}&city=${currentGroup?.city}`,
          });
        }
        return history.push(`/proposal?enquiryId=${enquiryId}`);
      });
    }
  };

  return (
    <>
      <CartProceedButtonStyled
        loader={!nextGroupProduct && query.isLoading}
        disabled={disableProceedCartButton}
        onClick={handleClick}
      >
        Proceed
      </CartProceedButtonStyled>
      {configurationModalState.show && (
        <ConfigurationModel
          type={configurationModalState.type}
          closeModal={setConfigurationModalState.bind(null, {
            show: false,
            type: "",
          })}
        />
      )}
    </>
  );
}

export const CartProceedButtonStyled = styled(Button)`
  padding: 0.75rem 1.5rem;
  border-radius: ${ROUNDED.md};
`;
