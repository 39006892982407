import { Fragment } from "react";
import { RiShareForwardFill } from "react-icons/ri";
import { useTheme } from "src/customHooks";
import FHTypography from "src/styles/Typography";
import {
  FLEX_ROW,
  FLEX_ROW_CENTER,
  FONT,
  FWEIGHT,
  ROUNDED,
  SHADES,
  SHADOW,
} from "src/styles/styleGuide";
import styled from "styled-components";
import useDropModule from "./hooks/useDropModule";
import Backdrop from "../Common/Backdrop";

function ShareModule({
  innerText,
  dropOptions = [],
  isDropDown = false,
  handleShareClick = () => {},
}) {
  const { colors } = useTheme();
  const { showDropDown, handleToggleDropDown } = useDropModule(isDropDown);

  //FUNCTION/s
  function handleShareButtonClick() {
    // If isDropDown is true, then toggle the dropDown else
    // call the handleShareClick function
    if (isDropDown) {
      handleToggleDropDown();
    } else {
      handleShareClick();
    }
  }

  return (
    <ShareModuleContainerStyled colors={colors}>
      <button
        data-html2canvas-ignore="true"
        className="shareModule_share_button"
        onClick={handleShareButtonClick}
      >
        <RiShareForwardFill size={18} />
        <FHTypography variant="subheading2" weight="md">
          {innerText}
        </FHTypography>
      </button>

      {
        // ShowDrop down with given options
        showDropDown && (
          <Fragment>
            <ShareModuleDropContainerStyled colors={colors}>
              {dropOptions.map(option => (
                <button
                  className={`shareModule_drop_option ${FONT.body1}`}
                  disabled={option.disabled}
                  onClick={() => {
                    // Function coming from the component that uses the shareDropDown Elements arrays
                    option.handleClickFunction();
                    handleToggleDropDown();
                  }}
                  key={option.label}
                >
                  {option.label}
                </button>
              ))}
            </ShareModuleDropContainerStyled>
            <Backdrop bgColor="transparent" onClick={handleToggleDropDown} />
          </Fragment>
        )
      }
    </ShareModuleContainerStyled>
  );
}

const ShareModuleDropContainerStyled = styled.div`
  position: absolute;
  top: 115%;
  right: 0;
  width: 120%;
  background-color: #fff;
  border-radius: ${ROUNDED.base};
  border: 1px solid ${SHADES.LIGHT_GRAY};
  box-shadow: ${SHADOW.xl};
  z-index: 1000;

  .shareModule_drop_option {
    ${FLEX_ROW};
    cursor: pointer;
    font-weight: ${FWEIGHT.semi};
    width: 100%;
    padding: 0.6rem 0.4rem;
    transition: all 0.2s ease-in-out;
    &:not(:last-child) {
      border-bottom: 1px solid ${SHADES.LIGHT_GRAY};
    }

    :disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }

    :hover {
      background-color: ${({ colors }) => colors.primary_shade};
    }
  }
`;

const ShareModuleContainerStyled = styled.div`
  position: relative;

  .shareModule_share_button {
    ${FLEX_ROW_CENTER};
    gap: 0.5rem;
    border: 1px solid ${({ colors }) => colors.primary_color};
    border-radius: ${ROUNDED.full};
    background-color: #fff;
    color: ${({ colors }) => colors.primary_color};
    padding: 0.6rem 0.5rem;
    transition: all 0.2s ease-in-out;

    :hover {
      background-color: ${({ colors }) => colors.primary_color};
      color: #fff;
    }
  }
`;

export default ShareModule;
