import { rbLoaderGif } from "src/assets/gifs";
import styled from "styled-components/macro";

const RenewBuyLoader = () => {
  return (
    <RBGifContainer>
      <img src={rbLoaderGif} alt="loading gif" loading="lazy" />
      <h2>
        <span className="">Please wait...</span>
        <span>The proposal summary page is being prepared</span>
      </h2>
    </RBGifContainer>
  );
};

export default RenewBuyLoader;

const RBGifContainer = styled.div`
  text-align: center;
  width: 100%;

  img {
    width: 12rem;
  }

  h2 {
    transform: translateY(-2rem);
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
