import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Calendar } from "@mui/x-date-pickers/internals/components/icons";
import moment from "moment";
import { useEffect, useState } from "react";
import useGetRouteMatch from "src/customHooks/useGetRouteMatch";

function ChildDatePicker({ member, updateMember }) {
  const { isQuoteRoute } = useGetRouteMatch();
  const myMinDate = moment().subtract(89, "days")._d;
  const myMaxDate = moment()._d;
  const [value, setValue] = useState(null);

  useEffect(() => {
    if (isQuoteRoute) {
      const selectedDate = moment().subtract(member.days, "days")._d;
      setValue(selectedDate);
    }

    return () => {};
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DesktopDatePicker
        value={value}
        minDate={myMinDate}
        maxDate={myMaxDate}
        inputFormat="DD-MM-YYYY"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Calendar />
            </InputAdornment>
          ),
        }}
        onChange={newValue => {
          setValue(newValue);
          const todayDate = moment();
          let previousDate = moment(newValue);
          const diffInDays = todayDate.diff(previousDate, "days");
          updateMember({
            memberType: member.code,
            age: member.ageString,
            displayName: member.display_name,
            displayCal: true,
            days: diffInDays,
            min_age: member.min_age,
            max_age: member.max_age,
            is_primary: member.is_primary,
          });
        }}
        renderInput={params => (
          <TextField
            placeholder="dd-mm-yyyy"
            autoComplete="off"
            color="primary"
            helperText={params.error && "Please provide a valid date"}
            sx={{
              //Styling for datepicker
              ".MuiInputBase-input": {
                fontSize: 12,
                cursor: "pointer",
                padding: "4px 0px",
              },
              ".MuiInputBase-input::placeholder": {
                color: "#000",
                fontWeight: 500,
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: 12,
                border: "1px solid",
                padding: "2px 8px",
                borderColor: params.error ? "#d32f2f" : "#b0bed0",
                "& fieldset": {
                  border: "none",
                },
              },
              "& .MuiButtonBase-root": {
                fontSize: "14px",
                marginRight: "-8px",
                "& svg": {
                  width: "1.25rem",
                  height: "1.25rem",
                },
              },
            }}
            {...params}
          />
        )}
      />
    </LocalizationProvider>
  );
}

export default ChildDatePicker;
