const useAsyncButton = ({ initialValue = "" }) => {
  const generateStatus = () => {
    if (initialValue) {
      return initialValue === "failure" ? "failure" : "success";
    } else {
      return "unverified";
    }
  };

  return {
    status: generateStatus(),
  };
};
export default useAsyncButton;
