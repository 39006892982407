import "styled-components/macro";
import {
  InputContainer,
  InnerContainer,
  InnerTextStyled,
  BrowseButton,
  Label,
} from "./FileInput2Styled";
import { FiUpload } from "react-icons/fi";
import { useTheme } from "src/customHooks";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { getBase64, sizeInMB } from "../../proposalPage.utility";

import { BsInfoCircle, BsFillCheckCircleFill } from "react-icons/bs";
import { stringLengthCutter } from "src/utils/string.utility";
import { useResponsiveCondition } from "src/pos/hooks";
import CustomToolTip from "src/components/Common/CustomTooltip";
import styled from "styled-components";

const FileInput2 = ({
  accept,
  onChange,
  checkValidation = {},
  label,
  error = "",
  required,
  name,
  disable,
  clearField = "",
  value,
}) => {
  const [currFile, setCurrFile] = useState(null);
  const [successMsg, setSuccessMsg] = useState(false);
  const { colors } = useTheme();
  const { width, breakpoint } = useResponsiveCondition(600);

  const supportedFileTypes = accept
    ? accept?.split(",")?.map(item => item.trim())
    : ["image/png", "image/jpeg", "image/jpg", "application/pdf"];

  const fileTypeText = supportedFileTypes
    ?.map(fileType => fileType.split("/")[1])
    .join(", ");

  // FUNCTION/s
  const fileChangeHandler = e => {
    const fileType = e.target.files[0]?.type;
    if (supportedFileTypes.some(item => item === fileType)) {
      setCurrFile(e.target.files[0]);
      getBase64(e.target.files?.[0], base64 => {
        onChange(base64);
      });
      if (!error) {
        setSuccessMsg(true);
      }
    } else {
      let supportedTypeString = supportedFileTypes?.map(
        type => type?.split("/")[1],
      );
      toast.error(
        `Unsupported file format! Try uploading ${supportedTypeString.join(
          "/",
        )}`,
        {
          position: "top-center",
          style: {
            maxWidth: 500,
          },
        },
      );
      setSuccessMsg(false);
    }
  };

  useEffect(() => {
    setCurrFile(null);
  }, [clearField]);

  useEffect(() => {
    if (error) {
      setSuccessMsg(false);
    }
    return () => {};
  }, [error]);

  return (
    <>
      <input
        type="file"
        onChange={e => fileChangeHandler(e)}
        onClick={e => (e.target.value = null)}
        accept={accept ? accept : "application/pdf,image/*"}
        css={`
          display: none;
        `}
        id={`${name}_fileInputElement`}
        required={required}
        disabled={disable}
      />
      <InputContainer
        htmlFor={`${name}_fileInputElement`}
        error={error}
        disable={disable}
      >
        <InnerContainer>
          <InnerTextStyled>
            {currFile ? (
              <>
                <span>
                  {width > breakpoint
                    ? stringLengthCutter(currFile?.name, 32)
                    : stringLengthCutter(currFile?.name, 10)}
                </span>
                <small>({sizeInMB(currFile?.size)} MB)</small>
              </>
            ) : value ? (
              <span>File Already Uploaded</span>
            ) : (
              <>
                <span>Drop your file here.</span>
                <FiUpload />
              </>
            )}
          </InnerTextStyled>
          <BrowseButton disable={disable} primaryColor={colors?.primary_color}>
            {currFile || value ? "Replace" : "Browse"}
          </BrowseButton>{" "}
        </InnerContainer>
        <Label checkValidation={checkValidation}>
          <CustomToolTip
            description={`Supported file types - ${fileTypeText}`}
            showInfoIcon={false}
          >
            <div>
              <BsInfoCircle />
            </div>
          </CustomToolTip>

          <LabelStyled>{label}</LabelStyled>
        </Label>
      </InputContainer>
      {successMsg && !error ? (
        <p className="formbuilder__success">
          <BsFillCheckCircleFill /> File uploaded successfully!
        </p>
      ) : (
        <></>
      )}
      {error && <p className="formbuilder__error">{error}</p>}
    </>
  );
};

const LabelStyled = styled.span`
  margin-left: 4px;
`;

export default FileInput2;
