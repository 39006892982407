import { useEffect } from "react";
import { useSelector } from "react-redux";
import "styled-components/macro";
import FormBuilder from "../../../components/FormBuilder/FormBuilder";
import { useMembers } from "src/customHooks";
import BackBtn from "../components/Buttons/BackBtn";
import ContinueBtn from "../components/Buttons/ContinueBtn";
import { components } from "../components/componentSchema";
import Panel from "./../components/AccordionPanel/Panel";
import { Form } from "./../ProposalPage.style";
import RevisedPremiumPopup from "../../ProductDetails/components/ReviewCart/components/RevisedPremiumPopup";
import useFormNavigation from "./useFormNavigation";
import useProposalSections from "./useProposalSections";

const PortDetails = ({
  formSchema = {},
  rawSchema = {},
  name,
  defaultValue,
  setActivateLoader,
  setBlockTabSwitch = () => {},
  listOfForms,
  isContinueClicked,
  setIsContinueClicked,
}) => {
  const { proposalData } = useSelector(state => state.proposalPage);

  const { getGroupMembers } = useMembers();

  const {
    values,
    setValues,
    isValid,
    setValid,
    submit,
    setSubmit,
    additionalErrors,
    revisedPremiumPopupUtilityObject,
    setErrorInField,
    triggerSaveForm,
    setErrors,
    show,
    setShow,
  } = useProposalSections({
    name,
    defaultValue,
    partialLength: Object.keys(formSchema).length,
    setActivateLoader,
    schema: rawSchema,
    setBlockTabSwitch,
    listOfForms,
    activeFormSchema: formSchema,
  });
  /* usePortDetails(name, schema, values, setValues, defaultValue, enquiriesData); */

  const fullName = proposalData["Proposer Details"]?.name;

  const { navigateTo } = useFormNavigation();

  const handleContinue = () => {
    setSubmit("PARTIAL");

    triggerSaveForm({ sendedVal: values, formName: name });
  };

  const handleBack = () => {
    navigateTo({ back: true });
  };

  useEffect(() => {
    //? To handle continue click on Proposal page Mobile
    if (isContinueClicked) {
      handleContinue();
      setIsContinueClicked("");
    }
    return () => {};
  }, [isContinueClicked]);

  return (
    <div>
      {Object.keys(formSchema).map((item, index) => {
        return (
          <Panel
            allMembers={getGroupMembers(parseInt(item))}
            formName={name}
            isFilled={
              Object.keys(values && values[item] ? values[item] : {}).length
            }
            key={index}
            title={`${item}`}
            show={show === index}
            onClick={() => setShow(prev => (prev === index ? false : index))}
          >
            <div>
              <Form
                className="word_break"
                onSubmit={e => {
                  e.preventDefault();
                }}
              >
                <FormBuilder
                  isInsuredDetails
                  keyStr={item}
                  lastName={fullName?.split(" ").slice(-1)}
                  schema={formSchema[item]}
                  components={components}
                  fetchValues={(res = () => {}) => {
                    setValues(prev => {
                      return { ...prev, [item]: res(prev?.[item]) };
                    });
                  }}
                  fetchErrors={res => {
                    setErrors(prev => ({ ...prev, [item]: res }));
                  }}
                  setErrorInField={setErrorInField}
                  fetchValid={res => {
                    let valid = isValid;
                    valid[index] = res;
                    setValid(valid);
                  }}
                  options={{
                    defaultValues: values?.[item] || {},
                    validateOn: "change",
                  }}
                  formName={name}
                  additionalErrors={additionalErrors[item]}
                  setSubmit={setSubmit}
                  submitTrigger={submit}
                  isPanelVisible={show === index}
                  proposalData={proposalData}
                  preFilledDataBase={defaultValue ? defaultValue[item] : {}}
                />
              </Form>
            </div>
          </Panel>
        );
      })}

      <div className="proposal_continue_back_margin container">
        <BackBtn onClick={handleBack} />

        <ContinueBtn disabled={false} onClick={handleContinue} />
      </div>
      {revisedPremiumPopupUtilityObject.isOnProposal && (
        <RevisedPremiumPopup
          revisedPremiumPopupUtilityObject={revisedPremiumPopupUtilityObject}
          onClose={revisedPremiumPopupUtilityObject.off}
        />
      )}
    </div>
  );
};

export default PortDetails;
