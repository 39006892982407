import { useTheme } from "src/customHooks";
import Checkbox2 from "../../components/Checkbox/Checbox";
import "styled-components/macro";
import styled from "styled-components";

const NoForAllMQ = ({
  initColor = "",
  noForAll,
  yesSelected,
  item,
  setNoForAll,
}) => {
  const { colors } = useTheme();

  return (
    <ContainerStyled initColor={initColor}>
      <div className="heading">
        <div>
          <Checkbox2
            showTitle={false}
            title={"No" + item}
            checked={noForAll[item]}
            onChange={e => {
              setNoForAll({
                ...noForAll,
                [item]: e.target.checked,
              });
            }}
          />{" "}
        </div>
        <span>No For All Questions </span>
      </div>

      {!noForAll[item] && !yesSelected[item] && (
        <p id={initColor === colors.primary_color ? "noID" : item}>
          Please select the checkbox if your answer is No for all the questions
        </p>
      )}
    </ContainerStyled>
  );
};

const ContainerStyled = styled.div`
  margin: 20px 29px;
  margin-top: -36px;
  @media (max-width: 1024px) {
    margin-top: 10px;
  }

  .heading {
    display: flex;
    justify-content: flex-end;
    & .container {
      margin: 0;
      width: max-content;
    }
    @media (max-width: 1024px) {
      justify-content: flex-start;
      margin-left: -26px;
    }
    div {
      margin-right: 15px;
    }
  }
  p {
    display: flex;
    font-size: 12px;
    justify-content: flex-end;
    color: ${({ initColor }) => initColor};
    @media (max-width: 1024px) {
      justify-content: flex-start;
      /* margin-left: -26px; */
    }
  }
`;

export default NoForAllMQ;
