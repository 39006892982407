import { useState, useEffect } from "react";
import styled from "styled-components/macro";
import useOtpVerify from "src/customHooks/useOtpVerify";
import { TiTimes } from "react-icons/ti";
import Timer from "./Timer";
import { useTheme } from "src/customHooks";
import { mobile } from "src/utils/mediaQueries";
import { Button } from "src/components/Common/Button/ContinueButton";
import TextInput2 from "src/components/TextInput2";

const OtpVerificationModal = ({
  details = {},
  onClose = () => {},
  setOtpVerified = () => {},
}) => {
  const [otp, setOtp] = useState("");
  const [second, setSecond] = useState(Number(59));
  const [secondThirty, setSecondThirty] = useState(Number(29));
  const [minutes, setMinutes] = useState(4);
  const { colors } = useTheme();
  const { error, setError, success, loading, verifyOtp, resendOtp } =
    useOtpVerify(details);
  useEffect(() => {
    if (success.message === "OTP verified successfully!") {
      setOtpVerified(true);
      onClose();
    }
    if (minutes === 0 && second === 1) {
      onClose();
    }
  }, [success.message, minutes, second]);
  const otpSubmitHandler = e => {
    e.preventDefault();
    e.stopPropagation();
    if (otp === "") {
      setError({
        isError: true,
        message: "Please enter OTP",
      });
    } else {
      if (otp.length >= 6) {
        verifyOtp(otp);
      } else {
        setError({
          isError: true,
          message: "Please enter a valid OTP!",
        });
      }
    }
  };
  return (
    <OtpVerificationComponent color={colors.primary_color} seconds={second}>
      <div className="Otp_modal">
        <span
          className="close"
          onClick={() => {
            onClose();
          }}
        >
          <TiTimes size={30} color="#565656" />
        </span>
        <section
          css={`
            width: 70%;
            ${mobile} {
              width: 90%;
            }
          `}
          className="Otp_head"
        >
          <h2>Verify OTP</h2>
          <p>
            Please verify One-Time Password sent to mobile number{" "}
            {details?.mobile ? (
              <strong>******{details.mobile.slice(6, 10)}</strong>
            ) : (
              <></>
            )}
          </p>
        </section>
        <section
          css={`
            position: absolute;
            right: 20px;
            top: 21%;
            ${mobile} {
              top: 29%;
              right: 40%;
            }
          `}
        >
          <Timer
            customCss={`
              font-size: 21px;
              color: ${minutes == 0 ? "red" : "#444444"};
            
              padding: 5px 20px 5px 20px;
              ${mobile}{
                font-size :18px;
                padding: 3px 10px 3px 10px;
                margin-top:20px;
              }
            `}
            seconds={second}
            minutes={minutes}
            setMinutes={setMinutes}
            setSeconds={setSecond}
          />
        </section>
        <form className="Otp_verify" onSubmit={otpSubmitHandler}>
          <section
            css={`
              width: 80% !important;
              ${mobile} {
                width: 100% !important;
              }
            `}
            className="input"
          >
            <TextInput2
              styledCss={`
                 border-color: ${colors.primary_color} !important;
                 & input{
                   &:focus {
                     border-color: ${colors.primary_color} !important;
                   }
                 }
                 width:100% !important;
                 ${mobile}{
                     margin-bottom:10px;
                     width: 100%;
                     & input {
                         padding-top : 35px !important;
                     }
                 }
               `}
              label="Enter OTP"
              type="text"
              value={otp}
              onChange={e => {
                setOtp(e.target.value.replace(/[^\d]+/g, ""));
              }}
              maxLength={6}
            />

            <div
              className="resend_btn"
              css={`
                max-width: 25% !important;
                height: 67px !important;
                margin-top: 0px !important;
                cursor: ${secondThirty === 0 ? "pointer" : "not-allowed"};
                ${mobile} {
                  height: 60px !important;
                }
              `}
              onClick={() => {
                if (secondThirty === 0) {
                  setSecondThirty(29);
                  resendOtp();
                }
              }}
            >
              {secondThirty === 0 ? (
                "Resend"
              ) : (
                <Timer
                  customCss={`
                    color: white;
                  `}
                  seconds={secondThirty}
                  setSeconds={setSecondThirty}
                  minutes={0}
                  setMinutes={setMinutes}
                />
              )}
            </div>
          </section>
          <div
            css={`
              height: 10px;
            `}
          >
            {error.isError && <p className="Otp_error">{error.message}</p>}
            {success.isSuccess && (
              <p className="Otp_success">{success.message}</p>
            )}
          </div>
          <span
            css={`
              position: absolute;
              right: 15%;
              top: 60%;
              height: 20px;
              ${mobile} {
                top: 61%;
                right: 25%;
              }
            `}
            className="Otp_info"
          >
            {secondThirty === 0 ? <h4>{"Didn't receive the OTP?"}</h4> : <></>}
          </span>
          {details?.customOTPVerification ? (
            <></>
          ) : (
            <button
              // onClick={() => {
              //   if (otp !== "") {

              //   }
              // }}
              css={`
                margin-top: 70px !important;
              `}
              type="submit"
            >
              {loading === true ? "Verifying..." : "Verify"}
            </button>
          )}
        </form>
        {details?.customOTPVerification ? (
          <Button
            // onClick={() => {
            //   if (otp !== "") {

            //   }
            // }}
            css={`
              margin-top: 70px !important;
            `}
            onClick={otpSubmitHandler}
          >
            {loading === true ? "Verifying..." : "Verify"}
          </Button>
        ) : (
          <></>
        )}

        <section className="timer" style={{ marginTop: "10px" }}></section>
        <section
          className="Otp_info"
          css={`
            margin-top: 20px;
            justify-items: center;
          `}
        >
          <h4>{"* This page will automatically expire in 5 minutes."}</h4>
          <div
            css={`
              background-color: #7772;
              height: 10px;
              margin: 0px 30px;
              margin-top: 15px;
              /* width: 70%; */
              ${mobile} {
                display: none;
              }
            `}
          >
            <div
              css={`
                background-color: ${minutes == 0
                  ? "red"
                  : `${colors.primary_color}`};
                width: ${(minutes * 60 + second) / 3}%;
                height: 10px;
              `}
            ></div>
          </div>
        </section>
      </div>
    </OtpVerificationComponent>
  );
};
const OtpVerificationComponent = styled.div`
  min-height: 100vh;
  width: 100vw;
  background-color: #54545455;
  position: fixed;
  top: 0;
  left: 0;
  padding: 20px;
  z-index: 10999999999;
  display: flex;
  align-items: center;
  justify-content: center;
  .Otp_error {
    font-size: 12px;
    color: red;
    margin-top: 4px;
    position: absolute;
    left: 15%;
    ${mobile} {
      margin-top: 20px !important;
      left: 32%;
    }
  }
  .Otp_success {
    font-size: 12px;
    color: #0db40d;
    font-weight: 500;
    margin-top: 4px;
    cursor: pointer;
    position: absolute;
    left: 15%;
    ${mobile} {
      margin-top: 20px !important;
      left: 30%;
    }
  }
  .Otp_modal {
    position: relative;
    margin: 10px auto;
    width: 50%;
    margin-bottom: 200px;
    padding: 40px 10px;
    background-color: white;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    ${mobile} {
      width: 95%;
      margin-top: 15%;
      padding: 10px 10px;
    }
    .close {
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
    }
    .Otp_head {
      text-align: center;
      margin-bottom: 30px;
      h2 {
        font-size: 30px;
        font-weight: 600;
        color: #777;
        margin-bottom: 20px;
        ${mobile} {
          font-size: 24px;
          margin-top: 20px;
        }
      }
      p {
        color: #777777;
      }
    }
    .Otp_verify {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      /* background-color: red; */
      .input {
        max-width: 72%;
        ${mobile} {
          max-width: 85%;
        }
        display: flex;
        flex-direction: row;
        label {
          font-size: 15px;
          color: #666666;
          font-weight: 500;
        }
        input {
          /* max-width: 300px; */
          width: 100%;
          padding: 8px 10px;
          border: 2px solid #e0e4e7;
          border-radius: 4px;
          font-size: 20px;
        }
        .resend_btn {
          margin-top: 15px;
          max-width: 200px;
          min-width: 85px;
          width: 100%;
          text-align: center;
          align-items: center;
          display: flex;
          justify-content: center;
          /* padding: 22px 10px 10px 10px; */
          background-color: ${props => props.color};
          color: white;
          border: none;
          /* border-radius: 2px; */
          border-bottom-right-radius: 4px;
          border-top-right-radius: 4px;
          font-size: 18px;
        }
      }
      button {
        margin-top: 15px;
        max-width: 200px;
        width: 100%;
        padding: 8px;
        background-color: ${props => props.color};
        color: white;
        border: none;
        border-radius: 2px;
        font-size: 20px;
      }
    }
    .orSection {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 20px 0;
      span {
        font-size: 20px;
        padding: 10px;
        border-radius: 100%;
        color: #999999;
      }
      .hr {
        flex: 1;
        background-color: #aaaaaa;
        height: 2px;
        width: 120px;
      }
    }
    .Otp_info {
      /* margin-top: 20px; */
      text-align: center;
      h4 {
        font-size: 15px;
        font-weight: 500;
        color: #999999;
        ${mobile} {
          margin-top: 4px;
        }
        span {
          opacity: ${props => (props.seconds === 0 ? 1 : 0.5)};
          cursor: ${props => (props.seconds === 0 ? "pointer" : "not-allowed")};
        }
      }
      span {
        color: ${props => props.theme.SecondaryColor};
        font-size: 16px;
        cursor: pointer;
        font-weight: 500;
        ::hover {
          filter: brightness(90%);
        }
      }
    }
  }
`;
export default OtpVerificationModal;
