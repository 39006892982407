import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import "styled-components/macro";
import { callApi } from "./FormBuilder.slice";
import {
  checkAllow,
  fetchMembers,
  generateRange,
  validNomineeRelationGenerator,
  ValueExtractor,
} from "./formUtils";
import useFormBuilder from "./useFormBuilder";
import useFormFieldVisibility from "./useFormFieldVisibility";
import Label from "src/pages/ProposalPage/components/Label";

const FormBuilder = ({
  components = {},
  schema = [],
  formName,
  fetchValues = () => {},
  fetchValid = () => {},
  submitTrigger = false,
  setSubmit = () => {},
  additionalErrors = {},
  options = {
    validateOn: "change",
    defaultValues: {},
  },
  setCustomValid = () => {},
  noForAll,
  setNoForAll,
  keyStr = "",
  lastName,
  canProceed,
  yesSelected,
  setErrorInField,
  fetchErrors,
  warnings,
  onAsyncSuccess = () => {},
  onAsyncFailure = () => {},
  onBeforeChange = () => {},
  setNomineeRelationAutoPopulated,
  nomineeRelationAutoPopulated,
  autoPopulateSelfOtherDetails,
  preFilledDataBase,
  isPanelVisible,
}) => {
  const insuredDetails = useSelector(
    ({ proposalPage }) => proposalPage.proposalData["Insured Details"],
  );

  const { proposalData } = useSelector(state => state.proposalPage);

  const {
    updateValue,
    values,
    triggerValidation,
    errors,
    setValues,
    insertValue,
    updateValues,
    checkReadOnly,
    updateValidateObjSchema,
    setBlockScrollEffect,
    scrollToErrors,
    readOnlyFieldsDetector,
    disableToggleDecider,
  } = useFormBuilder({
    schema,
    fetchValues,
    defaultValues: options.defaultValues,
    noForAll,
    setNoForAll,
    formName,
    canProceed,
    yesSelected,
    setErrorInField,
    fetchErrors,
    fetchValid,
    isPanelVisible,
    keyStr,
  });

  const { renderField, disableField, clearField } = useFormFieldVisibility();

  useEffect(() => {
    if (formName === "Other Details") {
      const nomineeRelation = validNomineeRelationGenerator({
        nominee_relation: values.nominee_relation,
      });

      if (nomineeRelation === "self" && proposalData["Proposer Details"]) {
        autoPopulateSelfOtherDetails({
          updateValues,
          selectedNomineeRelation: nomineeRelation,
        });
      } else if (nomineeRelation && insuredDetails[nomineeRelation]) {
        autoPopulateSelfOtherDetails({
          updateValues,
          selectedNomineeRelation: nomineeRelation,
        });
      } else if (
        preFilledDataBase &&
        Object.keys(preFilledDataBase).length &&
        preFilledDataBase?.nominee_relation &&
        validNomineeRelationGenerator({
          nominee_relation: preFilledDataBase?.nominee_relation,
        }) === nomineeRelation
      ) {
        updateValues(preFilledDataBase, "SAVE_AS_IT_IS");
      } else
        updateValues(
          {
            nominee_relation: values.nominee_relation,
          },
          "SAVE_AS_IT_IS",
        );
    }
    return () => {};
  }, [values?.nominee_relation]);

  const [trigger, setTrigger] = useState(false);

  //! Never change based on IC (generic code for nominee relations).
  const relationships = [
    "spouse",
    "son",
    "son1",
    "son2",
    "son3",
    "son4",
    "daughter",
    "daughter1",
    "daughter2",
    "daughter3",
    "daughter4",
    "father",
    "mother",
    "grand_father",
    "grand_mother",
    "brother",
    "brother_in_law",
    "sister",
    "sister_in_law",
    "daughter_in_law",
    "son_in_law",
    "father_in_law",
    "mother_in_law",
  ];

  useEffect(() => {
    if (trigger) {
      triggerValidation(trigger);
      setTrigger(false);
    }
    return () => {};
  }, [trigger, triggerValidation]);

  useEffect(() => {
    if (submitTrigger) {
      triggerValidation && triggerValidation();
      scrollToErrors && scrollToErrors();
      setSubmit && setSubmit("SUBMIT");
    }
    return () => {};
  }, [submitTrigger]);

  const [fillBus, setFillBus] = useState([]);

  const { asyncOptions, asyncValues } = useSelector(state => state.formBuilder);

  const dispatch = useDispatch();

  useEffect(() => {
    let temp = {};
    if (schema instanceof Array)
      schema.forEach(item => {
        if (item.fill) temp = Object.assign(temp, { [item.name]: item.fill });
      });
    setFillBus(temp);
  }, [schema]);

  useEffect(() => {
    let pinCodeSchema = schema.filter(item =>
      item?.name?.includes("pincode"),
    )[0];

    if (pinCodeSchema && pinCodeSchema?.value && pinCodeSchema.fill) {
      dispatch(
        callApi(pinCodeSchema.fill?.using, {
          [pinCodeSchema.name]: pinCodeSchema.value,
        }),
      );
    }
    return () => {};
  }, []);
  const additionalQuestionsValueGenerator = ({ innerItem, values, member }) => {
    if (innerItem?.parent?.includes(".")) {
      return (
        values[innerItem?.parent?.split(".")[0]]?.[member]?.[innerItem?.name] ||
        ""
      );
    }

    return values[innerItem.parent]
      ? values[innerItem.parent][member]
        ? values[innerItem.parent][member][innerItem.name]
        : ""
      : "" || innerItem.value;
  };

  useEffect(() => {
    if (formName !== "Medical Details") {
      setValues(prev => ({ ...prev, ...asyncValues }));
    }
    return () => {};
  }, [asyncValues]);

  const additionalQuestionsVisibility = useCallback(
    (item, member) => {
      if (item.parent.includes(".")) {
        const parentsHierarchy = item.parent.split(".");

        if (values?.[parentsHierarchy[0]]?.[member]) {
          if (values?.[parentsHierarchy[0]]?.[member]?.[parentsHierarchy[1]]) {
            return true;
          }
        }
        return false;
      } else {
        const { additionalOptions } = item;
        if (additionalOptions?.showMembersIf) {
          const dependentMqNames = additionalOptions.showMembersIf.split("||");
          return dependentMqNames.some(
            mqName => values[mqName]?.members[member],
          );
        } else {
          return (
            (values[item?.parent] &&
              values[item?.parent]?.members &&
              values[item?.parent]?.members instanceof Object &&
              values[item?.parent]?.members?.[member]) ||
            item.render === "noDependency"
          );
        }
      }
    },
    [values],
  );

  return (
    <>
      {schema instanceof Array &&
        schema.map((item, index) => {
          if (item instanceof Array) {
            return (
              <>
                {/* THIS IS RESPONSIBLE FOR RENDERING sUB QUESTIONS OF MEDICAL QUESTIONS. */}
                {item[0]?.additionalOptions?.members?.map(member => {
                  if (additionalQuestionsVisibility(item[0], member)) {
                    if (
                      item.some(innerItem =>
                        renderField({
                          item: innerItem,
                          currentFormValues: values,
                          proposalData,
                          member,
                        }),
                      )
                    ) {
                      return (
                        <CustomWrapper key={index + member}>
                          <div>
                            <Label heading={member} />
                            <div className="fields">
                              {item.map(innerItem => {
                                const Comp = components[innerItem.type];
                                if (!Comp) {
                                  return <></>;
                                } else {
                                  if (
                                    renderField({
                                      item: innerItem,
                                      currentFormValues: values,
                                      proposalData,
                                      member,
                                    })
                                  ) {
                                    return (
                                      <Wrapper
                                        key={index + member + innerItem.name}
                                        width={innerItem.width}
                                        id={
                                          innerItem.parent +
                                          member +
                                          innerItem.name
                                        }
                                        medical
                                      >
                                        <Comp
                                          allow={innerItem.allow}
                                          name={innerItem.name}
                                          heading={innerItem.heading}
                                          checkValidation={innerItem.validate}
                                          innerMember={member}
                                          onChange={(
                                            e,
                                            value,
                                            additional_info = {
                                              utilize_parent: "",
                                            },
                                          ) => {
                                            if (innerItem.allow) {
                                              if (
                                                checkAllow(
                                                  innerItem.allow,
                                                  e,
                                                  "change",
                                                )?.notAllowed
                                              ) {
                                                return;
                                              }
                                            }

                                            if (
                                              additional_info?.utilize_parent
                                            ) {
                                              insertValue(
                                                additional_info?.utilize_parent,
                                                member,
                                                innerItem.name,
                                                e.target.value,
                                              );
                                            } else if (
                                              innerItem.parent &&
                                              innerItem.type === "checkboxGroup"
                                            ) {
                                              insertValue(
                                                innerItem.parent,
                                                member,
                                                innerItem.name,
                                                value,
                                              );
                                            } else if (
                                              innerItem.parent &&
                                              innerItem.type === "checkBox2"
                                            ) {
                                              insertValue(
                                                innerItem.parent,
                                                member,
                                                innerItem.name,
                                                e.target.checked ? "Y" : "N",
                                              );
                                            } else if (innerItem.parent) {
                                              insertValue(
                                                innerItem.parent,
                                                member,
                                                innerItem.name,
                                                e.target.value,
                                              );
                                            } else {
                                              if (
                                                !innerItem.type.includes(
                                                  "custom",
                                                )
                                              ) {
                                                updateValue(
                                                  innerItem.name,
                                                  e.target.value,
                                                );
                                              } else
                                                e.target.value &&
                                                  updateValue(
                                                    innerItem.name,
                                                    e.target.value,
                                                  );
                                            }
                                            if (
                                              innerItem.fill &&
                                              (e.target.value.length === 6 ||
                                                innerItem.type === "select")
                                            ) {
                                              dispatch(
                                                callApi(
                                                  fillBus[innerItem.name].using,
                                                  {
                                                    [innerItem.name]:
                                                      e.target.value,
                                                    [fillBus[innerItem.name]
                                                      .alsoUse]:
                                                      values[
                                                        fillBus[innerItem.name]
                                                          .alsoUse
                                                      ],
                                                  },
                                                ),
                                                fillBus[innerItem.name],
                                              );
                                            }
                                            if (
                                              options.validateOn === "change"
                                            ) {
                                              setTrigger({
                                                variableName: innerItem.name,
                                                parent: innerItem.parent,
                                                member,
                                              });
                                            }
                                          }}
                                          readOnly={innerItem.readOnly}
                                          onInput={e => {
                                            if (innerItem.allow) {
                                              checkAllow(
                                                innerItem.allow,
                                                e,
                                                "input",
                                              );
                                            }
                                          }}
                                          onFocus={() =>
                                            setBlockScrollEffect(false)
                                          }
                                          onBlur={() => {
                                            if (options.validateOn === "blur") {
                                              setTrigger(innerItem.name);
                                            }
                                            setBlockScrollEffect(true);
                                          }}
                                          onKeyDown={e => {
                                            if (innerItem.allow) {
                                              checkAllow(
                                                innerItem.allow,
                                                e,
                                                "down",
                                              );
                                            }
                                          }}
                                          onKeyPress={e => {
                                            if (innerItem.allow) {
                                              checkAllow(
                                                innerItem.allow,
                                                e,
                                                "press",
                                              );
                                            }
                                          }}
                                          options={
                                            innerItem.additionalOptions &&
                                            innerItem.additionalOptions
                                              .customOptions &&
                                            generateRange(
                                              innerItem.additionalOptions
                                                .customOptions,
                                              values,
                                            )
                                          }
                                          asyncOptions={
                                            asyncOptions[innerItem.name]
                                          }
                                          hideMemberOption={
                                            innerItem?.additionalOptions
                                              ?.hideMemberOption
                                          }
                                          allValues={proposalData}
                                          value={additionalQuestionsValueGenerator(
                                            {
                                              innerItem,
                                              values,
                                              member,
                                            },
                                          )}
                                          error={
                                            innerItem.parent.includes(".")
                                              ? errors[
                                                  `${innerItem.parent}-${member}${innerItem.name}`
                                                ]
                                              : errors[
                                                  innerItem.parent +
                                                    member +
                                                    innerItem.name
                                                ]
                                          }
                                          submitTrigger={submitTrigger}
                                          setCustomValid={setCustomValid}
                                          values={values}
                                          formSchema={schema}
                                          item={innerItem}
                                          member={member}
                                          rules={innerItem?.rules || []}
                                          {...innerItem.additionalOptions}
                                          label={ValueExtractor(
                                            innerItem?.additionalOptions?.label,
                                            values,
                                            member,
                                          )}
                                          notAllowed={ValueExtractor(
                                            innerItem?.additionalOptions
                                              ?.notAllowed,
                                            values,
                                            member,
                                          )}
                                          noNotAllowed={ValueExtractor(
                                            innerItem?.additionalOptions
                                              ?.noNotAllowed,
                                            values,
                                            member,
                                          )}
                                          parent={innerItem?.parent}
                                          validate={innerItem?.validate}
                                        />
                                      </Wrapper>
                                    );
                                  } else {
                                    return <></>;
                                  }
                                }
                              })}
                            </div>
                          </div>
                        </CustomWrapper>
                      );
                    }
                  } else return <></>;
                })}
                {renderField({
                  item: schema[index - 1],
                  currentFormValues: values,
                  proposalData,
                }) && <HR></HR>}
              </>
            );
          } else {
            {
              /* RESPONSIBLE FOR RENDERING ALL THE FIELDS OF THE PROPOSAL FORM EXCEPT MEDICAL QUESTION'S SUB QUESTION. */
            }
            const Comp = components[item.type];

            const initialValue = relationships.includes(`${keyStr}`)
              ? lastName
              : null;

            if (
              !renderField({ item, currentFormValues: values, proposalData }) &&
              item.render &&
              item.render.when.includes(".") &&
              values[item.name] &&
              values[item.name][`is${item.name}`] === "Y"
            ) {
              setValues(prev => {
                return { ...prev, [item.name]: "" };
              });
            } else if (
              item.render &&
              item.render.when.includes(".") &&
              values[item.name] &&
              values[item.name][`is${item.name}`] === "Y" &&
              !fetchMembers(item.render.when, values).length &&
              !(
                "showMembers" in
                schema.find(_i => _i.name === item.render.when.split(".")[0])
                  .additionalOptions
              )
            ) {
              setValues(prev => {
                return { ...prev, [item.name]: "" };
              });
            }
            if (!Comp) {
              return <></>;
            }
            if (
              item?.name ===
                "healthinfinity_discount_policy_holder_employee_of_reliance_ADA_group" ||
              item?.name ===
                "healthinfinity_discount_RGI_health_policy_holder" ||
              item?.name ===
                "healthinfinity_discount_purchased_any_health_policy"
            ) {
              return <></>;
            }

            return (
              <>
                {renderField({
                  item,
                  currentFormValues: values,
                  proposalData,
                  member: undefined,
                  callBack: show => {
                    if (!show && Boolean(values[item?.name])) {
                      const keysMappedToValues = Object.keys(values);
                      //* Removing the values of hidden fields
                      const hiddenFieldsKeysRemoved = keysMappedToValues.filter(
                        key => {
                          return key !== item.name;
                        },
                      );
                      const finalValues = hiddenFieldsKeysRemoved.reduce(
                        (acc, key) => ({ ...acc, [key]: values[key] }),
                        {},
                      );
                      updateValues(finalValues, "SAVE_AS_IT_IS");
                    }
                  },
                }) && (
                  <Wrapper
                    key={index + item.name}
                    id={item.name + keyStr}
                    width={item.width}
                    data-auto-test-script="tag-id"
                  >
                    <Comp
                      allow={item.allow}
                      name={item.name}
                      heading={item.heading}
                      checkValidation={updateValidateObjSchema(item)}
                      selectedValues={values}
                      data={item.data}
                      onChange={(e /* value  ,additional_info={} */) => {
                        if (item.parent && item.members) {
                          insertValue(
                            item.parent,
                            item.members,
                            item.name,
                            e.target.value,
                          );
                        } else if (item.type === "file") {
                          onBeforeChange();
                          updateValue(item.name, e);
                        } else if (item.type === "lastNameCheckbox") {
                          updateValue(item.name, e.target.checked);
                        } else if (item.name === "occupation") {
                          updateValues({
                            [item.name]: e.target.value,
                            [item.name + "__value"]: e.target.name,
                          });
                        } else {
                          if (item.name === "town" || item.name === "area") {
                            updateValues({
                              [item.name]: e.target.value,
                              [item.name + "__value"]: e.target.name,
                            });
                          } else if (!item.type.includes("custom")) {
                            if (
                              item.name === "nominee_relation" &&
                              !insuredDetails[e.target.value] &&
                              nomineeRelationAutoPopulated
                            ) {
                              updateValue(item.name, e.target.value, true);
                              setNomineeRelationAutoPopulated(false);
                            } else if (item.type === "otp_text_field") {
                              if (
                                e.target.value &&
                                e.target.value !== "failure"
                              ) {
                                updateValues({
                                  [item.name]: e.target.value,
                                  mode: item?.additionalOptions?.mode,
                                });
                              } else {
                                updateValue(item.name, e.target.value);
                              }
                            } else {
                              updateValue(item.name, e.target.value);
                            }
                          } else {
                            updateValue(item.name, e);
                          }
                        }

                        if (
                          item.fill &&
                          (e.target.value.length === 6 ||
                            item.type === "select")
                        ) {
                          dispatch(
                            callApi(fillBus[item.name].using, {
                              [item.name]: e.target.value,
                              [fillBus[item.name].alsoUse]:
                                values[fillBus[item.name].alsoUse],
                            }),
                            fillBus[item.name],
                          );
                        }

                        if (
                          options.validateOn === "change" &&
                          Object.keys(options.defaultValues).length &&
                          formName !== "Medical Details"
                        ) {
                          setTrigger(item.name);
                        }
                        if (item.allow) {
                          checkAllow(item.allow, e, "change");
                        }
                      }}
                      disable={disableField({
                        item,
                        currentFormValues: values,
                      })}
                      clearField={clearField({
                        item,
                        currentFormValues: values,
                      })}
                      notAllowed={item?.notAllowed}
                      noNotAllowed={item?.noNotAllowed}
                      age={item?.validate?.age}
                      ageInDays={item?.age_in_days}
                      warning={warnings?.[keyStr]?.[item.name] || {}}
                      directUpdateValue={(name, value) => {
                        updateValue(name, value, false, () => {
                          setTrigger(name);
                        });
                      }}
                      fill={item.fill}
                      render={item.render}
                      deleteValue={() => {
                        updateValues(
                          Object.keys(values)
                            .filter(key => key !== item.name)
                            .reduce(
                              (acc, key) => ({ ...acc, [key]: values[key] }),
                              {},
                            ),
                          "SAVE_AS_IT_IS",
                        );
                      }}
                      additionalQuestionsToggle={
                        formName === "Medical Details" &&
                        Array.isArray(schema[index + 1])
                          ? schema[index + 1]
                          : []
                      }
                      readOnly={
                        item.readOnly ||
                        checkReadOnly(
                          item.name,
                          formName,
                          item?.additionalOptions,
                        ) ||
                        readOnlyFieldsDetector({
                          relationName: keyStr,
                          formName: formName,
                          fieldName: item?.name,
                          ...item.additionalOptions,
                        })
                      }
                      noForAll={noForAll}
                      allValues={proposalData}
                      onFocus={() => setBlockScrollEffect(false)}
                      customMembers={
                        item.render &&
                        item.render.when.includes(".") &&
                        !(
                          "showMembers" in
                          schema.find(
                            _i => _i.name === item.render.when.split(".")[0],
                          ).additionalOptions
                        ) &&
                        fetchMembers(item.render.when, values)
                      }
                      onInput={e => {
                        if (item.allow) {
                          checkAllow(item.allow, e, "input");
                        }
                      }}
                      onBlur={() => {
                        if (options.validateOn === "blur") {
                          setTrigger(item.name);
                        }
                        setBlockScrollEffect(true);
                      }}
                      onKeyDown={e => {
                        if (item.allow) {
                          checkAllow(item.allow, e, "down");
                        }
                      }}
                      onKeyPress={e => {
                        if (item.allow) {
                          checkAllow(item.allow, e, "press");
                        }
                      }}
                      triggerValidation={triggerValidation}
                      options={
                        item.additionalOptions &&
                        item.additionalOptions.customOptions &&
                        generateRange(item.additionalOptions.customOptions)
                      }
                      additionalOptions={item.additionalOptions}
                      asyncOptions={asyncOptions[item.name]}
                      defaultValue={
                        item.type === "text" && item.name === "name"
                          ? values[item.name] || initialValue || item.value
                          : values[item.name] || item.value
                      }
                      value={values[item.name] || item.value}
                      error={errors[item.name] || additionalErrors[item.name]}
                      submitTrigger={submitTrigger}
                      formSchema={schema}
                      setCustomValid={setCustomValid}
                      values={values}
                      rules={item.rules || []}
                      relation={keyStr}
                      asyncValidate={item?.asyncValidate}
                      formName={formName}
                      onAsyncSuccess={onAsyncSuccess}
                      onAsyncFailure={onAsyncFailure}
                      groups={item.groups}
                      groupId={item.group_id}
                      disable_toggle_to_NO={disableToggleDecider({
                        values,
                        current_item: item,
                        special_conditions:
                          item?.additionalOptions?.special_conditions,
                      })}
                      {...item.additionalOptions}
                    />
                  </Wrapper>
                )}
                {item.type === "custom_toggle" &&
                  renderField({
                    item,
                    currentFormValues: values,
                    proposalData,
                  }) && <HR />}
              </>
            );
          }
        })}
    </>
  );
};
export default FormBuilder;
export const HR = styled.hr`
  border-top: 0;
  border-bottom: 2px dashed #ddd;
  width: 100%;

  margin-top: 0px;
  @media (max-width: 767px) {
    margin-left: 0px;
  }
`;
const Wrapper = styled.div`
  width: ${props => (props.width ? props.width : "25%")};
  display: inline-block;
  padding-left: ${props => (props.medical ? "0px" : "15px")};
  padding-right: 15px;
  margin-bottom: ${props => props.mb || "10px"};
  @media (max-width: 1023px) {
    width: 100%;
    padding-left: 8px;
    padding-right: 8px;
  }
`;

const CustomWrapper = styled.div`
  /* box-shadow: #e2e3ed 0px 6px 12px !important; */
  margin-left: 30px;
  padding: 16px 6px 0px;
  border-radius: 6px;
  margin-top: 0px;
  width: 100%;
  margin-bottom: 18px;
  @media (max-width: 767px) {
    width: calc(100% - 17px);
    margin-left: 8px;

    box-shadow: unset !important;
    & input,
    & select {
      background-color: #fff;
    }
  }
  .fields {
    display: flex;
    flex-wrap: wrap;
  }
`;
