// Styles used in FileInput2.jsx component
import { FWEIGHT, ROUNDED } from "src/styles/styleGuide";
import styled from "styled-components/macro";

export const InputContainer = styled.label`
  margin-top: 0.3rem !important;
  position: relative;
  width: 100%;
  margin-bottom: 2px !important;
  border: ${props => {
    return props.error ? "1px solid  #c7222a" : "1px solid  #ced4da";
  }};
  box-sizing: border-box;
  border-radius: ${ROUNDED.base};
  cursor: ${props => {
    return props.disable ? "not-allowed" : "pointer";
  }};

  .label_style {
    display: flex;
    align-items: center;
  }
  .label_name {
    margin-left: 4px;
  }
`;

export const InnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const InnerTextStyled = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  color: grey;
  padding: 0 12px;
  font-size: 0.875rem;
`;

export const BrowseButton = styled.div`
  font-size: 0.875rem;
  width: 30%;
  color: ${props => {
    return props.disable ? "grey" : "#fff";
  }};
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  padding: 12px 0;
  border-top-right-radius: ${ROUNDED.base};
  border-bottom-right-radius: ${ROUNDED.base};
  background: ${props => {
    return props.disable ? "lightgrey" : props.primaryColor;
  }};

  @media (min-width: 768px) and (max-width: 1050px) {
    font-size: 14px;
  }

  :hover {
    background-image: ${props => {
      return (
        !props.disable &&
        "linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1))"
      );
    }};
  }
`;

export const Label = styled.label`
  display: flex;
  text-align: left;
  font-size: 13px !important;
  color: #000;
  position: absolute;
  left: 8px;
  top: 0;
  margin: 0;
  background: #fff;
  transition: all 0.3s ease-in-out;
  font-weight: ${FWEIGHT.semi};
  transform: translateY(-50%);
  padding: 0 4px;
  max-width: 95%;

  ${({ checkValidation }) => {
    return checkValidation?.required
      ? "&::after {  content: '*'; color: red;}"
      : "";
  }}
`;
