import Select, { components } from "react-select";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import styled from "styled-components";
import FHTypography from "src/styles/Typography";
import { useTheme } from "src/customHooks";
import { ROUNDED, SHADES, SHADOW } from "src/styles/styleGuide";
import { stringLengthCutter } from "src/utils/string.utility";

const MultiSelectDropdown = ({
  label,
  options = {},
  limit = 4,
  onChange = () => {},
  value = [],
  error,
  checkValidation = {},
}) => {
  // this values is use for react select
  const [values, setValues] = useState(
    () => value.map(item => ({ label: item, value: item })) || [],
  );

  const [selectedValues, setSelectedValues] = useState(() => {
    return value || [];
  });

  //hooks
  const { colors } = useTheme();

  const handleChange = val => {
    if (val.length === limit) {
      toast.error(`you can add upto ${limit}`);
      return;
    }
    setValues(val);
    setSelectedValues(val.map(item => item.value));
  };
  const formattedOptions = Object.keys(options).map(key => ({
    label: options[key],
    value: key,
  }));

  useEffect(() => {
    onChange(
      {
        target: { value: selectedValues },
      },
      selectedValues,
    );
  }, [JSON.stringify(selectedValues)]);
  return (
    <>
      <SelectContainer>
        <Select
          isMulti
          components={{ Option, MultiValue }}
          value={values}
          options={formattedOptions}
          onChange={(val, evt) => handleChange(val, evt)}
          hideSelectedOptions={false}
          styles={{
            menu: provided => ({
              ...provided,
              borderRadius: ROUNDED.md,
              border: `1px solid ${SHADES.LIGHT_GRAY}`,
              overflow: "hidden",
              padding: "0.125rem 0",
              boxShadow: SHADOW.lg,
            }),
            option: provided => ({
              ...provided,
              padding: "0.75rem",
              textAlign: "left",
              display: "flex",
              gap: "0.5rem",
              cursor: "pointer",
              backgroundColor: "#fff",
              color: "inherit",
              fontSize: "0.7rem",
              ":active": {
                backgroundColor: colors.primary_shade,
              },
              ":hover": {
                backgroundColor: colors.primary_shade,
              },
            }),

            control: base => ({
              ...base,
              boxShadow: "none",
              width: "100%",
              height: "40px",
              padding: "0.1rem 0.5rem",
            }),
            placeholder: provided => ({
              ...provided,
              fontSize: "0.7rem",
            }),
            container: provided => ({
              ...provided,
              width: "100%",
            }),
            indicatorSeparator: provided => ({
              ...provided,
              display: "none",
            }),
            multiValue: (provided, state) => ({
              ...provided,
              backgroundColor: colors.primary_color,
              color: "#fff",
              width: "fit-content",
              display: "flex",
              borderRadius: ROUNDED.xl,
              fontSize: "0.65rem",
              paddingRight: state.index > 0 ? "0.25rem" : "inherit",
            }),
            multiValueLabel: provided => ({
              ...provided,
              color: "#fff",
            }),

            multiValueRemove: (provided, state) => ({
              ...provided,
              ":hover": {
                background: "none",
              },
              display: state.index > 0 ? "none" : "inherit",
            }),
            valueContainer: provided => ({
              ...provided,
              padding: "0",
              flexDirection: "row",
              cursor: "pointer !important",
            }),
            dropdownIndicator: (provided, state) => ({
              ...provided,
              padding: 0,
              color: "var(--body_color)",
              cursor: "pointer",
              fontSize: "12px",
              transition: "all .2s ease",
              transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
            }),
            clearIndicator: (provided, state) => ({
              ...provided,
              display: state.selectProps.value.length > 1 ? "block" : "none",
            }),
            input: provided => ({
              ...provided,
              caretColor: "transparent",
              cursor: "pointer",
              padding: 0,
              margin: 0,
            }),
          }}
        />
        <Label mandatory={checkValidation?.required === true}>{label}</Label>
        {error && <p className="formbuilder__error">{error}</p>}
      </SelectContainer>
    </>
  );
};

export default MultiSelectDropdown;

const SelectContainer = styled.div`
  margin-top: ${props => {
    return !props.height ? "0.3rem !important" : "9px !important";
  }};
  position: relative;

  margin-bottom: ${props => {
    return !props.height ? "12px !important" : "9px !important";
  }};

  @media (max-width: 767px) {
    margin-bottom: 12px !important;
    margin-top: ${props => !props.height && "15px !important"};
  }

  & > h6 {
    font-size: 0.5rem;
  }
`;
const Label = styled(FHTypography).attrs(() => ({
  variant: "body1",
  weight: "md",
}))`
  display: inline-block;
  color: #000;
  position: absolute;
  left: 8px;
  top: 0;
  margin: 0;
  background: #fff;
  transform: translateY(-50%);
  padding: 0 4px;
  font-size: 13px;
  font-weight: 600;

  ${({ mandatory }) => {
    return mandatory ? "&::after {  content: '*'; color: red; }" : "";
  }}
`;
const Input = styled.input`
  cursor: pointer;
`;

const Option = props => {
  return (
    <components.Option {...props}>
      <Input type="checkbox" checked={props.isSelected} />
      <FHTypography variant="subheading1" weight="md" colorType="heading">
        {props.children}
      </FHTypography>
    </components.Option>
  );
};

const MultiValue = props => {
  const multiSelectCount = props.selectProps.value.length - 1;
  if (props.index === 0) {
    return (
      <components.MultiValue {...props}>
        {stringLengthCutter(props.children, 4)}
      </components.MultiValue>
    );
  } else if (multiSelectCount && props.index === 1) {
    return (
      <components.MultiValue {...props}>
        +{multiSelectCount} more
      </components.MultiValue>
    );
  } else {
    return <></>;
  }
};
