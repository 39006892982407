// TRY ADDING THE SCROLL TOP BEHAVIOR

import { memo } from "react";
import { FloatingLabel, Form } from "react-bootstrap";
import { AiOutlineCloseCircle } from "react-icons/ai";
import styled from "styled-components/macro";
import { useTheme } from "../customHooks";
import { FWEIGHT } from "src/styles/styleGuide";
const TextInput = ({
  label,
  type,
  placeholder,
  value,
  clear,
  autoComplete,
  styledCss,
  onChange,
  onPaste,
  inputRef,
  onClick = () => {},
  onKeyDown,
  onBlur,
  maxLength,
  onKeyPress,
  name,
  id,
}) => {
  const { colors } = useTheme();

  return (
    <TextInputContainer styledCss={styledCss} colors={colors}>
      <FloatingLabelStyled label={label}>
        <label className="visually-hidden" htmlFor={id}>
          {label}
        </label>
        <Form.Control
          ref={inputRef}
          autoComplete={autoComplete}
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onPaste={onPaste}
          onClick={onClick}
          onCopy={onPaste}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
          maxLength={maxLength}
          onKeyPress={onKeyPress}
          name={name}
          id={id}
        />
      </FloatingLabelStyled>
      {value?.length > 1 && clear && (
        <span onClick={clear}>
          <AiOutlineCloseCircle />
        </span>
      )}
    </TextInputContainer>
  );
};

TextInput.defaultProps = {
  type: "text",
  label: "text",
  placeholder: "text",
};

const FloatingLabelStyled = styled(FloatingLabel)`
  z-index: 0;
`;

const TextInputContainer = styled.div`
  position: relative;

  & span {
    position: absolute;
    top: 11px;
    font-size: 28px;
    color: ${({ colors }) => colors.primary_color};
    right: 14px;
    cursor: pointer;
    & img {
      height: 34px;
    }
  }
  & input {
    padding-top: 41px !important;
    padding-bottom: 22px !important;
    font-weight: ${FWEIGHT.semi};
  }
  & label {
    padding: 19px 0.75rem;
    color: #505b6d;
  }
  ${({ styledCss }) => styledCss}
`;

export default TextInput;
export const MemoizedTextInput = memo(TextInput);
