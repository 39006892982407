import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { api, useCallApiMutation } from "src/api/api";
import GroupOTPVerificationModal from "src/components/GroupOTPVerificationModal/GroupOTPVerificationModal";
import {
  setFormRenderingModal,
  setShowErrorPopup,
  setShowGroupOTPVerificationModal,
} from "../../ProposalSections.slice";
import ErrorPopup from "../ErrorPopup";
import BMI from "./BMI";
import CartRefetchLoopModal from "./CartRefetchLoopModal";
import NSTP from "./NSTP";
import PlanUnavailable from "./PlanUnavailable";
import ProposalRefetchConfirmationModal from "./ProposalRefetchConfirmationModal";
import ProposalRefetchModal from "./ProposalRefetchModal";
import FormRenderingModal from "src/components/FormRenderingModal/FormRenderingModal";
import useUrlQuery from "src/customHooks/useUrlQuery";
import {
  useLocation,
  useHistory,
} from "react-router-dom/cjs/react-router-dom.min";
import { useEffect } from "react";
import useInfoPopup from "src/components/Common/Popups/InfoPopup/useInfoPopup";

const PopupUtility = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { showErrorPopup } = useSelector(state => state.proposalPage);
  const [triggerMutation, triggerMutationQuery] = useCallApiMutation();
  const queryStrings = useUrlQuery();
  const enquiryId = queryStrings.get("enquiryId");
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const error_popup_msg = params?.get("error_popup_msg");
  const { handleOpenInfoPopup } = useInfoPopup();

  //* --------GroupOTPVerificationModal-----------------------------------------------------
  const closeGroupOTPVerificationModal = () => {
    dispatch(setShowGroupOTPVerificationModal(false));
  };
  const onOTPVerify = async data => {
    try {
      const response = await triggerMutation({
        url: "companies/health_insurances/proposals",
        body: data,
        method: "POST",
      });
      if (response?.error) {
        throw response?.error;
      }

      dispatch(api.util.resetApiState());
      history.push("/proposal_summary?enquiryId=" + enquiryId);

      closeGroupOTPVerificationModal();
    } catch (error) {
      toast.error("Failed to verify the otp.");
      dispatch(
        setShowErrorPopup({ show: true, head: "", msg: error?.data?.message }),
      );
      //dispatch(setFormRenderingModal({ visible: true, json: {} }));
      closeGroupOTPVerificationModal();
    }
  };
  //* --------------------------------------------------------------------------------------

  //* --------FormRenderingModal-----------------------------------------------------
  const closeFormRenderingModal = () => {
    dispatch(setFormRenderingModal({ visible: false, json: {} }));
  };
  const onFormSubmit = async data => {
    try {
      const response = await triggerMutation({
        url: "companies/health_insurances/proposals",
        body: data,
        method: "POST",
      });
      if (response?.error) {
        throw response?.error;
      }

      dispatch(api.util.resetApiState());
      history.push("/proposal_summary?enquiryId=" + enquiryId);

      closeFormRenderingModal();
    } catch (error) {
      toast.error("Failed to verify the details.");
    }
  };
  //* -------------------------------------------------------------------------------

  useEffect(() => {
    if (error_popup_msg) {
      handleOpenInfoPopup({
        title: "",
        description: error_popup_msg,
        hideCloseButton: true,
        buttonText: "Okay",
      });
    }
    return () => {};
  }, [error_popup_msg]);

  return (
    <>
      <GroupOTPVerificationModal
        onClose={closeGroupOTPVerificationModal}
        otpVerificationQuery={triggerMutationQuery}
        onOTPVerify={onOTPVerify}
      />
      <FormRenderingModal
        title="Please provide the following details."
        onClose={closeFormRenderingModal}
        formSubmitQuery={triggerMutationQuery}
        onFormSubmit={onFormSubmit}
      />
      <PlanUnavailable />
      <NSTP />
      <BMI />
      <ProposalRefetchModal />
      <ProposalRefetchConfirmationModal />
      <CartRefetchLoopModal title="Fetching KYC Status" />

      {/* //TODO => Make the state to accessible inside the popup.  */}
      {showErrorPopup.show ? (
        <ErrorPopup
          show={showErrorPopup.show}
          head={showErrorPopup.head}
          msg={showErrorPopup.msg}
          htmlProps={showErrorPopup.htmlProps}
          showQuotesBtn={showErrorPopup?.showQuotesBtn}
          tataAigCKYCDetailsNotMatching={
            showErrorPopup?.tataAigCKYCDetailsNotMatching
          }
          handleClose={() => {
            dispatch(
              setShowErrorPopup({
                show: false,
                head: "",
                msg: "",
              }),
            );
            showErrorPopup?.handleClose && showErrorPopup?.handleClose();
          }}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default PopupUtility;
