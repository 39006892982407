import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  useCreateEnquiry,
  useMembers,
  useRevisedPremiumModal,
  useTheme,
  useUpdateGroupMembers,
} from "src/customHooks";
import {
  useFrontendBoot,
  useGetEnquiry,
} from "src/customHooks/useGetCommonQueries";
import useGetRouteMatch from "src/customHooks/useGetRouteMatch";
import { addToSelectedMembersAction } from "src/pages/InputPage/greetingPage.slice";
import { ageStringToYear, getGlobalAgeString } from "src/utils/string.utility";
import { getUrlQueries } from "src/utils/url.utility";
import {
  countMultiSelectMember,
  getChildCount,
  getLastMultiMemberIndex,
  getOrderedMultiSelectMembers,
  isMemberSelected,
} from "../addMembers.utility";
import useAddMembers from "./useAddMembers";
import { api, useUpdateGroupsMutation } from "src/api/api";
import toast from "react-hot-toast";

function useAddMemberModal() {
  const { settings } = useFrontendBoot();
  const { colors } = useTheme();
  const history = useHistory();
  const { enquiryId } = getUrlQueries();
  const { isInputMembersRoute, isQuoteRoute, isProductDetailsRoute } =
    useGetRouteMatch();
  const { input, enquiryData, groupCode, currentGroupObject } = useGetEnquiry();
  const { getGroupMembers } = useMembers();
  const [createEnquiry, createEnquiryQueryResponse] = useCreateEnquiry();
  const [updateGroups] = useUpdateGroupsMutation();
  const dispatch = useDispatch();
  const {
    allMembersList,
    displayMembersList,
    selectedMembers,
    handleUpdateDisplayMembersList,
  } = useAddMembers();

  const { updateGroupMembers, query: updateMemberQueryResponse } =
    useUpdateGroupMembers(groupCode);

  const { getUpdatedCart } = useRevisedPremiumModal();

  // STATE/s
  const [selectedMembersModal, setSelectedMembersModal] = useState([]);
  const [membersErrorModal, setMembersErrorModal] = useState(false);
  const [memberErrorMessageModal, setMemberErrorMessageModal] = useState("");
  const [allMembersListModal, setAllMembersListModal] = useState([]);
  const [editStep, setEditStepValue] = useState(1);

  //EFFECT/s ====
  // This will preselect all the selected members over input pages modal
  useEffect(() => {
    if (!allMembersListModal.length && isInputMembersRoute) {
      const noRegex = /^[A-Za-z]+$/;
      const multiMembers = selectedMembers?.filter(
        memberUnit => !noRegex.test(memberUnit.code),
      );
      if (multiMembers.length) {
        const newALLMembersList = getOrderedMultiSelectMembers({
          multiMembers,
          allMembersList,
        });
        setAllMembersListModal(newALLMembersList);
      } else {
        setAllMembersListModal(allMembersList);
      }

      // update display members list with selected members
      if (selectedMembers.length) {
        setSelectedMembersModal(selectedMembers);
      }
    }

    return () => {};
  }, [displayMembersList, selectedMembers]);

  // On quotes page when we click on other members button
  // then we pre-set all the already selected members from enquiry data
  useEffect(() => {
    const noRegex = /^[A-Za-z]+$/;
    if (isQuoteRoute && input) {
      const multiMembers = input.members?.filter(
        memberUnit => !noRegex.test(memberUnit.code),
      );

      if (multiMembers.length) {
        const newALLMembersList = getOrderedMultiSelectMembers({
          multiMembers,
          allMembersList,
        });

        setAllMembersListModal(newALLMembersList);
      } else {
        setAllMembersListModal(allMembersList);
      }

      const mappedMembers = input.members.map(memberUnit => {
        return {
          code: memberUnit.type,
          ageString: getGlobalAgeString(memberUnit.age, true),
          ...memberUnit,
        };
      });
      setSelectedMembersModal(mappedMembers || []);
    }

    return () => {};
  }, [input]);

  // populate selected members for updating on product details page
  useEffect(() => {
    if (isProductDetailsRoute && input) {
      const currentMembers = getGroupMembers(groupCode);
      const memberCodes = currentMembers.map(member => member.code);
      const membersToAdd = input.members.filter(member =>
        memberCodes.includes(member.type),
      );
      const orderedMembers = reorderFamilyMembers(membersToAdd);
      setAllMembersListModal(orderedMembers);
      setSelectedMembersModal(input.members);
    }
    return () => {};
  }, [input]);

  // FUNCTION/s ========================

  // re order child members
  function reorderFamilyMembers(members) {
    let sonCount = 1;
    let daughterCount = 1;

    return members.map(member => {
      let newMember = { ...member }; // Create a new object to avoid read-only error
      if (newMember.code.startsWith("son")) {
        newMember.display_name = sonCount === 1 ? "Son" : "Son " + sonCount;
        sonCount++;
      } else if (newMember.code.startsWith("daughter")) {
        newMember.display_name =
          daughterCount === 1 ? "Daughter" : "Daughter " + daughterCount;
        daughterCount++;
      }
      return newMember;
    });
  }
  // function that handles count of multi select members
  // and display them according to there count
  function handleMultiSelectMemberModal({
    member,
    actionType,
    reAlignMembers = false,
  }) {
    let currentMember = member;
    if (reAlignMembers === true) {
      currentMember = allMembersListModal.find(memberUnit =>
        member.code.includes(memberUnit.code),
      );
    }

    // retrieve the count of multi select members for base-member
    const memberCount = countMultiSelectMember({
      member: currentMember,
      allMembersList: allMembersListModal,
    });

    // retrieve the index of last multi member like (son3)
    const lastMultiMemberIndex = getLastMultiMemberIndex({
      membersList: allMembersListModal,
      baseMember: currentMember,
      memberCount: memberCount,
    });

    const childCount = getChildCount({ membersList: selectedMembersModal });
    const newArray = [...allMembersListModal];

    // perform add multi member or remove multi member
    // according to action type
    if (actionType === "add") {
      if (childCount.length >= settings.broker_wise_max_child_count) {
        toast("Max child count reached!", {
          id: "max-child-count",
          position: "top-center",
          style: {
            background: colors.primary_color,
            color: "#fefefe",
          },
        });
        setMembersErrorModal(true);
        return;
      }
      newArray.splice(lastMultiMemberIndex + 1, 0, {
        ...currentMember,
        code: `${currentMember.code}${memberCount + 1}`,
        display_name: `${currentMember.display_name} ${memberCount + 1}`,
      });

      // this will preselect member in modal
      handleAddMemberModal({
        displayName: `${currentMember.display_name} ${memberCount + 1}`,
        memberType: `${currentMember.code}${memberCount + 1}`,
        age: "",
        max_age: currentMember.max_age,
        min_age: currentMember.min_age,
        is_primary: currentMember.is_primary,
      });
      setAllMembersListModal(newArray);
    }
    if (actionType === "remove") {
      setMembersErrorModal(false);
      if (memberCount === 1) return;
      newArray.splice(lastMultiMemberIndex, 1);
      setAllMembersListModal(newArray);
      // update the state of selected members
      const updatedSelectedMembers = selectedMembersModal.filter(
        memberUnit =>
          memberUnit.code !==
          `${currentMember.code}${memberCount > 1 ? memberCount : ""}`,
      );
      setSelectedMembersModal(updatedSelectedMembers);
    }
  }

  function checkForAnyChange() {
    if (selectedMembersModal.length !== input.members.length) {
      return true;
    }

    const anyChange = selectedMembersModal.find((memberUnit, index) => {
      if (
        memberUnit.age !== input.members[index].age ||
        memberUnit.code !== input.members[index].code ||
        memberUnit.days !== input.members[index].days
      ) {
        return true;
      }
    });
    return anyChange;
  }

  // When we click on agg drop down of any insure member
  // this function will be called
  const handleAddMemberModal = ({
    memberType,
    age,
    max_age,
    min_age,
    displayName,
    displayCal = false,
    days = 0,
  }) => {
    setMembersErrorModal(false);
    const memberObject = {
      ageString: age,
      age: ageStringToYear(age),
      type: memberType,
      days: days,
      code: memberType,
      display_name: displayName,
      display_calender: displayCal,
      max_age,
      min_age,
    };
    // get child count in add member modal
    const childCount = getChildCount({ membersList: selectedMembersModal });

    // this will update the location if no new group created
    if (isQuoteRoute) {
      memberObject.new_member = true;
    }
    // As required in backend we need to change son to son1
    if (memberType === "son") {
      memberObject.type = "son1";
    }
    if (memberType === "daughter") {
      memberObject.type = "daughter1";
    }
    // throw toast msg if child length is 6 and add daughter and son

    if (
      isMemberSelected({ memberType, selectedMembers: selectedMembersModal })
    ) {
      const updatedSelectedMembers = selectedMembersModal.filter(
        member => member.code !== memberType,
      );

      setSelectedMembersModal([...updatedSelectedMembers, memberObject]);
      return;
    }
    if (childCount.length >= settings.broker_wise_max_child_count) {
      if (memberType === "daughter" || memberType === "son") {
        toast("Max child count reached!", {
          id: "max-child-count",
          position: "top-center",
          style: {
            background: colors.primary_color,
            color: "#fefefe",
          },
        });
        return;
      }
    }
    setSelectedMembersModal([...selectedMembersModal, memberObject]);
  };

  // It will remove member from selected members list when toggled
  // through check box
  function removeMemberModal({ memberType, multiRemove = false }) {
    setMembersErrorModal(false);
    if (multiRemove === true) {
      const updatedDisplayMemberList = allMembersListModal.filter(
        memberUnit => memberUnit.code !== memberType,
      );

      setAllMembersListModal(updatedDisplayMemberList);
    }
    const updatedSelectedMembers = selectedMembersModal.filter(
      memberUnit => memberUnit.code !== memberType,
    );
    setSelectedMembersModal(updatedSelectedMembers);
  }

  // function that check if any member is selected or having empty age to display error
  function checkEmptyAgeMembersOnModal() {
    const nonAgedMembers = selectedMembersModal.filter(
      member => member.age === "",
    );

    if (nonAgedMembers.length) {
      setMemberErrorMessageModal("Select age for Insured Member !");
      setMembersErrorModal(true);
      return false;
    }

    // Extra validation where don't allow child without self
    // any change should be performed in useAddMembers.js
    // coz of duplicate c
    const isChildSelected = selectedMembersModal.find(
      member => member.code.includes("son") || member.code.includes("daughter"),
    );
    const isSelfOrSpouseSelected = selectedMembersModal.find(
      member => member.code === "self" || member.code === "spouse",
    );
    if (isChildSelected && !isSelfOrSpouseSelected) {
      setMemberErrorMessageModal("Please select self or spouse!");
      setMembersErrorModal(true);

      return false;
    }

    return true;
  }

  // function that will be called when we click on modal apply button
  // over input members page
  function handleSubmitMembersModal({ onClose }) {
    // check if any member is selected or having empty age to display error
    if (!checkEmptyAgeMembersOnModal()) {
      return;
    }
    const updatedNewMembers = allMembersListModal.filter(member => {
      const memberData = selectedMembersModal.find(
        selectedMember => selectedMember.code === member.code,
      );
      if (memberData) {
        return member;
      }
    });

    dispatch(addToSelectedMembersAction([...selectedMembersModal]));
    handleUpdateDisplayMembersList({ updatedList: updatedNewMembers });
    onClose();
  }

  // function that will be called when we click on modal apply button
  // over quotes edit  members page
  async function handleApplyMembersEdit({ onClose }) {
    if (!checkEmptyAgeMembersOnModal()) {
      return;
    }

    if (!selectedMembersModal.length) {
      setMemberErrorMessageModal("Select at least one Insured Member!");
      setMembersErrorModal(true);
      return;
    }

    if (!checkForAnyChange()) {
      onClose();
      return;
    }

    // Resetting plan_type against selected members count
    // if 1 => "I" otherwise default is "M"
    // get previous selection if currentGroup is not individual
    const getPlantype =
      selectedMembersModal.length > 1
        ? currentGroupObject.plan_type === "I"
          ? "F"
          : currentGroupObject.plan_type
        : "I";

    let createEnquiryObject = {
      enquiryId: enquiryId,
      email: enquiryData?.email,
      mobile: enquiryData?.mobile,
      name: enquiryData?.name,
      members: selectedMembersModal,
      params: input?.params,
      section: enquiryData?.section,
      gender: input.gender,
      pincode: input.pincode,
      plan_type: getPlantype,
      groups: enquiryData?.groups,
      updateCache: false,
    };

    if (isQuoteRoute && window.location.origin.includes("local")) {
      createEnquiryObject = {
        ...createEnquiryObject,
        type: "port",
      };
    }

    const response = await createEnquiry(createEnquiryObject);

    if (response.error) {
      const errorMessage =
        response.error?.data?.errors?.please_note[0] ||
        "Something went wrong !";
      setMemberErrorMessageModal(errorMessage);
      setMembersErrorModal(true);
      return;
    }

    // find group with type all
    const groupTypeAll = response?.data?.data.groups.find(
      group => group.type === "all",
    );

    // if group type all exist with no pincode set
    if (groupTypeAll && !groupTypeAll.pincode) {
      updateGroups({
        groupCode: groupTypeAll.id,
        pincode: response?.data?.data.groups[0].pincode,
      });
    }

    // groups other than type all
    const anyGroupWithoutPincode = response?.data?.data.groups.find(
      group => !group.pincode && group.type !== "all",
    );
    if (anyGroupWithoutPincode) {
      history.push(
        `/quotes/${groupCode}?enquiryId=${response.data.data.enquiry_id}`,
      );
      setEditStepValue(2);
      return;
    }

    if (response.data) {
      const firstGroupObject = response.data.data.groups[0].id;
      onClose();
      history.push(
        `/quotes/${firstGroupObject}?enquiryId=${response.data.data.enquiry_id}`,
      );
    }
  }

  // function that will be called when we click on modal apply button
  // on product details Edit members modal
  function handleUpdateMembersEdit({ onClose }) {
    const updatedSelectedMembers = selectedMembersModal.map(member => {
      if (member.ageString === "Below 3 months" && member.days > 0) {
        return {
          ...member,
          age: 0,
        };
      }
      return member;
    });

    updateGroupMembers(updatedSelectedMembers).then(res => {
      if (res?.error) {
        const updateError = Object.values(res.error?.data?.errors || {});
        setMemberErrorMessageModal(updateError);
        setMembersErrorModal(true);
        return;
      }
      dispatch(api.util.invalidateTags(["custom_quotes"]));
      onClose();
      getUpdatedCart();
    });
  }

  //function to update edit step
  function setEditStep(step) {
    if (step === 3) {
      if (!checkForAnyChange()) {
        setEditStepValue(3);
      }
    } else {
      setEditStepValue(step);
    }
  }

  return {
    allMembersList,
    allMembersListModal,
    editStep,
    selectedMembersModal,
    memberErrorMessageModal,
    membersErrorModal,
    createEnquiryQueryResponse,
    updateMemberQueryResponse,

    setEditStep,
    handleAddMemberModal,
    setSelectedMembersModal,
    removeMemberModal,
    handleSubmitMembersModal,
    handleApplyMembersEdit,
    handleMultiSelectMemberModal,
    handleUpdateMembersEdit,
  };
}

export default useAddMemberModal;
