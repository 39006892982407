import ReactSelect from "react-select";
import { MemberTileStyled, MembersListStyled } from "../addMembersForm.styled";

import { Fragment, useRef } from "react";
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from "react-icons/ai";
import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";
import { useTheme } from "src/customHooks";
import FHTypography from "src/styles/Typography";
import { SHADES } from "src/styles/styleGuide";
import ChildDatePicker from "./DatePicker";
import {
  ageDropDownStyle,
  countMultiSelectMember,
  getMemberAgeArray,
  isMemberError,
  isMemberMultiCount,
  isMemberMultiSelect,
  isMemberSelected,
  retrieveMemberData,
  validateMember,
} from "../addMembers.utility";
import useGetRouteMatch from "src/customHooks/useGetRouteMatch";
import { useGetEnquiry } from "src/customHooks/useGetCommonQueries";

function MembersList({
  selectedMembers,
  membersList,
  handleAddMember,
  removeMember,
  handleMultiSelectMember,
  isModalList = false,
}) {
  const { colors } = useTheme();
  const { isProductDetailsRoute } = useGetRouteMatch();
  const { input } = useGetEnquiry();
  const inputRef = useRef([]);

  //FUNCTION/s
  const toggleMemberSelect = (member, memberIndex) => {
    if (isMemberSelected({ memberType: member.code, selectedMembers })) {
      const isMemberWithCount = isMemberMultiCount({ member });
      if (isMemberWithCount) {
        handleMultiSelectMember({
          member,
          actionType: "remove",
          reAlignMembers: true,
        });
        return;
      }
      removeMember({ memberType: member.code });
    } else {
      handleAddMember({
        memberType: member.code,
        age: "",
        displayName: member.display_name,
        min_age: member.min_age,
        max_age: member.max_age,
        is_primary: member.is_primary,
      });
      inputRef.current[memberIndex].focus();
    }
  };

  return (
    <MembersListStyled
      isModalList={isModalList}
      isProductDetailsRoute={isProductDetailsRoute}
    >
      {membersList.map((member, memberIndex) => {
        const { isBlocked, min_age } = validateMember({
          member,
          selectedMembers,
          selfGender: input?.gender || "M",
        });
        const memberAgeArray = getMemberAgeArray({
          member: { ...member, min_age },
        });
        const isMultiSelect = isMemberMultiSelect({ member });

        const isSelected = isMemberSelected({
          memberType: member.code,
          selectedMembers,
        });

        const selectedMemberUnit = selectedMembers.find(memberUnit => {
          return memberUnit.code === member.code;
        });

        const memberCount = countMultiSelectMember({
          member,
          allMembersList: membersList,
        });

        // Return Element with unique key
        return (
          <Fragment key={member.code}>
            <MemberTileStyled
              isError={() =>
                isMemberError({ memberType: member.code, selectedMembers })
              }
              isBlocked={isBlocked}
              id={`auto_${member.code}`}
            >
              <section className="memberTile_data">
                <button
                  disabled={isMultiSelect && memberCount !== 1}
                  onClick={() => toggleMemberSelect(member, memberIndex)}
                >
                  {isProductDetailsRoute ? null : isSelected ? (
                    <MdCheckBox size={24} color={colors.primary_color} />
                  ) : (
                    <MdCheckBoxOutlineBlank
                      size={24}
                      color={SHADES.SUB_HEADING}
                    />
                  )}
                </button>

                <FHTypography
                  variant="subheading1"
                  weight="semi"
                  colorType="subHeading"
                >
                  {member.display_name}
                </FHTypography>
              </section>
              {!isProductDetailsRoute && isMultiSelect && isSelected && (
                <section className="memberTile_multiple">
                  {memberCount !== 1 && (
                    <span
                      className="multiple_button"
                      onClick={() => {
                        handleMultiSelectMember({
                          member,
                          actionType: "remove",
                        });
                      }}
                    >
                      <AiOutlineMinusCircle size={18} />
                    </span>
                  )}

                  <FHTypography
                    variant="body1"
                    weight="semi"
                    colorType="subHeading"
                    className="multiple_count_member"
                  >
                    {memberCount}
                  </FHTypography>
                  <span
                    className="multiple_button"
                    onClick={() => {
                      handleMultiSelectMember({ member, actionType: "add" });
                    }}
                  >
                    <AiOutlinePlusCircle size={18} />
                  </span>
                </section>
              )}

              <section className="memberTile_action">
                <ReactSelect
                  ref={ref => (inputRef.current[memberIndex] = ref)}
                  id="coverDrop"
                  isSearchable={true}
                  placeholder="Select Age"
                  options={memberAgeArray?.map(ageCount => ({
                    value: ageCount,
                    label: ageCount,
                  }))}
                  menuPosition="fixed"
                  onChange={e => {
                    handleAddMember({
                      memberType: member.code,
                      age: e.value,
                      displayName: member.display_name,
                      displayCal: e.value === "Below 3 months" && true,
                      min_age: member.min_age,
                      max_age: member.max_age,
                      is_primary: member.is_primary,
                    });
                  }}
                  value={{
                    value:
                      retrieveMemberData({
                        memberType: member.code,
                        selectedMembers,
                      })?.ageString || "Select Age",
                    label:
                      retrieveMemberData({
                        memberType: member.code,
                        selectedMembers,
                      })?.ageString || "Select Age",
                  }}
                  theme={theme => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: colors.primary_color,
                      primary25: colors.primary_shade,
                    },
                  })}
                  styles={ageDropDownStyle("D")}
                />
              </section>
            </MemberTileStyled>
            {selectedMemberUnit?.display_calender && (
              <MemberTileStyled
                isError={() =>
                  isMemberError({ memberType: member.code, selectedMembers })
                }
              >
                <section className="memberTile_data">
                  <FHTypography
                    variant="subheading1"
                    weight="semi"
                    colorType="subHeading"
                  >
                    {member.display_name}&#39;s DOB:
                  </FHTypography>
                </section>

                <section>
                  <ChildDatePicker
                    member={selectedMemberUnit}
                    updateMember={handleAddMember}
                  />
                </section>
              </MemberTileStyled>
            )}
          </Fragment>
        );
      })}
    </MembersListStyled>
  );
}

export default MembersList;
