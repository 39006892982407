import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { validNomineeRelationGenerator } from "../../../components/FormBuilder/formUtils";

const useOtherDetails = ({
  defaultValue = {},
  setValues,
  name,
  insuredDetails,
  proposalDetails,
}) => {
  const [nomineeRelationAutoPopulated, setNomineeRelationAutoPopulated] =
    useState(false);

  let memberGroupsAsPerMembers = useSelector(({ greetingPage }) =>
    greetingPage.proposerDetails.groups.reduce(
      (acc, { id, members }) => ({
        ...acc,
        [members.reduce((acc, el) => acc + el, "")]: id,
      }),
      {},
    ),
  );

  useEffect(() => {
    if (name === "Other Details") {
      setValues(prev => ({ ...defaultValue, ...prev }));
    }
    return () => {};
  }, []);

  const autoPopulateSelfOtherDetails = ({
    setValues,
    schema,
    selectedNomineeRelation,
  }) => {
    let nomineeRelation = selectedNomineeRelation;

    let dataForAutoPopulate;
    if (nomineeRelation === "self") {
      dataForAutoPopulate = {
        ...proposalDetails,
        ...(insuredDetails ? insuredDetails["self"] : {}),
      };
    } else if (insuredDetails[nomineeRelation]) {
      dataForAutoPopulate = insuredDetails[nomineeRelation];
      let groupIdOfMember =
        memberGroupsAsPerMembers[
          Object.keys(memberGroupsAsPerMembers).find(key =>
            key.includes(nomineeRelation),
          )
        ];
      let memberDetailsInProposerD = Object.keys(proposalDetails)
        .filter(key => key.includes(groupIdOfMember))
        .reduce((acc, key) => ({ ...acc, [key]: proposalDetails[key] }), {});

      let filledData = Object.keys(defaultValue)
        .filter(key => key.includes(groupIdOfMember))
        .reduce((acc, key) => ({ ...acc, ...defaultValue[key] }), {});

      dataForAutoPopulate = {
        ...memberDetailsInProposerD,
        ...insuredDetails[nomineeRelation],
        ...filledData,
      };
    }

    let acc = {};

    schema.forEach(({ name }) => {
      let nameWithoutNominee = name?.slice(name.indexOf("_") + 1, name.length);
      if (nameWithoutNominee === "contact") nameWithoutNominee = "mobile";
      if (nameWithoutNominee?.includes("address"))
        nameWithoutNominee = Object.keys(dataForAutoPopulate).find(key =>
          key.includes(nameWithoutNominee),
        );
      if (name?.includes("pincode"))
        nameWithoutNominee = Object.keys(dataForAutoPopulate).find(key =>
          key.includes("pincode"),
        );
      if (dataForAutoPopulate[nameWithoutNominee])
        acc[name] = dataForAutoPopulate[nameWithoutNominee];
    });

    setValues(
      {
        ...acc,
        nominee_relation: validNomineeRelationGenerator({
          nominee_relation: nomineeRelation,
          reverseLogic: true,
        }),
      },
      "SAVE_AS_IT_IS",
    );
    setNomineeRelationAutoPopulated(true);
  };

  return {
    nomineeRelationAutoPopulated,
    setNomineeRelationAutoPopulated,
    autoPopulateSelfOtherDetails,
  };
};

export default useOtherDetails;
